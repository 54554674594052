import {
  PayloadAction,
  createSlice,
  createSelector,
  createAction,
} from "@reduxjs/toolkit";
import {
  signIn,
  signOut,
  confirmSignIn,
  fetchAuthSession,
} from "aws-amplify/auth";
import { get } from "lodash-es";

import { login } from "../legacyGraphql/resolvers/mutation/users";
import { OnmoStorage } from "../models/onmoStorage";
import {
  isErrorMaintenance,
  isAccountDisable,
  isErrorTenant,
} from "../models/users/users";
import { APP_URL } from "../constants/onmo";

import { startMaintenance } from "../slices/maintenance";
import { AppThunk, AsyncAppThunk, RootState } from "../store";
import { resetUserState, updatePermissionGroup } from "./user";
import { PageRoutes } from "../constants/historyOnmo";
import { QueryParams } from "../models/queryParam";
import { addPopupQueue } from "./popup";
import { PopupType } from "../constants/popup";

export enum CognitoUserState {
  UNLOADED,
  SIGNIN,
  SIGNOUT,
}
interface ICognitoUser {
  accessToken: null | string | undefined;
  status: CognitoUserState;
  loginState: null | {
    error: null | { message: string };
    loading: boolean;
    isAuthenticated: boolean;
  };
}

const initialState = {
  accessToken: null,
  status: CognitoUserState.UNLOADED,
  loginState: {
    error: null,
    loading: false,
    isAuthenticated: false,
  },
} as ICognitoUser;

export const resetCognitoUserState = createAction("cognitoUser/resetState");

export const cognitoUserSlice = createSlice({
  name: "cognitoUser",
  initialState: initialState,
  reducers: {
    signOut: (state) => {
      state.accessToken = null;
      state.status = CognitoUserState.SIGNOUT;
    },
    signIn: (
      state,
      action: PayloadAction<{
        accessToken: string | undefined;
      }>
    ) => {
      state.loginState = {
        error: null,
        loading: false,
        isAuthenticated: true,
      };
      state.accessToken = action.payload.accessToken;
      state.status = CognitoUserState.SIGNIN;
    },
    onError: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      state.loginState = {
        loading: false,
        isAuthenticated: false,
        error: { message: action.payload.message },
      };
    },
    updateStatus: (
      state,
      action: PayloadAction<{ state: CognitoUserState }>
    ) => {
      state.status = action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetCognitoUserState, () => initialState);
  },
});

const {
  signOut: signoutAction,
  signIn: signInAction,
  updateStatus,
  onError,
} = cognitoUserSlice.actions;

export const amplifySignOut =
  (isSkipAuth = false): AsyncAppThunk =>
  async (dispatch) => {
    if (!isSkipAuth) {
      await signOut();
    }
    OnmoStorage.removeShareOncode();
    dispatch(signoutAction());
    dispatch(resetUserState());
  };

export const currentAuthenticatedUser =
  (): AsyncAppThunk => async (dispatch) => {
    try {
      if (QueryParams.getErrorDescription()) {
        throw new Error(QueryParams.getErrorDescription() || "");
      }
      const url = new URL(window.location.href);
      const session = await fetchAuthSession();

      const loginToken = url.searchParams.get("code");
      const loginPasswordless =
        url.searchParams.get("state") &&
        url.pathname === PageRoutes.SignInProvider;
      if (!session.tokens && !loginToken && !loginPasswordless) {
        throw new Error("First time using the app");
      }
      // Check case login passwordless
      if (!session.tokens && loginPasswordless) {
        dispatch(updateStatus({ state: CognitoUserState.SIGNOUT }));
        return;
      }
      console.log("[cognito] Authenticating user...");
      const oncode = OnmoStorage.getOncode();
      const encryptedOidcState = OnmoStorage.getState();
      const loginRes = await login({ oncode, encryptedOidcState });
      dispatch(
        updatePermissionGroup({
          permissionGroup: loginRes.authUser.permissions,
        })
      );
      OnmoStorage.removeOncode();
      OnmoStorage.removeState();
      if (!OnmoStorage.getUserId()) {
        OnmoStorage.setIsLogin();
      }
      if (
        loginRes.oncodeResponse &&
        (loginRes.oncodeResponse.validationError ||
          loginRes.oncodeResponse.result[0].status !== "SUCCESS")
      ) {
        throw Error("Error on login!");
      }
      console.log("[cognito] User authenticated");
      const redirectUrl = get(
        loginRes,
        "oncodeResponse.result[0].data.redirect"
      );
      if (redirectUrl) {
        OnmoStorage.setDeepLink(`${APP_URL}${redirectUrl}`);
      } else {
        const deepLink = OnmoStorage.getDeepLink();
        if (deepLink) {
          OnmoStorage.removeDeepLink();
          window.location.href = deepLink;
        }
      }
      const currentSession = await fetchAuthSession();
      const accessToken = currentSession.tokens?.accessToken.toString();
      dispatch(
        signInAction({
          accessToken,
        })
      );
    } catch (e) {
      console.error(e);
      if (isAccountDisable((e as Error)?.message)) {
        throw new Error((e as Error)?.message);
      } else if (isErrorTenant(e)) {
        dispatch(addPopupQueue({ type: PopupType.ErrorTenantMismatch }));
      } else if (isErrorMaintenance(e)) {
        dispatch(startMaintenance({ reason: "" }));
      } else {
        dispatch(amplifySignOut());
      }
      OnmoStorage.setDeepLink();
    }
  };

export const amplifySignIn =
  (
    options:
      | { type: "email"; email: string; password: string }
      | { type: "phone"; code: string } // eslint-disable-line @typescript-eslint/no-explicit-any
  ): AsyncAppThunk =>
  async (dispatch) => {
    if (options.type === "email") {
      const { email, password } = options;
      await signIn({ username: email, password });
    } else {
      const { code } = options;
      await confirmSignIn({ challengeResponse: code });
    }
    const oncode = OnmoStorage.getOncode();
    const encryptedOidcState = OnmoStorage.getState();
    const loginRes = await login({ oncode, encryptedOidcState });
    dispatch(
      updatePermissionGroup({
        permissionGroup: loginRes.authUser.permissions,
      })
    );
    OnmoStorage.removeOncode();
    OnmoStorage.removeState();
    if (!OnmoStorage.getUserId()) {
      OnmoStorage.setIsLogin();
    }
    if (
      loginRes.oncodeResponse &&
      (loginRes.oncodeResponse.validationError ||
        loginRes.oncodeResponse.result[0].status !== "SUCCESS")
    ) {
      throw Error("Error on login!");
    }
    const redirectUrl = get(loginRes, "oncodeResponse.result[0].data.redirect");
    if (redirectUrl) {
      OnmoStorage.setDeepLink(`${APP_URL}${redirectUrl}`);
    }
    const currentSession = await fetchAuthSession();
    const accessToken = currentSession.tokens?.accessToken.toString();

    dispatch(
      signInAction({
        accessToken,
      })
    );
  };

export const signInPasswordless =
  (username: string, code: string): AppThunk =>
  async (dispatch) => {
    try {
      await signIn({
        username,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      });

      await confirmSignIn({
        challengeResponse: code,
      });

      const currentSession = await fetchAuthSession();
      const accessToken = currentSession.tokens?.accessToken.toString();
      const oncode = OnmoStorage.getOncode();
      const loginRes = await login({ oncode });
      dispatch(
        updatePermissionGroup({
          permissionGroup: loginRes.authUser.permissions,
        })
      );
      OnmoStorage.removeOncode();
      OnmoStorage.removeState();
      if (!OnmoStorage.getUserId()) {
        OnmoStorage.setIsLogin();
      }
      if (
        loginRes.oncodeResponse &&
        (loginRes.oncodeResponse.validationError ||
          loginRes.oncodeResponse.result[0].status !== "SUCCESS")
      ) {
        throw Error("Error on login!");
      }
      const redirectUrl = get(
        loginRes,
        "oncodeResponse.result[0].data.redirect"
      );
      if (redirectUrl) {
        OnmoStorage.setDeepLink(`${APP_URL}${redirectUrl}`);
      } else {
        window.history.pushState({}, "", "/");
      }
      dispatch(
        signInAction({
          accessToken,
        })
      );
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(onError({ message: error.message }));
      } else if (typeof error === "string") {
        dispatch(onError({ message: error }));
      } else {
        dispatch(onError({ message: "Error on login!" }));
      }
    }
  };

export const isSignedIn = createSelector(
  (state: RootState) => state.cognitoUser.status,
  (status: CognitoUserState) => status === CognitoUserState.SIGNIN
);

export default cognitoUserSlice.reducer;
