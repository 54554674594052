import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    fontSize: 24,
    lineHeight: "24px",
  },
  iconsList: {
    display: "grid",
    gap: 12,
    "& > p": {
      display: "flex",
    },
  },
});
