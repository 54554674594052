import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ImgHTMLAttributes,
  useEffect,
  useRef,
} from "react";
import clsx from "classnames";
import { useStyles } from "./Dialog.style";
import { GridX } from "../../core/GridX/GridX";
import { Button } from "../../core";
import {
  Typography,
  TypographyColor,
  TypographyVariant,
} from "../Typography/Typography";
import { LIMIT_BUTTON_SETTINGS } from "../../../constants/popup";
import { IButtonProps } from "../Button/Button";

interface IButtonAction {
  text: string | React.ReactNode;
  icon?: React.HTMLAttributes<HTMLDivElement>;
  loading?: boolean;
}

interface IDialogProps {
  open: boolean;
  mainAction?: IButtonAction & IButtonProps & HTMLAttributes<HTMLDivElement>;
  secondAction?: IButtonAction & IButtonProps & HTMLAttributes<HTMLDivElement>;
  title?: string;
  description?: string;
  icon?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  onClose?: () => void;
  stickBottom?: boolean;
  enableScrollBackground?: boolean;
  reverseButtons?: boolean;
}

export const Dialog: FC<IDialogProps & HTMLAttributes<HTMLDialogElement>> = ({
  open,
  icon,
  mainAction,
  secondAction,
  children,
  title,
  description,
  reverseButtons,
  ...otherProps
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDialogElement>(null);

  const closeModal = () => {
    ref.current?.close?.();
  };

  const showModal = () => {
    ref.current?.showModal?.();
  };

  const lockScroll = () => {
    const xPos = window.scrollX;
    const yPos = window.scrollY;
    window.document.onscroll = () => {
      window.scroll(xPos, yPos);
      window.document.body.style.overflow = "hidden";
    };
  };

  const unlockScroll = () => {
    window.document.body.style.overflow = "initial";
    window.document.onscroll = () => {};
  };

  useEffect(() => {
    if (!ref.current) return;
    if (open) {
      lockScroll();
      showModal();
    } else {
      unlockScroll();
      closeModal();
    }
    return () => {
      unlockScroll();
    };
  }, [open, ref]);

  if (!open) {
    return null;
  }

  return (
    <dialog
      {...otherProps}
      ref={ref}
      className={clsx(classes.root, { open }, otherProps.className)}
    >
      {icon && <div className={classes.icon}>{icon}</div>}
      <GridX
        flexGrow={1}
        className={classes.content}
        spacingBottom={icon ? "l" : "none"}
        spacingTop={icon ? "l" : "none"}
      >
        {title && (
          <Typography
            variant={TypographyVariant.HeadingH5Bold}
            color={TypographyColor.DarkDefault}
            data-testid="title-dialog"
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant={TypographyVariant.BodyB5Regular}
            color={TypographyColor.DarkSecondary}
            data-testid="description-dialog"
          >
            {description}
          </Typography>
        )}
        {children}
      </GridX>
      <GridX
        container
        gap={"m"}
        flexDirection={reverseButtons ? "row-reverse" : "row"}
      >
        {secondAction && (
          <GridX item size={mainAction ? 6 : 12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              loading={secondAction?.loading}
              {...secondAction}
              limitHeight={LIMIT_BUTTON_SETTINGS}
              data-testid="accept-action"
            >
              {secondAction.icon} {secondAction.text}
            </Button>
          </GridX>
        )}
        {mainAction && (
          <GridX item size={secondAction ? 6 : 12}>
            <Button
              variant="contained"
              fullWidth
              loading={mainAction?.loading}
              {...mainAction}
              limitHeight={LIMIT_BUTTON_SETTINGS}
              data-testid="cancel-action"
            >
              {mainAction?.icon} {mainAction?.text || "Ok"}
            </Button>
          </GridX>
        )}
      </GridX>
    </dialog>
  );
};

Dialog.defaultProps = {
  open: false,
};
