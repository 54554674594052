import { createUseStyles } from "react-jss";
import { GridPaddingMapping } from "../../../constants/padding";
import { DarkThemeColors } from "../../../constants/colors";
import { BorderRadius } from "../../../constants/borderRadius";

// To check support for older iOS version
const hasHTMLDialog = typeof HTMLDialogElement === "function";

export const useStyles = createUseStyles({
  root: {
    insetInlineStart: 0,
    insetInlineEnd: 0,
    height: "fit-content",
    margin: "auto",
    overflow: "visible",
    outline: "none",
    background: DarkThemeColors.Background4,
    borderRadius: BorderRadius.md,
    display: "none",
    flexDirection: "column",
    border: "none",
    maxWidth: 343,
    width: `calc(100% - ${GridPaddingMapping.m}px)`,
    padding: `${GridPaddingMapping.xl}px ${GridPaddingMapping.m}px`,
    boxSizing: "border-box",
    "&::backdrop": {
      backgroundColor: `rgba(0,0,0, 0.7)`,
    },
    ...(hasHTMLDialog
      ? {
          "&[open]": {
            display: "flex",
          },
        }
      : {
          "&.open": {
            display: "flex",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }),
  },
  icon: {
    height: 100,
    margin: "auto",
    overflow: "hidden",
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    textAlign: "center",
    overflow: "auto",
  },
});
