import { createUseStyles } from "react-jss";
import { createGridVars } from "./utils";

export const useStyles = createUseStyles({
  xs: {
    ...createGridVars["xs"],
  },
  sm: {
    ...createGridVars["sm"],
  },
  md: {
    ...createGridVars["md"],
  },
  lg: {
    ...createGridVars["lg"],
  },
  xl: {
    ...createGridVars["xl"],
  },
  item: {
    margin: 0,
    boxSizing: "border-box",
  },
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
  },
  spacing: {
    "&.sp2": {
      width: "100%",
      margin: -2,
      "& > div": {
        padding: 2,
      },
    },
    "&.sp3": {
      width: "100%",
      margin: -3,
      "& > div": {
        padding: 3,
      },
    },
    "&.sp4": {
      width: "100%",
      margin: -4,
      "& > div": {
        padding: 4,
      },
    },
    "&.sp5": {
      width: "100%",
      margin: -5,
      "& > div": {
        padding: 5,
      },
    },
    "&.sp6": {
      width: "100%",
      margin: -6,
      "& > div": {
        padding: 6,
      },
    },
    "&.sp7": {
      width: "100%",
      margin: -7,
      "& > div": {
        padding: 7,
      },
    },
    "&.sp8": {
      width: "100%",
      margin: -8,
      "& > div": {
        padding: 8,
      },
    },
  },
});
