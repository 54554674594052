import { reduce } from "lodash-es";

const createGridItemProps = (size: number) => {
  const sizeToPercentage = parseFloat(((size / 12) * 100).toFixed(6));
  return {
    flexGlow: 0,
    "-webkit-box-flex": 0,
    maxWidth: `${sizeToPercentage}%`,
    flexBasis: `${sizeToPercentage}%`,
  };
};

const createGridSize = (size: string) => {
  return reduce(
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    (
      res: {
        [key: string]: {
          flexGlow: number;
          "-webkit-box-flex": number;
          maxWidth: string;
          flexBasis: string;
        };
      },
      value: number
    ) => {
      res[`&.${size}${value}`] = createGridItemProps(value);
      return res;
    },
    {}
  );
};

export const createGridVars = {
  xs: {
    ...createGridSize("xs"),
  },
  sm: {
    "@media (max-width: 600px)": {
      ...createGridSize("sm"),
    },
  },
  md: {
    "@media (max-width: 960px)": {
      ...createGridSize("md"),
    },
  },
  lg: {
    "@media (max-width: 1280px)": {
      ...createGridSize("lg"),
    },
  },
  xl: {
    "@media (max-width: 1920px)": {
      ...createGridSize("xl"),
    },
  },
};
