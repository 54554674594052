import { gql } from "graphql-tag";

export const getContestsGQL = gql`
  query Contests {
    contests {
      items {
        startAt
        endAt
        banner
        url
        isQualified
      }
      totalCount
    }
  }
`;
