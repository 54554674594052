import React, { HTMLAttributes, FC } from "react";
import { useStyles } from "./Grid.style";
import clsx from "classnames";

type IGridProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  item?: boolean;
  spacing?: number;
  container?: boolean;
  display?: "flex" | "inline-flex" | "inline" | "block";
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  flexGrow?: number;
};

const GridComponent: FC<IGridProps & HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const {
    xs,
    sm,
    md,
    lg,
    xl,
    item,
    spacing,
    container,
    display,
    alignItems,
    justifyContent,
    flexDirection,
    flexGrow,
    ...othersProps
  } = props;
  const classes = useStyles();

  return (
    <div
      {...othersProps}
      style={{
        ...props.style,
        display,
        alignItems,
        justifyContent,
        flexDirection,
        flexGrow,
      }}
      className={clsx(
        xs && classes.xs,
        xs && `xs${xs}`,
        sm && classes.sm,
        sm && `sm${sm}`,
        md && classes.md,
        md && `md${md}`,
        lg && classes.lg,
        lg && `lg${lg}`,
        xl && classes.xl,
        xl && `xl${xl}`,
        item && classes.item,
        container && classes.container,
        spacing && classes.spacing,
        spacing && `sp${spacing}`,
        props.className
      )}
    >
      {children}
    </div>
  );
};

export const Grid = React.memo(GridComponent);
export default Grid;
