import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    height: 20,
    position: "relative",
    display: "flex",
    width: 35,
  },
  tgl: {
    display: "none",
    "&:checked + .tgl-btn:after": {
      insetInlineStart: "43%",
      background: theme.palette.secondary.main,
    },
    "&:checked + .tgl-btn": {
      background: theme.palette.secondary.main,
    },
  },
  genButton: {
    outline: 0,
    display: "block",
    width: 35,
    height: 14,
    margin: "3px 0",
    cursor: "pointer",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    background: DarkThemeColors.Text3,
    borderRadius: "2em",
    opacity: 0.5,
    "&:before": {
      display: "none",
    },
  },
  disabled: {
    opacity: 0.5,
  },
  circle: {
    width: 20,
    height: 20,
    background: DarkThemeColors.Text2,
    borderRadius: "50%",
    position: "absolute",
    insetInlineStart: 0,
    "-webkit-transition": "all .4s ease",
    transition: "all .4s ease",
    pointerEvents: "none",
  },
  checked: {
    insetInlineStart: "43%",
    background: theme.palette.secondary.main,
  },
}));
