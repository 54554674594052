export const BorderRadius = {
  /** borderRadius 8px */
  sm: 8,
  /** borderRadius 16px */
  md: 16,
  /** borderRadius 24px */
  lg: 24,
  /** borderRadius 30px */
  xl: 30,
  xxl: 40,
};
