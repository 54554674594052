import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  mainLoading: {
    height: "100%",
  },
  contentBlock: {
    height: "100%",
    padding: 15,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
});
