import { useEffect, useRef, forwardRef, RefObject } from "react";
import { useStyles } from "./Button.style";
import clsx from "classnames";
import { Icon, IconName } from "../../core/Icon/Icon";
import { CircularProgress } from "../../core";
import { Translations } from "../../../models/translations";

type ButtonVariant =
  | "outlined"
  | "contained"
  | "borderless"
  | "white-outlined"
  | "white-borderless";
export type ButtonColor = "primary" | "secondary" | "error";
type ButtonSize = "small" | "large" | "icon";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  disabled?: boolean;
  fullWidth?: boolean;
  afterIcon?: IconName | React.ReactNode;
  loading?: boolean;
  limitHeight?: number;
  transformIcon?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className,
      variant,
      size,
      disabled,
      fullWidth,
      color,
      children,
      afterIcon,
      loading,
      limitHeight,
      transformIcon,
      ...otherProps
    },
    ref
  ) => {
    const classes = useStyles();
    const mainButtonRef =
      (ref as RefObject<HTMLButtonElement>) || useRef<HTMLButtonElement>(null);
    function createRipple(event: React.TouchEvent<HTMLButtonElement>) {
      const button = event.currentTarget;
      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      circle.style.width = circle.style.height = `${diameter}px`;
      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }

      button.appendChild(circle);
    }

    const reduceFontSizeMain = () => {
      if (
        !mainButtonRef.current ||
        mainButtonRef.current.clientHeight + 2 === limitHeight
      )
        return;
      const [fontSize] = window
        .getComputedStyle(mainButtonRef.current, null)
        .getPropertyValue("font-size")
        .split("px");

      mainButtonRef.current.style.fontSize = `${parseInt(fontSize, 10) - 1}px`;
      reduceFontSizeMain();
    };

    useEffect(() => {
      setTimeout(() => {
        if (!mainButtonRef?.current || !limitHeight) return;
        if (mainButtonRef.current.clientHeight + 2 > limitHeight) {
          reduceFontSizeMain();
        }
      }, 0);
    }, [limitHeight, disabled]);

    return (
      <button
        id="button"
        ref={mainButtonRef}
        {...otherProps}
        className={clsx(
          classes.root,
          variant,
          color,
          size,
          {
            disabled,
            fullWidth,
          },
          afterIcon && "with-icon",
          afterIcon && !children && "only-icon",
          className
        )}
        onTouchStart={(e: React.TouchEvent<HTMLButtonElement>) => {
          if (disabled) return;
          createRipple(e);
        }}
        onClick={(e) => {
          if (disabled) return;
          otherProps.onClick && otherProps.onClick(e);
        }}
      >
        {color === "primary" && !disabled && (
          <div className={classes.overlay} />
        )}
        {loading ? (
          <CircularProgress enableAnimation size={20} borderLight />
        ) : (
          <>
            {children}
            {typeof afterIcon === "string" ? (
              <Icon
                name={afterIcon as IconName}
                className={clsx(classes.iconSize, size, {
                  transformIcon: transformIcon && Translations.isRtlMode(),
                })}
              />
            ) : (
              afterIcon
            )}
          </>
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  variant: "contained",
  size: "large",
  color: "primary",
  disabled: false,
  fullWidth: false,
};

Button.displayName = "Button";

export default Button;
