import { MutationOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import {
  Mutation,
  UserMetadataInput,
  UserUpdateInput,
} from "../../../graphql/graphql";
import { updateUserGQL, updateUserMetadataGQL } from "../../mutations/users";

const apolloClient = apollo.getInstance();

export const updateUser = async (
  input: Partial<UserUpdateInput>
): Promise<Mutation["updateUser"]> => {
  const res = await apolloClient.mutate({
    variables: { input },
    mutation: updateUserGQL,
  });

  return res.data.updateUser;
};

export const updateUserMetadata = async (
  input: Partial<UserMetadataInput>,
  options: Partial<MutationOptions> = {}
): Promise<Mutation["updateUserMetadata"]> => {
  const res = await apolloClient.mutate({
    ...options,
    variables: { input },
    mutation: updateUserMetadataGQL,
  });

  return res.data;
};
