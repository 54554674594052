import { gql } from "graphql-tag";

export const purchaseInventoryGQL = gql`
  mutation PurchaseInventory($purchaseInventoryInput: PurchaseInventoryInput!) {
    purchaseInventory(purchaseInventoryInput: $purchaseInventoryInput) {
      status
    }
  }
`;

export const applyInventoryGQL = gql`
  mutation ApplyInventory($applyInventoryInput: ApplyInventoryInput) {
    applyInventory(applyInventoryInput: $applyInventoryInput) {
      status
    }
  }
`;
