import apollo from "../../../clients/apollo";
import * as Apollo from "@apollo/client";
import { eventRulesGQL } from "../../queries/eventRules";
import { Query } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getEventRules = async (
  options: Partial<Apollo.QueryOptions> = {}
): Promise<Query["eventRules"]> => {
  const res = await apolloClient.query({
    ...options,
    query: eventRulesGQL,
    variables: {},
    context: { api: ApiLegacyName.Legacy },
  });

  return res?.data.eventRules;
};
