import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { QueryOptions } from "@apollo/client";
import { map, filter } from "lodash-es";
import { RootState, AsyncAppThunk } from "../store";
import {
  Inventory,
  InventoriesInput,
  Inventory_Category,
  PurchaseInventoryInput,
  InventoryStatus,
} from "../legacyGraphql/graphql";
import {
  fetchListInventories as fetchListInventoriesResolver,
  fetchUserInventories as fetchUserInventoriesResolver,
} from "../legacyGraphql/resolvers/queries/inventories";
import { IParamsInventories } from "../types/inventories";
import { setNotificationError, showAlert } from "./alert";
import {
  applyInventory as applyInventoryMutation,
  purchaseInventory as purchaseInventoryMutation,
} from "../legacyGraphql/resolvers/mutation/inventories";
import { currentOnmoUser, getOnmoUser, updateWallets } from "./user";
import { INVENTORIES_CATEGORY, ShopTab } from "../constants/shopWallet";
import { addPopupQueue, removePopupQueue } from "./popup";
import { PopupType } from "../constants/popup";
import { Inventories } from "../models/inventories/inventories";
import { getInventoryImage } from "../models/shop/shop";
import { QueryParams } from "../models/queryParam";
import { OnmoLocation } from "../models/onmoLocation";
import { queryCoinWallets } from "../graphql/resolvers/queries/users";

interface IInventoriesSlice {
  listBundlesInventories: null | Inventory[];
  listInventories: null | Inventory[][];
  userInventories: null | Inventory[];
  openingInventory: undefined | Inventory;
  listInventoriesByBundle?: { [key: string]: Inventory[] };
}

const initialState = {
  listBundlesInventories: null,
  listInventories: null,
  userInventories: null,
  openingInventory: undefined,
} as IInventoriesSlice;

export const inventoriesSlice = createSlice({
  name: "inventories",
  initialState: initialState,
  reducers: {
    updateListBundlesInventories: (
      state,
      action: PayloadAction<{
        inventories: (Inventory & { listAvatarInventories?: Inventory[] })[];
      }>
    ) => {
      state.listBundlesInventories = action.payload.inventories;
    },
    updateListInventories: (
      state,
      action: PayloadAction<{ inventories: Array<Inventory[]> }>
    ) => {
      state.listInventories = action.payload.inventories;
    },
    updateUserInventories: (
      state,
      action: PayloadAction<{ inventories: Inventory[] }>
    ) => {
      state.userInventories = action.payload.inventories;
    },
    updateListInventoriesByBundle: (
      state,
      action: PayloadAction<{
        listInventoriesByBundle: { [key: string]: Inventory[] };
      }>
    ) => {
      state.listInventoriesByBundle = action.payload.listInventoriesByBundle;
    },
    updateOpeningInventory: (
      state,
      action: PayloadAction<{ openingInventory?: Inventory }>
    ) => {
      state.openingInventory = action.payload.openingInventory;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBundleInventory.rejected, () => {})
      .addCase(fetchBundleInventory.fulfilled, (state, action) => {
        const inventoryFiltered = action.payload.bundleInventory.items.filter(
          (item) =>
            [InventoryStatus.Available, InventoryStatus.Restricted].includes(
              item.status
            )
        );
        const newInventory = Inventories.sortAvatars(inventoryFiltered);

        state.listInventoriesByBundle = {
          ...state.listInventoriesByBundle,
          [action.payload.bundleInventoryId]: newInventory,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateListBundlesInventories,
  updateListInventories,
  updateUserInventories,
  updateListInventoriesByBundle,
  updateOpeningInventory,
} = inventoriesSlice.actions;

export const fetchListBundlesInventories =
  (
    params?: IParamsInventories,
    option?: Partial<QueryOptions>
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const listInventories = await fetchListInventoriesResolver(
        {
          ...params,
          filters: {
            restrictToBundles: true,
            restrictToFree: false,
          },
        },
        option
      );
      dispatch(
        updateListBundlesInventories({
          inventories: listInventories.items,
        })
      );
    } catch (e) {
      dispatch(updateListBundlesInventories({ inventories: [] }));
      console.error(e);
    }
  };

export const fetchListInventories =
  (
    params?: IParamsInventories,
    option?: Partial<QueryOptions>
  ): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const listInventories = await Promise.all(
        INVENTORIES_CATEGORY.map((category) => {
          return fetchListInventoriesResolver(
            {
              ...params,
              categories: [category as Inventory_Category],
            },
            option
          );
        })
      );
      const inventoriesByCategories = filter(
        map(listInventories, "items"),
        (inventory) => inventory?.length > 0
      );
      dispatch(
        updateListInventories({
          inventories: inventoriesByCategories,
        })
      );
    } catch (e) {
      dispatch(updateListInventories({ inventories: [] }));
      console.error(e);
    }
  };

export const fetchUserInventories =
  (params?: InventoriesInput, option?: Partial<QueryOptions>): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const listInventories = await fetchUserInventoriesResolver(option, {
        ...params,
        limit: 200,
        offset: 0,
      });
      dispatch(updateUserInventories({ inventories: listInventories.items }));
    } catch (e) {
      dispatch(updateUserInventories({ inventories: [] }));
      console.error(e);
    }
  };

export const applyInventory =
  (inventory: Inventory): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      await applyInventoryMutation(inventory.id);
      const state = getState();
      const onmoUser = state.user.me;
      const previewUrl = getInventoryImage(inventory);
      if (onmoUser) {
        dispatch(
          currentOnmoUser({
            me: {
              ...onmoUser,
              avatar: previewUrl ?? "",
            },
          })
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
      console.error(e);
    }
  };

export const purchaseInventory =
  (inventory: Inventory, bundleId?: string): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      await purchaseInventoryMutation({
        applyImmediately: inventory.applyImmediately,
        inventoryId: inventory.id,
        inventoryCost: !inventory?.costs?.length
          ? undefined
          : {
              coinType: inventory.costs[0].coinType,
              amount: inventory.costs[0].amount,
            },
      } as PurchaseInventoryInput);
      dispatch(fetchUserInventories());
      dispatch(getOnmoUser());
      if (inventory.isBundle) {
        dispatch(fetchListBundlesInventories());
        dispatch(fetchBundleInventory(inventory.id));
      } else if (
        QueryParams.getShopTab() === ShopTab.All &&
        OnmoLocation.isShopPage()
      ) {
        dispatch(fetchListInventories());
      } else if (bundleId) {
        dispatch(fetchBundleInventory(bundleId));
      }
      const { coinWallets } = await queryCoinWallets();
      dispatch(updateWallets({ wallets: coinWallets }));

      const openingInventory = getState().inventories.openingInventory;
      openingInventory &&
        dispatch(
          updateOpeningInventory({
            openingInventory: { ...openingInventory, isPurchased: true },
          })
        );
    } catch (e) {
      const openingInventory = getState().inventories.openingInventory;
      openingInventory &&
        dispatch(
          updateOpeningInventory({
            openingInventory: { ...openingInventory, isPurchased: false },
          })
        );
      if (e instanceof Error) {
        console.error(e);
      }
    }
    dispatch(removePopupQueue({ type: PopupType.Inventory }));
    dispatch(addPopupQueue({ type: PopupType.PurchaseResult }));
  };

export const fetchBundleInventory = createAsyncThunk(
  "inventories/fetchBundleInventory",
  async (bundleInventoryId: string, { dispatch, signal }) => {
    try {
      const bundleInventory = await fetchListInventoriesResolver(
        { bundleInventoryId: bundleInventoryId },
        {
          context: {
            fetchOptions: {
              signal,
            },
          },
        }
      );
      return { bundleInventory, bundleInventoryId };
    } catch (e) {
      if (e instanceof Error) {
        dispatch(showAlert({ classify: "error", message: e.message }));
      }
      throw e;
    }
  }
);

// ---------- createSelector ---------- //

export const selectBundleById = createSelector(
  [
    (state: RootState) => state.inventories.listBundlesInventories,
    (state: RootState, bundleId: string) => bundleId,
  ],
  (listBundlesInventories, bundleId) => {
    if (!listBundlesInventories) return;

    const bundle = Inventories.getBundleById(listBundlesInventories, bundleId);
    return bundle;
  }
);

export const selectBundleByInventory = createSelector(
  [
    (state: RootState) => state.inventories.listBundlesInventories,
    (state: RootState, inventoryId?: string) => inventoryId,
  ],

  (listBundlesInventories, inventoryId) => {
    if (!listBundlesInventories?.length || !inventoryId) return undefined;

    const bundle = Inventories.getBundleByInventory(
      listBundlesInventories,
      inventoryId
    );
    return bundle;
  }
);

export const selectOwnedBundles = createSelector(
  [(state: RootState) => state.inventories.userInventories],
  (userInventories) => {
    if (!userInventories) return;

    const ownedBundles = Inventories.getOwnedBundles(userInventories);
    return ownedBundles;
  }
);

export const selectOwnedItemsGroupByCategory = createSelector(
  [(state: RootState) => state.inventories.userInventories],
  (userInventories) => {
    if (!userInventories) return;

    const ownedItemsGroupByCategory =
      Inventories.getOwnedItemsGroupByCategory(userInventories);
    return ownedItemsGroupByCategory;
  }
);

export const selectBundlesNotPurchase = createSelector(
  [(state: RootState) => state.inventories.listBundlesInventories],
  (listBundlesInventories) => {
    if (!listBundlesInventories?.length) return;

    const ownedItemsGroupByCategory = Inventories.getBundlesNotPurchase(
      listBundlesInventories
    );
    return ownedItemsGroupByCategory;
  }
);

export const selectAvatarNotPurchase = createSelector(
  [(state: RootState) => state.inventories.listInventories],
  (listInventories) => {
    if (!listInventories) return;

    const avatarNotPurchase = Inventories.getAvatarNotPurchase(listInventories);
    return avatarNotPurchase;
  }
);

export default inventoriesSlice.reducer;
