export const BADGE_SIZES_JSS = {
  fontFamily: "roboto",
  fontWeight: 700,
  "&.xs": { width: 12, height: 12 },
  "&.s": {
    width: 16,
    height: 16,
    fontSize: 12,
    lineHeight: "16px",
  },
  "&.m": {
    width: 20,
    height: 20,
    fontSize: 14,
    lineHeight: "20px",
  },
  "&.l": {
    width: 24,
    height: 24,
    fontSize: 16,
    lineHeight: "24px",
  },
  "&.xl": {
    width: 32,
    height: 32,
    fontSize: 18,
    lineHeight: "32px",
  },
  "&.xxl": {
    width: 40,
    height: 40,
    fontSize: 24,
    lineHeight: "40px",
  },
  "&.xxxl": {
    width: 52,
    height: 52,
    fontSize: 32,
    lineHeight: "52px",
  },
  "&.xxxxl": { width: 80, height: 80 },
  "&.xxxxxl": { width: 120, height: 120 },
  "&.xxxxxxl": { width: 140, height: 140 },
};
