import React, { HTMLAttributes, FC } from "react";
import { Translations } from "../../../models/translations";
import clsx from "classnames";
import { useStyles } from "./StaticImage.style";

interface IStaticImageProps {
  src?: string;
  src2x?: string;
  src3x?: string;
  disableTransformImg?: boolean;
  alt?: string;
  height?: number | string;
  width?: number | string;
}

const StaticImageComponent: FC<
  IStaticImageProps & HTMLAttributes<HTMLImageElement>
> = (props) => {
  const {
    src,
    src2x,
    src3x,
    disableTransformImg,
    alt,
    height,
    width,
    className,
    ...otherProps
  } = props;
  const classes = useStyles();

  const dataSrc = src2x && src3x ? `${src} 1x, ${src2x} 2x, ${src3x} 3x` : src;

  return (
    <img
      {...otherProps}
      data-src={dataSrc}
      srcSet={dataSrc}
      alt={alt}
      height={height}
      width={width}
      className={clsx(
        className,
        !disableTransformImg && Translations.isRtlMode() && classes.transformImg
      )}
    />
  );
};

export const StaticImage = React.memo(StaticImageComponent);
