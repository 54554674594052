import { filter, find, includes, isEmpty, orderBy } from "lodash-es";
import { Game as OldGame } from "../../legacyGraphql/graphql";
import { NETWORK_STATUS } from "../../constants/games";
import {
  GAME_IMAGE_ASSETS_ENDPOINT_V2,
  ONMO_CONFIG,
} from "../../constants/onmo";
import { Game } from "../../graphql/graphql";

export const orderByTimesPlayed = (listGame: Game[] | null) => {
  return orderBy(listGame, ["featured", "timesPlayed"], ["desc", "desc"]);
};

export const searchGameByTitle = (games: Game[] | null, query: string) => {
  return filter(games, (game) =>
    includes(game?.title.toLocaleLowerCase(), query.toLocaleLowerCase())
  );
};

export const getGameImage = (type: "big" | "small", gameData?: Game) => {
  const gameImages = JSON.parse(gameData?.data || "null")?.gameImages;
  const imageUrl = GAME_IMAGE_ASSETS_ENDPOINT_V2 + gameData?.id;

  return {
    png: gameImages?.[type]
      ? imageUrl + `/${gameImages?.[type]}.png`
      : undefined,
    webp: gameImages?.[type]
      ? imageUrl + `/${gameImages?.[type]}.webp`
      : undefined,
    png2x: gameImages?.[`${type}2x`]
      ? imageUrl + `/${gameImages?.[`${type}2x`]}.png`
      : undefined,
    webp2x: gameImages?.[`${type}2x`]
      ? imageUrl + `/${gameImages?.[`${type}2x`]}.webp`
      : undefined,
    png3x: gameImages?.[`${type}3x`]
      ? imageUrl + `/${gameImages?.[`${type}3x`]}.png`
      : undefined,
    webp3x: gameImages?.[`${type}3x`]
      ? imageUrl + `/${gameImages?.[`${type}3x`]}.webp`
      : undefined,
  };
};

export const findGameImage = (
  listGames: Game[] | null,
  options: { gameId: string; type: "big" | "small" }
) => {
  const { gameId, type } = options;
  const currentGame = listGames?.find((game) => game.id === gameId);
  return getGameImage(type, currentGame);
};

export const getHighResGameImage = (gameImage: {
  png: string | undefined;
  webp: string | undefined;
  png2x: string | undefined;
  webp2x: string | undefined;
  png3x: string | undefined;
  webp3x: string | undefined;
}) => {
  return {
    png:
      gameImage.png2x && gameImage.png3x
        ? `${gameImage.png} 1x, ${gameImage.png2x} 2x, ${gameImage.png3x} 3x`
        : gameImage.png,
    webp:
      gameImage.webp2x && gameImage.webp3x
        ? `${gameImage.webp} 1x, ${gameImage.webp2x} 2x, ${gameImage.webp3x} 3x`
        : gameImage.webp,
  };
};

export const filterGameByCategory = (
  gameList: Game[] | null,
  category: string
) => {
  return filter(gameList, (game) => {
    const categoryFormat = game.category.toLowerCase();
    return categoryFormat.includes(category?.toLocaleLowerCase());
  });
};

export const getGameById = (
  games: Game[] | null,
  id: string
): Game | undefined => {
  return find(games, (g) => g.id === id);
};

export const getDataGameMoment = (
  lng: string,
  momentData?: string,
  gameData?: string
) => {
  try {
    const getIdxKey = (translationKey: string[], lng: string) => {
      const languageNames = new Intl.DisplayNames(["en"], {
        type: "language",
      });
      return translationKey.findIndex(
        (key) =>
          key.toLowerCase() ===
          `${languageNames.of(lng)?.toLowerCase()}_loadingtips`
      );
    };

    // Return Moment Data if exist
    if (momentData) {
      const momentDataParse = JSON.parse(momentData || "");
      const translationKey = Object.keys(momentDataParse.translation);
      const idxKey = getIdxKey(translationKey, lng);
      if (!isEmpty(momentDataParse.translation[translationKey[idxKey]])) {
        return momentDataParse?.translation;
      }
    }

    // Return Game Data if exist
    if (gameData) {
      const gameDataParse = JSON.parse(gameData || "");
      const translatioGameKey = Object.keys(gameDataParse.translation);
      const idxGameKey = getIdxKey(translatioGameKey, lng);
      if (!isEmpty(gameDataParse.translation[translatioGameKey[idxGameKey]])) {
        return gameDataParse.translation;
      }
    }
  } catch (error) {
    console.error(error);
  }

  // Return null if Moment Data and Game Data not exist
  return null;
};

export const getMomentTitle = (lng: string, momentData?: string) => {
  const momentDataParse = JSON.parse(momentData || "{}");
  const languageNames = new Intl.DisplayNames(["en"], {
    type: "language",
  });
  return momentDataParse.translation?.[`${languageNames.of(lng)}_title`];
};

export const getNetworksStatus = (score?: number) => {
  if (!score || NETWORK_STATUS.Low.includes(score)) return "bad";
  else if (NETWORK_STATUS.Medium.includes(score)) return "weak";
  else if (NETWORK_STATUS.High.includes(score)) return "good";
  else return "bad";
};

export const getNextAvailableMoment = (game: OldGame) => {
  if (!game.hasSolo) return null;

  const listMoment = orderBy(game.soloChallenges, "order", "asc");
  const currentMoment = listMoment.find((moment) => {
    return !moment.isCompleted;
  });
  return currentMoment || listMoment[0];
};

export const getAvailableHouseBattle = (game?: OldGame, matchId?: string) => {
  return game?.houseBattles.find((battle) => battle.matchId !== matchId);
};

export const filterGameByTitle = (games: Game[] | null, query: string) => {
  return filter(games, (game) =>
    includes(game?.title.toLocaleLowerCase(), query.toLocaleLowerCase())
  );
};

export const getFeaturedGame = (listGame: Game[] | null) => {
  return filter(listGame, { featured: true, hasTournament: false }) ?? [];
};

export const getCurrentGameVersion = (gameData?: string) => {
  if (!gameData) return null;
  try {
    const data = JSON.parse(gameData);
    return data?.currentGameVersion;
  } catch (error) {
    return null;
  }
};

export const getPathGameData = (gameData?: string, gameId?: string) => {
  const currentGameVersion = getCurrentGameVersion(gameData);
  const pathGameData = currentGameVersion
    ? `${ONMO_CONFIG?.cms?.cdnPath}games/${gameId}/${currentGameVersion}`
    : `${ONMO_CONFIG?.cms?.cdnPath}games/${gameId}/game.js`;
  return pathGameData;
};

export const getGameSeed = (matchId: string) => {
  const hash = (s: string) => {
    let h = 9;
    for (let i = 0; i < s.length; ) {
      h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9);
    }
    return h ^ (h >>> 9);
  };

  return matchId && matchId !== "N/A" ? hash(matchId) : new Date().getTime();
};
