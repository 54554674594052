import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";
import { Colors, DarkThemeColors } from "../../../constants/colors";
import { BorderRadius } from "../../../constants/borderRadius";
import { GridPaddingMapping } from "../../../constants/padding";

export const TOTAL_PROGRESS = 116;
const DIMENSION = {
  circle: {
    small: {
      width: 48,
      height: 48,
    },
    medium: {
      width: 72,
      height: 72,
    },
    large: {
      width: 96,
      height: 96,
    },
    extraLarge: {
      width: 120,
      height: 120,
    },
  },
  linear: {
    small: {
      height: 10,
    },
    medium: {
      height: 14,
    },
    large: {
      height: 20,
    },
    extraLarge: {
      height: 50,
    },
  },
};

const getInitialValue = (initial: number) =>
  initial >= 100 ? TOTAL_PROGRESS : (TOTAL_PROGRESS / 100) * initial;

const getTargetValue = (target: number) =>
  target >= 100 ? TOTAL_PROGRESS : (TOTAL_PROGRESS / 100) * target;

export const useStyles = (initial: number, target: number, timing?: number) => {
  const initialValue = getInitialValue(initial);
  const targetValue = getTargetValue(target);

  return createUseStyles((theme: ITheme) => ({
    root: {
      ...DIMENSION.linear.large,
      padding: GridPaddingMapping.tiny2,
      position: "relative",
      boxSizing: "border-box",
      background: Colors.BackgroundDefault,
      width: "100%",
      borderRadius: BorderRadius.lg,
      "&.small": {
        ...DIMENSION.linear.small,
        padding: GridPaddingMapping.tiny1,
        "& $bar": {
          boxShadow: `inset 0 0 0 1px ${DarkThemeColors.Border1}`,
          padding: GridPaddingMapping.tiny1,
        },
      },
      "&.medium": {
        ...DIMENSION.linear.medium,
        padding: GridPaddingMapping.tiny1,
        "& $bar": {
          boxShadow: `inset 0 0 0 1px ${DarkThemeColors.Border1}`,
          padding: GridPaddingMapping.tiny1,
        },
      },
      "&.large": {
        ...DIMENSION.linear.large,
        padding: GridPaddingMapping.tiny2,
      },
      "&.extraLarge": {
        ...DIMENSION.linear.extraLarge,
        padding: GridPaddingMapping.tiny2,
        borderRadius: BorderRadius.md,
        "& $bar": {
          borderRadius: BorderRadius.md,
          boxShadow: `none`,
        },
      },
      "&.bg-primary": {
        background: DarkThemeColors.Background5,
      },
    },
    bar: {
      minWidth: "min-content",
      height: "100%",
      borderRadius: BorderRadius.lg,
      boxShadow: `inset 0 0 0 2px ${DarkThemeColors.Border1}`,
      boxSizing: "border-box",
      transition: "width 1s",
    },
    oProgressCircle: {
      display: "inlineBlock",
      position: "relative",
      ...DIMENSION.circle.small,
      "&.small": { ...DIMENSION.circle.small },
      "&.medium": { ...DIMENSION.circle.medium },
      "&.large": { ...DIMENSION.circle.large },
      "&.extraLarge": { ...DIMENSION.circle.extraLarge },
    },
    circleBackground: {
      stroke: DarkThemeColors.Border1,
    },
    circleG: {
      fill: "none",
      strokeWidth: 1.2,
    },
    text: {
      display: "flex",
      position: "absolute",
      insetArea: "center",
      width: "100%",
      height: "100%",
    },
    circleMain: {
      strokeLinecap: "round",
      animation: `$loadCircle forwards ${timing}ms`,
      stroke: theme.palette.primary.main,
      strokeDasharray: TOTAL_PROGRESS,
      "&.disabled": {
        animation: "none",
      },
    },
    "@keyframes loadCircle": {
      "0%": {
        strokeDashoffset: TOTAL_PROGRESS - initialValue,
      },
      "100%": {
        strokeDashoffset: TOTAL_PROGRESS - targetValue,
      },
    },
  }))();
};
