import { isDesktop, isIOS } from "react-device-detect";
import {
  GameSessionType,
  GameSessionV2,
  GameType,
  Moment,
  RotationMode,
} from "../../legacyGraphql/graphql";
import { ISessionResults } from "../../types/sessionResult";
import { get } from "lodash-es";
import { StreamStatus } from "../../constants/gameSession";
import { MomentType } from "../../constants/moments";
import { GAMESESSION_STATUS } from "../../slices/gameSession";
import { Time } from "../time";
import { MOMENT_IMAGE_ASSETS_ENDPOINT } from "../../constants/onmo";

interface IMomentDetectorPayload {
  event_type: string;
  message: string;
  data: {
    time: number;
    score: number;
    stateId: string;
    gameStartTime?: number;
    currentDuration?: number;
  };
}

interface IMomentDetectorEventCallbacks {
  setStateId: (stateId: string) => void;
  setNbScore: (score: number) => void;
  setStreamStatus: (status: StreamStatus) => void;
  onDockerErrorHandler: (errorMsg: string) => void;
  setGameStartTime: (gameStartTime: number) => void;
  setMEDPlayDuration: (playDuration: number) => void;
}

export const getSessionResults = (
  gamesession?: GameSessionV2
): ISessionResults | undefined => {
  if (
    !gamesession?.score &&
    !gamesession?.time &&
    !gamesession?.failureMessage
  ) {
    return undefined;
  } else {
    return {
      results: {
        time: gamesession.time,
        score: gamesession.score,
        failureMessage: gamesession.failureMessage,
      },
    };
  }
};

export const getMomentType = (sessionType?: GameSessionType) => {
  switch (sessionType) {
    case GameSessionType.Battle:
    case GameSessionType.HouseBattle:
      return "Battle";
    case GameSessionType.Tournament:
      return "Tournament";
    default:
      return "Challenge";
  }
};

export const isOnRightRotationMode = (rotationMode?: RotationMode) => {
  if (isDesktop) return true;

  const checkWindowOrientation = (rotationMode?: RotationMode) => {
    if (Math.abs(window.orientation) === 90)
      return rotationMode === RotationMode.Landscape;
    else return rotationMode === RotationMode.Portrait;
  };

  const isRightOrientation = checkWindowOrientation(rotationMode);
  // orientation is wrong in iOs 16.4
  if (isIOS) return isRightOrientation;

  switch (get(window, "screen.orientation.type")) {
    case "landscape-primary":
    case "landscape-secondary":
      return rotationMode === RotationMode.Landscape;
    case "portrait-secondary":
    case "portrait-primary":
      return rotationMode === RotationMode.Portrait;
    default:
      return isRightOrientation;
  }
};

export const hasSessionResult = (gamesession?: GameSessionV2) => {
  return (
    !!gamesession && gamesession?.score !== null && gamesession?.time !== null
  );
};

export const isMomentTime = (type?: string) => {
  return type?.toUpperCase() === MomentType.Time;
};

export const getDefaultStateId = (momentData?: string) => {
  try {
    const dataMoment = JSON.parse(momentData || "null");
    return dataMoment?.versions["v3.0"]?.defaultStateId;
  } catch (error) {
    return "";
  }
};

export const isHtmlSession = (gameType?: GameType) => {
  return gameType === GameType.Html;
};

export const isStreamSession = (gameType?: GameType) => {
  return gameType === GameType.Stream;
};

export const isBattleSession = (sessionType?: GameSessionType) => {
  return (
    sessionType === GameSessionType.Battle ||
    sessionType === GameSessionType.HouseBattle
  );
};

export const isTournamentSession = (sessionType?: GameSessionType) => {
  return sessionType === GameSessionType.Tournament;
};

export const isCasualSession = (sessionType?: GameSessionType) => {
  return sessionType === GameSessionType.Casual;
};

export const isWonSoloGameSession = (
  sessionResults: ISessionResults | undefined | null
) => {
  if (sessionResults === undefined || sessionResults === null) return false;
  return (
    !sessionResults?.results.failureMessage ||
    sessionResults?.results.failureMessage?.length === 0
  );
};

export const isStatusPlaying = (status: number) => {
  return status === GAMESESSION_STATUS.PLAYING;
};

export const isStatusPaused = (status: number) => {
  return (
    status === GAMESESSION_STATUS.PAUSING ||
    status === GAMESESSION_STATUS.SHOW_LEAVE_POPUP
  );
};

export const isSessionEnded = (status: number) => {
  return status === GAMESESSION_STATUS.SESSION_ENDED;
};

export const isDisconnected = (status: number) => {
  return (
    status === GAMESESSION_STATUS.ERROR_DISCONNECTED ||
    status === GAMESESSION_STATUS.SESSION_ENDED
  );
};

export const isStatusLeavePopup = (status: number) => {
  return status === GAMESESSION_STATUS.SHOW_LEAVE_POPUP;
};

export const isStatusEndScreen = (status: number) => {
  return (
    status === GAMESESSION_STATUS.CALCULATE_RESULT ||
    status === GAMESESSION_STATUS.VIEWING_END_RESULT
  );
};

export const formatScoreByMomentType = (score: number, momentType?: string) => {
  if (!score || score === 0 || score < 0) return 0;
  if (isMomentTime(momentType)) {
    return Time.formatDurationSecInMinSec(score);
  }
  return score;
};

export const getMomentImages = (moment?: Moment) => {
  const momentImages = JSON.parse(moment?.data || "null")?.momentImages;
  if (
    !momentImages ||
    (!momentImages.horizontal &&
      !momentImages.horizontal2x &&
      !momentImages.horizontal3x)
  )
    return null;

  return {
    png: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal}.png`,
    webp: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal}.webp`,
    png2x: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal2x}.png`,
    webp2x: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal2x}.webp`,
    png3x: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal3x}.png`,
    webp3x: `${MOMENT_IMAGE_ASSETS_ENDPOINT}${moment?.id}/${momentImages.horizontal3x}.webp`,
  };
};

export const momentDetectorEventHandler = (
  payload: IMomentDetectorPayload,
  streamStatus: StreamStatus,
  gameStartTime: number,
  callbacks: IMomentDetectorEventCallbacks
) => {
  let currentScore;
  let stateIdFromPayload;
  const {
    setStateId,
    setNbScore,
    setStreamStatus,
    onDockerErrorHandler,
    setGameStartTime,
    setMEDPlayDuration,
  } = callbacks;
  switch (payload.event_type) {
    case "game_start_time":
      if (payload.data?.gameStartTime && !gameStartTime) {
        setGameStartTime(payload.data.gameStartTime);
      }
      break;
    case "ping":
      if (payload.data?.gameStartTime && !gameStartTime) {
        setGameStartTime(payload.data.gameStartTime);
      }
      break;
    case "score": {
      if (
        [
          StreamStatus.ShowEndScreen,
          StreamStatus.Done,
          StreamStatus.HasFinalScore,
        ].indexOf(streamStatus) > -1
      )
        break;
      currentScore = Math.floor(payload.data?.score);
      stateIdFromPayload = payload.data?.stateId;

      setStateId(stateIdFromPayload);
      if (currentScore) {
        setNbScore(currentScore);
      }
      break;
    }
    case "calculate": {
      if (navigator.onLine) {
        setStreamStatus(StreamStatus.ShowEndScreen);
      } else {
        setStreamStatus(StreamStatus.Done);
      }
      break;
    }
    case "final_score": {
      setStreamStatus(StreamStatus.HasFinalScore);
      const finalScore = Math.floor(payload.data.score);
      finalScore && setNbScore(finalScore);

      break;
    }
    case "sync_time": {
      if (payload.data.currentDuration) {
        setMEDPlayDuration(payload.data.currentDuration);
      }

      break;
    }
    case "docker_error": {
      onDockerErrorHandler(payload.message);
    }
  }
};
