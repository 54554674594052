import React, { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { StaticImage } from "../StaticImage";
import { Theme } from "../../../models/theme";
import { useStyles } from "./B2bLoginTemplateHeader.style";
import { Typography } from "../../core";
import { useAppSelector } from "../../../hooks";
import { TypographyVariant } from "../../core/Typography/Typography";

export const B2bLoginTemplateHeader: FC<HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { theme } = useAppSelector((state) => state.theme);
  const { app, pages } = theme;

  const headingIcon = Theme.getImage3x(
    app?.name,
    pages?.login?.template?.heading || ""
  );
  const poweredByIcon = Theme.getImage3x(
    app?.name,
    pages?.login?.template?.logo2 || ""
  );
  const loginLogoIcon = Theme.getImage3x(
    app?.name,
    pages?.login?.template?.logo1 || ""
  );

  return (
    <div {...props}>
      {pages?.login?.template?.logo1 && (
        <StaticImage
          src={loginLogoIcon?.oneX}
          src2x={loginLogoIcon?.twoX}
          src3x={loginLogoIcon?.threeX}
          alt="loginLogoIcon"
          data-testid="logo-login"
        />
      )}
      <div>
        <Typography
          data-testid="welcome-text-1"
          variant={TypographyVariant.HeadingH1Bold}
        >
          {t("common_welcome to", {
            defaultValue: "Welcome to",
          })}
        </Typography>
        {pages?.login?.template?.heading ? (
          <StaticImage
            src={headingIcon?.oneX}
            src2x={headingIcon?.twoX}
            src3x={headingIcon?.threeX}
            alt="headingIcon"
          />
        ) : (
          <Typography className={classes.appName}>{app?.brandName}</Typography>
        )}
      </div>
      {pages?.login?.template?.hasTitle && (
        <Typography
          variant={TypographyVariant.BodyB3Regular}
          className={classes.desc}
          data-testid="description-welcome"
        >
          {t(
            "B2bLoginTemplateHeader_Play top games and battle with friends for the ultimate gaming experience!",
            {
              defaultValue:
                "Play top games and battle with friends for the ultimate gaming experience!",
            }
          )}
        </Typography>
      )}
      {pages?.login?.template?.logo2 && (
        <div className={classes.powerBy}>
          <Typography
            variant={TypographyVariant.BodyB5Bold}
            className={classes.powerByText}
          >
            {t("login_Powered by", {
              defaultValue: "Powered by",
            })}
          </Typography>
          <StaticImage
            src={poweredByIcon?.oneX}
            src2x={poweredByIcon?.twoX}
            src3x={poweredByIcon?.threeX}
            alt="power-by"
          />
        </div>
      )}
    </div>
  );
};
