import { gql } from "graphql-tag";

export const globalLeaderboardsGQL = gql`
  mutation GlobalLeaderboards {
    globalLeaderboards {
      alltime {
        endDate
        myRank
        myScore
        leaderboard {
          id
          username
          avatar
          banner
          xp
          score
        }
      }
      weekly {
        endDate
        myRank
        myScore
        leaderboard {
          id
          username
          avatar
          banner
          xp
          score
        }
      }
      monthly {
        endDate
        myRank
        myScore
        leaderboard {
          id
          username
          avatar
          banner
          xp
          score
        }
      }
      contest {
        endDate
        myRank
        myScore
        leaderboard {
          id
          username
          avatar
          banner
          xp
          score
        }
      }
    }
  }
`;
