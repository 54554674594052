import { APP_URL } from "./onmo";

export enum ThemeName {
  Onmo = "ONMO",
  Dialog = "DIALOG",
  Zain = "Zain",
  Safaricom = "Safaricom",
  VodafoneIndia = "Vodafone India",
  VodafoneQatar = "Vodafone Qatar",
  Telefonica = "Telefonica",
  GameGo = "GAMEGO",
  Arena = "Arena",
  AisThaiLand = "AIS Thailand",
  OoredooOman = "Ooredoo Oman",
  EthiotelEthiopia = "Ethiotel Ethiopia",
  OmanTelOman = "OmanTel OMAN",
  MtnSouthAfrica = "MTN South Africa",
  ViplayIndia = "VIPlay India",
  AtomMyanmar = "ATOM Myanmar",
  MtnNigeria = "MTN Nigeria",
  MtnZambia = "MTN Zambia",
  Cellcard = "Cellcard",
  AirtelNigeria = "Airtel Nigeria",
  O2CzechRepublic = "O2 Czech Republic",
  AsiacellIraq = "Asiacell IRAQ",
  ClaroColombia = "Claro Colombia",
  MobifoneVietNam = "Mobifone VietNam",
  RubycomFrance = "Rubycom France",
  DigitalizeSwiss = "Digitalize Swiss",
  MptMyanmar = "MPT Myanmar",
  TrueMoveThaiLand = "TrueMove Thailand",
  DitoPhilippines = "DITO Philippines",
  StarHubSingapore = "StarHub Singapore",
  TelcelMexico = "Telcel Mexico",
  FourTDenmark = "4T Denmark",
  OrangeSpain = "Orange Spain",
  VinaphoneVietNam = "Vinaphone VietNam",
  SOSIMHongKong = "SOSIM Hong Kong",
  RobiBangladesh = "Robi Bangladesh",
  BkashBangladesh = "Bkash Bangladesh",
  TIMBrazil = "TIM Brazil",
  OoredooMyanmar = "Ooredoo Myanmar",
  OoredooQatar = "Ooredoo Qatar",
  EntelPeru = "Entel Peru",
}

export const ASSETS_ENDPOINT_THEME = `${APP_URL}/b2b/`;

export const BREAK_POINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};
