import { gql } from "graphql-tag";

export const gamesGQL = gql`
  query Games($input: ListGamesInput!) {
    games(input: $input) {
      total
      items {
        id
        title
        description
        data
        category
        rotationMode
        status
        type
        featured
        hasBattle
        hasSolo
        hasTournament
      }
    }
  }
`;
