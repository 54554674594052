import { gql } from "graphql-tag";

export const updateUserMetadataGQL = gql`
  mutation UpdateUserMetadata(
    $updateUserMetadataInput: UpdateUserMetadataInput!
  ) {
    updateUserMetadata(updateUserMetadataInput: $updateUserMetadataInput) {
      status
    }
  }
`;

export const updateUserV2GQL = gql`
  mutation UpdateUserV2($updateUserV2Input: UpdateUserV2Input!) {
    updateUserV2(updateUserV2Input: $updateUserV2Input) {
      status
    }
  }
`;

export const deleteUserV2GQL = gql`
  mutation DeleteUserV2 {
    deleteUserV2 {
      status
    }
  }
`;
export const disableUserGQL = gql`
  mutation DisableUser {
    disableUser {
      status
    }
  }
`;

export const getFreeCoinsGQL = gql`
  mutation GetFreeCoins {
    getFreeCoins {
      nextAvailableTime
      status
    }
  }
`;

export const checkInUserGQL = gql`
  mutation CheckinUser($input: CheckinUserInput) {
    checkinUser(input: $input) {
      userId
      lastCheckin
    }
  }
`;

export const loginGQL = gql`
  mutation Login($loginInput: LoginInput, $oncode: String) {
    login(loginInput: $loginInput, oncode: $oncode) {
      authUser {
        email
        name
        phone
        permissions
        userId
      }
      permissions
      oncodeResponse {
        validationError
        result {
          status
          action
          data {
            failureReason
            redirect
          }
        }
      }
    }
  }
`;

export const CreateShareableUrlGQL = gql`
  mutation CreateShareableUrl(
    $createShareableUrlInput: CreateShareableUrlInput
  ) {
    createShareableUrl(createShareableUrlInput: $createShareableUrlInput) {
      url
    }
  }
`;
export const ClaimLoginRewardGQL = gql`
  mutation Mutation($claimLoginRewardInput: ClaimLoginRewardInput!) {
    claimLoginReward(claimLoginRewardInput: $claimLoginRewardInput) {
      status
    }
  }
`;
