import { createUseStyles } from "react-jss";
import { GridPaddingMapping } from "../../../constants/padding";

const createGridItemProps = (size: number): Object => {
  const sizeToPercentage = parseFloat(((size / 12) * 100).toFixed(6));
  return {
    flexGrow: 0,
    "-webkit-box-flex": 0,
    maxWidth: `${sizeToPercentage}%`,
    flexBasis: `${sizeToPercentage}%`,
  };
};

const createGridSize = () => {
  return Object.values([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]).reduce(
    (res: { [key: string]: Object }, value: number) => {
      res[`&.size-${value}`] = createGridItemProps(value);
      return res;
    },
    {}
  );
};

const JSSSpacing = Object.entries(GridPaddingMapping).reduce(
  (acc, [size, padding]) => {
    acc[`&.${size}`] = {
      padding,
    };
    return acc;
  },
  {} as { [key: string]: Object }
);

export const useStyles = createUseStyles({
  root: {
    boxSizing: "border-box",
    "&.item": {
      ...createGridSize(),
    },
    "&.container": {
      width: "100%",
      display: "flex",
      // gap property isn't working with flexWrap
      // so it's disabled in order to simplify
      // the css until there is a need
      // flexWrap: "wrap",
    },
    ...JSSSpacing,
  },
});
