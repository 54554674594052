import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { useStyles } from "./CircularProgress.style";
import { useAppSelector } from "../../../hooks";
import { LegacyColor } from "../../../constants/colors";

interface ICircularProgressProps {
  size?: number;
  enableAnimation?: boolean;
  borderLight?: boolean;
}

export const CircularProgress: FC<
  ICircularProgressProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const { theme } = useAppSelector((state) => state.theme);
  const {
    className,
    style,
    size,
    enableAnimation,
    borderLight,
    ...othersProps
  } = props;
  const sizeApply = size || 30;

  const styleInternal = {
    width: sizeApply,
    height: sizeApply,
    border: `${sizeApply / 10}px solid transparent`,
    borderTop: `${sizeApply / 10}px solid ${
      borderLight ? LegacyColor.color15 : theme?.theme?.palette?.primary?.main
    }`,
  };

  return (
    <div
      {...othersProps}
      className={clsx(
        classes.root,
        enableAnimation && classes.showAnimation,
        className
      )}
      style={{ ...styleInternal, ...style }}
    />
  );
};
