import { gql } from "graphql-tag";

export const getUserB2bSubscriptionGQL = gql`
  query Subscription {
    getUserV2 {
      subscription {
        field
        renewalDate
        status
        umrid
      }
    }
  }
`;
