import { getMeasurementScheduler } from "../../appland/MeasurementScheduler";

/**
 * To get device info
 */
const getDeviceInfo = async (): Promise<string> => {
  const res = await getMeasurementScheduler().getDeviceInfo();
  //do not delete this console log
  console.log("[Rob0] DeviceInfo payload: " + JSON.stringify(res));
  return JSON.stringify(res);
};

/**
 * get predicted game experiences
 */
const getPredictedGameExperiences = async (): Promise<
  { appId: number; score: number; available: boolean }[]
> => {
  const res = await getMeasurementScheduler().getPredictedGameExperiences();
  return res?.apps;
};

export const Appland = {
  getDeviceInfo,
  getPredictedGameExperiences,
};
