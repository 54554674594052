import React, { FC, HTMLAttributes, useEffect } from "react";
import { Grid, CircularProgress } from "../../core";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./SignIn.style";
import { HistoryOnmo } from "../../../models/historyOnmo";
import { OnmoStorage } from "../../../models/onmoStorage";
import { PageRoutes } from "../../../constants/historyOnmo";
import { QueryParams } from "../../../models/queryParam";
import { B2bLoginTemplate } from "../../common/B2bLoginTemplate";
import { useAppSelector } from "../../../hooks";
import { AccountBlocked } from "../../common/AccountBlocked";
import { System } from "../../../models/system";
import { signInWithRedirect } from "aws-amplify/auth";

export const SignIn: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { theme } = useAppSelector((state) => state.theme);
  const { pages } = theme;
  const location = useLocation();
  const path = location?.pathname;

  useEffect(() => {
    try {
      // Analytics page change
      // @ts-ignore
      window.dataLayer?.push({
        event: "pageView",
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const onLogin = async (provider: string) => {
    if (provider === "Facebook" || provider === "Google") {
      await signInWithRedirect({
        provider: provider,
      });
    } else if (provider === "email") {
      HistoryOnmo.navigateToSignInEmailPage(history);
    } else if (provider === "phone") {
      HistoryOnmo.navigateToSignInPhonePage(history);
    } else if (provider === "onmoPay") {
      HistoryOnmo.navigateToOnmoPay(pages?.login?.onmoPayUrl || "/");
    }
  };

  useEffect(() => {
    if (path === PageRoutes.Guest || path === PageRoutes.Landing) {
      OnmoStorage.setLoginType("");
    }
  }, [path]);

  if (
    pages?.login?.isOnmoPayRedirect &&
    pages?.login?.onmoPayUrl &&
    System.isProd
  ) {
    return (
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.mainLoading}
      >
        <CircularProgress size={40} enableAnimation />
      </Grid>
    );
  }

  if (QueryParams.isUserDisabled()) {
    return <AccountBlocked className={classes.contentBlock} />;
  }

  return <B2bLoginTemplate onLogin={onLogin} />;
};

export default SignIn;
