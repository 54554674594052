import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

interface IAlert {
  classify: string;
  message: string;
  isShowAlert: boolean;
  isShowSnackbar: boolean;
  isShowCustomSnackbar: boolean;
  data?: string;
}

type AlertClassify = "success" | "info" | "error";

const initialState = {
  classify: "",
  message: "",
  isShowAlert: false,
  isShowSnackbar: false,
  isShowCustomSnackbar: false,
} as IAlert;

export const resetAlertState = createAction("alert/resetState");

export const alertSlice = createSlice({
  name: "alert",
  initialState: initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ classify: AlertClassify; message: string }>
    ) => {
      state.isShowAlert = true;
      state.message = action.payload.message;
      state.classify = action.payload.classify;
    },
    showSnackbar: (
      state,
      action: PayloadAction<{ message: string; data?: {} }>
    ) => {
      state.isShowSnackbar = true;
      state.message = action.payload.message;

      if (action.payload.data) {
        state.data = JSON.stringify({
          ...action.payload.data,
          message: state.message,
        });
      }
    },
    showCustomSnackbar: (state, action: PayloadAction<{ message: string }>) => {
      state.isShowCustomSnackbar = true;
      state.message = action.payload.message;
    },
    hideAlert: (state) => {
      state.classify = "";
      state.message = "";
      state.isShowAlert = false;
    },
    hideSnackbar: (state) => {
      state.message = "";
      state.isShowSnackbar = false;
      state.isShowCustomSnackbar = false;
      state.data = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAlertState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  showAlert,
  hideAlert,
  showSnackbar,
  hideSnackbar,
  showCustomSnackbar,
} = alertSlice.actions;

const setValueToAlert =
  (message: string, classify: AlertClassify): AppThunk =>
  async (dispatch) => {
    return new Promise((resolve) => {
      dispatch(showAlert({ message, classify }));
      setTimeout(() => {
        dispatch(hideAlert());
        resolve();
      }, 3000);
    });
  };

export const setNotificationSuccess = (message: string) =>
  setValueToAlert(message, "success");

export const setNotificationInfo = (message: string) =>
  setValueToAlert(message, "info");

export const setNotificationError = (message: string) =>
  setValueToAlert(message, "error");

export const setNotificationSnackbar =
  (message: string): AppThunk =>
  async (dispatch, getState) => {
    const gameSession = getState().gameSession.gameSession;
    const user = getState().user;
    const game = getState().game.game;
    const livestreamSession = getState().liveStream.livestreamSession;

    const data = gameSession && {
      userId: user?.me?.id,
      gameId: game?.id || gameSession?.moment.appId,
      gameSessionId: gameSession?.id,
      edgeNodeId: gameSession?.edgeNodeId,
      sessionId: livestreamSession?.id,
    };
    dispatch(showSnackbar({ message, data }));
  };

export default alertSlice.reducer;
