import { compact, filter, find, map, sortBy } from "lodash-es";
import { ACHIEVEMENTS } from "../../constants/achievements";
import { Achievement, Achievement_Type } from "../../legacyGraphql/graphql";
import { AchievementItemType } from "../../types/achievement";

const filterLockedAchievements = (achievements: AchievementItemType[]) => {
  return filter(achievements, (achievement) => achievement.currentTier > 0);
};

export const getAchievementsData = (
  achievements: Achievement[] | undefined,
  filterLocked = false
) => {
  if (!achievements || achievements.length === 0) return [];
  const achievementsIds = map(achievements, (a) => a.id);
  const achievementsData = sortBy(
    compact(
      map(ACHIEVEMENTS, (achievementData) => {
        if (achievementsIds.includes(achievementData.id as Achievement_Type)) {
          const achievement = find(
            achievements,
            (item) => item.id === achievementData.id
          );
          if (achievement) {
            return {
              ...achievement,
              name: achievementData.name,
              description: achievementData.description,
              icons: achievementData.icons[achievement.currentTier],
              values: achievementData.values[achievement.currentTier - 1],
              nextTier:
                achievement.currentTier <= 2
                  ? {
                      icons: achievementData.icons[achievement.currentTier + 1],
                      values: achievementData.values[achievement.currentTier],
                    }
                  : null,
            };
          }
        }
      })
    ),
    "id"
  );

  return filterLocked
    ? filterLockedAchievements(achievementsData)
    : achievementsData;
};

export const getBeforePlayAchievementsData = (
  beforePlayData: Achievement[] | undefined,
  achievementsData: AchievementItemType[] | undefined
) => {
  return sortBy(
    compact(
      map(beforePlayData, (achievement) => {
        const achievementData = find(
          achievementsData,
          (item) => item.id === achievement.id
        );
        if (!achievementData) return null;

        return {
          ...achievement,
          name: achievementData.name,
          description: achievementData?.description,
          icons: find(ACHIEVEMENTS, (data) => data.id === achievement.id)
            ?.icons[achievement.currentTier],
          values: find(ACHIEVEMENTS, (data) => data.id === achievement.id)
            ?.values[
            achievement.currentTier < 2 ? achievement.currentTier + 1 : 2
          ],
        };
      })
    ),
    "id"
  );
};

export const filterCompletedAchievements = (
  earnedAchievementsData: AchievementItemType[],
  earnedAchievementIds: Achievement_Type[]
) => {
  return sortBy(
    compact(
      map(earnedAchievementsData, (item) => {
        if (earnedAchievementIds?.includes(item.id)) {
          return item;
        }
      })
    ),
    "id"
  );
};

export const getCoinsFromAchievements = (
  achievements: AchievementItemType[]
) => {
  return achievements.reduce((acc, achievement) => {
    return acc + achievement.values.gems;
  }, 0);
};

export const getAchievementItemData = (
  achievementItem: AchievementItemType
) => {
  const getNextTierTarget = () => {
    if (!achievementItem) return 0;
    if (achievementItem.currentTier === 3) {
      return achievementItem.values.value;
    }

    return achievementItem.nextTier
      ? achievementItem.nextTier.values.value
      : achievementItem.nextTierTarget;
  };

  const getCurrentTierProgress = () => {
    return Math.round(
      achievementItem.id === "time_played"
        ? (achievementItem.progress || 0) / 60
        : achievementItem.progress || 0
    );
  };

  const getNextTierValue = () => {
    return achievementItem.currentTier === 3
      ? achievementItem.values.value
      : achievementItem.nextTier
      ? achievementItem.nextTier.values.value
      : achievementItem.nextTierTarget;
  };

  return {
    currentTierProgress: getCurrentTierProgress(),
    nextTierValue: getNextTierValue(),
    nextTierTarget: Math.round(getNextTierTarget()),
  };
};
