import React, {
  FC,
  ImgHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from "react";
import clsx from "classnames";
import { useStyles } from "./Image.style";
import { Skeleton } from "../Skeleton";

interface IImageProps {
  url: string;
  radius?: number | string;
}

const getUrlWithRatio = (url: string, ratio: "@2x" | "@3x"): string => {
  const arraySplitted = url.split(".");

  const extension = arraySplitted.pop();
  const name = arraySplitted.pop();

  arraySplitted.push(name + ratio);
  arraySplitted.push(extension || "");
  return arraySplitted.join(".");
};

export const ImageComponent: FC<
  IImageProps & ImgHTMLAttributes<HTMLImageElement>
> = ({ alt, url, radius, ...otherProps }) => {
  const imageUrl2x = getUrlWithRatio(url, "@2x");
  const imageUrl3x = getUrlWithRatio(url, "@3x");
  const ref = useRef<HTMLImageElement>(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (ref.current) {
      if (ref.current.srcset !== url) {
        ref.current.srcset = url;
      } else if (otherProps.onError) {
        otherProps.onError(e);
      } else {
        ref.current.style.opacity = "0";
      }
    }
  };

  return (
    <>
      {isLoading && (
        <Skeleton
          {...otherProps}
          data-testid="skeleton-image"
          style={{ ...otherProps.style, borderRadius: radius }}
          className={clsx(
            classes.root,
            { isHide: !isLoading },
            otherProps.className
          )}
        />
      )}
      <img
        {...otherProps}
        className={clsx(
          classes.root,
          { isHide: isLoading },
          otherProps.className
        )}
        srcSet={
          otherProps.srcSet || `${url} 1x, ${imageUrl2x} 2x, ${imageUrl3x} 3x`
        }
        style={{ borderRadius: radius }}
        ref={ref}
        src={url}
        alt={alt}
        onError={onError}
        onLoad={handleLoad}
      />
    </>
  );
};

export const Image = React.memo(ImageComponent);
