import React, { FC, ImgHTMLAttributes } from "react";
import clsx from "classnames";
import { useStyles } from "./BadgeGame.style";
import UnknownWebp from "./assets/unknown.webp";
import Unknown2xWebp from "./assets/unknown@2x.webp";
import Unknown3xWebp from "./assets/unknown@3x.webp";
import BronzeWebp from "./assets/bronze.webp";
import Bronze2xWebp from "./assets/bronze@2x.webp";
import Bronze3xWebp from "./assets/bronze@3x.webp";
import SilverWebp from "./assets/silver.webp";
import Silver2xWebp from "./assets/silver@2x.webp";
import Silver3xWebp from "./assets/silver@3x.webp";
import GoldWebp from "./assets/gold.webp";
import Gold2xWebp from "./assets/gold@2x.webp";
import Gold3xWebp from "./assets/gold@3x.webp";
import ExpertWebp from "./assets/expert.webp";
import Expert2xWebp from "./assets/expert@2x.webp";
import Expert3xWebp from "./assets/expert@3x.webp";
import MasterWebp from "./assets/master.webp";
import Master2xWebp from "./assets/master@2x.webp";
import Master3xWebp from "./assets/master@3x.webp";
import ChampionWebp from "./assets/champion.webp";
import Champion2xWebp from "./assets/champion@2x.webp";
import Champion3xWebp from "./assets/champion@3x.webp";
import GrandChampionWebp from "./assets/grand-champion.webp";
import GrandChampion2xWebp from "./assets/grand-champion@2x.webp";
import GrandChampion3xWebp from "./assets/grand-champion@3x.webp";

export type BadgeGameType =
  | "unranked"
  | "beginner"
  | "intermediate"
  | "advanced"
  | "expert"
  | "master"
  | "champion"
  | "grand-champion";

type BadgeGameSize =
  | "xs"
  | "s"
  | "m"
  | "l"
  | "xl"
  | "xxl"
  | "xxxl"
  | "xxxxl"
  | "xxxxxl"
  | "xxxxxxl";

interface IBadgeGame {
  type: BadgeGameType;
  size?: BadgeGameSize;
}

const getIconFromType = (type: BadgeGameType) => {
  switch (type) {
    case "grand-champion":
      return `${GrandChampionWebp} 1x, ${GrandChampion2xWebp} 2x, ${GrandChampion3xWebp} 3x`;
    case "champion":
      return `${ChampionWebp} 1x, ${Champion2xWebp} 2x, ${Champion3xWebp} 3x`;
    case "master":
      return `${MasterWebp} 1x, ${Master2xWebp} 2x, ${Master3xWebp} 3x`;
    case "expert":
      return `${ExpertWebp} 1x, ${Expert2xWebp} 2x, ${Expert3xWebp} 3x`;
    case "advanced":
      return `${GoldWebp} 1x, ${Gold2xWebp} 2x, ${Gold3xWebp} 3x`;
    case "intermediate":
      return `${SilverWebp} 1x, ${Silver2xWebp} 2x, ${Silver3xWebp} 3x`;
    case "beginner":
      return `${BronzeWebp} 1x, ${Bronze2xWebp} 2x, ${Bronze3xWebp} 3x`;
    default:
      return `${UnknownWebp} 1x, ${Unknown2xWebp} 2x, ${Unknown3xWebp} 3x`;
  }
};

export const BadgeGame: FC<
  IBadgeGame & ImgHTMLAttributes<HTMLImageElement>
> = ({ type, size, ...otherProps }) => {
  const classes = useStyles();

  return (
    <img
      {...otherProps}
      srcSet={getIconFromType(type)}
      alt={"badgegame"}
      className={clsx(classes.root, otherProps.className, size)}
    />
  );
};

BadgeGame.defaultProps = {
  type: "unranked",
  size: "m",
};
