import { QueryOptions } from "@apollo/client";
import { meGQL, userGQL, coinWalletsGQL } from "../../queries/users";
import apollo from "../../../clients/apollo";
import { Query } from "../../../graphql/graphql";

const apolloClient = apollo.getInstance();

export const queryMe = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["me"]> => {
  const res = await apolloClient.query({
    ...options,
    query: meGQL,
  });

  return res.data.me;
};

export const queryUser = async (
  options: Partial<QueryOptions> = {},
  userId: string
): Promise<Query["user"]> => {
  const res = await apolloClient.query({
    ...options,
    query: userGQL,
    variables: {
      userId,
    },
  });

  return res.data.user;
};

export const queryCoinWallets = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["me"]> => {
  const res = await apolloClient.query({
    ...options,
    query: coinWalletsGQL,
  });

  return res.data.me;
};
