export enum TimeLine {
  Weekly = "Weekly",
  Monthly = "Monthly",
  AllTime = "All time",
  Contest = "Contest",
}

export const LIST_TAB_LEADERBOARDS = [
  TimeLine.Weekly,
  TimeLine.Monthly,
  TimeLine.AllTime,
];

export enum LeaderboardType {
  Tournament = "Tournament",
  GlobalLeaderboard = "GlobalLeaderboard",
  HistoryGlobalLeaderboard = "HistoryGlobalLeaderboard",
  TournamentOnGoing = "TournamentOnGoing",
}
