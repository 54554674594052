import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { GridPaddingMapping } from "../../../constants/padding";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  spinner: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    border: `solid 5px #cfd0d1`,
    transform: "translate3d(-50%, -50%, 0)",
    willChange: "transform",
    animation: "$spinner 1.5s linear infinite",
    animationPlayState: "inherit",
    borderColor: `${DarkThemeColors.Background4} ${DarkThemeColors.Background4} ${theme.palette.primary.main}`,
    borderWidth: GridPaddingMapping.tiny2,
    marginTop: GridPaddingMapping.m,
  },
  "@keyframes spinner": {
    "0%": {
      transform: "translate3d(-50%, -50%, 0) rotate(0deg)",
    },
    "100%": {
      transform: "translate3d(-50%, -50%, 0) rotate(360deg)",
    },
  },
}));
