import { gql } from "graphql-tag";

export const achievementsGQL = gql`
  query Achievements($achievementsInput: AchievementsInput) {
    achievements(achievementsInput: $achievementsInput) {
      items {
        id
        currentTier
        progress
        nextTierTarget
      }
      totalCount
    }
  }
`;
