import { useEffect } from "react";
import { Routing } from "./routes";
import { ThemeProvider } from "react-jss";
import { withProfiler } from "@sentry/react";
import { useAppDispatch, useAppSelector } from "./hooks";
import { OnmoSplashScreen } from "./components/common/OnmoSplashScreen";
import { fetchTheme } from "./slices/theme";

function App() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);
  const splashScreen = useAppSelector((state) => state.splashScreen);

  useEffect(() => {
    // HIDE LAZY LOADING
    const loadingElement = document.getElementsByClassName(
      "loader"
    )[0] as HTMLDivElement;
    if (loadingElement) {
      loadingElement.style.display = "none";
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTheme());
  }, []);

  return (
    <ThemeProvider theme={theme.theme}>
      <OnmoSplashScreen
        open={splashScreen.isOpen}
        percentage={splashScreen.percentage}
      />
      <Routing />
    </ThemeProvider>
  );
}

export default withProfiler(App);
