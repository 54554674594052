export enum TabActivities {
  All = "All",
  Battles = "Battles",
  Challenges = "Challenges",
  Tournaments = "Tournaments",
  Leaderboards = "Leaderboards",
  Friends = "Friends",
}

export const LIST_TAB_ACTIVITIES = [
  TabActivities.All,
  TabActivities.Battles,
  TabActivities.Challenges,
  TabActivities.Tournaments,
  TabActivities.Leaderboards,
];

export type BattleTypeCategoryWithFilter =
  | "All"
  | "Battles"
  | "Challenges"
  | "Tournaments"
  | "Leaderboards"
  | "Friends";

export const LIST_TAB_PROFILE = [
  "Performance",
  "Achievements",
  "Recent Activities",
];
