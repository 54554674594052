import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { SpacingMapping } from "../../../constants/spacing";
import { GridPaddingMapping } from "../../../constants/padding";

export const useStyles = createUseStyles({
  root: {
    position: "fixed",
    color: DarkThemeColors.Text1,
    width: "100%",
    height: "100%",
    overflow: "scroll",
    flexWrap: "nowrap",
  },
  templateHeader: {
    padding: `${GridPaddingMapping.xxxl}px ${GridPaddingMapping.m}px ${GridPaddingMapping.m}px ${GridPaddingMapping.m}px`,
  },
  methods: {
    padding: SpacingMapping.space16,
    margin: "0px !important",
    position: "relative",
    alignItems: "flex-end",
    justifyContent: "end",
    minHeight: 340,
  },
  legal: {
    margin: `${GridPaddingMapping.m}px 0px`,
    width: "100%",
  },
  agreement: {
    fontSize: 14,
    textAlign: "center",
  },
  checkBox: {
    height: 13,
    width: 13,
  },
  freeCoins: {
    float: "right",
    position: "relative",
    marginBottom: 15,
    border: `2px solid ${DarkThemeColors.Border2}`,
  },
  backgroundImg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: -1,
    left: 0,
    objectFit: "fill",
  },
});
