import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  checkbox: {
    appearance: "none",
    cursor: "pointer",
    width: 16,
    height: 16,
    border: `2px solid ${DarkThemeColors.Text2}`,
    borderRadius: "10%",
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease",
    "&:disabled": {
      opacity: 0.5,
      background: DarkThemeColors.Text3,
      cursor: "default",
    },
    "&:checked": {
      appearance: "checkbox",
      width: 16,
      height: 16,
      accentColor: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
    },
  },
}));
