import { OnmoStorage } from "../models/onmoStorage";

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
  }
}

export const requestFullScreen = async () => {
  const isOpenDialog = document.getElementsByTagName("dialog")?.length;
  const fullscreenElement = document.fullscreenElement;
  const isHideFullScreen = OnmoStorage.getHideFullScreen();
  if (isHideFullScreen || fullscreenElement || isOpenDialog) return;

  const element = document.body;
  // Add try catch to prevent error when fullscreen is not supported
  try {
    if (element.requestFullscreen) {
      await element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      await element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      await element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      await element.msRequestFullscreen();
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error("Error requesting fullscreen mode:", error.message);
    }
  }
};
