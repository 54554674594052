import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { achievementsGQL } from "../../queries/achievements";
import { Query } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getAchievements = async (
  userId?: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["achievements"]> => {
  const res = await apolloClient.query({
    ...options,
    query: achievementsGQL,
    variables: {
      achievementsInput: {
        userId,
      },
    },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.achievements;
};
