import { gql } from "graphql-tag";

export const followersGQL = gql`
  query Followers($followersInput: FollowersInput) {
    followers(followersInput: $followersInput) {
      totalCount
      items {
        createdAt
        id
        receiver {
          lastCheckin
          username
          id
          avatar
          xp
          followStatus
        }
        sender {
          avatar
          id
          xp
          username
          lastCheckin
          followStatus
        }
        updatedAt
      }
    }
  }
`;

export const followingGQL = gql`
  query Following($followingInput: FollowersInput) {
    following(followingInput: $followingInput) {
      totalCount
      items {
        createdAt
        id
        receiver {
          username
          id
          avatar
          xp
          lastCheckin
          followStatus
        }
        sender {
          avatar
          id
          xp
          username
          lastCheckin
          followStatus
        }
        updatedAt
      }
    }
  }
`;

export const followSuggestionsGQL = gql`
  query FollowSuggestions {
    followSuggestions {
      items {
        lastCheckin
        avatar
        criteria
        id
        username
        xp
      }
    }
  }
`;
