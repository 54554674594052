import React, { FC, HTMLAttributes, useEffect } from "react";
import { OnmoSplashScreen } from "../../common/OnmoSplashScreen";
import { useHistory, useLocation } from "react-router-dom";
import { Base64 } from "js-base64";
import { signInPasswordless } from "../../../slices/cognitoUser";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setNotificationError } from "../../../slices/alert";

export const SignInProvider: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { loginState } = useAppSelector((state) => state.cognitoUser);
  const [fakeLoadingProgress, setFakeLoadingProgress] = React.useState(0);
  const { search } = location;
  const state = new URLSearchParams(search).get("state");

  const loginPasswordless = async (state: {
    cognitoUsername: string;
    secretCode: string;
  }) => {
    dispatch(signInPasswordless(state.cognitoUsername, state.secretCode));
  };

  useEffect(() => {
    const stateDecoded = Base64.decode(state || "");
    if (stateDecoded) {
      const parsedState = JSON.parse(stateDecoded);
      loginPasswordless(parsedState);
    }
  }, [state]);

  useEffect(() => {
    if (loginState?.error) {
      dispatch(setNotificationError(loginState.error.message));
      history.push("/");
    } else if (loginState?.isAuthenticated) {
      setFakeLoadingProgress(100);
    }
  }, [loginState]);

  useEffect(() => {
    const timer = setInterval(() => {
      setFakeLoadingProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          history.push("/");
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <OnmoSplashScreen open={true} percentage={fakeLoadingProgress} />
    </div>
  );
};
