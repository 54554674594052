import { gql } from "graphql-tag";

export const getGameSessionV2GQL = gql`
  query GetGameSessionV2($getGameSessionInput: GetGameSessionInput!) {
    getGameSessionV2(getGameSessionInput: $getGameSessionInput) {
      id
      edgeNodeId
      matchId
      host {
        id
        username
      }
      tournamentId
      tournamentPlayerId
      moment {
        id
        appId
        title
        description
        type
        momentType
        showTimer
        time
        rankOrder
        isCompleted
        data
        isCompleted
        zoneId
        status
        order
        app {
          title
          rotationMode
          type
          data
          status
        }
      }
      sessionType
      status
      time
      score
      failureMessage
    }
  }
`;
