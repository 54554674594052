import { PayloadAction, createSlice, createAction } from "@reduxjs/toolkit";
import {
  SubscriptionOperations,
  SubscriptionStatus,
} from "../constants/apollo";
import { AppThunk } from "../store";
import {
  ILiveStreamMessage,
  ILiveStreamMessageReceived,
} from "../types/liveStream";
import { handleLiveStreamMessage } from "../models/liveStream/liveStream";
import { HistoryOnmo } from "../models/historyOnmo";

// TODO
// BE does not provide type
// eslint-disable-next-line @typescript-eslint/no-explicit-any

interface IApollo {
  wsConnectionId: string | null;
  isSubAuthenticated: boolean;
  liveStreamMessage?: ILiveStreamMessage;
  isWalletSubReady: boolean;
}

const initialState = {
  wsConnectionId: null,
  isSubAuthenticated: false,
  liveStreamMessage: undefined,
  isWalletSubReady: false,
} as IApollo;

export const resetApolloState = createAction("apollo/resetState");

export const apolloSlice = createSlice({
  name: "apollo",
  initialState: initialState,
  reducers: {
    resetLiveStreamMessage: (state) => {
      state.liveStreamMessage = undefined;
    },
    subAuthenticated: (
      state,
      action: PayloadAction<{ connectionId: string }>
    ) => {
      state.isSubAuthenticated = true;
      state.wsConnectionId = action.payload.connectionId;
    },
    subUnauthenticated: (state) => {
      state.isSubAuthenticated = false;
    },
    setLivestreamMessage: (
      state,
      action: PayloadAction<{ liveStreamMessage: ILiveStreamMessageReceived }>
    ) => {
      state.liveStreamMessage = handleLiveStreamMessage(
        action.payload.liveStreamMessage
      );
    },
    setReadyWalletSub: (state) => {
      state.isWalletSubReady = true;
    },
    setNotReadyWalletSub: (state) => {
      state.isWalletSubReady = false;
    },
    disconnect: (state) => {
      state.isSubAuthenticated = false;
      state.isWalletSubReady = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetApolloState, () => initialState);
  },
});

export const {
  resetLiveStreamMessage,
  subAuthenticated,
  subUnauthenticated,
  setLivestreamMessage,
  setReadyWalletSub,
  setNotReadyWalletSub,
  disconnect,
} = apolloSlice.actions;

export const onWsMessage =
  (data: string): AppThunk =>
  (dispatch) => {
    try {
      const dataReceived = JSON.parse(data);
      if (dataReceived?.payload?.data?.status === SubscriptionStatus.Success) {
        const connectionId = dataReceived?.id?.split("=")[0];
        dispatch(subAuthenticated({ connectionId: connectionId + "=" }));
      } else if (
        dataReceived?.payload?.data?.status === SubscriptionStatus.Error
      ) {
        dispatch(subUnauthenticated());
      } else if (
        dataReceived?.payload?.data?.status === SubscriptionStatus.Subscribed &&
        dataReceived?.payload?.data?.operation ===
          SubscriptionOperations.WalletUpdated
      ) {
        dispatch(setReadyWalletSub());
      } else if (
        dataReceived.format === "onmowsv1" &&
        HistoryOnmo.isInGameSession()
      ) {
        dispatch(setLivestreamMessage({ liveStreamMessage: dataReceived }));
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log("[ws] onMessage got error ", e?.message);
      }
    }
  };

export default apolloSlice.reducer;
