import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { getUserNotification } from "../../queries/notifications";
import { Query } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getUserNotifications = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["userNotifications"]> => {
  try {
    const res = await apolloClient.query({
      ...options,
      query: getUserNotification,
      variables: {},
      context: { api: ApiLegacyName.Legacy },
    });

    return res.data.userNotifications;
  } catch (error) {
    console.log(error);
    return [];
  }
};
