import { SignInOutput, confirmSignIn, signIn, signUp } from "aws-amplify/auth";

export class Auth {
  static signInWithoutPassword = async (
    username: string
  ): Promise<SignInOutput> => {
    const res = await signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });

    return res;
  };

  static signUp = async (phone: string) => {
    await signUp({
      username: phone,
      password: Date.now().toString() + "dD",
      options: {
        userAttributes: {
          preferred_username: "",
          picture: "",
          email: "",
        },
      },
    });
  };

  static sendCustomChallengeAnswer = async (code: string) => {
    const response = await confirmSignIn({ challengeResponse: code });
    // need to keep this log to check with correct code. Will remove after ticket is done
    console.log("response verify code: ", response);
    if (!response.isSignedIn) {
      throw new Error("Incorrect code");
    }
  };

  static getGameIdFromOnCode = (redirectUrl: string) => {
    const match = redirectUrl.match(/\/game\/([^/?]+)(?:[/?]|$)/);
    const gameId = match ? match[1] : "";
    return gameId.toString();
  };
}
