import { createUseStyles } from "react-jss";
import { BREAK_POINTS } from "../../../constants/theme";

export const useStyles = createUseStyles({
  root: {
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    marginInlineStart: "auto",
    marginInlineEnd: "auto",
    paddingInlineStart: 16,
    paddingInlineEnd: 16,
  },
  maxWidth: {
    "&.maxWidthxs": {
      maxWidth: BREAK_POINTS.xs,
    },
    "&.maxWidthsm": {
      maxWidth: BREAK_POINTS.sm,
    },
    "&.maxWidthmd": {
      maxWidth: BREAK_POINTS.md,
    },
    "&.maxWidthlg": {
      maxWidth: BREAK_POINTS.lg,
    },
    "&.maxWidthxl": {
      maxWidth: BREAK_POINTS.xl,
    },
  },
});
