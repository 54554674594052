import React, { HTMLAttributes, FC, useEffect } from "react";
import clsx from "classnames";
import { Grid, Typography } from "../../core";
import { OnmoStorage } from "../../../models/onmoStorage";
import { Theme } from "../../../models/theme";
import { useStyles } from "./B2bSplashScreen.style";
import { StaticImage } from "../StaticImage";
import { useAppSelector } from "../../../hooks";
import OnmoLogo from "./assets/onmo-logo.webp";
import OnmoLogo2x from "./assets/onmo-logo@2x.webp";
import OnmoLogo3x from "./assets/onmo-logo@3x.webp";
import { ProgressBar } from "../../core/ProgressBar";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";

interface IB2bSplashScreenProps {
  percentage: number;
}

const B2bSplashScreenComponent: FC<
  IB2bSplashScreenProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const { theme } = useAppSelector((state) => state.theme);
  const { theme: themeStyle, app, pages } = theme;
  const { percentage } = props;

  useEffect(() => {
    OnmoStorage.setHideSplashScreen();
  }, []);

  const logoCenter = Theme.getImage3x(app?.name, pages?.splash?.logo1 || "");
  const logoPoweredBy = Theme.getImage3x(app?.name, pages?.splash?.logo3 || "");
  const logo2 = Theme.getImage3x(app?.name, pages?.splash?.logo2 || "");
  const logo4 = Theme.getImage3x(app?.name, pages?.splash?.logo4 || "");
  const logo5 = Theme.getImage3x(app?.name, pages?.splash?.logo5 || "");

  return (
    <Grid
      {...props}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.root}
      style={{ background: pages?.splash?.backgroundColor }}
    >
      {(pages?.splash?.logo4 || pages?.splash?.logo5) && (
        <Grid
          display="flex"
          justifyContent="space-between"
          className={classes.topContent}
        >
          {pages?.splash?.logo4 && (
            <StaticImage
              src={logo4?.oneX}
              src2x={logo4?.twoX}
              src3x={logo4?.threeX}
              disableTransformImg
            />
          )}
          {pages?.splash?.logo5 && (
            <StaticImage
              src={logo5?.oneX}
              src2x={logo5?.twoX}
              src3x={logo5?.threeX}
              disableTransformImg
            />
          )}
        </Grid>
      )}
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        className={classes.header}
      >
        <StaticImage
          src={logoCenter?.oneX || OnmoLogo}
          src2x={logoCenter?.twoX || OnmoLogo2x}
          src3x={logoCenter?.threeX || OnmoLogo3x}
          disableTransformImg
        />
        {pages?.splash?.logo2 && (
          <div className={classes.powerBy1}>
            <Typography
              variant={TypographyVariant.BodyB3Medium}
              color={TypographyColor.DarkDefault}
              className={classes.powerBy}
            >
              Powered by
            </Typography>
            <StaticImage
              src={logo2?.oneX}
              src2x={logo2?.twoX}
              src3x={logo2?.threeX}
              disableTransformImg
            />
          </div>
        )}
        {pages?.splash?.hasLoadingBar && (
          <div
            className={clsx(
              classes.progressContainer,
              pages?.splash?.logo2 && classes.hasLogo2
            )}
          >
            <ProgressBar
              color={themeStyle.palette.primary.main}
              value={{ initial: 0, target: percentage, total: 100 }}
              size="medium"
            />
          </div>
        )}
      </Grid>
      <div className={classes.bottom}>
        {pages?.splash?.logo3 && (
          <div>
            <Typography
              variant={TypographyVariant.BodyB3Medium}
              color={TypographyColor.DarkDefault}
              className={classes.powerBy}
              style={{
                color: Theme.pickTextColorFromBG(
                  pages?.splash?.backgroundColor || "#FFFFFF",
                  "#FFFFFF",
                  "#222222",
                  themeStyle?.overrideFontColor
                ),
              }}
            >
              Powered by
            </Typography>
            <StaticImage
              src={logoPoweredBy?.oneX}
              src2x={logoPoweredBy?.twoX}
              src3x={logoPoweredBy?.threeX}
              alt="power-by"
            />
          </div>
        )}
      </div>
    </Grid>
  );
};

export const B2bSplashScreen = React.memo(B2bSplashScreenComponent);
