import { gql } from "graphql-tag";

export const getListInventoriesGQL = gql`
  query Inventories($inventoriesInput: InventoriesInput) {
    inventories(inventoriesInput: $inventoriesInput) {
      items {
        applyImmediately
        category
        costs {
          amount
          coinType
        }
        createdAt
        data {
          ... on InventoryBundleData {
            items
            previewUrl
          }
          ... on InventoryAvatarData {
            url
          }
        }
        id
        isBundle
        isPurchased
        metadata
        status
        title
        updatedAt
      }
      totalCount
    }
  }
`;

export const getUserInventoriesGQL = gql`
  query UserInventories($userInventoriesInput: PaginationInput) {
    userInventories(userInventoriesInput: $userInventoriesInput) {
      items {
        applyImmediately
        category
        costs {
          amount
          coinType
        }
        createdAt
        data {
          ... on InventoryBundleData {
            items
            previewUrl
          }
          ... on InventoryAvatarData {
            url
          }
        }
        id
        isBundle
        isPurchased
        metadata
        status
        title
        updatedAt
      }
      totalCount
    }
  }
`;
