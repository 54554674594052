import { compact, concat } from "lodash-es";
import Rank1Icon from "../assets/competition/rank1_2x.webp";
import Rank2Icon from "../assets/competition/rank2_2x.webp";
import Rank3Icon from "../assets/competition/rank3_2x.webp";
import OutTopIcon from "../assets/competition/out-top_2x.webp";
import { getGlobalLeaderboards } from "../legacyGraphql/resolvers/mutation/globalLeaderboards";
import { ILeaderboardsInput } from "../types/leaderboards";
import { getGlobalLeaderboardProfile } from "../legacyGraphql/resolvers/queries/globalLeaderboards";
import {
  GlobalLeaderboard,
  GlobalLeaderboardItem,
  LeaderboardActivity,
  LeaderboardUserNotificationData,
  Leaderboard_Type,
  TimeFrame,
  TournamentInfo,
} from "../legacyGraphql/graphql";
import { LeaderboardType, TimeLine } from "../constants/leaderboards";
import { TFunction } from "i18next";
import { Month } from "../constants/month";
import { QueryOptions } from "@apollo/client";
import { formatScoreByMomentType } from "./gamesession/gamesession";
import { User } from "../graphql/graphql";

export class GlobalLeaderboardsMd {
  static getGlobalLeaderboards = () => getGlobalLeaderboards();

  static getGlobalLeaderboardProfile = (
    endDate: string,
    leaderboardType: string,
    options: Partial<QueryOptions> = {}
  ) => getGlobalLeaderboardProfile(endDate, leaderboardType, options);

  static addRankForPlayer = (leaderboard: ILeaderboardsInput[] | undefined) => {
    if (!leaderboard) return null;
    return leaderboard.map(
      (leaderboard: ILeaderboardsInput, index: number) => ({
        ...leaderboard,
        rank: index + 1,
      })
    );
  };

  static getListOutTop3Lb = (
    leaderboard: ILeaderboardsInput[] | undefined,
    user: User | undefined | null,
    myRank: number,
    myScore: number
  ) => {
    if (!leaderboard || !user) return null;

    const newLb = this.addRankForPlayer(leaderboard);
    if (!newLb || newLb.length < 4) return null;

    const outTop3LbWithoutMyRanking = newLb
      .slice(3)
      .filter((player) => player.id !== user.id);
    const myRanking = {
      banner: "",
      id: user.id,
      avatar: user.avatar,
      username: user.username,
      xp: user.xp,
      rank: myRank + 1,
      score: myScore,
    };

    const isMyRankingValid = myRanking.rank > 3 && myRanking.score !== -1;
    return compact(
      concat([isMyRankingValid && myRanking], outTop3LbWithoutMyRanking)
    );
  };

  static getTop3Lb = (leaderboard?: ILeaderboardsInput[]) => {
    if (!leaderboard) return null;
    const listTop3Lb = leaderboard.slice(0, 3);
    const sortedRank = [2, 1, 3];
    return sortedRank.map((rank) => {
      const user = listTop3Lb.find((_, i) => i === rank - 1);
      return { ...user, rank, type: "top3" };
    }) as ILeaderboardsInput[];
  };

  static getShareBackground = (rank: number) => {
    switch (rank) {
      case 1:
        return Rank1Icon;
      case 2:
        return Rank2Icon;
      case 3:
        return Rank3Icon;
      default:
        return OutTopIcon;
    }
  };

  static getTitleLb = (
    t: TFunction,
    lbActivity?: LeaderboardActivity,
    lbNotificationData?: LeaderboardUserNotificationData
  ) => {
    if (
      lbActivity?.type === "global-leaderboard-contests" ||
      lbNotificationData?.name
    ) {
      return t("common_Contest Leaderboard", { name: lbActivity?.name });
    } else {
      const date = new Date(
        lbNotificationData
          ? lbNotificationData?.endDate
          : JSON?.parse(lbActivity?.endDate ?? "")
      );
      const thisMonth = Month[date.getUTCMonth()];
      const typeLeaderboard =
        lbActivity?.type === "global-leaderboard-weekly" ||
        lbNotificationData?.timeFrame === TimeFrame.Weekly
          ? TimeLine.Weekly
          : thisMonth;

      return t(`competition_${typeLeaderboard} Leaderboard`);
    }
  };

  static getLeaderboardType = (type?: string) => {
    switch (type) {
      case "global-leaderboard-weekly":
        return Leaderboard_Type.Weekly;
      case "global-leaderboard-monthly":
        return Leaderboard_Type.Monthly;
      case "global-leaderboard-contests":
        return Leaderboard_Type.Contest;
      default:
        return Leaderboard_Type.Weekly;
    }
  };

  static convertTournamentToGlobalLeaderboard = (
    tournament?: TournamentInfo,
    myUserId?: string
  ) => {
    if (!tournament) return undefined;
    const { expiredAt, leaderboards, moment, myRank } = tournament;

    const myScore =
      leaderboards.find((data) => data.userId === myUserId)?.score ?? 0;
    const formattedLb = leaderboards.map((data) => ({
      ...data.user,
      score: formatScoreByMomentType(data.score as number, moment.type),
      __typename: "LeaderboardUser",
    }));

    return {
      endDate: expiredAt,
      leaderboard: formattedLb,
      myRank: myRank ? myRank - 1 : undefined,
      myScore: formatScoreByMomentType(myScore as number, moment.type),
      __typename: "GlobalLeaderboardItem",
    } as GlobalLeaderboardItem;
  };

  static getCurrentLeaderboard = (
    activeTab: string,
    globalLeaderboards?: GlobalLeaderboard | null,
    historyGlobalLeaderboard?: GlobalLeaderboardItem | null,
    tournamentLeaderboard?: GlobalLeaderboardItem | null,
    leaderboardType?: LeaderboardType
  ) => {
    switch (leaderboardType) {
      case LeaderboardType.GlobalLeaderboard:
        if (!globalLeaderboards) return null;
        switch (activeTab) {
          case TimeLine.Weekly:
            return globalLeaderboards.weekly;
          case TimeLine.Monthly:
            return globalLeaderboards.monthly;
          case TimeLine.AllTime:
            return globalLeaderboards.alltime;
          default:
            return null;
        }
      case LeaderboardType.Tournament:
      case LeaderboardType.TournamentOnGoing:
        return tournamentLeaderboard;
      case LeaderboardType.HistoryGlobalLeaderboard:
        return historyGlobalLeaderboard;
      default:
        return null;
    }
  };

  static getItemSize = (index: number) => {
    if (index === 0) {
      return 95;
    }
    return 70;
  };
}
