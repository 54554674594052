// font weight
const fontWeightBold = {
  fontWeight: 700,
};

const fontWeightMedium = {
  fontWeight: 500,
};

const fontWeightRegular = {
  fontWeight: 400,
};

const fontWeightLight = {
  fontWeight: 300,
};

const fontFamilyBody = {
  fontFamily: "Roboto",
};

// Heading
const h1Options = {
  fontSize: 26,
  lineHeight: "40px",
};

const h2Options = {
  fontSize: 22,
  lineHeight: "36px",
};

const h3Options = {
  fontSize: 18,
  lineHeight: "30px",
};

const h4Options = {
  fontSize: 16,
  lineHeight: "24px",
};

const h5Options = {
  fontSize: 14,
  lineHeight: "20px",
};

const h6Options = {
  fontSize: 12,
  lineHeight: "16px",
};

// Subtitle
const s1Options = {
  fontSize: 20,
  lineHeight: "28px",
};

const s2Options = {
  fontSize: 18,
  lineHeight: "24px",
};

const s3Options = {
  fontSize: 16,
  lineHeight: "24px",
};

// Body
const b1Options = {
  fontSize: 18,
  lineHeight: "24px",
};

const b2Options = {
  fontSize: 16,
  lineHeight: "22px",
};

const b3Options = {
  fontSize: 15,
  lineHeight: "22px",
};

const b4Options = {
  fontSize: 14,
  lineHeight: "20px",
};

const b5Options = {
  fontSize: 13,
  lineHeight: "20px",
};

const b6Options = {
  fontSize: 12,
  lineHeight: "16px",
};

// Caption
const captionOptions = {
  fontSize: 12,
  lineHeight: "16px",
};

// Overline
const o1Options = {
  fontSize: 14,
  lineHeight: "20px",
};

const o2Options = {
  fontSize: 12,
  lineHeight: "20px",
};

const e1Options = {
  fontSize: 40,
  lineHeight: "48px",
};

export default {
  // Heading
  "&.heading-h1-bold": {
    ...h1Options,
    ...fontWeightBold,
  },
  "&.heading-h2-bold": {
    ...h2Options,
    ...fontWeightBold,
  },
  "&.heading-h3-bold": {
    ...h3Options,
    ...fontWeightBold,
  },
  "&.heading-h4-bold": {
    ...h4Options,
    ...fontWeightBold,
  },
  "&.heading-h5-bold": {
    ...h5Options,
    ...fontWeightBold,
  },
  "&.heading-h6-bold": {
    ...h6Options,
    ...fontWeightBold,
  },

  // Subtitle s1
  "&.subtitle-s1-regular": {
    ...s1Options,
    ...fontWeightRegular,
  },
  "&.subtitle-s1-medium": {
    ...s1Options,
    ...fontWeightMedium,
  },
  "&.subtitle-s1-bold": {
    ...s1Options,
    ...fontWeightBold,
  },

  // Subtitle s2
  "&.subtitle-s2-regular": {
    ...s2Options,
    ...fontWeightRegular,
  },
  "&.subtitle-s2-medium": {
    ...s2Options,
    ...fontWeightMedium,
  },
  "&.subtitle-s2-bold": {
    ...s2Options,
    ...fontWeightBold,
  },

  // Subtitle s3
  "&.subtitle-s3-regular": {
    ...s3Options,
    ...fontWeightRegular,
  },
  "&.subtitle-s3-medium": {
    ...s3Options,
    ...fontWeightMedium,
  },
  "&.subtitle-s3-bold": {
    ...s3Options,
    ...fontWeightBold,
  },

  // Body b1
  "&.body-b1-bold": {
    ...b1Options,
    ...fontWeightBold,
    ...fontFamilyBody,
  },
  "&.body-b1-regular": {
    ...b1Options,
    ...fontWeightRegular,
    ...fontFamilyBody,
  },
  "&.body-b1-light": {
    ...b1Options,
    ...fontWeightLight,
    ...fontFamilyBody,
  },

  // Body b2
  "&.body-b2-bold": {
    ...b2Options,
    ...fontWeightBold,
  },
  "&.body-b2-regular": {
    ...b2Options,
    ...fontWeightRegular,
  },
  "&.body-b2-light": {
    ...b2Options,
    ...fontWeightLight,
  },

  // Body b3
  "&.body-b3-bold": {
    ...b3Options,
    ...fontWeightBold,
  },
  "&.body-b3-regular": {
    ...b3Options,
    ...fontWeightRegular,
  },
  "&.body-b3-light": {
    ...b3Options,
    ...fontWeightLight,
  },

  // Body b4
  "&.body-b4-bold": {
    ...b4Options,
    ...fontWeightBold,
  },
  "&.body-b4-regular": {
    ...b4Options,
    ...fontWeightRegular,
  },
  "&.body-b4-light": {
    ...b4Options,
    ...fontWeightLight,
  },

  // Body b5
  "&.body-b5-bold": {
    ...b5Options,
    ...fontWeightBold,
  },
  "&.body-b5-regular": {
    ...b5Options,
    ...fontWeightRegular,
  },
  "&.body-b5-light": {
    ...b5Options,
    ...fontWeightLight,
  },

  // Body b6
  "&.body-b6-bold": {
    ...b6Options,
    ...fontWeightBold,
  },
  "&.body-b6-regular": {
    ...b6Options,
    ...fontWeightRegular,
  },
  "&.body-b6-light": {
    ...b6Options,
    ...fontWeightLight,
  },

  // Caption
  "&.caption-regular": {
    ...captionOptions,
    ...fontWeightRegular,
  },
  "&.caption-medium": {
    ...captionOptions,
    ...fontWeightMedium,
  },
  "&.caption-bold": {
    ...captionOptions,
    ...fontWeightBold,
  },

  // Overlines
  "&.overline-o1-regular": {
    ...o1Options,
    ...fontWeightRegular,
  },
  "&.overline-o1-bold": {
    ...o1Options,
    ...fontWeightBold,
  },
  "&.overline-o2-regular": {
    ...o2Options,
    ...fontWeightRegular,
  },
  "&.overline-o2-bold": {
    ...o2Options,
    ...fontWeightBold,
  },

  // Emphatic
  "&.emphatic-e1-regular": {
    ...e1Options,
    ...fontWeightBold,
  },
  "&.emphatic-e1-medium": {
    ...e1Options,
    ...fontWeightBold,
  },
  "&.emphatic-e1-bold": {
    ...e1Options,
    ...fontWeightBold,
  },
};
