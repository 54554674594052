import React, { FC, HTMLAttributes, useState } from "react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "../../core";
import { B2bLoginTemplateHeader } from "../B2bLoginTemplateHeader";
import { B2bLoginTemplateActions } from "../B2bLoginTemplateActions";
import { FreeCoinsBanner } from "../FreeCoinsBanner";
import { useStyles } from "./B2bLoginTemplate.style";
import Background from "./assets/background.webp";
import Background2x from "./assets/background@2x.webp";
import Background3x from "./assets/background@3x.webp";
import { StaticImage } from "../StaticImage";
import { HistoryOnmo } from "../../../models/historyOnmo";
import { useAppSelector } from "../../../hooks";
import { TypographyVariant } from "../../core/Typography/Typography";

interface IB2bLoginTemplateProps {
  onLogin: (provider: string) => Promise<void>;
}

export const B2bLoginTemplate: FC<
  IB2bLoginTemplateProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { theme } = useAppSelector((state) => state.theme);
  const { pages } = theme;
  const { onLogin, ...otherProps } = props;
  const [isAccept, setIsAccept] = useState(false);

  const onClickTermsPrivacy = (type: "privacy" | "term") => {
    if (type === "term") {
      HistoryOnmo.navigateToTermAndConditionPage(history);
    } else {
      HistoryOnmo.navigateToPrivacyPolicyPage(history);
    }
  };

  const onChangeAccept = () => {
    setIsAccept(!isAccept);
  };

  return (
    <Grid
      className={clsx(classes.root, props.className)}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      {...otherProps}
    >
      <B2bLoginTemplateHeader className={classes.templateHeader} />
      <Grid display="flex" flexDirection="column" className={classes.methods}>
        {pages?.login?.template?.freeCoins && (
          <FreeCoinsBanner
            className={classes.freeCoins}
            data-testid="banner-bonus-coin"
          />
        )}
        <B2bLoginTemplateActions onLogin={onLogin} isAccept={isAccept} />
        <Grid display="flex" className={classes.legal}>
          {pages?.login?.template?.hasTextLegal && (
            <input
              type="checkbox"
              checked={isAccept}
              onChange={onChangeAccept}
              className={classes.checkBox}
            />
          )}
          {pages?.login?.template?.hasTextCheckbox && (
            <Typography
              className={classes.agreement}
              variant={TypographyVariant.BodyB5Regular}
              data-testid="text-agree-legal"
            >
              {t("common_By continuing, you agree to our", {
                defaultValue: "By continuing, you agree to our",
              })}{" "}
              <span
                role="none"
                onClick={() => onClickTermsPrivacy("term")}
                data-testid="term-link"
              >
                {t("common_Terms of Use", {
                  defaultValue: "Terms of Use",
                })}
              </span>
              {` ${t("common_and")} `}
              <span
                role="none"
                onClick={() => onClickTermsPrivacy("privacy")}
                data-testid="privacy-link"
              >
                {t("common_Privacy Policy", {
                  defaultValue: "Privacy Policy",
                })}
              </span>
            </Typography>
          )}
        </Grid>
      </Grid>
      <StaticImage
        src={Background}
        src2x={Background2x}
        src3x={Background3x}
        className={classes.backgroundImg}
        disableTransformImg
        alt="backgroundImg"
      />
    </Grid>
  );
};
