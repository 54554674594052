import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";
import TypographyDefault from "./TypographyDefault.style";
import TypographyMedium from "./TypographyMedium.style";
import TypographySmall from "./TypographySmall.style";

export const useStyles = createUseStyles((theme: ITheme) => {
  let textStyle: { [key: string]: unknown } = TypographyDefault;
  if (theme.textStyle === "small") {
    textStyle = TypographySmall;
  } else if (theme.textStyle === "medium") {
    textStyle = TypographyMedium;
  }
  return {
    root: {
      fontFamily: "roboto",
      fontWeight: "400",
      fontStyle: "normal",
      letterSpacing: "0px",
      margin: 0,
      padding: 0,
      "&.lines-1": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
      },
      "&.lines-2": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
      },
      "&.lines-3": {
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
      },

      // ===========================================================
      // Colors
      // ===========================================================

      // Primary & Secondary
      "&.primary": { color: theme.palette.primary.main },
      "&.secondary": { color: theme.palette.secondary.main },

      // Text on Dark
      "&.dark-default": { color: "#FFFFFF" },
      "&.dark-secondary": { color: "#C0C0C0" },
      "&.dark-tiertiary": { color: "#8D8D8D" },
      "&.dark-quinary": { color: "#303030" },
      "&.dark-senary": { color: "#6A6A6A" },
      "&.dark-relative": { color: "#1F1F1F" },
      "&.dark-success": { color: "#25E343" },
      "&.dark-warning": { color: "#F7A039" },
      "&.dark-error": { color: "#FC4848" },
      "&.dark-information": { color: "#6DA4D8" },
      "&.dark-disabled": { color: "#6A6A6A" },
      "&.dark-coins": { color: "#EEC55B" },
      "&.dark-gems": { color: "#36D2A5" },
      "&.inherit": { color: "inherit" },

      // ===========================================================
      // Font style, decoration, size, line height
      // ===========================================================

      ...textStyle,
    },
  };
});
