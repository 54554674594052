import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

interface IMaintenance {
  isEnabled: boolean;
  reason: string;
}

const initialState = {
  isEnabled: false,
  reason: "",
} as IMaintenance;

export const resetMaintenanceState = createAction("maintenance/resetState");

export const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState: initialState,
  reducers: {
    startMaintenance: (state, action: PayloadAction<{ reason: string }>) => {
      state.isEnabled = true;
      state.reason = action.payload.reason;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetMaintenanceState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { startMaintenance } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
