import { createUseStyles } from "react-jss";
import { DarkThemeColors, LegacyColor } from "../../../constants/colors";
import { ZIndex } from "../../../constants/zIndex";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    position: "fixed",
    zIndex: ZIndex.dialog,
    top: 0,
    insetInlineStart: 0,
    minHeight: "100vh",
    background: "rgba(0, 0, 0, 0.9)",
    width: "100%",
  },
  logo: {
    position: "absolute",
    height: 15,
    width: 39,
    insetInlineStart: 0,
    insetInlineEnd: 0,
    margin: "0 auto",
  },
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  spinner: {
    width: 64,
    height: 64,
    borderRadius: "50%",
    background: `conic-gradient(from 5deg at 50% 50%, rgba(39, 174, 96, 0) 0deg, rgba(72, 159, 133, 0) 0.04deg, ${LegacyColor.color17} 157.08deg, ${theme.palette.primary.main} 360deg)`,
    position: "relative",
    animation: "$spin 1.4s linear infinite",
    transform: "translateZ(0)",
    "&:after": {
      background: "#191414",
      width: "80%",
      height: "80%",
      borderRadius: "50%",
      content: `""`,
      margin: "auto",
      position: "absolute",
      top: 0,
      insetInlineStart: 0,
      bottom: 0,
      insetInlineEnd: 0,
    },
    "&:before": {
      content: `""`,
      background: theme.palette.primary.main,
      width: 6,
      height: 6,
      position: "absolute",
      borderRadius: "50%",
      insetInlineStart: 31,
    },
  },
  textLoading: {
    fontWeight: 500,
    color: DarkThemeColors.Text1,
    marginTop: "10px !important",
    textAlign: "center",
  },
}));
