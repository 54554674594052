import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  ripple: {
    display: "flex",
    flexDirection: "column",
    objectFit: "cover",
    "& > :first-child": {
      backgroundPosition: "center",
      transition: "background 0.5s",
      "&:active": {
        backgroundColor: "#fff",
        backgroundSize: "100%",
        transition: "background 0s",
      },
    },
    "&.button": {
      "& > :first-child": {
        backgroundPosition: "center",
        transition: "background 0.5s",
        "&:active": {
          backgroundColor: "#fff",
          backgroundSize: "100%",
          transition: "background 0s",
        },
      },
    },
    "&.card": {
      "& > :first-child": {
        backgroundPosition: "center",
        transition: "background 0.5s",
        "&:active": {
          backgroundColor: "rgba(204, 204, 204, 1)",
          backgroundSize: "100%",
          transition: "background 0s",
        },
      },
    },
  },
});
