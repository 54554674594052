import React, { FC, HTMLAttributes } from "react";
import { useStyles } from "./FallbackError.style";
import { Box, Button, Typography } from "../../core";
import clsx from "classnames";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";
import { useTranslation } from "react-i18next";

export const FallbackError: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box {...props} className={clsx(classes.root, props.className)}>
      <Typography
        variant={TypographyVariant.HeadingH5Bold}
        color={TypographyColor.DarkDefault}
      >
        {t("Maintenance_We are having issues", {
          defaultValue: "We are having issues",
        })}
      </Typography>
      <Typography
        variant={TypographyVariant.BodyB5Regular}
        color={TypographyColor.DarkSecondary}
        className={classes.description}
      >
        {t(
          "Maintenance_We are having internal issues with our servers, please come back later.",
          {
            defaultValue:
              "We are having internal issues with our servers, please come back later.",
          }
        )}
      </Typography>
      <Button
        variant="contained"
        data-testid="button-error"
        className={classes.button}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <Typography
          variant={TypographyVariant.BodyB5Bold}
          color={TypographyColor.DarkDefault}
        >
          {t("noInternet_Refresh", { defaultValue: "Refresh" })}
        </Typography>
      </Button>
    </Box>
  );
};
