import apollo from "../../../clients/apollo";
import { ApiLegacyName } from "../../../constants/apollo";
import {
  Mutation,
  PurchaseInventoryInput,
} from "../../../legacyGraphql/graphql";
import {
  applyInventoryGQL,
  purchaseInventoryGQL,
} from "../../mutations/inventories";

const apolloClient = apollo.getInstance();

export const purchaseInventory = async (
  purchaseInventoryInput: PurchaseInventoryInput
): Promise<Mutation["purchaseInventory"]> => {
  const res = await apolloClient.mutate({
    mutation: purchaseInventoryGQL,
    variables: { purchaseInventoryInput },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.applyInventory;
};
export const applyInventory = async (
  inventoryId: string
): Promise<Mutation["applyInventory"]> => {
  const res = await apolloClient.mutate({
    mutation: applyInventoryGQL,
    variables: { applyInventoryInput: { inventoryId } },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.applyInventory;
};
