import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SignIn as SignInComponent } from "../../components/pages/Guest";
import { HistoryOnmo } from "../../models/historyOnmo";
import { isSignedIn } from "../../slices/cognitoUser";
import { useAppSelector } from "../../hooks";

export const SignIn = () => {
  const signedIn = useAppSelector(isSignedIn);
  const history = useHistory();

  useEffect(() => {
    if (signedIn) {
      HistoryOnmo.navigateToHomePage(history);
    }
  }, [signedIn]);

  return <SignInComponent />;
};

export default SignIn;
