import { gql } from "graphql-tag";

export const listTransactionsGQL = gql`
  query Items($listTransactionsInput: TransactionsInput) {
    transactions(listTransactionsInput: $listTransactionsInput) {
      items {
        amount
        category
        currency
        createdAt
        expiryDate
        entryId
        gameId
        gameTitle
        reference
        runningBalance
        status
        transactionType
        type
        walletType
        workflow
      }
      totalCount
    }
  }
`;
