import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { ISessionResults } from "../types/sessionResult";
import { showSnackbar } from "./alert";
import { AppThunk, RootState } from "../store";
import { isErrorMaintenance } from "../models/users/users";
import { startMaintenance } from "./maintenance";
import { getGameSession } from "../legacyGraphql/resolvers/queries/gameSessions";
import { GameSessionV2, GameType } from "../legacyGraphql/graphql";
import { selectChallengesSorted, updateGame } from "./game";
import {
  getSessionResults,
  isBattleSession,
  isCasualSession,
} from "../models/gamesession/gamesession";
import { resetSessionLivestream } from "./liveStream";
import { resetLiveStreamMessage } from "./apollo";

export const GAMESESSION_STATE = {
  NONE: 0,
  LOADING: 1,
  PLAYING: 2,
  ERROR: 4,
};

export const GAME_STATUS = {
  UNLOAD: 0,
  LOADING: 1,
  LOADED: 2,
  READY: 3,
  PLAYING: 4,
  ENDED: 5,
  ERROR: 6,
};

export const GAMESESSION_STATUS = {
  STARTED: 8,
  LOADING_STREAM: 17,
  PLAYING: 18,
  PAUSING: 34,
  CALCULATE_RESULT: 66,
  VIEWING_END_RESULT: 130,
  SESSION_ENDED: 258,
  SHOW_LEAVE_POPUP: 1026,
  ERROR_STREAM_LOAD: 36,
  ERROR_API_END_RESULT: 68,
  ERROR_LIVE_CRASH: 132,
  ERROR_DISCONNECTED: 388,
  ERROR_NOT_ENOUGH_COIN: 516,
  ERROR_GAME_UNABLE_TO_PLAY: 1028,
};

export const BLOCKING_STATUS = {
  BLOCKING: 1,
  UNBLOCK: 0,
};

interface IGameSession {
  gameSession?: GameSessionV2;
  gameSessionStatus: number;
  gameStatus: number;
  sessionResults?: ISessionResults;
  blockingStatus: number;
  currentEdgeNodeId?: string;
}

const initialState = {
  gameSession: undefined,
  gameSessionStatus: 0,
  blockingStatus: 0,
  gameStatus: GAME_STATUS.UNLOAD,
  sessionResults: undefined,
} as IGameSession;

export const resetStateGameSession = createAction("gamesession/resetState");

export const gamesessionSlice = createSlice({
  name: "gamesession",
  initialState: initialState,
  reducers: {
    setGameSessionStatus: (
      state,
      action: PayloadAction<{ gameSessionStatus: number }>
    ) => {
      switch (state.gameSessionStatus) {
        case GAMESESSION_STATUS.ERROR_STREAM_LOAD:
        case GAMESESSION_STATUS.ERROR_API_END_RESULT:
        case GAMESESSION_STATUS.ERROR_LIVE_CRASH:
        case GAMESESSION_STATUS.ERROR_DISCONNECTED:
        case GAMESESSION_STATUS.ERROR_NOT_ENOUGH_COIN:
        case GAMESESSION_STATUS.ERROR_GAME_UNABLE_TO_PLAY:
        case GAMESESSION_STATUS.SESSION_ENDED:
        case GAMESESSION_STATUS.CALCULATE_RESULT:
        case GAMESESSION_STATUS.SHOW_LEAVE_POPUP:
          // Cancel update
          if (
            action.payload.gameSessionStatus === GAMESESSION_STATUS.PAUSING ||
            action.payload.gameSessionStatus === GAMESESSION_STATE.LOADING
          ) {
            return;
          }
          break;

        case GAMESESSION_STATUS.PAUSING:
          // Cancel update
          if (
            action.payload.gameSessionStatus ===
            GAMESESSION_STATUS.LOADING_STREAM
          ) {
            return;
          }
          break;
      }

      state.gameSessionStatus = action.payload.gameSessionStatus;
    },
    setGameSession: (
      state,
      action: PayloadAction<{ gameSession?: GameSessionV2 }>
    ) => {
      state.gameSession = action.payload.gameSession;
      if (action.payload.gameSession?.moment.app.type === GameType.Stream) {
        state.currentEdgeNodeId = action.payload.gameSession?.edgeNodeId;
      }
      state.sessionResults = getSessionResults(action.payload.gameSession);
    },
    setCurrentEdgeNodeId: (
      state,
      action: PayloadAction<{ edgeNodeId?: string }>
    ) => {
      state.currentEdgeNodeId = action.payload.edgeNodeId;
    },
    setGameSessionResults: (
      state,
      action: PayloadAction<{ sessionResults?: ISessionResults }>
    ) => {
      state.sessionResults = action.payload.sessionResults;
    },
    setBlockingStatus: (
      state,
      action: PayloadAction<{ blockingStatus: number }>
    ) => {
      state.blockingStatus = action.payload.blockingStatus;
    },
    resetGameSession: (state) => {
      state.gameSession = undefined;
    },
    resetGameSessionStatus: (state) => {
      state.gameSessionStatus = GAMESESSION_STATE.NONE;
    },
    setGameStatus: (state, action: PayloadAction<{ gameStatus: number }>) => {
      state.gameStatus = action.payload.gameStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameSession.rejected, (state) => {
        state.gameSession = undefined;
        state.sessionResults = undefined;
      })
      .addCase(fetchGameSession.fulfilled, (state, action) => {
        state.gameSession = action.payload;
        if (action.payload?.moment.app.type === GameType.Stream) {
          state.currentEdgeNodeId = action.payload?.edgeNodeId;
        }
        state.sessionResults = getSessionResults(action.payload);
      })
      .addCase(resetStateGameSession, () => initialState);
  },
});

export const fetchGameSession = createAsyncThunk(
  "gamesession/fetchGameSession",
  async (gameSessionId: string, { dispatch, signal }) => {
    try {
      const gameSession = await getGameSession(gameSessionId, {
        fetchPolicy: "network-only",
        context: {
          fetchOptions: {
            signal,
          },
        },
      });
      return gameSession;
    } catch (e) {
      if (isErrorMaintenance(e)) {
        dispatch(startMaintenance({ reason: "" }));
      } else if (e instanceof Error) {
        dispatch(
          showSnackbar({
            message: `Fail to get game session ${gameSessionId} ` + e.message,
            data: {
              gameSessionId: gameSessionId,
            },
          })
        );
      }
      throw e;
    }
  }
);

export const clearAllGameSessionState = (): AppThunk => async (dispatch) => {
  dispatch(resetStateGameSession());
  dispatch(resetSessionLivestream());
  dispatch(resetLiveStreamMessage());
  dispatch(updateGame({ game: undefined }));
};

// Action creators are generated for each case reducer function
export const {
  setGameSessionStatus,
  setGameSession,
  setGameSessionResults,
  setBlockingStatus,
  resetGameSession,
  resetGameSessionStatus,
  setGameStatus,
  setCurrentEdgeNodeId,
} = gamesessionSlice.actions;

export const selectChallengeGemReward = createSelector(
  [
    (state: RootState) => state.game.game,
    (state: RootState) => state.gameSession.gameSession,
    (state: RootState) => state.user.config,
    (state: RootState, isWin: boolean) => isWin,
  ],
  (game, gameSession, config, isWin) => {
    const currentGameMoment = game?.soloChallenges.find(
      (moment) => moment.id === gameSession?.moment.id
    );
    if (!isWin) {
      return config?.prizePools.challenge.coinsFailure;
    } else {
      if (currentGameMoment?.isCompleted) {
        return config?.prizePools.challenge.coinsCompletion;
      } else {
        return config?.prizePools.challenge.coinsFirstTimeCompletion;
      }
    }
  }
);
export const selectNextMoment = createSelector(
  [
    selectChallengesSorted,
    (state: RootState) => state.game.game,
    (state: RootState) => state.gameSession.gameSession,
  ],
  (listMomentSorted, game, gameSession) => {
    if (!game) return;
    const currentMomentIndex =
      listMomentSorted?.findIndex(
        (moment) => moment.id === gameSession?.moment.id
      ) || 0;

    if (!listMomentSorted) return;
    if (currentMomentIndex + 1 >= listMomentSorted.length) {
      return listMomentSorted[0];
    } else return listMomentSorted[currentMomentIndex + 1];
  }
);

export const selectWinGemReward = createSelector(
  [
    (state: RootState) => state.user.config,
    (state: RootState) => state.gameSession.gameSession,
  ],
  (config, gameSession) => {
    if (isCasualSession(gameSession?.sessionType)) {
      return !gameSession?.moment.isCompleted
        ? config?.prizePools.challenge.coinsFirstTimeCompletion
        : config?.prizePools.challenge.coinsCompletion;
    } else if (isBattleSession(gameSession?.sessionType)) {
      return config?.prizePools.battle.coinsWinner;
    } else return undefined;
  }
);

export const selectGameLoading = createSelector(
  [(state: RootState) => state.gameSession],
  (gameSessionState) => {
    return (
      gameSessionState.gameStatus < GAME_STATUS.READY &&
      [GAMESESSION_STATUS.STARTED, GAMESESSION_STATUS.LOADING_STREAM].includes(
        gameSessionState.gameSessionStatus
      )
    );
  }
);

export default gamesessionSlice.reducer;
