import { gql } from "graphql-tag";

export const getUserNotification = gql`
  query UserNotificationQuery {
    userNotifications {
      id
      type
      userId
      createdAt
      data {
        ... on FriendUserNotificationData {
          friend {
            avatar
            id
            username
          }
        }
        ... on LeaderboardUserNotificationData {
          timeFrame
          endDate
          name
          rank
          banner
          leaderboardType
        }
        ... on TournamentUserNotificationData {
          tournamentId
          gameId
          rank
          demotingUser {
            avatar
            id
            username
          }
        }
        ... on AchievementRewardUserNotificationData {
          type
          balance
          isEndScreenReward
        }
        ... on BattleUserNotificationData {
          matchState
          matchId
          gameId
          expiryTime
          host {
            avatar
            id
            username
          }
          opponent {
            username
            avatar
            id
          }
        }
        ... on FollowNotificationData {
          followerUser {
            id
            username
            avatar
            banner
            xp
            lastCheckin
            followStatus
          }
        }
      }
    }
  }
`;
