import { createUseStyles } from "react-jss";
import { LegacyColor } from "../../../constants/colors";
import { BorderRadius } from "../../../constants/borderRadius";
import { GridPaddingMapping } from "../../../constants/padding";

export const useStyles = createUseStyles({
  root: {
    textAlign: "center",
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
  },
  btnContact: {
    borderRadius: BorderRadius.sm,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: `${GridPaddingMapping.m}px 0`,
    minWidth: 136,
  },
  supportLink: {
    color: LegacyColor.color18,
  },
});
