import { gql } from "graphql-tag";

export const getGlobalLeaderboardProfileGQL = gql`
  query Query($globalLeaderboardInput: GlobalLeaderboardInput) {
    globalLeaderboard(globalLeaderboardInput: $globalLeaderboardInput) {
      endDate
      leaderboard {
        id
        username
        avatar
        banner
        score
        xp
      }
      myRank
      myScore
    }
  }
`;
