import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { IEventRules } from "../types/eventRules";
import { AppThunk, RootState } from "../store";
import { OnmoStorage } from "../models/onmoStorage";
import { EventRules } from "../models/eventRules";
import { ContestsOutput, TournamentInfo } from "../legacyGraphql/graphql";
import { getContests } from "../legacyGraphql/resolvers/queries/contests";
import { setNotificationSnackbar } from "./alert";
import {
  getTournament,
  listMWCTournaments,
  listTournaments,
} from "../legacyGraphql/resolvers/queries/tournament";
import { Game } from "../graphql/graphql";
import map from "lodash-es/map";
import { FeaturedType } from "../constants/tournaments";
import { Feature } from "../models/feature";
import { getListTournamentSorted } from "../models/tournament/tournament";
import { getFeaturedGame } from "../models/game/game";

type ContestCustom = {
  id: string;
  imageUrl: string;
  url?: string;
  __typename: string;
};
export type Featured = (TournamentInfo | Game | ContestCustom)[];

const typeMapping: Record<string, FeaturedType> = {
  NGame: FeaturedType.Game,
  Game: FeaturedType.Game,
  TournamentInfo: FeaturedType.Tournament,
  Banner: FeaturedType.Banner,
  ShopBanner: FeaturedType.ShopBanner,
  Contest: FeaturedType.Contest,
};

interface IDiscover {
  tournamentList: TournamentInfo[] | null;
  eventRules: IEventRules[] | null;
  contests: ContestsOutput | null;
  tournamentMWC: TournamentInfo | null;
}

const initialState = {
  tournamentList: null,
  eventRules: null,
  contests: null,
  tournamentMWC: null,
} as IDiscover;

export const resetDiscoverState = createAction("discover/resetState");

export const discoverSlice = createSlice({
  name: "discover",
  initialState: initialState,
  reducers: {
    updateTournaments: (
      state,
      action: PayloadAction<{ tournamentList: TournamentInfo[] }>
    ) => {
      state.tournamentList = action.payload.tournamentList;
    },
    updateTournamentsMWC: (
      state,
      action: PayloadAction<{ tournamentMWC: TournamentInfo | null }>
    ) => {
      state.tournamentMWC = action.payload.tournamentMWC;
    },
    updateEventRules: (
      state,
      action: PayloadAction<{ eventRules: IEventRules[] }>
    ) => {
      state.eventRules = action.payload.eventRules;
    },
    updateContests: (
      state,
      action: PayloadAction<{ contests: ContestsOutput }>
    ) => {
      state.contests = action.payload.contests;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetDiscoverState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateTournaments: updateTournamentsAction,
  updateEventRules: updateEventRulesAction,
  updateContests: updateContestsAction,
  updateTournamentsMWC,
} = discoverSlice.actions;

export const fetchTournamentList =
  (limit?: number): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentList = await listTournaments({
        getLeaderboardInput: { limit },
      });
      OnmoStorage.setListTournament(JSON.stringify(tournamentList));
      dispatch(updateTournamentsAction({ tournamentList }));
    } catch (e) {
      if (e instanceof Error) {
        console.error(e?.message);
      }
      dispatch(updateTournamentsAction({ tournamentList: [] }));
    }
  };

export const fetchMWCTournamentList =
  (limit?: number): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentList = await listMWCTournaments({
        getLeaderboardInput: { limit },
      });
      OnmoStorage.setListTournament(JSON.stringify(tournamentList));
      dispatch(updateTournamentsAction({ tournamentList }));
    } catch (e) {
      if (e instanceof Error) {
        console.error(e?.message);
      }
      dispatch(updateTournamentsAction({ tournamentList: [] }));
    }
  };

export const fetchTournamentMWC =
  (tournamentId: string): AppThunk =>
  async (dispatch) => {
    try {
      const tournamentMWC = await getTournament(
        { id: tournamentId, limit: 50 },
        { fetchPolicy: "network-only" }
      );
      dispatch(updateTournamentsMWC({ tournamentMWC }));
    } catch (e) {
      dispatch(setNotificationSnackbar((e as Error)?.message));
      dispatch(updateTournamentsMWC({ tournamentMWC: null }));
    }
  };

export const fetchEventRules = (): AppThunk => async (dispatch) => {
  try {
    const eventRules = await EventRules.getEventRules();
    dispatch(updateEventRulesAction({ eventRules }));
    return eventRules;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
    dispatch(updateEventRulesAction({ eventRules: [] }));
  }
};

export const fetchContests = (): AppThunk => async (dispatch) => {
  try {
    const contests = await getContests();
    dispatch(updateContestsAction({ contests }));
    return contests;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e?.message);
    }
  }
};

export const selectQualifiedContests = createSelector(
  [(state: RootState) => state.discover.contests],
  (contests) => {
    return contests?.items.filter((item) => item.isQualified);
  }
);

export const tournamentMWCSelector = createSelector(
  [
    (state: RootState) => state.discover.tournamentList,
    (state: RootState) => state.discover.tournamentMWC,
    (state: RootState, tournamentId?: string) => tournamentId,
  ],
  (tournamentList, tournamentMWC, tournamentId) => {
    const theMostPlayerInLeaderboards = tournamentList?.reduce((a, b) => {
      return a.leaderboards.length > b.leaderboards.length ? a : b;
    });

    if (tournamentId) {
      return tournamentMWC;
    } else return theMostPlayerInLeaderboards;
  }
);

export const featuredListSelector = createSelector(
  [
    (state: RootState) => state.game.listGame,
    (state: RootState) => state.theme.theme.pages,
    (state: RootState) => state.discover.contests,
    (state: RootState) => state.discover.tournamentList,
  ],
  (listGame, pages, contests, tournamentList) => {
    const listFeaturedTournament = getListTournamentSorted(tournamentList);
    const listFeaturedGame = getFeaturedGame(listGame);

    let featureList = [
      ...(Feature.getQualifiedContests(contests?.items) ?? []),
      ...listFeaturedTournament,
      ...(listFeaturedGame ?? []),
    ] as Featured;

    //add banner to feature list
    if (pages?.home?.bannerImageUrl) {
      featureList = [
        Feature.createBanner(
          pages.home.bannerImageUrl,
          pages.home?.bannerRedirectUrl
        ),
        ...featureList,
      ];
    }

    //add shop banner to feature list
    if (pages?.home?.showFeaturedShopBanner) {
      featureList = [Feature.createShopBanner(), ...featureList];
    }

    const mapDataWithType = map(featureList, (item) => ({
      type: typeMapping[item.__typename],
      data: item,
    }));

    return mapDataWithType;
  }
);

export default discoverSlice.reducer;
