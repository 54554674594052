import { gql } from "graphql-tag";

export const getActivitiesGQL = gql`
  query Activities($activitiesInput: ActivitiesInput) {
    activities(activitiesInput: $activitiesInput) {
      items {
        ... on BattleInviteActivity {
          battleId
          gameId
          battleType
          momentId
          activityType
          opponentUsers {
            lastCheckin
            id
            username
            avatar
            banner
            xp
            followStatus
          }
          gameTitle
          createdAt
        }
        ... on BattleInviteSentActivity {
          battleId
          gameId
          battleType
          momentId
          activityType
          gameTitle
          createdAt
          opponentUsers {
            id
            username
            avatar
            banner
            xp
          }
        }
        ... on BattleRejectedActivity {
          battleId
          gameId
          battleType
          momentId
          activityType
          gameTitle
          createdAt
          opponentUsers {
            id
            username
            avatar
            banner
            xp
          }
        }
        ... on BattleFinishActivity {
          battleId
          gameId
          gameTitle
          battleType
          momentId
          activityType
          rank
          winner
          createdAt
          challengeNumber
          opponentUsers {
            id
            username
            avatar
            banner
            xp
          }
        }
        ... on BattleCancelledActivity {
          battleId
          gameId
          battleType
          momentId
          activityType
          gameTitle
          createdAt
          opponentUsers {
            id
            username
            avatar
            banner
            xp
          }
        }
        ... on BattleExpiredActivity {
          battleId
          gameId
          gameTitle
          battleType
          momentId
          activityType
          createdAt
          opponentUsers {
            id
            username
            avatar
            banner
            xp
          }
        }
        ... on LeaderboardActivity {
          activityType
          type
          rank
          endDate
          name
          banner
          createdAt
          timeFrame
        }
        ... on TournamentPlayedActivity {
          activityType
          gameId
          battleId
          gameTitle
          battleType
          rank
          createdAt
        }
        ... on BattlePlayedActivity {
          activityType
          gameId
          gameTitle
          battleId
          battleType
          createdAt
        }
        ... on DefaultActivity {
          activityType
          createdAt
        }
      }
      totalCount
    }
  }
`;
export const getTotalActivitiesGQL = gql`
  query Activities($activitiesInput: ActivitiesInput) {
    activities(activitiesInput: $activitiesInput) {
      totalCount
    }
  }
`;
