import { use } from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { ONMO_CONFIG } from "./constants/onmo";
import { Translations } from "./models/translations";

export const initializeI18N = () => {
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      load: "currentOnly",
      fallbackLng: "en",
      lng: Translations.getDefaultLanguage(),
      supportedLngs: ONMO_CONFIG?.i18n?.languages,
      defaultNS: "common",
      debug: false,
      ns: ["common"],
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: {
        loadPath: (lng: string[], ns: string[]) => {
          const [fileName] = ns;
          return `/i18n/${lng}/${fileName}.json?v=${
            import.meta.env.REACT_APP_BUILD
          }`;
        },
        crossDomain: true,
      },
      keySeparator: "",
      react: {
        useSuspense: false,
      },
    });
};
