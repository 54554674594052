export const ZIndex = {
  searchBar: 100,
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 110,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
  dialog: 1900,
  normal: 1,
  hidden: -1,
  topLayer: 9999,
};
