export enum Colors {
  // Text on Light
  TextLightDefault = "#222222",
  TextLightSecondary = "#4C4C4C",
  TextLightTiertiary = "#7F7F7F",
  TextLightQuaternary = "#B2B2B2",
  TextLightRelative = "#F9F9F9",
  TextLightError = "#EC3737",
  TextLightSuccess = "#3D9B2E",
  TextLightWarning = "#E47414",
  TextLightInformation = "#1770C5",
  TextLightDisabled = "#979797",
  TextLightCoins = "#604500",
  TextLightGems = "#2B5F50",

  // Text on Dark
  TextDarkDefault = "#F9F9F9",
  TextDarkSecondary = "#E5E5E5",
  TextDarkTiertiary = "#CCCCCC",
  TextDarkQuaternary = "#7A7A7A",
  TextDarkRelative = "#1F1F1F",
  TextDarkError = "#C96868",
  TextDarkSuccess = "#80AC78",
  TextDarkWarning = "#EDA76B",
  TextDarkInformation = "#6DA4D8",
  TextDarkDisabled = "#686868",
  TextDarkCoins = "#EEC55B",
  TextDarkGems = "#36D2A5",

  // Borders
  BorderLightDefault = "#CCCCCC",
  BorderLightSecondary = "#A0A0A0",
  BorderDarkDefault = "#5B5B5B",
  BorderDarkSecondary = "#B6B6B6",

  // Backgrounds
  BackgroundDefault = "#FFFFFF",
  BackgroundLightCard = "#F2F2F2",
  BackgroundLightDisabled = "#C7C7C7",
  BackgroundDarkCard = "#34373A",
  BackgroundDarkIngame = "#25272A",
  BackgroundDarkSelected = "#9647D8",
  BackgroundGreyCard = "#F9F9F9",

  // Surface
  SurfaceLightCoins = "#FFF7E3",
  SurfaceLightGems = "#EEFFFC",
  SurfaceDarkCoins = "#FFE29D",
  SurfaceDarkGems = "#C0FFF3",

  // Gradient linear
  GradientGameCard = "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.12) 23.26%, rgba(0, 0, 0, 0.2) 64.93%, #000000 100%)",
  GradientHeader = "linear-gradient(360deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.5) 33.13%, rgba(34, 34, 34, 0.8) 100%)",
  GradientLiveStream = "linear-gradient(96.56deg, #FF5753 5.42%, #FF2B26 94.7%)",
  GradientGold = "linear-gradient(100.91deg, #EBB017 3.37%, #FFDE8A 95.2%)",
  GradientSilver = "linear-gradient(96.73deg, #A4A4A4 0.31%, #D9D9D9 100%)",
  GradientBronze = "linear-gradient(96.72deg, #C89D74 0.23%, #E4C4A6 97.85%)",
  GradientUnranked = "linear-gradient(101.75deg, #D5D5D5 3.49%, #B2B2B2 96.01%)",
  GradientBeginner = "linear-gradient(276.45deg, #834402 5.48%, #D9904D 96.26%)",
  GradientIntermediate = "linear-gradient(102.04deg, #C8D4E9 5.12%, #8E9EB8 92.83%)",
  GradientAdvanced = "linear-gradient(101.68deg, #FFDB5B 4.81%, #FFA00D 94.9%)",
  GradientExpert = "linear-gradient(101.48deg, #FFA89C 6.11%, #ED0000 94.25%)",
  GradientMaster = "linear-gradient(101.48deg, #76BCFE 6.11%, #3771FF 94.25%)",
  GradientChampion = "linear-gradient(101.75deg, #C289FF 3.49%, #474DFF 96.01%)",
  GradientGrandChampion = "linear-gradient(269.98deg, #4844FF -5.22%, #E544FF 31.5%, #FFEA31 70.96%, #44FFC7 100.01%)",
  TournamentCard = "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 51.91%, rgba(0, 0, 0, 0.40) 78.24%, rgba(11, 11, 11, 0.70) 89.12%, #000 100%)",
  GradientCardGame = "linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.6) 51.04%, rgba(20, 20, 20, 0.85) 100%)",
  GradientSkeletonLoading = "linear-gradient(270deg, rgba(63, 63, 63, 0) 0%, rgba(65, 65, 65, 0.66) 14.64%, #464646 49.5%, rgba(65, 65, 65, 0.66) 84.64%, rgba(63, 63, 63, 0) 100%)",
}

export const DarkThemeColors = {
  Primary: "#9647D8",
  Secondary: "#C486F8",

  Success: "#25E343",
  Warning: "#F7A039",
  Error: "#EE4E42",

  BackgroundBlack: "#222222",
  Background1: "#141414",
  Background2: "#1B1B1B",
  Background3: "#222222",
  Background4: "#343434",
  Background5: "#3F3F3F",
  Background6: "#BBBBBB",
  Background7: "#FFF",
  Background8: "#434343",
  Background9: "#101113",
  Background10: "rgba(255, 255, 255, 0.20)",
  BackgroundGameLoading:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%)",
  BackgroundOverlay:
    "linear-gradient(123.13deg, rgba(255, 255, 255, 0) 43.99%, rgba(255, 255, 255, 0.25) 89.91%)",
  BackgroundOverlay2: "rgba(255, 255, 255, 0.12)",
  BackgroundDisabled: "rgba(255, 255, 255, 0.15)",

  Border1: "rgba(255, 255, 255, 0.2)",
  Border2: "rgba(255, 255, 255, 0.4)",
  Border3: "rgba(255, 255, 255, 0.6)",
  Border4: "rgba(255, 255, 255, 0.8)",

  Text1: "#FFFFFF",
  Text2: "#C0C0C0",
  Text3: "#8D8D8D",
  Text4: "#6A6A6A",
  Text5: "#505050",
  Text6: "#303030",

  Brown: "linear-gradient(96deg, #F3C47E 10.99%, #AE5C00 86.69%)",
  Silver: "linear-gradient(100deg, #C8D4E9 11.56%, #8E9EB8 88.44%)",
  Gold: "linear-gradient(100deg, #FFE9C3 11.65%, #F1AE2B 89.2%)",
  Red: "linear-gradient(100deg, #FFA89C 12.22%, #ED0000 87.78%)",
  Blue: "linear-gradient(100deg, #A2D2FF 11.8%, #376FFF 88.2%)",
  Purple: "linear-gradient(100deg, #C289FF 11.57%, #474DFF 89.29%)",
  Colorful:
    "linear-gradient(270deg, #4844FF -5.22%, #E544FF 31.5%, #FFEA31 70.96%, #44FFC7 100.01%)",

  LightOverlay:
    "linear-gradient(123deg, rgba(255, 255, 255, 0.00) 43.99%, rgba(255, 255, 255, 0.25) 89.91%)",
  DarkOverlay:
    "linear-gradient(180deg, rgba(20, 20, 20, 0.00) 0%, rgba(20, 20, 20, 0.60) 51.04%, rgba(20, 20, 20, 0.85) 100%)",
  DarkOverlay2:
    "linear-gradient(180deg, rgba(20, 20, 20, 0.90) 0%, rgba(20, 20, 20, 0.90) 100%)",
  DarkOverlay3:
    "linear-gradient(180deg, rgba(20, 20, 20, 0.36) 0%, rgba(20, 20, 20, 0.36) 100%)",
  BattleCard:
    "linear-gradient(229deg, rgba(255, 255, 255, 0.00) 23.9%, rgba(255, 255, 255, 0.25) 77.16%)",
  GameHubOverlay:
    "linear-gradient(0deg, rgba(20, 20, 20, 0.00) 0%, rgba(20, 20, 20, 0.60) 51.04%, rgba(20, 20, 20, 0.85) 100%)",
  BackgroundOnboarding:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.91) 0%, rgba(0, 0, 0, 0.79) 40.02%, rgba(0, 0, 0, 0.00) 67.39%, rgba(0, 0, 0, 0.94) 100%)",
  BackgroundShare:
    "radial-gradient(48.48% 48.48% at 48.53% 70.67%, #3F4042 4.63%, #101113 100%)",
  StatusOnline: "#3CB84E",
  StatusOffline: "#3F3F3F",
  StatusAway: "#FAA356",
};

export const LegacyColor = {
  color1: "#ddd",
  color2:
    "linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255,255, 0))",
  color3: "rgba(0, 29, 46, 0.11)",
  color4: "rgb(145 158 171 / 24%)",
  color5: "#ebeef2",
  color6: "#BF0E08",
  color7: "#EEEEEF",
  color8: "rgba(255, 255, 255, 0.5)",
  color9:
    "linear-gradient(178.37deg, rgba(0, 0, 0, 0) 4.52%, #111111 27.83%, #222222 98.62%)",
  color10: "#DEEFEA",
  color11: "#FAFAFA",
  color12:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 1) 60%, rgba(255, 255,255, 0))",
  color13: "rgba(0, 0, 0, 0.7)",
  color14: "#000",
  color15: "#fff",
  color16: "#001D2E",
  color17: "#45A677",
  color18: "#8A8A8E",
  color19: "#3C3C43",
  color20:
    "linear-gradient(179.69deg, rgba(34, 34, 34, 0) 0.27%, #222222 129.68%)",
  color21: "#F8F8F8",
  color22: "#1877F2",

  Background: "#fff",
  Background2: "#FAFAFA",
  Text: "#001D2E",
  Text2: "#000",
  Facebook: "#1877F2",
};

export const THEME_SHADOW = {
  shadow1:
    "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
  shadow2:
    "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
};
