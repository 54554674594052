import React, { HTMLAttributes, FC } from "react";
import { useStyles } from "./Box.style";
import clsx from "classnames";

type IBoxProps = {
  display?: "flex" | "inline-flex" | "inline" | "block";
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch" | "baseline";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around";
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  flexGrow?: number;
};

const BoxComponent: FC<IBoxProps & HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const {
    display,
    alignItems,
    justifyContent,
    flexDirection,
    flexGrow,
    ...othersProps
  } = props;

  return (
    <div
      {...othersProps}
      style={{
        display,
        alignItems,
        justifyContent,
        flexDirection,
        flexGrow,
        ...props.style,
      }}
      className={clsx(classes.root, props.className)}
    >
      {children}
    </div>
  );
};

export const Box = React.memo(BoxComponent);
