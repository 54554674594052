import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash-es";
import {
  PopupPriority,
  PopupType,
  popupWithPriority,
} from "../constants/popup";

interface IPopupQueueItem {
  type: PopupType;
  priority?: PopupPriority;
}

const initialState = {
  popupQueue: [] as IPopupQueueItem[],
  currentPopupId: PopupType.unset,
};

export const resetPopupState = createAction("popup/resetState");

export const popupSlice = createSlice({
  name: "popup",
  initialState: initialState,
  reducers: {
    addPopupQueue: (state, action: PayloadAction<{ type: PopupType }>) => {
      const newQueue = sortBy(
        state.popupQueue.concat({
          type: action.payload.type,
          priority: popupWithPriority?.[action.payload.type],
        }),
        "priority"
      ).reverse();
      state.popupQueue = newQueue;
      state.currentPopupId = newQueue[0]?.type;
    },
    removePopupQueue: (state, action: PayloadAction<{ type: PopupType }>) => {
      const newQueue = state.popupQueue.filter(
        (item) => item.type !== action.payload.type
      );
      state.currentPopupId = newQueue?.[0]?.type;
      state.popupQueue = newQueue;
    },
    setIsShowPopup: (state, action: PayloadAction<{ isShow: boolean }>) => {
      if (action.payload.isShow) {
        state.currentPopupId = state.popupQueue?.[0]?.type;
      } else {
        state.currentPopupId = PopupType.unset;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPopupState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { addPopupQueue, removePopupQueue, setIsShowPopup } =
  popupSlice.actions;

export default popupSlice.reducer;
