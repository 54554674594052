import { ONMO_CONFIG } from "../constants/onmo";

export class Translations {
  static getDefaultLanguage = () => {
    const languageFromBrowser = navigator.language?.slice(0, 2);
    return (
      ONMO_CONFIG?.i18n?.languages?.find((lng) =>
        languageFromBrowser.includes(lng)
      ) ||
      ONMO_CONFIG?.i18n?.default ||
      "en"
    );
  };

  static getNewlanguage = (language?: string) => {
    const userLanguage = ONMO_CONFIG?.i18n?.languages?.find(
      (lng) => language?.includes(lng)
    );
    return userLanguage || ONMO_CONFIG?.i18n?.default || "en";
  };

  static isRtlMode() {
    return document.documentElement.dir === "rtl";
  }

  static setDirection = (language?: string) => {
    const index = document.documentElement;
    if (language && ONMO_CONFIG?.i18n?.rtlLanguages?.includes(language)) {
      index.setAttribute("dir", "rtl");
    } else {
      index.setAttribute("dir", "ltr");
    }
  };

  static getTextByLanguage = (
    data: { [key: string]: string },
    lng?: string
  ) => {
    if (lng && data?.[lng]) return data?.[lng];
    return data?.[this.getDefaultLanguage()] || Object.values(data)[0];
  };
}
