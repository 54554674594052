import merge from "ts-deepmerge";
import { ThemeName, ASSETS_ENDPOINT_THEME } from "../constants/theme";
import { APP_URL, ONMO_CONFIG } from "../constants/onmo";
import { DEFAULT_THEME } from "../theme/theme";
import {
  IParamsSubcribe,
  IFullTheme,
  IParamsUnsubcribe,
  IParamsShowUnsubscribe,
  IThemeCustom,
} from "../types/theme";
import { SubscriptionStatus } from "../constants/user";
import { includes, filter, omit, set, map } from "lodash-es";
import { OnmoStorage } from "./onmoStorage";
import i18n from "i18next";
import {
  GameRating,
  GAME_DEFAULT_RATING,
  GAME_RUBYCOM_RATING,
} from "../constants/games";
import { IValueMenu } from "../components/common/Menu/Menu";
import { MIN_PROGRESS } from "../constants/limit";
import { System } from "./system";

interface IMetadata {
  [language: string]: boolean;
}
interface IOverrideMetadata {
  [themeName: string]: IMetadata;
}

interface IColorOffset {
  color: string;
  offset: string;
}

const FIELDS_LOGIN_OMIT = [
  "pages.login.template.logo1",
  "pages.login.template.freeCoins",
];

export class Theme {
  // IF YOU NEED TO OVERRIDE A LANGUAGE FOR B2B YOU NEED TO ADD CONFIG HERE
  static overrideMetadata: IOverrideMetadata = {
    gamego: {
      en: true,
    },
    arena: {
      en: true,
    },
    "mobifone-vietnam": {
      en: true,
      vi: true,
    },
  };

  static getProdTheme = (): IFullTheme => {
    const themeCustom = JSON.parse(import.meta.env.REACT_APP_THEME || "{}");
    if (
      themeCustom?.meta?.name?.toLowerCase() !== ThemeName.Onmo.toLowerCase()
    ) {
      return merge(
        omit(DEFAULT_THEME, FIELDS_LOGIN_OMIT) as IFullTheme,
        this.getFormatTheme(themeCustom)
      );
    }
    return merge(DEFAULT_THEME, this.getFormatTheme(themeCustom));
  };

  static getTheme = async () => {
    try {
      const isProd = System.isProd;
      if (isProd && import.meta.env.REACT_APP_THEME) {
        return Theme.getProdTheme();
      }

      const themeEnabled =
        OnmoStorage.getTheme() || import.meta.env.REACT_APP_ENABLED_THEME;

      const themeCustom = JSON.parse(import.meta.env.REACT_APP_THEME || "{}");
      if (
        themeEnabled?.toLocaleLowerCase() !== ThemeName.Onmo.toLocaleLowerCase()
      ) {
        const response = await fetch(
          `${APP_URL}/themes/${ONMO_CONFIG?.debug?.themes[
            themeEnabled?.toLocaleLowerCase() as string
          ]}`
        );
        const theme = await response.json();
        return merge(
          omit(DEFAULT_THEME, FIELDS_LOGIN_OMIT),
          this.getFormatTheme(theme),
          isProd ? this.getFormatTheme(themeCustom) : {}
        );
      } else {
        return merge(DEFAULT_THEME, this.getFormatTheme(themeCustom));
      }
    } catch (e) {
      console.log("Invalid theme. Default will be used: ", e);
      return DEFAULT_THEME;
    }
  };

  static onSubcribe = (params: IParamsSubcribe) => {
    let routerNavigate = `${params?.subscribeUrl}&${params?.field}=${params?.umrid}&prurl=${
      import.meta.env.REACT_APP_BASE_URL || APP_URL
    }/&tpuserid=${params?.userId}&lang=${params?.lang}`;
    if (params?.status === SubscriptionStatus.subscribed) {
      routerNavigate += "&opsopt=ppd";
    } else {
      routerNavigate += params?.unsubscribedParams;
    }
    if (params?.subscribeParams) {
      routerNavigate += `&ommedium=${params?.subscribeParams}`;
    }
    window.location.href = routerNavigate;
  };

  static getImage = (themeName: string, name: string) => {
    if (!name) return "";
    return `${ASSETS_ENDPOINT_THEME}${themeName
      .toLocaleLowerCase()
      .replace(/ /g, "-")}/${name}`;
  };

  static getImage3x = (themeName: string, name: string) => {
    if (!name) return;
    const folderPath = `${ASSETS_ENDPOINT_THEME}${themeName
      .toLocaleLowerCase()
      .replace(/ /g, "-")}`;
    return {
      oneX: `${folderPath}/1x/${name}`,
      twoX: `${folderPath}/2x/${name}`,
      threeX: `${folderPath}/3x/${name}`,
    };
  };

  static onUnSubscribe = (params: IParamsUnsubcribe) => {
    window.location.href = `${params?.unSubscribeUrl}&${params?.field}=${
      params.umrid
    }&prurl=${
      import.meta.env.REACT_APP_BASE_URL || APP_URL
    }/&lang=${params?.lang}`;
  };

  static isShowUnsubscribe = (params: IParamsShowUnsubscribe) => {
    return (
      params?.themeName !== ThemeName?.Onmo &&
      params?.status === SubscriptionStatus.subscribed &&
      !params?.renewalDate &&
      !params?.hideSubscriptionButtons &&
      !params?.hideUnsubscribeButton
    );
  };

  static pickTextColorFromBG = (
    bgColor: string,
    lightColor: string,
    darkColor: string,
    overrideFontColor?: string
  ) => {
    if (overrideFontColor) {
      return overrideFontColor === "light" ? lightColor : darkColor;
    }
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
  };

  static getVersionApp = (theme: Partial<IFullTheme>) => {
    if (theme?.app?.name === ThemeName.VodafoneIndia) {
      return "menuBottom_Games Zone";
    }
    if (theme?.app?.name === ThemeName.Telefonica) {
      return "menuBottom_Onmo Game";
    }
    return "ONMO";
  };

  static getDocUrl = (
    type: "privacy" | "term" | "faq" | "cookie",
    theme: IFullTheme,
    language: string,
    defaultLanguage: string
  ) => {
    if (!theme?.legalDocuments) return "";
    try {
      if (type === "privacy") {
        return (
          theme?.legalDocuments[language]?.privacyPolicy ||
          theme?.legalDocuments[defaultLanguage]?.privacyPolicy
        );
      }
      if (type === "term") {
        return (
          theme?.legalDocuments[language]?.termsAndConditions ||
          theme?.legalDocuments[defaultLanguage]?.termsAndConditions
        );
      }
      if (type === "cookie") {
        return (
          theme?.legalDocuments[language]?.cookiePolicy ||
          theme?.legalDocuments[defaultLanguage]?.cookiePolicy
        );
      }
      return (
        theme?.legalDocuments[language]?.faq ||
        theme?.legalDocuments[defaultLanguage]?.faq
      );
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  static fetchTranslationOverrides = async (
    themeName: string,
    language: string
  ) => {
    try {
      const response = await fetch(
        `${APP_URL}/overrides-i18n/${themeName}/${language}/common.json`
      );
      const jsonData = await response.json();
      i18n.addResources(language, "common", jsonData);
    } catch (e) {
      console.log(e);
    }
  };

  static getTranslationOverrideName = (themeName?: string) => {
    if (!themeName) return "Onmo";
    return themeName.toLowerCase().replace(/ /g, "-");
  };

  static hasOverrideMetadata = (themeName: string, language: string) => {
    try {
      return Theme.overrideMetadata[themeName][language];
    } catch (e) {
      return false;
    }
  };

  static getApplyGradientColor = (color: string) => {
    if (includes(color, "gradient")) {
      const regex = /#(?:[0-9a-fA-F]{3}){1,2}/g;
      const [primary] = color.match(regex) as string[];
      return primary || color;
    }
    return color;
  };

  static getFormatTheme = (themeCustom: IThemeCustom): IFullTheme => {
    const { theme, app } = themeCustom;
    const primaryColor = app?.theme?.primary || theme?.palette?.primary?.main;
    const secondaryColor =
      app?.theme?.secondary || theme?.palette?.secondary?.main;
    const fontColor = app?.theme?.overrideFontColor;
    const themeData = themeCustom;
    if (primaryColor) {
      set(themeData, "theme.palette.primary.main", primaryColor);
    }
    if (secondaryColor) {
      set(themeData, "theme.palette.secondary.main", secondaryColor);
    }
    if (fontColor) {
      set(themeData, "theme.overrideFontColor", fontColor);
    }
    return themeData;
  };

  static getGoogleDocTitle = (
    type: "privacy" | "term" | "faq" | "cookie" | null
  ) => {
    if (type === "privacy") {
      return "common_Privacy Policy";
    } else if (type === "term") {
      return "common_Terms & Conditions";
    } else if (type === "faq") {
      return "menu_FAQ";
    } else if (type === "cookie") {
      return "common_Cookie Policy";
    } else {
      return "";
    }
  };

  static getPrefixAppName = (appName?: string) => {
    if ((appName || ThemeName.Onmo).toLowerCase() !== "onmo") {
      return `${appName || ThemeName.Onmo} -`;
    }
    return "";
  };

  static getThemeConfig = () => {
    try {
      const themeCustom = JSON.parse(import.meta.env.REACT_APP_THEME || "{}");
      return themeCustom;
    } catch (e) {
      return {};
    }
  };

  static isHideLabel = (menu: IValueMenu[]) => {
    return filter(menu, (m) => !m.isHide).length === 0;
  };

  static getRatingGameImage = (gameId: string, rating: GameRating) => {
    if (rating === GameRating.Rubycom) {
      if (!GAME_RUBYCOM_RATING[gameId]) {
        return `${APP_URL}/games-rating/${rating}/3.webp`;
      }
      return `${APP_URL}/games-rating/${rating}/${GAME_RUBYCOM_RATING[gameId]}.webp`;
    }
    if (!GAME_DEFAULT_RATING[gameId]) {
      return `${APP_URL}/games-rating/${GameRating.Default}/A.webp`;
    }
    return `${APP_URL}/games-rating/${GameRating.Default}/${GAME_DEFAULT_RATING[gameId]}.webp`;
  };

  static getSingleColorOffset = (
    colorOrigin: string
  ): string | IColorOffset[] => {
    if (!colorOrigin.includes("gradient")) {
      return colorOrigin;
    }
    const listSingle = colorOrigin.split("#");
    listSingle.shift();
    const listColorOffset = map(listSingle, (c, i) => {
      const [color, offset] = c.split(" ");
      return {
        color: `#${color?.replace(/[)]/g, "")}`,
        offset: offset?.replace(/[,)]/g, "") || `${i * 100}%`,
      };
    });
    return listColorOffset;
  };

  static getCovertPercent = (
    initial: number,
    total: number,
    target: number
  ) => {
    const targetFinal = target / total > 1 ? 100 : (target / total) * 100;
    return {
      initial: initial / total > 1 ? 100 : (initial / total) * 100,
      target: Math.max(targetFinal, MIN_PROGRESS),
      total: 100,
    };
  };
}
