import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  appName: {
    fontSize: `48px !important`,
    lineHeight: "50px !important",
    fontWeight: `700 !important`,
  },
  desc: {
    fontSize: 18,
    margin: "15px 0px",
  },
  powerBy: {
    marginTop: 15,
  },
  powerByText: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 10,
  },
});
