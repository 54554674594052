import { Achievement_Type } from "../legacyGraphql/graphql";

import BattleWonLock1x from "../assets/achievements/battles_won/battles_won_1x.webp";
import BattleWonLock2x from "../assets/achievements/battles_won/battles_won_2x.webp";
import BattleWonLock3x from "../assets/achievements/battles_won/battles_won_3x.webp";
import BattleWon1x from "../assets/achievements/battles_won_level_1/name_battles_won_level_1_1x.webp";
import BattleWon2x from "../assets/achievements/battles_won_level_1/name_battles_won_level_1_2x.webp";
import BattleWon3x from "../assets/achievements/battles_won_level_1/name_battles_won_level_1_3x.webp";
import BattleWon21x from "../assets/achievements/battles_won_level_2/name_battles_won_level_2_1x.webp";
import BattleWon22x from "../assets/achievements/battles_won_level_2/name_battles_won_level_2_2x.webp";
import BattleWon23x from "../assets/achievements/battles_won_level_2/name_battles_won_level_2_3x.webp";
import BattleWon31x from "../assets/achievements/battles_won_level_3/name_battles_won_level_3_1x.webp";
import BattleWon32x from "../assets/achievements/battles_won_level_3/name_battles_won_level_3_2x.webp";
import BattleWon33x from "../assets/achievements/battles_won_level_3/name_battles_won_level_3_3x.webp";

import BattleStreakLock1x from "../assets/achievements/battle_streak/battle_streak_1x.webp";
import BattleStreakLock2x from "../assets/achievements/battle_streak/battle_streak_2x.webp";
import BattleStreakLock3x from "../assets/achievements/battle_streak/battle_streak_3x.webp";
import BattleStreak1x from "../assets/achievements/battle_streak_level_1/name_battle_streak_level_1_1x.webp";
import BattleStreak2x from "../assets/achievements/battle_streak_level_1/name_battle_streak_level_1_2x.webp";
import BattleStreak3x from "../assets/achievements/battle_streak_level_1/name_battle_streak_level_1_3x.webp";
import BattleStreak21x from "../assets/achievements/battle_streak_level_2/name_battle_streak_level_2_1x.webp";
import BattleStreak22x from "../assets/achievements/battle_streak_level_2/name_battle_streak_level_2_2x.webp";
import BattleStreak23x from "../assets/achievements/battle_streak_level_2/name_battle_streak_level_2_3x.webp";
import BattleStreak31x from "../assets/achievements/battle_streak_level_3/name_battle_streak_level_3_1x.webp";
import BattleStreak32x from "../assets/achievements/battle_streak_level_3/name_battle_streak_level_3_2x.webp";
import BattleStreak33x from "../assets/achievements/battle_streak_level_3/name_battle_streak_level_3_3x.webp";

import ChallengerLock1x from "../assets/achievements/challenger/challenger_1x.webp";
import ChallengerLock2x from "../assets/achievements/challenger/challenger_2x.webp";
import ChallengerLock3x from "../assets/achievements/challenger/challenger_3x.webp";
import Challenger1x from "../assets/achievements/challlenger_level_1/name_challlenger_level_1_1x.webp";
import Challenger2x from "../assets/achievements/challlenger_level_1/name_challlenger_level_1_2x.webp";
import Challenger3x from "../assets/achievements/challlenger_level_1/name_challlenger_level_1_3x.webp";
import Challenger21x from "../assets/achievements/challlenger_level_2/name_challlenger_level_2_1x.webp";
import Challenger22x from "../assets/achievements/challlenger_level_2/name_challlenger_level_2_2x.webp";
import Challenger23x from "../assets/achievements/challlenger_level_2/name_challlenger_level_2_3x.webp";
import Challenger31x from "../assets/achievements/challlenger_level_3/name_challlenger_level_3_1x.webp";
import Challenger32x from "../assets/achievements/challlenger_level_3/name_challlenger_level_3_2x.webp";
import Challenger33x from "../assets/achievements/challlenger_level_3/name_challlenger_level_3_3x.webp";

import CompletionistLock1x from "../assets/achievements/completionist/completionist_1x.webp";
import CompletionistLock2x from "../assets/achievements/completionist/completionist_2x.webp";
import CompletionistLock3x from "../assets/achievements/completionist/completionist_3x.webp";
import Completionist1x from "../assets/achievements/completionist_level_1/name_completionist_level_1_1x.webp";
import Completionist2x from "../assets/achievements/completionist_level_1/name_completionist_level_1_2x.webp";
import Completionist3x from "../assets/achievements/completionist_level_1/name_completionist_level_1_3x.webp";
import Completionist21x from "../assets/achievements/completionist_level_2/name_completionist_level_2_1x.webp";
import Completionist22x from "../assets/achievements/completionist_level_2/name_completionist_level_2_2x.webp";
import Completionist23x from "../assets/achievements/completionist_level_2/name_completionist_level_2_3x.webp";
import Completionist31x from "../assets/achievements/completionist_level_3/name_completionist_level_3_1x.webp";
import Completionist32x from "../assets/achievements/completionist_level_3/name_completionist_level_3_2x.webp";
import Completionist33x from "../assets/achievements/completionist_level_3/name_completionist_level_3_3x.webp";

import FriendsInvitedLock1x from "../assets/achievements/friend_invited/friend_invited_1x.webp";
import FriendsInvitedLock2x from "../assets/achievements/friend_invited/friend_invited_2x.webp";
import FriendsInvitedLock3x from "../assets/achievements/friend_invited/friend_invited_3x.webp";
import FriendsInvited1x from "../assets/achievements/friends_invited_level_1/name_friends_invited_level_1_1x.webp";
import FriendsInvited2x from "../assets/achievements/friends_invited_level_1/name_friends_invited_level_1_2x.webp";
import FriendsInvited3x from "../assets/achievements/friends_invited_level_1/name_friends_invited_level_1_3x.webp";
import FriendsInvited21x from "../assets/achievements/friends_invited_level_2/name_friends_invited_level_2_1x.webp";
import FriendsInvited22x from "../assets/achievements/friends_invited_level_2/name_friends_invited_level_2_2x.webp";
import FriendsInvited23x from "../assets/achievements/friends_invited_level_2/name_friends_invited_level_2_3x.webp";
import FriendsInvited31x from "../assets/achievements/friends_invited_level_3/name_friends_invited_level_3_1x.webp";
import FriendsInvited32x from "../assets/achievements/friends_invited_level_3/name_friends_invited_level_3_2x.webp";
import FriendsInvited33x from "../assets/achievements/friends_invited_level_3/name_friends_invited_level_3_3x.webp";

import TimePlayedLock1x from "../assets/achievements/time_played/time_played_1x.webp";
import TimePlayedLock2x from "../assets/achievements/time_played/time_played_2x.webp";
import TimePlayedLock3x from "../assets/achievements/time_played/time_played_3x.webp";
import TimePlayed1x from "../assets/achievements/time_played_level_1/name_time_played_level_1_1x.webp";
import TimePlayed2x from "../assets/achievements/time_played_level_1/name_time_played_level_1_2x.webp";
import TimePlayed3x from "../assets/achievements/time_played_level_1/name_time_played_level_1_3x.webp";
import TimePlayed21x from "../assets/achievements/time_played_level_2/name_time_played_level_2_1x.webp";
import TimePlayed22x from "../assets/achievements/time_played_level_2/name_time_played_level_2_2x.webp";
import TimePlayed23x from "../assets/achievements/time_played_level_2/name_time_played_level_2_3x.webp";
import TimePlayed31x from "../assets/achievements/time_played_level_3/name_time_played_level_3_1x.webp";
import TimePlayed32x from "../assets/achievements/time_played_level_3/name_time_played_level_3_2x.webp";
import TimePlayed33x from "../assets/achievements/time_played_level_3/name_time_played_level_3_3x.webp";

import TournamentsParticipationLock1x from "../assets/achievements/tournament_participation/tournament_participation_1x.webp";
import TournamentsParticipationLock2x from "../assets/achievements/tournament_participation/tournament_participation_2x.webp";
import TournamentsParticipationLock3x from "../assets/achievements/tournament_participation/tournament_participation_3x.webp";
import TournamentsParticipation1x from "../assets/achievements/tournaments_participation_level_1/name_tournaments_participation_level_1_1x.webp";
import TournamentsParticipation2x from "../assets/achievements/tournaments_participation_level_1/name_tournaments_participation_level_1_2x.webp";
import TournamentsParticipation3x from "../assets/achievements/tournaments_participation_level_1/name_tournaments_participation_level_1_3x.webp";
import TournamentsParticipation21x from "../assets/achievements/tournaments_participation_level_2/name_tournaments_participation_level_2_1x.webp";
import TournamentsParticipation22x from "../assets/achievements/tournaments_participation_level_2/name_tournaments_participation_level_2_2x.webp";
import TournamentsParticipation23x from "../assets/achievements/tournaments_participation_level_2/name_tournaments_participation_level_2_3x.webp";
import TournamentsParticipation31x from "../assets/achievements/tournaments_participation_level_3/name_tournaments_participation_level_3_1x.webp";
import TournamentsParticipation32x from "../assets/achievements/tournaments_participation_level_3/name_tournaments_participation_level_3_2x.webp";
import TournamentsParticipation33x from "../assets/achievements/tournaments_participation_level_3/name_tournaments_participation_level_3_3x.webp";

import BattleFriendsPlayedLock1x from "../assets/achievements/battle_friend_played/battle_friend_played_1x.webp";
import BattleFriendsPlayedLock2x from "../assets/achievements/battle_friend_played/battle_friend_played_2x.webp";
import BattleFriendsPlayedLock3x from "../assets/achievements/battle_friend_played/battle_friend_played_3x.webp";
import BattleFriendsPlayed1x from "../assets/achievements/battle_friends_played_level_1/name_battle_friends_played_level_1_1x.webp";
import BattleFriendsPlayed2x from "../assets/achievements/battle_friends_played_level_1/name_battle_friends_played_level_1_2x.webp";
import BattleFriendsPlayed3x from "../assets/achievements/battle_friends_played_level_1/name_battle_friends_played_level_1_3x.webp";
import BattleFriendsPlayed21x from "../assets/achievements/battle_friends_played_level_2/name_battle_friends_played_level_2_1x.webp";
import BattleFriendsPlayed22x from "../assets/achievements/battle_friends_played_level_2/name_battle_friends_played_level_2_2x.webp";
import BattleFriendsPlayed23x from "../assets/achievements/battle_friends_played_level_2/name_battle_friends_played_level_2_3x.webp";
import BattleFriendsPlayed31x from "../assets/achievements/battle_friends_played_level_3/name_battle_friends_played_level_3_1x.webp";
import BattleFriendsPlayed32x from "../assets/achievements/battle_friends_played_level_3/name_battle_friends_played_level_3_2x.webp";
import BattleFriendsPlayed33x from "../assets/achievements/battle_friends_played_level_3/name_battle_friends_played_level_3_3x.webp";

import WeeklyParticipationLock1x from "../assets/achievements/weekly_participation/weekly_participation_1x.webp";
import WeeklyParticipationLock2x from "../assets/achievements/weekly_participation/weekly_participation_2x.webp";
import WeeklyParticipationLock3x from "../assets/achievements/weekly_participation/weekly_participation_3x.webp";
import WeeklyParticipation1x from "../assets/achievements/weekly_participation_level_1/name_weekly_participation_level_1_1x.webp";
import WeeklyParticipation2x from "../assets/achievements/weekly_participation_level_1/name_weekly_participation_level_1_2x.webp";
import WeeklyParticipation3x from "../assets/achievements/weekly_participation_level_1/name_weekly_participation_level_1_3x.webp";
import WeeklyParticipation21x from "../assets/achievements/weekly_participation_level_2/name_weekly_participation_level_2_1x.webp";
import WeeklyParticipation22x from "../assets/achievements/weekly_participation_level_2/name_weekly_participation_level_2_2x.webp";
import WeeklyParticipation23x from "../assets/achievements/weekly_participation_level_2/name_weekly_participation_level_2_3x.webp";
import WeeklyParticipation31x from "../assets/achievements/weekly_participation_level_3/name_weekly_participation_level_3_1x.webp";
import WeeklyParticipation32x from "../assets/achievements/weekly_participation_level_3/name_weekly_participation_level_3_2x.webp";
import WeeklyParticipation33x from "../assets/achievements/weekly_participation_level_3/name_weekly_participation_level_3_3x.webp";

import WeeklyWinnerLock1x from "../assets/achievements/weekly_winner/weekly_winner_1x.webp";
import WeeklyWinnerLock2x from "../assets/achievements/weekly_winner/weekly_winner_2x.webp";
import WeeklyWinnerLock3x from "../assets/achievements/weekly_winner/weekly_winner_3x.webp";
import WeeklyWinner1x from "../assets/achievements/weekly_winner_level_1/name_weekly_winner_level_1_1x.webp";
import WeeklyWinner2x from "../assets/achievements/weekly_winner_level_1/name_weekly_winner_level_1_2x.webp";
import WeeklyWinner3x from "../assets/achievements/weekly_winner_level_1/name_weekly_winner_level_1_3x.webp";
import WeeklyWinner21x from "../assets/achievements/weekly_winner_level_2/name_weekly_winner_level_2_1x.webp";
import WeeklyWinner22x from "../assets/achievements/weekly_winner_level_2/name_weekly_winner_level_2_2x.webp";
import WeeklyWinner23x from "../assets/achievements/weekly_winner_level_2/name_weekly_winner_level_2_3x.webp";
import WeeklyWinner31x from "../assets/achievements/weekly_winner_level_3/name_weekly_winner_level_3_1x.webp";
import WeeklyWinner32x from "../assets/achievements/weekly_winner_level_3/name_weekly_winner_level_3_2x.webp";
import WeeklyWinner33x from "../assets/achievements/weekly_winner_level_3/name_weekly_winner_level_3_3x.webp";

export const ACHIEVEMENTS = [
  {
    icons: [
      {
        src1x: BattleWonLock1x,
        src2x: BattleWonLock2x,
        src3x: BattleWonLock3x,
      },
      {
        src1x: BattleWon1x,
        src2x: BattleWon2x,
        src3x: BattleWon3x,
      },
      {
        src1x: BattleWon21x,
        src2x: BattleWon22x,
        src3x: BattleWon23x,
      },
      {
        src1x: BattleWon31x,
        src2x: BattleWon32x,
        src3x: BattleWon33x,
      },
    ],
    name: "achievement_Conqueror",
    description: "achievement_Win battles",
    id: "battles_won",
    values: [
      { value: 10, gems: 20 },
      { value: 50, gems: 100 },
      { value: 100, gems: 200 },
    ],
  },
  {
    icons: [
      {
        src1x: BattleStreakLock1x,
        src2x: BattleStreakLock2x,
        src3x: BattleStreakLock3x,
      },
      {
        src1x: BattleStreak1x,
        src2x: BattleStreak2x,
        src3x: BattleStreak3x,
      },
      {
        src1x: BattleStreak21x,
        src2x: BattleStreak22x,
        src3x: BattleStreak23x,
      },
      {
        src1x: BattleStreak31x,
        src2x: BattleStreak32x,
        src3x: BattleStreak33x,
      },
    ],
    name: "achievement_Unstoppable",
    description: "achievement_Win battles in a row",
    id: "battle_streak",
    values: [
      { value: 5, gems: 25 },
      { value: 10, gems: 50 },
      { value: 20, gems: 100 },
    ],
  },
  {
    icons: [
      {
        src1x: ChallengerLock1x,
        src2x: ChallengerLock2x,
        src3x: ChallengerLock3x,
      },
      {
        src1x: Challenger1x,
        src2x: Challenger2x,
        src3x: Challenger3x,
      },
      {
        src1x: Challenger21x,
        src2x: Challenger22x,
        src3x: Challenger23x,
      },
      {
        src1x: Challenger31x,
        src2x: Challenger32x,
        src3x: Challenger33x,
      },
    ],
    name: "achievement_Challenger",
    description: "achievement_Complete unique challenges",
    id: "challenger",
    values: [
      { value: 10, gems: 10 },
      { value: 20, gems: 40 },
      { value: 50, gems: 250 },
    ],
  },
  {
    icons: [
      {
        src1x: CompletionistLock1x,
        src2x: CompletionistLock2x,
        src3x: CompletionistLock3x,
      },
      {
        src1x: Completionist1x,
        src2x: Completionist2x,
        src3x: Completionist3x,
      },
      {
        src1x: Completionist21x,
        src2x: Completionist22x,
        src3x: Completionist23x,
      },
      {
        src1x: Completionist31x,
        src2x: Completionist32x,
        src3x: Completionist33x,
      },
    ],
    name: "achievement_Mastery",
    description: "achievement_Complete all challenges of games",
    id: "completionist",
    values: [
      { value: 1, gems: 50 },
      { value: 5, gems: 250 },
      { value: 10, gems: 500 },
    ],
  },
  {
    icons: [
      {
        src1x: TournamentsParticipationLock1x,
        src2x: TournamentsParticipationLock2x,
        src3x: TournamentsParticipationLock3x,
      },
      {
        src1x: TournamentsParticipation1x,
        src2x: TournamentsParticipation2x,
        src3x: TournamentsParticipation3x,
      },
      {
        src1x: TournamentsParticipation21x,
        src2x: TournamentsParticipation22x,
        src3x: TournamentsParticipation23x,
      },
      {
        src1x: TournamentsParticipation31x,
        src2x: TournamentsParticipation32x,
        src3x: TournamentsParticipation33x,
      },
    ],
    name: "achievement_Gladiator",
    description: "achievement_Compete in tournaments",
    id: "tournaments_participation",
    values: [
      { value: 2, gems: 5 },
      { value: 10, gems: 30 },
      { value: 50, gems: 150 },
    ],
  },
  {
    icons: [
      {
        src1x: TimePlayedLock1x,
        src2x: TimePlayedLock2x,
        src3x: TimePlayedLock3x,
      },
      {
        src1x: TimePlayed1x,
        src2x: TimePlayed2x,
        src3x: TimePlayed3x,
      },
      {
        src1x: TimePlayed21x,
        src2x: TimePlayed22x,
        src3x: TimePlayed23x,
      },
      {
        src1x: TimePlayed31x,
        src2x: TimePlayed32x,
        src3x: TimePlayed33x,
      },
    ],
    name: "achievement_Devoted",
    description: "achievement_Play minutes of any game",
    id: "time_played",
    values: [
      { value: 100, gems: 50 },
      { value: 500, gems: 250 },
      { value: 1000, gems: 500 },
    ],
  },
  {
    icons: [
      {
        src1x: BattleFriendsPlayedLock1x,
        src2x: BattleFriendsPlayedLock2x,
        src3x: BattleFriendsPlayedLock3x,
      },
      {
        src1x: BattleFriendsPlayed1x,
        src2x: BattleFriendsPlayed2x,
        src3x: BattleFriendsPlayed3x,
      },
      {
        src1x: BattleFriendsPlayed21x,
        src2x: BattleFriendsPlayed22x,
        src3x: BattleFriendsPlayed23x,
      },
      {
        src1x: BattleFriendsPlayed31x,
        src2x: BattleFriendsPlayed32x,
        src3x: BattleFriendsPlayed33x,
      },
    ],
    name: "achievement_Showdown",
    description: "achievement_Play battles with friends",
    id: "battle_friends_played",
    values: [
      { value: 10, gems: 10 },
      { value: 50, gems: 50 },
      { value: 200, gems: 200 },
    ],
  },
  {
    icons: [
      {
        src1x: WeeklyParticipationLock1x,
        src2x: WeeklyParticipationLock2x,
        src3x: WeeklyParticipationLock3x,
      },
      {
        src1x: WeeklyParticipation1x,
        src2x: WeeklyParticipation2x,
        src3x: WeeklyParticipation3x,
      },
      {
        src1x: WeeklyParticipation21x,
        src2x: WeeklyParticipation22x,
        src3x: WeeklyParticipation23x,
      },
      {
        src1x: WeeklyParticipation31x,
        src2x: WeeklyParticipation32x,
        src3x: WeeklyParticipation33x,
      },
    ],
    name: "achievement_Contender",
    description: "achievement_Compete in weekly leaderboards",
    id: "weekly_gl_participant",
    values: [
      { value: 1, gems: 10 },
      { value: 7, gems: 70 },
      { value: 20, gems: 200 },
    ],
  },
  {
    icons: [
      {
        src1x: WeeklyWinnerLock1x,
        src2x: WeeklyWinnerLock2x,
        src3x: WeeklyWinnerLock3x,
      },
      {
        src1x: WeeklyWinner1x,
        src2x: WeeklyWinner2x,
        src3x: WeeklyWinner3x,
      },
      {
        src1x: WeeklyWinner21x,
        src2x: WeeklyWinner22x,
        src3x: WeeklyWinner23x,
      },
      {
        src1x: WeeklyWinner31x,
        src2x: WeeklyWinner32x,
        src3x: WeeklyWinner33x,
      },
    ],
    name: "achievement_Dominator",
    description: "achievement_Reach the top 10 in weekly leaderboards",
    id: "weekly_gl_winner",
    values: [
      { value: 1, gems: 30 },
      { value: 5, gems: 150 },
      { value: 10, gems: 300 },
    ],
  },

  {
    icons: [
      {
        src1x: FriendsInvitedLock1x,
        src2x: FriendsInvitedLock2x,
        src3x: FriendsInvitedLock3x,
      },
      {
        src1x: FriendsInvited1x,
        src2x: FriendsInvited2x,
        src3x: FriendsInvited3x,
      },
      {
        src1x: FriendsInvited21x,
        src2x: FriendsInvited22x,
        src3x: FriendsInvited23x,
      },
      {
        src1x: FriendsInvited31x,
        src2x: FriendsInvited32x,
        src3x: FriendsInvited33x,
      },
    ],
    name: "achievement_Influencer",
    description: "achievement_Have followers",
    id: "followers",
    values: [
      { value: 1, gems: 5 },
      { value: 10, gems: 50 },
      { value: 50, gems: 250 },
    ],
  },
];

export const ACHIEVEMENTS_BG_COLORS = [
  "linear-gradient(270deg, #4F4F4F 0%, #DADADA 100%)",
  "linear-gradient(270deg, #154260 0%, #2B6891 62.17%, #88C6F5 100%)",
  "linear-gradient(269.96deg, #C6882D 0.03%, #FFF8ED 97.98%)",
  "linear-gradient(316.94deg, #181818 37.86%, #B5B5B5 90.44%)",
];

export const getAchievementIconsFromIdAndTier = (id: string, tier: number) => {
  const achievement = ACHIEVEMENTS.find((a) => a.id === id);
  if (!achievement) return null;

  return achievement.icons[tier];
};

export const ACHIEVEMENTS_ORDER = [
  "battles_won",
  "battle_streak",
  "challenger",
  "completionist",
  "tournaments_participation",
  "time_played",
  "followers",
  "battle_friends_played",
  "weekly_gl_participant",
  "weekly_gl_winner",
];
