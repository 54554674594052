import React, { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
import { Grid, Typography } from "../../core";
import TreasureIcon from "./assets/treasure.webp";
import TreasureIcon2x from "./assets/treasure@2x.webp";
import TreasureIcon3x from "./assets/treasure@3x.webp";
import { StaticImage } from "../StaticImage";
import { useStyles } from "./FreeCoinsBanner.style";
import { useAppSelector } from "../../../hooks";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";

export const FreeCoinsBanner: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { theme } = useAppSelector((state) => state.theme);
  const { pages } = theme;

  return (
    <Grid
      {...props}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.root, props.className)}
    >
      <StaticImage
        src={TreasureIcon}
        src2x={TreasureIcon2x}
        src3x={TreasureIcon3x}
        height={60}
        width={60}
        alt="freeCoin"
      />
      <Grid display="flex" alignItems="center" className={classes.info}>
        <Grid display="flex" alignItems="baseline">
          <h1 className={classes.freeCoin}>
            {pages?.login?.template?.freeCoins}
          </h1>
        </Grid>
        <Grid display="flex" flexDirection="column">
          <Typography variant={TypographyVariant.BodyB3Bold}>
            {t("transaction_Free Coins", {
              defaultValue: "Free Coins",
            })}
          </Typography>
          <Typography
            variant={TypographyVariant.BodyB3Regular}
            color={TypographyColor.DarkDefault}
          >
            {t("b2bLoginTemplate_Coins on Signup", {
              defaultValue: "on Signup!",
            })}
          </Typography>
        </Grid>
      </Grid>
      <span className={classes.triangle} />
    </Grid>
  );
};
