import { createUseStyles } from "react-jss";
import { DarkThemeColors, LegacyColor } from "../../../constants/colors";
import { BorderRadius } from "../../../constants/borderRadius";
import { SpacingMapping } from "../../../constants/spacing";
import { Theme } from "../../../models/theme";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    width: "100%",
    margin: "0px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: SpacingMapping.space12,
  },
  button: {
    border: "none !important",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: BorderRadius.sm,
    fontWeight: "normal",
  },
  hidden: {
    opacity: 0,
  },
  loading: {
    justifyContent: "center !important",
  },
  btnFacebook: {
    background: `${LegacyColor.Facebook} !important`,
  },
  btnGoogle: {
    background: `${LegacyColor.Background} !important`,
  },
  btnPhone: {
    justifyContent: "center",
    color: `${Theme.pickTextColorFromBG(
      theme.palette.secondary.main,
      DarkThemeColors.Text1,
      LegacyColor.color14,
      theme.overrideFontColor
    )} !important`,
  },
  btnEmail: {
    background: `${DarkThemeColors.BackgroundBlack} !important`,
    justifyContent: "center",
  },
  btnBold: {
    fontSize: 24,
    fontWeight: 700,
    fontFamily: "AlumniSans",
  },
}));
