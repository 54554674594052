import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { getGameSessionV2GQL } from "../../queries/gameSessions";
import { Query } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getGameSession = async (
  gameSessionId: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["getGameSessionV2"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getGameSessionV2GQL,
    variables: { getGameSessionInput: { gameSessionId } },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.getGameSessionV2;
};
