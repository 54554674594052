export type Spacing =
  | "space2"
  | "space4"
  | "space8"
  | "space12"
  | "space16"
  | "space20"
  | "space24"
  | "space32"
  | "space36"
  | "space40"
  | "space48"
  | "space56"
  | "space64"
  | "space72"
  | "space80";

export const SpacingMapping: { [key in Spacing]: number } = {
  space2: 2,
  space4: 4,
  space8: 8,
  space12: 12,
  space16: 16,
  space20: 20,
  space24: 24,
  space32: 32,
  space36: 36,
  space40: 40,
  space48: 48,
  space56: 56,
  space64: 64,
  space72: 72,
  space80: 80,
};
