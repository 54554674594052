import { QueryOptions } from "@apollo/client";
import apollo from "../../../clients/apollo";
import { Query } from "../../../legacyGraphql/graphql";
import { getGlobalLeaderboardProfileGQL } from "../../queries/globalLeaderboard";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getGlobalLeaderboardProfile = async (
  endDate: string,
  leaderboardType: string,
  options: Partial<QueryOptions> = {}
): Promise<Query["globalLeaderboard"]> => {
  const response = await apolloClient.query({
    ...options,
    query: getGlobalLeaderboardProfileGQL,
    variables: {
      globalLeaderboardInput: {
        endDate: endDate,
        leaderboardType: leaderboardType,
      },
    },
    context: { api: ApiLegacyName.Legacy },
  });

  return response.data.globalLeaderboard;
};
