import Emojis1Icon from "../assets/liveStream/webp/Emojis1.webp";
import Emojis2Icon from "../assets/liveStream/webp/Emojis2.webp";
import Emojis3Icon from "../assets/liveStream/webp/Emojis3.webp";
import Emojis4Icon from "../assets/liveStream/webp/Emojis4.webp";
import Emojis5Icon from "../assets/liveStream/webp/Emojis5.webp";
import Emojis6Icon from "../assets/liveStream/webp/Emojis6.webp";
import Emojis1_2xIcon from "../assets/liveStream/webp/icon2x/Emojis1_2x.webp";
import Emojis2_2xIcon from "../assets/liveStream/webp/icon2x/Emojis2_2x.webp";
import Emojis3_2xIcon from "../assets/liveStream/webp/icon2x/Emojis3_2x.webp";
import Emojis4_2xIcon from "../assets/liveStream/webp/icon2x/Emojis4_2x.webp";
import Emojis5_2xIcon from "../assets/liveStream/webp/icon2x/Emojis5_2x.webp";
import Emojis6_2xIcon from "../assets/liveStream/webp/icon2x/Emojis6_2x.webp";
import Emojis1_3xIcon from "../assets/liveStream/webp/icon3x/Emojis1_3x.webp";
import Emojis2_3xIcon from "../assets/liveStream/webp/icon3x/Emojis2_3x.webp";
import Emojis3_3xIcon from "../assets/liveStream/webp/icon3x/Emojis3_3x.webp";
import Emojis4_3xIcon from "../assets/liveStream/webp/icon3x/Emojis4_3x.webp";
import Emojis5_3xIcon from "../assets/liveStream/webp/icon3x/Emojis5_3x.webp";
import Emojis6_3xIcon from "../assets/liveStream/webp/icon3x/Emojis6_3x.webp";

export enum LiveStreamActions {
  playerHeartbeat = "livestream/player_heartbeat",
  viewerHeartbeat = "livestream/viewer_heartbeat",
  endSession = "livestream/end_session",
  leaveSession = "livestream/leave_session",
  sendEmoji = "livestream/send_emoji",
  endBattle = "livestream/end_battle",
  newBattle = "livestream/new_battle",
  battleReady = "livestream/battle_ready",
  giveControl = "livestream/give_control",
  acceptControl = "livestream/accept_control",
  takeControl = "livestream/take_control",
  denyControl = "livestream/deny_control",
  revokeControl = "livestream/revoke_control",
  returnControl = "livestream/return_control",
  cancelControlRequest = "livestream/cancel_control_request",
}

export enum LiveStreamEvents {
  sessionCreated = "livestream/session_created",
  sessionEnded = "livestream/session_ended",
  sessionJoined = "livestream/session_joined",
  viewers = "livestream/viewers",
  playerDisconnected = "livestream/player_disconnected",
  emoji = "livestream/emoji",
  battleEnded = "livestream/battle_ended",
  newBattleStarted = "livestream/new_battle_started",
  battleReady = "livestream/battle_ready",
  controlRequest = "livestream/control_request",
  newPlayer = "livestream/new_player",
  controlRequestDenied = "livestream/control_request_denied",
  error = "livestream/error",
  controlBackToHost = "livestream/control_back_to_host",
  controlRequestCancelled = "livestream/control_request_cancelled",
}

export enum WsLiveStreamCodes {
  BattleIdRequired = "battle_id_required",
  BattleIdInvalid = "battle_id_invalid",
  UserAlreadyInStream = "user_already_in_stream",
  GameSessionDoesNotExist = "game_session_does_not_exist",
  BattleDoesNotBelongToUser = "battle_does_not_belong_to_user",
  BattleStateInvalid = "battle_state_invalid",
  HtmlNotAllowed = "html_not_allowed",
  SessionIdRequired = "session_id_required",
  SessionIdInvalid = "session_id_invalid",
  NotPlayer = "not_player",
  UserDoesNotExist = "user_does_not_exist",
  LiveStreamFull = "livestream_full",
  NotViewer = "not_viewer",
  SessionAlreadyClosed = "session_already_closed",
  MissingInputField = "missing_input_field",
  InvalidInputField = "invalid_input_field",
  ViewerDoesNotExist = "viewer_does_not_exist",
  InvalidControlRequest = "invalid_control_request",
  ControlRequestExpired = "control_request_expired",
  PlayerStillAlive = "player_still_alive",
  battleTypeRequired = "battle_type_required",
  battleTypeInvalid = "battle_type_invalid",
  internalServerError = "INTERNAL_SERVER_ERROR",
  controlTransferNotAllowed = "control_transfer_not_allowed",
}

export const EMOJIS = [
  {
    key: "1",
    value: {
      src1x: Emojis1Icon,
      src2x: Emojis1_2xIcon,
      src3x: Emojis1_3xIcon,
    },
  },
  {
    key: "2",
    value: {
      src1x: Emojis2Icon,
      src2x: Emojis2_2xIcon,
      src3x: Emojis2_3xIcon,
    },
  },
  {
    key: "3",
    value: {
      src1x: Emojis6Icon,
      src2x: Emojis6_2xIcon,
      src3x: Emojis6_3xIcon,
    },
  },
  {
    key: "4",
    value: {
      src1x: Emojis4Icon,
      src2x: Emojis4_2xIcon,
      src3x: Emojis4_3xIcon,
    },
  },
  {
    key: "5",
    value: {
      src1x: Emojis5Icon,
      src2x: Emojis5_2xIcon,
      src3x: Emojis5_3xIcon,
    },
  },
  {
    key: "6",
    value: {
      src1x: Emojis3Icon,
      src2x: Emojis3_2xIcon,
      src3x: Emojis3_3xIcon,
    },
  },
];

export const MAX_PLAYER_IN_LIVE_STREAM = 5;
