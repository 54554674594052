export enum TournamentGemReward {
  Top1 = 10,
  Top2 = 6,
  Top3 = 4,
}

export enum FeaturedType {
  Tournament = "tournament",
  Game = "game",
  Contest = "contest",
  Banner = "banner",
  ShopBanner = "shop-banner",
}
