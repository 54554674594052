import { createUseStyles } from "react-jss";
import { ITheme } from "../../../types/theme";
import { GridPaddingMapping } from "../../../constants/padding";
import { SpacingMapping } from "../../../constants/spacing";
import { BorderRadius } from "../../../constants/borderRadius";
import { DarkThemeColors } from "../../../constants/colors";
import { FontWeight } from "../../../constants/fontWeight";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    position: "relative",
  },
  input: {
    border: `1px solid ${DarkThemeColors.Border3}`,
    borderRadius: BorderRadius.sm,
    minHeight: 48,
    boxSizing: "border-box",
    paddingInlineStart: GridPaddingMapping.s,
    paddingInlineEnd: GridPaddingMapping.s,
    background: "transparent",
    outline: "none",
    "&:focus": {
      border: `1px solid ${DarkThemeColors.Border4}`,
    },
    color: DarkThemeColors.Text1,
    caretColor: theme.palette.primary.main,
    fontWeight: FontWeight.regular,
    fontSize: 16,
    lineHeight: "24px",
  },
  fullWidth: {
    width: "100%",
  },
  startAdornment: {
    marginInlineEnd: SpacingMapping.space8,
    position: "absolute",
    insetInlineStart: GridPaddingMapping.s,
    display: "flex",
    alignItems: "center",
    color: DarkThemeColors.Text4,
  },
  content: {
    position: "relative",
    cursor: "text",
    display: "flex",
    fontSize: 15,
    alignItems: "center",
    fontFamily: theme.fontFamily,
    margin: `${GridPaddingMapping.xxs}px 0px`,
  },
  fitContent: {
    width: "fit-content",
  },
  hasStartIcon: {
    paddingInlineStart: GridPaddingMapping.xh,
  },
  hasClearIcon: {
    paddingInlineEnd: GridPaddingMapping.xxxl,
  },
  closeIcon: {
    position: "absolute",
    insetInlineEnd: GridPaddingMapping.s,
    cursor: "pointer",
  },
  errorBorder: {
    border: `1px solid ${DarkThemeColors.Error} !important`,
  },
  textDisable: {
    color: DarkThemeColors.Text4,
    background: DarkThemeColors.Background4,
  },

  endAdornment: {
    position: "absolute",
    insetInlineEnd: GridPaddingMapping.xxs,
    cursor: "pointer",
  },
}));
