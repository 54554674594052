import apollo from "../../../clients/apollo";
import { ApiLegacyName } from "../../../constants/apollo";
import { Mutation } from "../../../legacyGraphql/graphql";
import { globalLeaderboardsGQL } from "../../mutations/globalLeaderboards";

const apolloClient = apollo.getInstance();

export const getGlobalLeaderboards = async (): Promise<
  Mutation["globalLeaderboards"]
> => {
  const response = await apolloClient.mutate({
    mutation: globalLeaderboardsGQL,
    variables: {},
    context: { api: ApiLegacyName.Legacy },
  });

  return response.data.globalLeaderboards;
};
