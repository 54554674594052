import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import alert from "./slices/alert";
import maintenance from "./slices/maintenance";
import cognitoUser from "./slices/cognitoUser";
import splashScreen from "./slices/splashScreen";
import theme from "./slices/theme";
import apollo from "./slices/apollo";
import discover from "./slices/discover";
import game from "./slices/game";
import liveStream from "./slices/liveStream";
import livePlayers from "./slices/livePlayers";
import gameSession from "./slices/gameSession";
import friends from "./slices/friends";
import user from "./slices/user";
import inAppNotification from "./slices/inAppNotification";
import popup from "./slices/popup";
import transactionHistory from "./slices/transactionHistory";
import animations from "./slices/animations";
import achievements from "./slices/achievements";
import inventories from "./slices/inventories";
import * as Sentry from "@sentry/react";
import globalLeaderboard from "./slices/globalLeaderboard";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    alert,
    maintenance,
    cognitoUser,
    splashScreen,
    theme,
    apollo,
    discover,
    game,
    liveStream,
    gameSession,
    friends,
    user,
    inAppNotification,
    popup,
    transactionHistory,
    livePlayers,
    animations,
    achievements,
    inventories,
    globalLeaderboard,
  },
  enhancers: (getDefaultEnhancers) => [
    ...getDefaultEnhancers,
    sentryReduxEnhancer,
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AsyncAppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
