import apollo from "../../../clients/apollo";
import { listTransactionsGQL } from "../../queries/transaction";
import { IParamsListTransactions } from "../../../types/historyTransaction";
import { QueryOptions } from "@apollo/client";
import { Query } from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const listTransactions = async (
  params: IParamsListTransactions,
  options: Partial<QueryOptions> = {}
): Promise<Query["transactions"]> => {
  const res = await apolloClient.query({
    ...options,
    query: listTransactionsGQL,
    variables: { listTransactionsInput: params },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.transactions;
};
