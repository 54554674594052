import { gql } from "graphql-tag";

export const eventRulesGQL = gql`
  query EventRules {
    eventRules {
      id
      ruleType
      filters
      parameters
    }
  }
`;
