import React, { FC, HTMLAttributes, useState } from "react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { CircularProgress, Button, Typography } from "../../core";
import FacebookIcon from "../../../assets/login/webp/facebook_logo_secondary_1_1x.webp";
import FacebookIcon2x from "../../../assets/login/webp/facebook_logo_secondary_1_2x.webp";
import FacebookIcon3x from "../../../assets/login/webp/facebook_logo_secondary_1_3x.webp";
import GoogleIcon from "../../../assets/login/webp/mediamodifier_design_template_1_1x.webp";
import GoogleIcon2x from "../../../assets/login/webp/mediamodifier_design_template_1_2x.webp";
import GoogleIcon3x from "../../../assets/login/webp/mediamodifier_design_template_1_3x.webp";
import { useStyles } from "./B2bLoginTemplateActions.style";
import { OnmoStorage } from "../../../models/onmoStorage";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setNotificationError } from "../../../slices/alert";
import { StaticImage } from "../StaticImage";
import {
  TypographyColor,
  TypographyVariant,
} from "../../core/Typography/Typography";
import { AnalyticsGA4 } from "../../../models/analyticsGA4";

interface IB2bLoginTemplateActionsProps {
  onLogin: (provider: string) => Promise<void>;
  isAccept: boolean;
}

export const B2bLoginTemplateActions: FC<
  IB2bLoginTemplateActionsProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loadingType, setLoadingType] = useState("");
  const { theme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const { pages } = theme;
  const { onLogin, isAccept, ...otherProps } = props;

  const onSubmitLogin = async (provider: string) => {
    if (pages?.login?.template?.hasTextLegal && !isAccept) {
      dispatch(
        setNotificationError(
          "Please approve the terms and conditions before log in"
        )
      );
      return;
    }
    AnalyticsGA4.authClick(provider);
    try {
      OnmoStorage.setLoginType(provider);
      setLoadingType(provider);
      await onLogin(provider);
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e?.message));
      }
    }
  };

  return (
    <div className={clsx(classes.root, props.className)} {...otherProps}>
      {pages?.login?.template?.hasButtonFb && (
        <Button
          variant="contained"
          className={clsx(
            classes.button,
            classes.btnFacebook,
            loadingType === "Facebook" && classes.loading
          )}
          fullWidth
          onClick={() => onSubmitLogin("Facebook")}
          data-testid="login-fb-btn"
          id="facebook"
        >
          {loadingType === "Facebook" ? (
            <CircularProgress enableAnimation size={25} />
          ) : (
            <>
              {loadingType !== "Facebook" && (
                <StaticImage
                  src={FacebookIcon}
                  src2x={FacebookIcon2x}
                  src3x={FacebookIcon3x}
                  alt="facebook"
                  height={24}
                />
              )}
              <Typography
                variant={TypographyVariant.BodyB3Regular}
                color={TypographyColor.DarkDefault}
              >
                {t("login_Connect with Facebook", {
                  defaultValue: "Connect with Facebook",
                })}
              </Typography>
              <StaticImage
                src={FacebookIcon}
                src2x={FacebookIcon2x}
                src3x={FacebookIcon3x}
                className={classes.hidden}
                alt="facebook"
                height={24}
              />
            </>
          )}
        </Button>
      )}
      {pages?.login?.template?.hasButtonGoogle && (
        <Button
          variant="contained"
          className={clsx(
            classes.button,
            classes.btnGoogle,
            loadingType === "Google" && classes.loading
          )}
          fullWidth
          onClick={() => onSubmitLogin("Google")}
          data-testid="login-gg-btn"
          id="google"
        >
          {loadingType === "Google" ? (
            <CircularProgress enableAnimation size={25} />
          ) : (
            <>
              {loadingType !== "Google" && (
                <StaticImage
                  src={GoogleIcon}
                  src2x={GoogleIcon2x}
                  src3x={GoogleIcon3x}
                  alt="google"
                  height={24}
                />
              )}
              <Typography
                variant={TypographyVariant.BodyB3Regular}
                color={TypographyColor.DarkQuinary}
              >
                {t("login_Connect with Google", {
                  defaultValue: "Connect with Google",
                })}
              </Typography>
              <StaticImage
                src={GoogleIcon}
                src2x={GoogleIcon2x}
                src3x={GoogleIcon3x}
                className={classes.hidden}
                alt="google"
                height={24}
              />
            </>
          )}
        </Button>
      )}
      {pages?.login?.template?.hasButtonEmail && (
        <Button
          variant="contained"
          className={clsx(classes.button, classes.btnEmail, classes.btnBold)}
          fullWidth
          size="large"
          onClick={() => onSubmitLogin("email")}
          data-testid="login-email-btn"
          id="email"
        >
          {t("login_Connect with email", {
            defaultValue: "Connect with Email",
          })}
        </Button>
      )}
      {(pages?.login?.template?.hasButtonPhone || pages?.login?.onmoPayUrl) && (
        <Button
          variant="contained"
          className={clsx(classes.button, classes.btnPhone, classes.btnBold)}
          size="large"
          fullWidth
          onClick={() =>
            onSubmitLogin(pages?.login?.onmoPayUrl ? "onmoPay" : "phone")
          }
          data-testid="login-phone-btn"
          id="phone"
        >
          {t("login_Connect with phone number", {
            defaultValue: "Connect with Phone Number",
          })}
        </Button>
      )}
    </div>
  );
};
