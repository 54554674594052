import { NotificationPermission } from "../constants/notifications";
import { ONMO_CONFIG } from "../constants/onmo";
import { DataFormatter } from "../models/dataFormatter";

export class PushNotification {
  static subscribe = async () => {
    try {
      const permission = await window.Notification.requestPermission();
      if (permission === NotificationPermission.Granted) {
        const registration = await navigator.serviceWorker.getRegistration();
        let sub = await registration?.pushManager.getSubscription();
        if (!sub) {
          const applicationServerKey = DataFormatter.urlB64ToUint8Array(
            ONMO_CONFIG?.pushNotification?.key || ""
          ) as BufferSource | string | null;
          sub = await registration?.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey,
          });
        }
        return JSON.stringify(sub);
      } else {
        throw new Error("Please enable push notification before");
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  static unsubscribe = async () => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      const sub = await registration?.pushManager.getSubscription();
      if (sub) {
        await sub.unsubscribe();
      }
    } catch (e) {
      console.error(e);
    }
    return "null";
  };
}
