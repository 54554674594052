import { FriendTab } from "../constants/friend";
import { PageRoutes } from "../constants/historyOnmo";
import { ShopTab } from "../constants/shopWallet";
import { isAccountDisable } from "./users/users";

export class QueryParams {
  static getQueryParams() {
    return new URLSearchParams(window.location.search);
  }

  static getBattleType = () => {
    return QueryParams.getQueryParams().get("battleType");
  };

  static getFriendTab = () => {
    return QueryParams.getQueryParams().get("friendTab") as FriendTab;
  };

  static getShopTab = () => {
    return (QueryParams.getQueryParams().get("shopTab") ||
      ShopTab.All) as ShopTab;
  };

  static isHouseBattle = () => {
    return QueryParams.getQueryParams().get("isHouseBattle") === "true";
  };

  static isPlayAgain = () => {
    return QueryParams.getQueryParams().get("playAgain") === "true";
  };

  static getGameId = () => {
    return QueryParams.getQueryParams().get("gameId");
  };

  static getMomentId = () => {
    return QueryParams.getQueryParams().get("momentId");
  };

  static isOnboardingMoment = () => {
    return QueryParams.getQueryParams().get("isOnboarding") === "true";
  };

  static getTournamentId = () => {
    return QueryParams.getQueryParams().get("tournamentId");
  };

  static getTransactionId = () => {
    return QueryParams.getQueryParams().get("refId");
  };

  static getTransactionStatus = () => {
    return QueryParams.getQueryParams().get("status");
  };

  static getAuthToken = () => {
    return QueryParams.getQueryParams().get("code");
  };

  static getUtmMedium = () => {
    return QueryParams.getQueryParams().get("utm_medium");
  };

  static getUtmSource = () => {
    return QueryParams.getQueryParams().get("utm_source");
  };

  static getUtmCampaign = () => {
    return QueryParams.getQueryParams().get("utm_campaign");
  };

  static getVariations = () => {
    return QueryParams.getQueryParams().get("variations");
  };

  static getMatchId = () => {
    return QueryParams.getQueryParams().get("matchId");
  };
  static getBattleCost = () => {
    return QueryParams.getQueryParams().get("cost");
  };

  static isUserDisabled = () => {
    const errorDescription =
      QueryParams.getQueryParams().get("error_description");
    return isAccountDisable(errorDescription as string);
  };

  static getErrorDescription = () => {
    return QueryParams.getQueryParams().get("error_description");
  };

  static getBattlePrize = () => {
    const prize = QueryParams.getQueryParams().get("prize");
    return prize ? parseInt(prize, 10) : null;
  };

  static getOpponentData = () => {
    const id = QueryParams.getQueryParams().get("id");
    const username = QueryParams.getQueryParams().get("username");
    const avatar = QueryParams.getQueryParams().get("avatar") || "";
    const xp = QueryParams.getQueryParams().get("xp") || "0";
    const score = QueryParams.getQueryParams().get("score") || "0";

    if (!id || !username) return null;

    return {
      id,
      username,
      avatar,
      xp: parseInt(xp, 10),
      score: parseInt(score),
    };
  };

  static isViewer = () => {
    const isViewer = QueryParams.getQueryParams().get("isViewer");
    return isViewer === "true";
  };

  static getStreamId = () => {
    const streamId = QueryParams.getQueryParams().get("streamId");
    return streamId as string;
  };
  static getIsShowCookiePolicy = () => {
    return QueryParams.getQueryParams().get("showCookiePolicy") === "true";
  };

  static deleteCookieParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("showCookiePolicy");
    return queryParams.toString();
  };

  static deleteActivityIdParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("data");
    queryParams.delete("gameId");
    queryParams.delete("battleType");
    return queryParams.toString();
  };

  static getShareUserId = () => {
    return QueryParams.getQueryParams().get("shareBy");
  };

  static getUserId = () => {
    return QueryParams.getQueryParams().get("userId");
  };

  static getUserIdpId = () => {
    return QueryParams.getQueryParams().get("userIdpId");
  };

  static getUserIdpName = () => {
    return QueryParams.getQueryParams().get("userIdpName");
  };

  static getShareParams = () => {
    const userId = this.getUserId();
    const userIdpId = this.getUserIdpId();
    const userIdpName = this.getUserIdpName();
    const username = QueryParams.getQueryParams().get("username");
    const avatar = QueryParams.getQueryParams().get("avatar");
    const gameId = QueryParams.getQueryParams().get("gameId");
    const type = QueryParams.getQueryParams().get("type");
    const tournamentId = QueryParams.getQueryParams().get("tournamentId");

    if (!userId || !type) return null;

    return {
      userId,
      userIdpId,
      userIdpName,
      username,
      avatar,
      gameId,
      type,
      tournamentId,
    };
  };

  static getOncode = () => {
    return QueryParams.getQueryParams().get("oncode");
  };

  static getState = () => {
    return QueryParams.getQueryParams().get("state");
  };

  static getProfileTab = () => {
    return QueryParams.getQueryParams().get("tab");
  };

  static isBattleSession = () => {
    return window.location.pathname.includes(PageRoutes.PlayBattle);
  };

  static isTournamentSession = () => {
    return window.location.pathname.includes(PageRoutes.PlayTournament);
  };

  static isChallengeSession = () => {
    return window.location.pathname.includes(PageRoutes.PlayChallenge);
  };
}
