import { BOTTOM_NAVIGATION } from "../constants/bottomNavigation";
import { PageRoutes } from "../constants/historyOnmo";

export class OnmoLocation {
  static isActiveCompetition = (path: string) => {
    return path.includes(PageRoutes.Leaderboard);
  };

  static isActiveGames = (path: string) => {
    return path === PageRoutes.Games;
  };

  static isActiveHomeTab = (path: string) => {
    return path === PageRoutes.HomePage;
  };

  static isActiveFriendTab = (path: string) => {
    return path === PageRoutes.Friends || path === PageRoutes.UserProfile;
  };

  static isPlayBattle = (path: string) => {
    return path.includes(PageRoutes.PlayBattle);
  };

  static isPlayTournament = (path: string) => {
    return path.includes(PageRoutes.PlayTournament);
  };

  static isPlaySolo = (path: string) => {
    return path.includes(PageRoutes.PlayChallenge);
  };

  static isViewLivestream = (path?: string) => {
    const pathname = path || window.location.pathname;
    return pathname.includes(PageRoutes.LiveStream);
  };

  static isShowNavigation = () => {
    const pathname = window.location.pathname;
    return (
      OnmoLocation.isActiveFriendTab(pathname) ||
      OnmoLocation.isActiveHomeTab(pathname) ||
      OnmoLocation.isActiveCompetition(pathname) ||
      OnmoLocation.isActiveGames(pathname)
    );
  };

  static isNotShowWelcomePopup = (path: string) => {
    return (
      OnmoLocation.isPlayBattle(path) ||
      OnmoLocation.isPlayTournament(path) ||
      OnmoLocation.isPlaySolo(path)
    );
  };

  static isShowAvatarHeader = () => {
    const pathname = window.location.pathname;
    return (
      pathname === PageRoutes.HomePage || pathname === PageRoutes.Leaderboard
    );
  };

  static isMWCLeaderboard = () => {
    const pathname = window.location.pathname;
    return pathname === PageRoutes.MWCLeaderboard;
  };

  static isFriendsPage = () => {
    const pathname = window.location.pathname;
    return pathname === PageRoutes.Friends;
  };

  static isShopPage = () => {
    const pathname = window.location.pathname;
    return pathname === PageRoutes.Shop;
  };

  static getDefaultTabActive = () => {
    const pathname = window.location.pathname;
    const activeTab = BOTTOM_NAVIGATION.find((item) => item.path === pathname);
    return activeTab?.id;
  };

  static getSourcePage = () => {
    const pathname = window.location.pathname;
    if (pathname === PageRoutes.HomePage) {
      return "home_tab";
    } else if (pathname.includes(PageRoutes.Games)) {
      return "game_tab";
    } else if (pathname.includes(PageRoutes.SelectGame)) {
      return "friend_tab";
    } else if (pathname.includes(PageRoutes.MyProfile)) {
      return "profile_tab";
    } else if (pathname.includes(PageRoutes.GoLive)) {
      return "go_live";
    } else if (pathname.includes(PageRoutes.UserProfile)) {
      return "friend_profile";
    } else if (pathname.includes(PageRoutes.GameDetail)) {
      return "game_detail";
    } else if (pathname.includes("/play/")) {
      return "end_screen";
    }
  };

  static isFriendsSearch = (path?: string) => {
    const pathname = window.location.pathname;
    return (path || pathname) === PageRoutes.FriendsSearch;
  };
}
