import React, { HTMLAttributes, FC, useEffect, useState } from "react";
import { B2bSplashScreen } from "../B2bSplashScreen";

interface IOnmoSplashScreenProps {
  open: boolean;
  percentage: number;
}

const OnmoSplashScreenComponent: FC<
  IOnmoSplashScreenProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const { open, percentage, ...otherProps } = props;
  const [isHidden, setHidden] = useState(false);

  // Delete the component from the DOM if hidden to avoid catching DOM events
  useEffect(() => {
    let isUnmounted = false;
    if (!open) {
      setTimeout(() => {
        if (!isUnmounted) {
          setHidden(true);
        }
      }, 600);
    }

    return () => {
      isUnmounted = true;
    };
  }, [open]);

  if (isHidden || percentage >= 100) {
    return null;
  }

  return <B2bSplashScreen {...otherProps} percentage={percentage} />;
};
export const OnmoSplashScreen = React.memo(OnmoSplashScreenComponent);
