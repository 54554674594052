/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDateTime: { input: any; output: any };
  AWSTimestamp: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  LongInt: { input: any; output: any };
};

/** Denotes the type of user achievement */
export enum Achievement_Type {
  BattleFriendsPlayed = "battle_friends_played",
  BattleStreak = "battle_streak",
  BattlesWon = "battles_won",
  Challenger = "challenger",
  Completionist = "completionist",
  Followers = "followers",
  LoginStreak = "login_streak",
  TimePlayed = "time_played",
  TournamentsParticipation = "tournaments_participation",
  WeeklyGlParticipant = "weekly_gl_participant",
  WeeklyGlWinner = "weekly_gl_winner",
}

/** Achievement status with user progress */
export type Achievement = {
  __typename: "Achievement";
  /**
   * User's current achievement tier
   * Zero (`0`) value indicates user has not received the achievement yet
   */
  currentTier: Scalars["Int"]["output"];
  /** ID of achievement */
  id: Achievement_Type;
  /**
   * Target progress value for the next tier of achievement
   * Value will be `null` when there's no more tiers to be achieved
   */
  nextTierTarget: Maybe<Scalars["Int"]["output"]>;
  /** User's overall progress towards achievement */
  progress: Scalars["Float"]["output"];
};

export type AchievementLoginRewardProgress = {
  __typename: "AchievementLoginRewardProgress";
  amount: Scalars["Int"]["output"];
  itemType: Achievement_Type;
  status: Login_Reward_Status;
  walletType: Wallet_Type;
};

export type AchievementRewardUserNotificationData = {
  __typename: "AchievementRewardUserNotificationData";
  /** Achievement balance that user got */
  balance: Scalars["Int"]["output"];
  /** Value to enable or disable achievement notification */
  isEndScreenReward: Scalars["Boolean"]["output"];
  /** Achievement type that user got */
  type: Achievement_Type;
};

export type AchievementsInput = {
  /** ID of the user whose achievements to fetch */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivitiesInput = {
  /**
   * Category for activities
   * @deprecated This field is deprecated. Instead use `categoryWithFilters`
   */
  category: InputMaybe<ActivityCategory>;
  /** Category with basic filter */
  categoryWithFilters: InputMaybe<ActivityCategoryInput>;
  /** Number of items to be returned */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of the items to be returned */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type Activity =
  | BattleCancelledActivity
  | BattleExpiredActivity
  | BattleFinishActivity
  | BattleInviteActivity
  | BattleInviteSentActivity
  | BattlePlayedActivity
  | BattleRejectedActivity
  | DefaultActivity
  | LeaderboardActivity
  | TournamentPlayedActivity;

export enum ActivityCategory {
  Friends = "FRIENDS",
  Profile = "PROFILE",
}

export type ActivityCategoryFilterInput = {
  /** Required activityType activities to be returned. E.g: For leaderboard, the value can be `[leaderboard_ended]` */
  activityType: InputMaybe<Array<InputMaybe<ActivityType>>>;
  /**
   * Battle type for listing activities of only specific type of battle.
   * @deprecated This field is deprecated. Instead use `battleTypes`
   */
  battleType: InputMaybe<CoinBattleType>;
  /** Battle types for listing activities of only specific type of battle. */
  battleTypes: InputMaybe<Array<InputMaybe<CoinBattleType>>>;
};

export type ActivityCategoryInput = {
  /** Filters of the category to be listed */
  filters: InputMaybe<ActivityCategoryFilterInput>;
  /** Name of the category of which activities to be listed */
  name: ActivityCategory;
};

export type ActivityOutput = {
  __typename: "ActivityOutput";
  items: Array<Activity>;
  totalCount: Scalars["Int"]["output"];
};

export enum ActivityType {
  /** @deprecated use tiered_reward instead */
  AchievementReward = "achievement_reward",
  BattleCancelled = "battle_cancelled",
  BattleExpired = "battle_expired",
  BattleExpiring = "battle_expiring",
  BattleFinish = "battle_finish",
  BattleInviteRejected = "battle_invite_rejected",
  BattleInviteSent = "battle_invite_sent",
  BattleInvited = "battle_invited",
  BattlePlayed = "battle_played",
  ChallengeFailed = "challenge_failed",
  FollowUser = "follow_user",
  /** @deprecated friend system has been replaced by follow system. See follow_user activity type instead. */
  FriendAccept = "friend_accept",
  /** @deprecated friend system has been replaced by follow system. See follow_user activity type instead. */
  FriendInvite = "friend_invite",
  GameSessionEnded = "game_session_ended",
  LeaderboardEnded = "leaderboard_ended",
  LoginCoinReward = "login_coin_reward",
  SystemLeaderboardEnded = "system_leaderboard_ended",
  SystemLeaderboardReminder = "system_leaderboard_reminder",
  SystemTournamentEnded = "system_tournament_ended",
  TieredReward = "tiered_reward",
  TournamentDemotion = "tournament_demotion",
  UserUpdate = "user_update",
}

/** Input data for adding tournament type details */
export type AddTournamentTypeDetailsInput = {
  /** Currency to be used for rewarding coin */
  coinType: InputMaybe<CoinType>;
  /** Cost for each user */
  costPerUser: InputMaybe<Scalars["Float"]["input"]>;
  /** Currency to be used by the type */
  currency: InputMaybe<Currency>;
  /** Tournament daily start time */
  dailyStartTime: InputMaybe<Scalars["String"]["input"]>;
  /** Number of hours */
  durationHours: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of minutes */
  durationPlusMinus: InputMaybe<Scalars["Int"]["input"]>;
  /** Fee for playing tournament */
  feePerUser: InputMaybe<Scalars["Float"]["input"]>;
  /** Game Ids specified for this tournament type details */
  gameIds: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Type of the game */
  gameType: InputMaybe<Array<InputMaybe<Game_Type>>>;
  /** Featured banner image for these tournaments */
  imageUrl: InputMaybe<Scalars["String"]["input"]>;
  /** Max bonus percentage */
  maxBonusPercent: InputMaybe<Scalars["Int"]["input"]>;
  /** Maximum percentage of cash */
  maxCashPercent: InputMaybe<Scalars["Int"]["input"]>;
  /** Maximum players to play */
  maxPlayers: InputMaybe<Scalars["Int"]["input"]>;
  /** Maximum random tournament */
  maxRandomTournaments: InputMaybe<Scalars["Int"]["input"]>;
  /** Minimum players to play */
  minPlayers: InputMaybe<Scalars["Int"]["input"]>;
  /** Mininmum winners */
  minWinners: InputMaybe<Scalars["Int"]["input"]>;
  /** Prize pool array */
  prizePool: InputMaybe<Array<InputMaybe<PrizePoolInput>>>;
  /** Type of the prize to be there for tournament */
  prizeType: InputMaybe<Prize_Type>;
  /** Featured title for these tournaments */
  title: InputMaybe<Scalars["String"]["input"]>;
  /** Categories of the tournament */
  tournamentCategory: InputMaybe<Array<InputMaybe<Tournament_Category>>>;
  /** Type of the tournament */
  tournamentType: InputMaybe<Tournament_Type>;
};

export type AdminAdjustCoinsInput = {
  amount: Scalars["Float"]["input"];
  description: Scalars["String"]["input"];
};

export type AdminAdjustIncomeInput = {
  amount: Scalars["Float"]["input"];
  currency: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
};

export type AdminAdjustMarketingInput = {
  amount: Scalars["Float"]["input"];
  currency: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
};

export type AdminAdjustUserWalletInput = {
  amount: Scalars["Float"]["input"];
  currency: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type AdminCreateCurrencyInput = {
  currency: Scalars["String"]["input"];
};

export type AdminFetchTransactionsInput = {
  /** Term used to fetch transactions, either by entry_id or reference */
  term: InputMaybe<Scalars["String"]["input"]>;
};

/** Transaction list with their associated wallet */
export type AdminFetchTransactionsOutput = {
  __typename: "AdminFetchTransactionsOutput";
  transaction: Maybe<Transaction>;
  wallet: Maybe<WalletAdmin>;
};

/** Game object type for 'adminGame' resolver */
export type AdminGame = {
  __typename: "AdminGame";
  battleCost: Maybe<Array<Maybe<BattleCost>>>;
  bonusTournamentInfo: Maybe<Array<Maybe<TournamentInfo>>>;
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  category: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  company: Maybe<Scalars["String"]["output"]>;
  countMoments: Maybe<Scalars["Int"]["output"]>;
  data: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  /** Indicates if the game is disabled */
  disabled: Maybe<Scalars["Boolean"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  gamesPlayedByUser: Maybe<Scalars["Int"]["output"]>;
  hasBattle: Maybe<Scalars["Boolean"]["output"]>;
  hasSolo: Maybe<Scalars["Boolean"]["output"]>;
  hasTournament: Maybe<Scalars["Boolean"]["output"]>;
  /** Hot battles for game */
  hotBattles: Maybe<Array<Maybe<HotBattle>>>;
  /** House battles for game */
  houseBattles: Maybe<Array<Maybe<HouseBattle>>>;
  id: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  isLike: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  likeCount: Maybe<Scalars["Int"]["output"]>;
  moments: Maybe<Array<Maybe<Moment>>>;
  publisher: Maybe<Scalars["String"]["output"]>;
  /** Rank Categories of the Game (Only available in getUserFeed and getGame resolver) */
  rankCategories: Maybe<Array<Maybe<ILevel>>>;
  rotationMode: Maybe<RotationMode>;
  /** User's ELO (not available for all resolvers) */
  skill: Maybe<Scalars["Int"]["output"]>;
  status: Maybe<GameStatus>;
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  totalCompleted: Maybe<Scalars["Int"]["output"]>;
  tournamentInfo: Maybe<Array<Maybe<TournamentInfo>>>;
  tournaments: Maybe<Array<Maybe<Tournament>>>;
  type: Maybe<GameType>;
  /**
   * Indicates whether the game uses server-side simulation
   * @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored.
   */
  useSimulation: Maybe<Scalars["Boolean"]["output"]>;
};

/** Denotes the paginated response of `adminGames` resolver */
export type AdminGames = {
  __typename: "AdminGames";
  /** List of all games */
  items: Maybe<Array<Maybe<AdminGame>>>;
  /** Total number of games */
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** Input data to list battle payments with filter and pagination */
export type AdminListBattlePaymentsInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<BattlePaymentFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list bonuses with filter and pagination */
export type AdminListBonusesInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<BonusFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list coin purchases with filter and pagination */
export type AdminListCoinPurchasesInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<CoinPurchaseFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list deposits with filter and pagination */
export type AdminListDepositsInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<DepositFilter>>>;
  /** Maximum of deposits to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of deposits to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type AdminListInternalTransactionsFilterInput = {
  currency: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  wallet: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type AdminListInternalTransactionsInput = {
  filter: InputMaybe<AdminListInternalTransactionsFilterInput>;
};

/** Input data to list promotions with filter and pagination */
export type AdminListPromotionsInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<PromotionFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list tournament type details with filter and pagination */
export type AdminListTournamentTypeDetailsInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<TournamentTypeDetailsFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type AdminListTransactionsWithPaginationFilterInput = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The transaction's field to filter on */
  field: TransactionFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Input data to search transactions with filter and pagination */
export type AdminListTransactionsWithPaginationInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<
    Array<InputMaybe<AdminListTransactionsWithPaginationFilterInput>>
  >;
  /** Maximum of transactions to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of transactions to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list users with filter and pagination */
export type AdminListUsersInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<UserFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type AdminListWalletsWithPaginationFilterInput = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The wallet's field to filter on */
  field: WalletFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Input data to search wallets with filter and pagination */
export type AdminListWalletsWithPaginationInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<
    Array<InputMaybe<AdminListWalletsWithPaginationFilterInput>>
  >;
  /** Maximum of wallets to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of wallets to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list withdrawals with filter and pagination */
export type AdminListWithdrawalsInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<WithdrawalFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type AdminRefundBattleEntryInput = {
  battleId: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  entryId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

/** Denotes the response type of the `AdminTournaments` */
export type AdminTournaments = {
  __typename: "AdminTournaments";
  /** List of all the tournaments */
  items: Maybe<Array<Maybe<Tournament>>>;
  /** Total number of tournaments */
  totalCount: Maybe<Scalars["Int"]["output"]>;
};

/** Legacy type that denotes a game. This is being replaced by the `Game` type. */
export type App = {
  __typename: "App";
  /** Indicates whether the game can use cash */
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  /** The category is represented as a JSON string which contains a array of category values */
  category: Scalars["String"]["output"];
  /**
   * Game Developer
   * @deprecated This field has been deprecated and should no longer be used.
   */
  company: Scalars["String"]["output"];
  /** The total number of LIVE moments associated with the game */
  countMoments: Maybe<Scalars["Int"]["output"]>;
  /** Date and time at which the game was created */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** Dynamic meta data which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  /** Game Description */
  description: Scalars["String"]["output"];
  /** Indicates whether game has one-on-one battle moments */
  hasBattle: Maybe<Scalars["Boolean"]["output"]>;
  /** Game ID */
  id: Scalars["ID"]["output"];
  /** List of game moments */
  moments: Maybe<Array<Maybe<Moment>>>;
  /** Orientation required to play the game */
  rotationMode: Maybe<RotationMode>;
  /** Game Status */
  status: Maybe<Status>;
  /** Number of times the game has been played by users */
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  /** Game Title */
  title: Scalars["String"]["output"];
  /** Number of moments completed by the currently logged-in user */
  totalCompleted: Maybe<Scalars["Int"]["output"]>;
  /** Game Type */
  type: Maybe<GameType>;
  /** Date and time at which the game was updated */
  updatedAt: Scalars["AWSDateTime"]["output"];
  /**
   * Indicates whether the game uses server-side simulation
   * @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored.
   */
  useSimulation: Maybe<Scalars["Boolean"]["output"]>;
};

/** Legacy type that denotes a game. This is being replaced by the `Game` type. */
export type AppMomentsArgs = {
  listMomentsWithPaginationInput: InputMaybe<ListMomentsWithPaginationInput>;
};

export type ApplyInventoryInput = {
  /** ID of the inventory to apply to the user */
  inventoryId: Scalars["String"]["input"];
};

/** User authentication information */
export type AuthUser = {
  __typename: "AuthUser";
  /** Authentication Email */
  email: Maybe<Scalars["String"]["output"]>;
  /** Username */
  name: Scalars["String"]["output"];
  /** Permissions */
  permissions: Maybe<Array<Maybe<PermissionGroup>>>;
  /** Authentication Phone */
  phone: Maybe<Scalars["String"]["output"]>;
  /** User ID */
  userId: Scalars["String"]["output"];
};

/** Input for calling B2B Admin API */
export type B2bAdminApiInput = {
  /** Name of the API to call */
  api: Scalars["String"]["input"];
  /** Name of the B2B environment */
  environmentName: Scalars["String"]["input"];
  /** JSON containing the parameters */
  params: Scalars["String"]["input"];
};

export type B2bAdminApiOutput = {
  __typename: "B2bAdminApiOutput";
  /** JSON string with the results of the API call */
  results: Scalars["String"]["output"];
  /** Status code of the response */
  status: Scalars["Int"]["output"];
  /** Status text of the response */
  statusText: Scalars["String"]["output"];
};

export type B2bEnvironment = {
  __typename: "B2bEnvironment";
  /** Environment name/identifier */
  envName: Scalars["String"]["output"];
  /** Friendly name */
  friendlyName: Scalars["String"]["output"];
  /** Path to store documents in S3 bucket */
  s3PublishFolder: Scalars["String"]["output"];
};

/** Denotes battle payment details. */
export type Battle = {
  __typename: "Battle";
  /** Battle ID */
  battleId: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether coins or cash can be used. */
  chargeType: Maybe<ChargeType>;
  /** Cost to play the battle by the user. */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Time at which the battle was created. */
  createdTime: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Currency for the payment. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Time at which the battle will end. */
  endTime: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Platform fees. This is included in the `costPerUser` amount. */
  entryFee: Maybe<Scalars["Float"]["output"]>;
  /** Error message */
  error: Maybe<Scalars["String"]["output"]>;
  /** Time in minutes for the battle to expire. */
  expiryMins: Maybe<Scalars["Int"]["output"]>;
  /** Game ID */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Moment ID */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Number of players in the battle. */
  noOfPlayers: Maybe<Scalars["Int"]["output"]>;
  /**
   * Breakup of the prize amount.
   * @deprecated Dead field. No longer being used.
   */
  prizeBreakup: Maybe<Array<Maybe<PrizeBreakup>>>;
  /** Total prize amount that can be won. */
  prizePool: Maybe<Scalars["Float"]["output"]>;
  /** Time at which the battle was started. */
  startTime: Maybe<Scalars["AWSTimestamp"]["output"]>;
};

export type BattleCancelledActivity = {
  __typename: "BattleCancelledActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
};

export type BattleCompleted = {
  __typename: "BattleCompleted";
  completeBattle: Maybe<CompleteBattleResponse>;
  gameId: Maybe<Scalars["String"]["output"]>;
  hostUser: Maybe<LightUser>;
  /** @deprecated Replaced with hostUser. */
  hostUserId: Maybe<Scalars["String"]["output"]>;
  matchId: Maybe<Scalars["String"]["output"]>;
  notificationType: Maybe<Scalars["String"]["output"]>;
  opponent: Maybe<LightUser>;
  /** @deprecated Replaced with opponent. */
  opponentId: Maybe<Scalars["String"]["output"]>;
};

/** Cost to play a battle moment for a game */
export type BattleCost = {
  __typename: "BattleCost";
  /** Cost of battle */
  cost: Maybe<Scalars["Float"]["output"]>;
  /** Currency */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Platform fees for battle */
  fee: Maybe<Scalars["Float"]["output"]>;
};

export type BattleExpiredActivity = {
  __typename: "BattleExpiredActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
};

export type BattleFinishActivity = {
  __typename: "BattleFinishActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  challengeNumber: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
  rank: Scalars["Int"]["output"];
  winner: Scalars["Boolean"]["output"];
};

export type BattleInviteActivity = {
  __typename: "BattleInviteActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
};

export type BattleInviteSentActivity = {
  __typename: "BattleInviteSentActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
};

/** Denotes a battle payment */
export type BattlePayment = {
  __typename: "BattlePayment";
  /** Battle ID associated with the battle payment */
  battle_id: Maybe<Scalars["String"]["output"]>;
  /** Currency of the amount involved in the battle payment. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the battle payment was created. */
  date: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** IDs of the transaction associated with the battle payment. */
  entry_ids: Maybe<Scalars["String"]["output"]>;
  /** Fee taken from each user for the battle */
  fee_per_user: Maybe<Scalars["Float"]["output"]>;
  /** ID of the wallet used for the battle fee */
  fee_wallet_id: Maybe<Scalars["String"]["output"]>;
  /** Maximum number of players for the battle */
  max_nb_users: Maybe<Scalars["Int"]["output"]>;
  /** Price pool maximum for the battle */
  max_prize_pool: Maybe<Scalars["Float"]["output"]>;
  /** % of bonus taken */
  perc_from_bonus: Maybe<Scalars["Float"]["output"]>;
  /** Current status of the battle payment. */
  status: Maybe<Scalars["String"]["output"]>;
  /** ID of the users who participated in the battle. */
  user_ids: Maybe<Scalars["String"]["output"]>;
  /** Wager made by each user participating in the battle */
  wager_per_user: Maybe<Scalars["Float"]["output"]>;
  /** ID of the wallet used for the battle wager */
  wager_wallet_id: Maybe<Scalars["String"]["output"]>;
};

export type BattlePaymentFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The battle payment's field to filter on */
  field: BattlePaymentFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list battle payments on */
export enum BattlePaymentFilterField {
  /** ID of the battle associated with the battle payment */
  BattleId = "battle_id",
  /** Currency the battle payment is in */
  Currency = "currency",
  /** Date the battle payment was created */
  Date = "date",
  /** Fee paid by the users */
  FeePerUser = "fee_per_user",
  /** Max amount of the prize pool for the battle */
  MaxPrizePool = "max_prize_pool",
  /** Current status of the coin purchase */
  Status = "status",
  /** IDs of the users who paticipated in the battle */
  UserIds = "user_ids",
  /** Wager made by each user who participated in the battle */
  WagerPerUser = "wager_per_user",
}

/** Denotes the Battle payout data */
export type BattlePayout = {
  __typename: "BattlePayout";
  /** Currency to be used for Battle Payout */
  coinType: Maybe<CoinType>;
  /** prize value of the loser (toRank = 2) */
  coinsLoser: Maybe<Scalars["Int"]["output"]>;
  /** prize value of the loser (toRank = 1) */
  coinsWinner: Maybe<Scalars["Int"]["output"]>;
};

export type BattlePlayedActivity = {
  __typename: "BattlePlayedActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  rank: Maybe<Scalars["Int"]["output"]>;
};

export type BattleRejectedActivity = {
  __typename: "BattleRejectedActivity";
  activityType: ActivityType;
  battleId: Scalars["String"]["output"];
  battleType: CoinBattleType;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  gameId: Scalars["String"]["output"];
  gameTitle: Scalars["String"]["output"];
  momentId: Maybe<Scalars["String"]["output"]>;
  opponentUsers: Maybe<Array<Maybe<PublicUser>>>;
};

/** Type representing Battle Streak data. */
export type BattleStreakData = {
  __typename: "BattleStreakData";
  /** The current battle streak value. */
  battleStreak: Maybe<Scalars["Int"]["output"]>;
  /** The best recorded battle streak value. */
  bestBattleStreak: Maybe<Scalars["Int"]["output"]>;
};

/** Data for battle notifications */
export type BattleUserNotificationData = {
  __typename: "BattleUserNotificationData";
  /** Datetime at which the battle will expire, can be null for certain battle notifications */
  expiryTime: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Game ID */
  gameId: Scalars["String"]["output"];
  /** User informations about the host */
  host: Maybe<PublicUser>;
  /** Match ID */
  matchId: Scalars["String"]["output"];
  /** Match State */
  matchState: Maybe<MatchState>;
  /** User information about the opponent */
  opponent: Maybe<PublicUser>;
};

export type BeatItActivity = {
  __typename: "BeatItActivity";
  gameId: Scalars["String"]["output"];
};

export type Bonus = {
  __typename: "Bonus";
  balance: Maybe<Scalars["Float"]["output"]>;
  bonusId: Maybe<Scalars["String"]["output"]>;
  bonusType: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  date: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  escrowId: Maybe<Scalars["String"]["output"]>;
  expiryDate: Maybe<Scalars["String"]["output"]>;
  initialAmount: Maybe<Scalars["Float"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

export type BonusFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The bonus's field to filter on */
  field: BonusFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list bonuses on */
export enum BonusFilterField {
  /** Balance at the time of the bonus */
  Balance = "balance",
  /** Type of the bonus */
  BonusType = "bonus_type",
  /** Currency the bonus is in */
  Currency = "currency",
  /** Date the bonus was created */
  Date = "date",
  /** identifier for the bonus */
  Id = "id",
  /** Amount of the bonus */
  InitialAmount = "initial_amount",
  /** User who made the bonus */
  UserId = "user_id",
  /** Wallet the bonus is from */
  WalletId = "wallet_id",
}

/** Denotes the bonus type */
export enum BonusType {
  /** Refund for entry cost */
  EntryRefund = "ENTRY_REFUND",
  /** Extra bonus given for various reasons */
  ExtraBonus = "EXTRA_BONUS",
  /** Bonus awarded for depositing cash for the first time. */
  FirstDepositBonus = "FIRST_DEPOSIT_BONUS",
  /** Bonus awarded when user manually claimed free coins. */
  FreeCoins = "FREE_COINS",
  /** Bonus for winning house battle */
  HouseBattle = "HOUSE_BATTLE",
  /** Bonus awarded for depositing minimum amount of cash. */
  MinimumDepositBonus = "MINIMUM_DEPOSIT_BONUS",
  /** Refund for not getting prize */
  PrizeRefund = "PRIZE_REFUND",
  /** Used for giving testing coins and cash for internal use */
  Testing = "TESTING",
  /** Bonus awarded for signing up. */
  WelcomeBonus = "WELCOME_BONUS",
}

/** Denotes bonus type information */
export type BonusTypeDetail = {
  __typename: "BonusTypeDetail";
  /** Indicates whether bonus type is active */
  active: Scalars["Boolean"]["output"];
  /** Indidcates whether claming multiple bonuses of the type is allowed */
  allowMultiple: Scalars["Boolean"]["output"];
  /** Bonus amount */
  amount: Scalars["Int"]["output"];
  /** Currency of bonus */
  currency: Scalars["String"]["output"];
  /** Bonus description */
  description: Scalars["String"]["output"];
  /** Number of days in which the bonus will expire */
  expirationTimeInDays: Scalars["Int"]["output"];
  /** Maximum wallet balance allowed for claiming bonus */
  maxBalance: Maybe<Scalars["Int"]["output"]>;
  /** Minimum amount that needs to be deposited to get bonus */
  minimumAmount: Maybe<Scalars["Int"]["output"]>;
  /** Denotes the type of bonus */
  type: BonusType;
};

export type BonusTypeDetailInput = {
  /** Indicates whether bonus type is active */
  active: Scalars["Boolean"]["input"];
  /** Indidcates whether claming multiple bonuses of the type is allowed */
  allowMultiple: Scalars["Boolean"]["input"];
  /** Bonus amount */
  amount: Scalars["Int"]["input"];
  /** Currency of bonus */
  currency: Scalars["String"]["input"];
  /** Bonus description */
  description: Scalars["String"]["input"];
  /** Number of days in which the bonus will expire */
  expirationTimeInDays: Scalars["Int"]["input"];
  /** Maximum wallet balance allowed for claiming bonus */
  maxBalance: InputMaybe<Scalars["Int"]["input"]>;
  /** Minimum amount that needs to be deposited to get bonus */
  minimumAmount: InputMaybe<Scalars["Int"]["input"]>;
  /** Type of bonus */
  type: BonusType;
};

/** Denotes the status of stream session */
export enum Completion_Status {
  Completed = "COMPLETED",
  StreamCrashed = "STREAM_CRASHED",
  TimeOut = "TIME_OUT",
  UserTerminated = "USER_TERMINATED",
}

export enum Currency {
  /** @deprecated Cash is no longer supported. */
  Btc = "BTC",
  /** @deprecated Cash is no longer supported. */
  Inr = "INR",
  Xxx = "XXX",
}

/** Input data to cancel an existing match. */
export type CancelMatchInput = {
  /** Match ID */
  matchId: Scalars["String"]["input"];
};

/** Response for `cancelMatch` mutation. */
export type CancelMatchOutput = {
  __typename: "CancelMatchOutput";
  /** Updated wallets of the user. */
  updatedWallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Input parameter for close tournament */
export type CancelTournamentInput = {
  /** Tournament ID */
  tournamentId: InputMaybe<Scalars["String"]["input"]>;
};

export type CashAllowance = {
  __typename: "CashAllowance";
  /** Indicates if cash is allowed */
  isDisabled: Maybe<Scalars["Boolean"]["output"]>;
  /** Indicates if allowance uses parent values */
  useDefault: Maybe<Scalars["Boolean"]["output"]>;
  /** Currencies allowed if not disabled and not using default */
  value: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type CashAllowanceInput = {
  /** Indicates if cash is allowed */
  isDisabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if allowance uses parent values */
  useDefault: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Currencies allowed if not disabled and not using default */
  value: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** This denotes a Challenge. */
export type Challenge = {
  __typename: "Challenge";
  /** The number of challenges that have been completed by the user. */
  completedChallenges: Scalars["Int"]["output"];
  /** The ID of the game. */
  gameId: Scalars["String"]["output"];
  /** The next available moment for the game. */
  nextMoment: Maybe<Moment>;
  /** The total number of challenges available. */
  totalChallenges: Scalars["Int"]["output"];
};

export type ChallengeActivity = {
  __typename: "ChallengeActivity";
  completedChallenges: Scalars["Int"]["output"];
  gameId: Scalars["String"]["output"];
  nextMoment: Moment;
  totalChallenges: Scalars["Int"]["output"];
};

/** Denotes the rewards for a challenge */
export type ChallengeRewards = {
  __typename: "ChallengeRewards";
  /** Currency to be used for Challenge rewards */
  coinType: Maybe<CoinType>;
  /** gems won the second, third, etc. time the challenge is passed */
  coinsCompletion: Maybe<Scalars["Int"]["output"]>;
  /** gems when the challenge isn’t passed */
  coinsFailure: Maybe<Scalars["Int"]["output"]>;
  /** gems won the first time the challenge is passed */
  coinsFirstTimeCompletion: Maybe<Scalars["Int"]["output"]>;
};

export enum ChargeType {
  Cash = "cash",
  Coin = "coin",
  Free = "free",
}

export type CheaterContact = {
  __typename: "CheaterContact";
  email: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  ip: Maybe<Scalars["String"]["output"]>;
  isActive: Maybe<Scalars["Boolean"]["output"]>;
  phone: Maybe<Scalars["String"]["output"]>;
};

export type CheaterContactFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The cheater contact field to filter on */
  field: CheaterContactFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter list cheater contact on */
export enum CheaterContactFilterField {
  /** email for the cheater contact */
  Email = "email",
  /** identifier for the cheater contact */
  Id = "id",
  /** Is cheater contact active or not */
  IsActive = "is_active",
  /** phone for the cheater contact */
  Phone = "phone",
}

/** User Check-In data */
export type Checkin = {
  __typename: "Checkin";
  /** Timestamp of the last check-in */
  lastCheckin: Scalars["LongInt"]["output"];
  /** User ID */
  userId: Scalars["String"]["output"];
  /** Returns users wallets */
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
  /** Onmo Pay URL */
  withdrawalUrl: Maybe<Scalars["String"]["output"]>;
};

/** Input data for checking in user activity */
export type CheckinUserInput = {
  /** User account parter */
  partner: InputMaybe<Scalars["String"]["input"]>;
  /** User account agent */
  userAgent: InputMaybe<Scalars["String"]["input"]>;
  /** Google analytics campaign */
  utmCampaign: InputMaybe<Scalars["String"]["input"]>;
  /** Google analytics medium */
  utmMedium: InputMaybe<Scalars["String"]["input"]>;
  /** Google analytics source */
  utmSource: InputMaybe<Scalars["String"]["input"]>;
};

export enum ChronoType {
  Daily = "daily",
  Overall = "overall",
  Weekly = "weekly",
}

export type ClaimLoginRewardInput = {
  /** Zero-based index of the reward to claim */
  rewardIndex: Scalars["Int"]["input"];
};

export type ClearNotificationsInput = {
  /** ID of the notification to clear */
  notificationIds: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** Input parameter for Close tournaments */
export type CloseTournamentsInput = {
  /** tournament status */
  status: InputMaybe<Scalars["String"]["input"]>;
  /** tournament id */
  tournamentId: InputMaybe<Scalars["String"]["input"]>;
};

/** Type for Code Build Environment. Note that every variable must be retrieved in order to later update */
export type CodeBuildEnvironment = {
  __typename: "CodeBuildEnvironment";
  /** Compute type used (e.g. BUILD_GENERAL1_MEDIUM) */
  computeType: ComputeType;
  /** List of environment variables */
  environmentVariables: Maybe<Array<Maybe<CodeBuildEnvironmentVariable>>>;
  /** Image used to create project (e.g. aws/codebuild/standard:6.0) */
  image: Scalars["String"]["output"];
  /** Type of credentials used (e.g. CODEBUILD) */
  imagePullCredentialsType: ImagePullCredentialsType;
  /** Indicates whether privledged mode is active or not */
  privilegedMode: Scalars["Boolean"]["output"];
  /** Type of CodeBuild Project (e.g. LINUX_CONTAINER) */
  type: Scalars["String"]["output"];
};

/** Input for Code Build Environment. Note that all values except environmentVariables should not be changed by the front end. */
export type CodeBuildEnvironmentInput = {
  /** Compute type used (e.g. BUILD_GENERAL1_MEDIUM) */
  computeType: ComputeType;
  /** List of environment variables */
  environmentVariables: InputMaybe<
    Array<InputMaybe<CodeBuildEnvironmentVariableInput>>
  >;
  /** Image used to create project (e.g. aws/codebuild/standard:6.0) */
  image: Scalars["String"]["input"];
  /** Type of credentials used (e.g. CODEBUILD) */
  imagePullCredentialsType: ImagePullCredentialsType;
  /** Indicates whether privledged mode is active or not */
  privilegedMode: Scalars["Boolean"]["input"];
  /** Type of CodeBuild Project (e.g. LINUX_CONTAINER) */
  type: Scalars["String"]["input"];
};

/** Type for holding code build environment variables */
export type CodeBuildEnvironmentVariable = {
  __typename: "CodeBuildEnvironmentVariable";
  /** Environment variable name */
  name: Scalars["String"]["output"];
  /** Environment variable type */
  type: EnvironmentVariableType;
  /** Environment variable value */
  value: Scalars["String"]["output"];
};

export type CodeBuildEnvironmentVariableInput = {
  /** Environment variable name */
  name: Scalars["String"]["input"];
  /** Environment variable type */
  type: EnvironmentVariableType;
  /** Environment variable value */
  value: Scalars["String"]["input"];
};

export type CoinAllowance = {
  __typename: "CoinAllowance";
  /** Indicates if coins are allowed */
  isDisabled: Maybe<Scalars["Boolean"]["output"]>;
  /** Indicates if allowance uses parent values */
  useDefault: Maybe<Scalars["Boolean"]["output"]>;
  /** Currency allowed if not disabled and not using default */
  value: Maybe<Scalars["String"]["output"]>;
};

export type CoinAllowanceInput = {
  /** Indicates if coins are allowed */
  isDisabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if allowance uses parent values */
  useDefault: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Currency allowed if not disabled and not using default */
  value: InputMaybe<Scalars["String"]["input"]>;
};

export enum CoinBattleType {
  Battle = "battle",
  HouseBattle = "house_battle",
  Solo = "solo",
  Tournament = "tournament",
}

/** Denotes a coin bucket */
export type CoinBucket = {
  __typename: "CoinBucket";
  /** Balance remaining in bucket */
  balance: Maybe<Scalars["Int"]["output"]>;
  /** Type of coin */
  coinType: Maybe<CoinType>;
  /** Date the bucket was created */
  createDate: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Date the bucket expires (null indicates no expiry date) */
  expiryDate: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Coin bucket ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Initial amount added to bucket */
  initialAmount: Maybe<Scalars["Int"]["output"]>;
  /** User ID of bucket owner */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes an amount to be deducted from a coin bucket */
export type CoinBucketDebit = {
  __typename: "CoinBucketDebit";
  /** Amount to be deducted from coin bucket */
  amount: Maybe<Scalars["Int"]["output"]>;
  /** Coin bucket ID */
  bucketId: Maybe<Scalars["String"]["output"]>;
};

export type CoinBucketsOutput = {
  __typename: "CoinBucketsOutput";
  items: Maybe<Array<Maybe<CoinBucket>>>;
  totalCount: Scalars["Int"]["output"];
};

export type CoinLoginRewardProgress = {
  __typename: "CoinLoginRewardProgress";
  amount: Scalars["Int"]["output"];
  itemType: CoinType;
  status: Login_Reward_Status;
  walletType: Wallet_Type;
};

/** Denotes a coin purchase */
export type CoinPurchase = {
  __typename: "CoinPurchase";
  /** Currency of the amount involved in the coin purchase. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the coin purchase was created. */
  date: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Coin purchase ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Amount paid for the coin purchase */
  price: Maybe<Scalars["Float"]["output"]>;
  /** Amount of coins purchased. */
  quantity: Maybe<Scalars["Float"]["output"]>;
  /** Current status of the coin purchase. */
  status: Maybe<Scalars["String"]["output"]>;
  /** ID of the transaction that represents the coin purchase. */
  transaction_id: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the coin purchase was updated. */
  updated_at: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** ID of the user who initiated the coin purchase. */
  user_id: Maybe<Scalars["String"]["output"]>;
  /** ID of the wallet the coin purchase was made from. */
  wallet_id: Maybe<Scalars["String"]["output"]>;
};

export type CoinPurchaseFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The coin purchase's field to filter on */
  field: CoinPurchaseFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list coin purchases on */
export enum CoinPurchaseFilterField {
  /** Currency the coin purchase is in */
  Currency = "currency",
  /** Date the coin purchase was created */
  Date = "date",
  /** identifier for the coin purchase */
  Id = "id",
  /** Price paid to purchase coins */
  Price = "price",
  /** Amount of coins purchased */
  Quantity = "quantity",
  /** Current status of the coin purchase */
  Status = "status",
  /** User who made the coin purchase */
  UserId = "user_id",
  /** Wallet the coin purchase is from */
  WalletId = "wallet_id",
}

/** Denotes a coin transaction */
export type CoinTransaction = {
  __typename: "CoinTransaction";
  /** Amount of the transaction */
  amount: Maybe<Scalars["Int"]["output"]>;
  /** Type of transaction */
  balance: Maybe<Scalars["String"]["output"]>;
  /** Type of coin */
  coinType: Maybe<CoinType>;
  /** Date the transaction was performed */
  createDate: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Coin transaction ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** User ID of transaction owner */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes the coin transaction type */
export enum CoinTransactionType {
  /** Coin transaction battle type */
  Battle = "battle",
  /** Coin transaction battle_cancel type */
  BattleCancel = "battle_cancel",
  /** Coin transaction bonus coins type */
  BonusCoins = "bonus_coins",
  /** Coin transaction entry_cancel type */
  EntryCancel = "entry_cancel",
  /** Coin transaction manual type */
  Manual = "manual",
  /** Coin transaction manual refund type */
  ManualRefund = "manual_refund",
  /** Coin transaction purchase type */
  Purchase = "purchase",
  /** Coin transaction solo type */
  Solo = "solo",
  /** Coin transaction subscription type */
  Subscription = "subscription",
  /** Coin transaction test type */
  Test = "test",
  /** Coin transaction topup type */
  Topup = "topup",
  /** Coin transaction win type */
  Win = "win",
}

/** Output from coinTransactions */
export type CoinTransactionsOutput = {
  __typename: "CoinTransactionsOutput";
  /** A list of transactions */
  items: Maybe<Array<Maybe<CoinTransaction>>>;
  /** Total count of results */
  totalCount: Scalars["Int"]["output"];
};

/** Denotes the coin type */
export enum CoinType {
  /** coin basic type */
  Basic = "basic",
  /** A custom coin type */
  Gems = "gems",
  /** A custom coin type for buying inventory */
  SpendableGems = "spendable_gems",
  /** Legacy onmo-payment wallet type for backwards compatibility with cash */
  Winnings = "winnings",
}

/** Denotes a coin wallet */
export type CoinWallet = {
  __typename: "CoinWallet";
  /** Coin balance */
  balance: Maybe<Scalars["Int"]["output"]>;
  /** Coin type */
  coinType: Maybe<CoinType>;
  /** User Id */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a user wallet earnings. */
export type CoinWalletEarning = {
  __typename: "CoinWalletEarning";
  /** Type of wallet. */
  coinType: Maybe<CoinType>;
  /** Sum of winnings. */
  total: Maybe<Scalars["Int"]["output"]>;
  /** User  ID */
  userId: Maybe<Scalars["String"]["output"]>;
};

export type CoinWalletsOutput = {
  __typename: "CoinWalletsOutput";
  items: Maybe<Array<Maybe<CoinWallet>>>;
  totalCount: Scalars["Int"]["output"];
};

export type CoinWalletsUpdatedEvent = {
  __typename: "CoinWalletsUpdatedEvent";
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
};

export type CompleteBattle = {
  __typename: "CompleteBattle";
  battleId: Maybe<Scalars["String"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  feePerUser: Maybe<Scalars["Float"]["output"]>;
  maxPrizePool: Maybe<Scalars["Float"]["output"]>;
  userIds: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  wagerPerUser: Maybe<Scalars["Float"]["output"]>;
};

export type CompleteBattleResponse = {
  __typename: "CompleteBattleResponse";
  battle: Maybe<CompleteBattle>;
  transactions: Maybe<Array<Maybe<Transaction>>>;
  updatedWallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Coin Purchase Response */
export type CompletePurchaseCoin = {
  __typename: "CompletePurchaseCoin";
  /** Status of the purchase. */
  status: Maybe<Scalars["String"]["output"]>;
  /** Transactions involved in the purchase. */
  transactions: Maybe<Array<Maybe<FullTransaction>>>;
  /** Wallets affected by the purchase. */
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Input data to complete coin purchase. */
export type CompletePurchaseCoinInput = {
  /** Onmo Pay Transaction ID. The transaction ID returned by Onmo Pay must be specified in this field so that the coins can be deposited to the user. */
  transaction_id: Scalars["String"]["input"];
};

/** Cash Deposit Response */
export type CompletePurchaseDeposit = {
  __typename: "CompletePurchaseDeposit";
  /**
   * Type of cash bonus awarded. If `null` then no bonus was awarded.
   * @deprecated No longer in use.
   */
  bonusType: Maybe<Scalars["String"]["output"]>;
  /**
   * Status of the deposit.
   * @deprecated No longer in use.
   */
  status: Maybe<Scalars["String"]["output"]>;
  /**
   * Transactions involved in the deposit.
   * @deprecated No longer in use.
   */
  transactions: Maybe<Array<Maybe<FullTransaction>>>;
  /**
   * Wallets affected by the deposit.
   * @deprecated No longer in use.
   */
  wallets: Maybe<Array<Maybe<Wallet>>>;
};

/** Input data to complete cash deposit. */
export type CompletePurchaseDepositInput = {
  /**
   * Onmo Pay Transaction ID. The transaction ID returned by Onmo Pay must be specified in this field so that the cash can be deposited into the user's wallet.
   * @deprecated No longer being used.
   */
  transaction_id: InputMaybe<Scalars["String"]["input"]>;
};

export enum ComputeType {
  BuildGeneral1_2Xlarge = "BUILD_GENERAL1_2XLARGE",
  BuildGeneral1Large = "BUILD_GENERAL1_LARGE",
  BuildGeneral1Medium = "BUILD_GENERAL1_MEDIUM",
  BuildGeneral1Small = "BUILD_GENERAL1_SMALL",
}

/** Denotes the config data. */
export type ConfigInfo = {
  __typename: "ConfigInfo";
  /** Free Coins bonus data */
  freeCoins: Maybe<FreeCoinsBonus>;
  /** Minimum amount to be withdrawn */
  minimumWithdrawal: Maybe<MinimumWithdrawalAmount>;
  /** Prize pools battle data */
  prizePools: Maybe<PrizePools>;
  /** Tier Levels data */
  tierLevels: Maybe<Array<Maybe<TierLevel>>>;
};

export type Contest = {
  __typename: "Contest";
  /** The URL to the image/banner associated with the contest. */
  banner: Scalars["String"]["output"];
  /** The end time of the contest. Should be in ISO 8601 format. */
  endAt: Scalars["AWSDateTime"]["output"];
  /** Indicates whether the user is qualified to participate in the contest. */
  isQualified: Scalars["Boolean"]["output"];
  /** The start time of the contest. Should be in ISO 8601 format. */
  startAt: Scalars["AWSDateTime"]["output"];
  /** The title of the contest, potentially localized based on the user's language preference. */
  title: Scalars["String"]["output"];
  /** The URL that users can visit to learn more about or participate in the contest. */
  url: Scalars["String"]["output"];
};

/** Defines the structure for the output of a query that fetches multiple contests. */
export type ContestsOutput = {
  __typename: "ContestsOutput";
  items: Array<Contest>;
  totalCount: Scalars["Int"]["output"];
};

export type ContinuePlayingActivity =
  | BeatItActivity
  | ChallengeActivity
  | TournamentActivity;

/** This is the filter object, count of moments will be filtered by these constraints. */
export type CountMomentsWithPaginationInput = {
  /** This denotes the Game ID */
  appId: InputMaybe<Scalars["String"]["input"]>;
  /** Type of the moment */
  momentType: InputMaybe<MomentType>;
  /** Snapshot ID */
  snapshotId: InputMaybe<Scalars["String"]["input"]>;
  /** Status */
  status: InputMaybe<Status>;
};

/**
 * Deprecated, to be removed once mutation 'createFriendRequest' is removed
 * Input data for creating a friend request.
 */
export type CreateFriendRequestInput = {
  /** User ID of the receiver. */
  receiverId: Scalars["ID"]["input"];
  /**
   * User ID of the sender.
   * @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored.
   */
  senderId: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateGameSessionInput = {
  appId: Scalars["ID"]["input"];
  controller: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  edgeNodeId: Scalars["String"]["input"];
  id: InputMaybe<Scalars["ID"]["input"]>;
  incognito: InputMaybe<Scalars["Boolean"]["input"]>;
  isStreamReady: InputMaybe<Scalars["Boolean"]["input"]>;
  matchId: InputMaybe<Scalars["String"]["input"]>;
  momentId: Scalars["ID"]["input"];
  sessionResults: InputMaybe<Scalars["String"]["input"]>;
  sessionType: InputMaybe<GameSessionType>;
  userId: Scalars["String"]["input"];
};

/** Input data to create a match. */
export type CreateHouseMatchInput = {
  /** Cost to start the match. */
  costPerUser: Scalars["Float"]["input"];
  /** Specify the desired height in pixels for the video stream */
  desiredResolutionHeight: InputMaybe<Scalars["Int"]["input"]>;
  /** Information about the user's device. */
  device: UserDeviceInput;
  /** Replay house battle provided edgeNodeId */
  edgeNodeId: InputMaybe<Scalars["String"]["input"]>;
  /** House match id */
  houseBattleId: Scalars["String"]["input"];
  /** Region for streaming house battle */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to create friend battle */
export type CreateMatchInput = {
  /** Cost to start the match. */
  costPerUser: InputMaybe<Scalars["Float"]["input"]>;
  /** Specify the desired height in pixels for the video stream */
  desiredResolutionHeight: InputMaybe<Scalars["Int"]["input"]>;
  /** Information about the user's device. */
  device: UserDeviceInput;
  /** Game ID */
  gameId: Scalars["String"]["input"];
  /** Invitation Code to be associated with match. */
  inviteCode: InputMaybe<Scalars["String"]["input"]>;
  /** Replay match id */
  matchId: InputMaybe<Scalars["String"]["input"]>;
  /** List of opponent user IDs. */
  opponents: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Streaming game match streamingRegion */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Response for `createHouseMatch` mutations. */
export type CreateMatchOutput = {
  __typename: "CreateMatchOutput";
  /** Specify the desired height in pixels for the video stream */
  desiredResolutionHeight: Maybe<Scalars["Int"]["output"]>;
  /** ID of stream node. Value will be `HTML` for HTML games. */
  edgeNodeId: Scalars["String"]["output"];
  /** New Game Session ID */
  gameSessionId: Scalars["String"]["output"];
  /** Match ID */
  matchId: Scalars["String"]["output"];
  /** Match Moment */
  moment: Moment;
  /** Match players */
  players: Array<Maybe<MatchUser>>;
};

/** Denotes the output of mutual follow code resolver. */
export type CreateMutualFollowCodeOutput = {
  __typename: "CreateMutualFollowCodeOutput";
  /** Oncode for mutual follow */
  oncode: Scalars["String"]["output"];
};

/** Input parameter for create or update cheater contact. */
export type CreateOrUpdateCheaterContactInput = {
  /** Email */
  email: InputMaybe<Scalars["String"]["input"]>;
  /** ID */
  id: InputMaybe<Scalars["String"]["input"]>;
  /** IP */
  ip: InputMaybe<Scalars["String"]["input"]>;
  /** Is Active */
  isActive: Scalars["Boolean"]["input"];
  /** Phone */
  phone: InputMaybe<Scalars["String"]["input"]>;
};

/** Input parameter for CreatePromo. */
export type CreatePromoInput = {
  /** Promo end date */
  endDate: Scalars["AWSDateTime"]["input"];
  /** Game ID */
  gameId: Scalars["ID"]["input"];
  /** Moment ID */
  momentId: Scalars["ID"]["input"];
  /** Game play score */
  score: Scalars["Int"]["input"];
  /** Promo start date */
  startDate: Scalars["AWSDateTime"]["input"];
  /** User ID */
  userId: Scalars["ID"]["input"];
};

/** Input data for creating a setting */
export type CreateSettingInput = {
  /** Key of the setting */
  key: InputMaybe<Setting_Keys>;
  /** Value of the setting */
  value: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to create a shareable url */
export type CreateShareableUrlInput = {
  /**
   * Relative path of the Frontend to create a shareable url for.
   * Must begin with `/`.
   * E.g. /games/101
   */
  path: Scalars["String"]["input"];
  /** Relative path of the preview image in the data S3 bucket to be used for preview while sharing in social apps. Must begin with `/`. */
  previewImagePath: InputMaybe<Scalars["String"]["input"]>;
  /** Custom title for the social preview. Will only be visible when `previewImagePath` is provided. Defaults to ONMO. */
  previewTitle: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for creating solo challenge transaction. */
export type CreateSoloChallengeTransactionInput = {
  /** Game Session ID */
  gameSessionId: Scalars["String"]["input"];
};

export type CreateUserActivityInput = {
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  data: InputMaybe<Scalars["String"]["input"]>;
  id: InputMaybe<Scalars["ID"]["input"]>;
  type: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type CreateUserMomentStatsInput = {
  appId: Scalars["ID"]["input"];
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  id: InputMaybe<Scalars["ID"]["input"]>;
  momentId: Scalars["ID"]["input"];
  numberOfTimesPlayed: Scalars["Int"]["input"];
  totalTimePlayed: Scalars["Int"]["input"];
  updatedAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  userId: Scalars["ID"]["input"];
  xp: Scalars["Int"]["input"];
};

/** Default type that gives the resulting status of the mutation. */
export type Default = {
  __typename: "Default";
  /** Result of mutation. */
  status: Scalars["String"]["output"];
};

export type DefaultActivity = {
  __typename: "DefaultActivity";
  /** @deprecated DefaultActivity is not required */
  activityType: ActivityType;
  /** @deprecated DefaultActivity is not required */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** @deprecated DefaultActivity is not required */
  data: Maybe<Scalars["String"]["output"]>;
};

/** Deprecated. To be removed along with associated mutation 'deleteFriend' */
export type DeleleFriendInput = {
  id: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteAppInput = {
  id: Scalars["ID"]["input"];
};

/** Deprecated. To be removed once mutation 'deleteFriend' is removed */
export type DeleteFriendInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteGameSessionInput = {
  id: Scalars["ID"]["input"];
};

/** Input parameter for Delete Promo. */
export type DeletePromoInput = {
  /** ID of the Promo */
  id: Scalars["ID"]["input"];
};

/** Input data to delete a regional allowance */
export type DeleteRegionalAllowanceInput = {
  /** Country Code of Regional Allowance to delete */
  country: Scalars["String"]["input"];
  /** Region Code of Regional Allowance to delete */
  region: Scalars["String"]["input"];
};

/** Input data for deleting a setting */
export type DeleteSettingInput = {
  /** Key of the setting */
  key: InputMaybe<Setting_Keys>;
};

export type DeleteUserActivityInput = {
  id: Scalars["ID"]["input"];
};

/** Input data to delete an User Allowance */
export type DeleteUserAllowanceInput = {
  /** Domain name part of email of User Allowance to delete */
  domain: Scalars["String"]["input"];
  /** Username part of email of User Allowance to delete */
  username: Scalars["String"]["input"];
};

export type DeleteUserInput = {
  id: Scalars["ID"]["input"];
};

export type DeleteUserMomentStatsInput = {
  id: Scalars["ID"]["input"];
};

/** Get the deployment status of the specified CodePipeline project */
export type DeployStatus = {
  __typename: "DeployStatus";
  /** The last deploy stage run */
  deployStage: Maybe<Scalars["String"]["output"]>;
  /** The status of the last deploy stage run */
  deployStageStatus: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a deposit. */
export type Deposit = {
  __typename: "Deposit";
  /** Balance for the deposit. */
  balance: Maybe<Scalars["String"]["output"]>;
  /** Currency of the amount involved in the deposit. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the deposit was created. */
  date: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Deposit ID */
  depositId: Maybe<Scalars["String"]["output"]>;
  /** Deposited amount to the user. */
  depositedAmount: Maybe<Scalars["Float"]["output"]>;
  /** ID of the escrow wallet. */
  escrowId: Maybe<Scalars["String"]["output"]>;
  /** Total deposit amount. */
  grossAmount: Maybe<Scalars["Float"]["output"]>;
  /** Current status of the deposit. */
  status: Maybe<Scalars["String"]["output"]>;
  /** Subscription id for the deposit. */
  subscriptionId: Maybe<Scalars["String"]["output"]>;
  /** Fees incurred for the deposit. */
  transactionFee: Maybe<Scalars["Float"]["output"]>;
  /** ID of the transaction that represents the deposit. */
  transactionId: Maybe<Scalars["String"]["output"]>;
  /** ID of the user who initiated the deposit. */
  userId: Maybe<Scalars["String"]["output"]>;
  /** Wallet ID */
  walletId: Maybe<Scalars["String"]["output"]>;
};

export type DepositBonusPaid = {
  __typename: "DepositBonusPaid";
  bonusType: Maybe<Scalars["String"]["output"]>;
  onmoPaymentResponse: Maybe<OnmoPaymentResponse>;
};

export type DepositFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The deposit's field to filter on */
  field: DepositFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter the admin deposits on. */
export enum DepositFilterField {
  /** Balance at the creation of this deposit */
  Balance = "balance",
  /** Currency the deposit is in */
  Currency = "currency",
  /** Date the deposit was completed */
  Date = "date",
  /** Amount of the deposit */
  DepositedAmount = "deposited_amount",
  /** identifier for the deposit */
  Id = "id",
  /** Current status of the deposit */
  Status = "status",
  /** Owner of the wallet containing the deposit */
  UserId = "user_id",
  /** Wallet containing the deposit */
  WalletId = "wallet_id",
}

export enum DisabledReason {
  /** User blocked cheating */
  BlockedCheating = "BLOCKED_CHEATING",
  /** User blocked other reasons */
  BlockedOthers = "BLOCKED_OTHERS",
}

export type EndGameSessionInput = {
  dockerURI: InputMaybe<Scalars["String"]["input"]>;
  dockerVersion: InputMaybe<Scalars["String"]["input"]>;
  edgeNodeId: Scalars["String"]["input"];
  failureMessage: InputMaybe<Scalars["String"]["input"]>;
  lastFrame: InputMaybe<LastFrame>;
  score: Scalars["Int"]["input"];
  sessionCompletionStatus: InputMaybe<Completion_Status>;
  time: Scalars["Int"]["input"];
};

/** Input data to end HTML Game Session */
export type EndHtmlGameSessionInput = {
  /** Version of the Docker being used */
  dockerVersion: InputMaybe<Scalars["String"]["input"]>;
  /** Failure message (if needed) */
  failureMessage: InputMaybe<Scalars["String"]["input"]>;
  /** ID of the Game Session ending */
  gameSessionId: Scalars["String"]["input"];
  /** Number of inputs made by user */
  inputEventCount: InputMaybe<Scalars["Int"]["input"]>;
  /** Front end score from the HTML game */
  score: Scalars["Int"]["input"];
  /** Duration of the game in seconds */
  time: Scalars["Int"]["input"];
  /** Number of times the score was updated */
  updateScoreCount: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input parameter for End Promo. */
export type EndPromoInput = {
  /** Docker version */
  dockerVersion: Scalars["String"]["input"];
  /** Failure message (optional) */
  failureMessage: InputMaybe<Scalars["String"]["input"]>;
  /** Game Session ID */
  gameSessionId: Scalars["String"]["input"];
  /** Promo result ID */
  promoSessionId: Scalars["String"]["input"];
  /** Score the promo session */
  score: Scalars["Int"]["input"];
  /** Duration of the promo session */
  time: Scalars["Int"]["input"];
};

/** Denotes a EndScreen */
export type EndScreen = {
  __typename: "EndScreen";
  /** Game Session ID */
  gameSessionId: Maybe<Scalars["String"]["output"]>;
};

/** Input data to enter a solo moment. */
export type EnterSoloMomentInput = {
  /** Specify the desired height in pixels for the video stream */
  desiredResolutionHeight: InputMaybe<Scalars["Int"]["input"]>;
  /** Device info. */
  device: UserDeviceInput;
  /** ID of the game that user will play. */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** Previous Game Session ID. */
  gameSessionId: InputMaybe<Scalars["String"]["input"]>;
  /** Moment ID. */
  momentId: InputMaybe<Scalars["String"]["input"]>;
  /** Session Type. */
  sessionType: InputMaybe<GameSessionType>;
  /** Game session streamingRegion */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Response of enterSoloMoment mutation. */
export type EnterSoloMomentOutput = {
  __typename: "EnterSoloMomentOutput";
  /** Edge Node ID for streaming games. */
  edgeNodeId: Scalars["String"]["output"];
  /** Game Session ID to enter the moment. */
  gameSessionId: Scalars["ID"]["output"];
};

/** Input data to enter a tournament. */
export type EnterTournamentInput = {
  /** Specify the desired height in pixels for the video stream */
  desiredResolutionHeight: InputMaybe<Scalars["Int"]["input"]>;
  /** User Device Information */
  device: UserDeviceInput;
  /** Edge Node Id */
  edgeNodeId: InputMaybe<Scalars["String"]["input"]>;
  /** Streaming tournament Region */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
  /** Tournament ID */
  tournamentId: Scalars["String"]["input"];
};

export enum EnvironmentVariableType {
  ParameterStore = "PARAMETER_STORE",
  Plaintext = "PLAINTEXT",
  SecretsManager = "SECRETS_MANAGER",
}

export type EventRule = {
  __typename: "EventRule";
  filters: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  parameters: Scalars["String"]["output"];
  ruleType: EventRuleType;
};

export enum EventRuleType {
  ChallengeCompletion = "CHALLENGE_COMPLETION",
  GameChallengeCompletion = "GAME_CHALLENGE_COMPLETION",
  GlobalLeaderboard = "GLOBAL_LEADERBOARD",
  RewardForFollowers = "REWARD_FOR_FOLLOWERS",
  RewardForLeaderboard = "REWARD_FOR_LEADERBOARD",
  RewardForLogin = "REWARD_FOR_LOGIN",
  RewardForPlay = "REWARD_FOR_PLAY",
  RewardForTimePlayed = "REWARD_FOR_TIME_PLAYED",
  RewardForWin = "REWARD_FOR_WIN",
  TrackBattleStreak = "TRACK_BATTLE_STREAK",
  UserNotification = "USER_NOTIFICATION",
}

/** Denotes the status of the free coins. */
export enum Free_Coins_Status {
  /** Failed to award the coins */
  Failed = "FAILED",
  /** User coin balance is more than the required the balance to earn */
  MaxBalanceExceed = "MAX_BALANCE_EXCEED",
  /** When free coins are successfully awarded to user */
  Success = "SUCCESS",
  /** User is in cash region so he cannot use it */
  UserInCashRegion = "USER_IN_CASH_REGION",
  /** User has already claimed it so he has to wait till next available time */
  UseInNextAvailableTime = "USE_IN_NEXT_AVAILABLE_TIME",
}

/** Feature flags */
export type FeatureFlags = {
  __typename: "FeatureFlags";
  /** Used to boostrap the LaunchDarkly client-side SDK. */
  bootstrap: Maybe<Scalars["JSONObject"]["output"]>;
  /** Current application context. Will be different if logged in or not. */
  context: Maybe<Scalars["JSONObject"]["output"]>;
  /** Secure mode hash for the current context. Generate at the same time as the context. */
  hash: Maybe<Scalars["String"]["output"]>;
};

/** Input parameter for FetchMoment. */
export type FetchMomentInput = {
  /** Moment ID */
  id: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * Input data for fetching users with search term or cheat status.
 *
 * **Note: Only one of the filters will be used at a time. If both `term` and `cheatStatus` are provided, only `term` will be used for the search operation.**
 */
export type FindUserAsAdminInput = {
  /** Search for all users with a certain cheat_status */
  cheatStatus: InputMaybe<Scalars["String"]["input"]>;
  /** Search string to match with id, email, phone, billing email or billing phone. */
  term: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for fetching users with username. */
export type FindUserInput = {
  /** Search string to match with username. */
  term: Scalars["String"]["input"];
};

/** Input data for fetching user's permissions with id, username or email */
export type FindUserPermissionsInput = {
  /** Search string to match with either id, username or email */
  term: Scalars["String"]["input"];
};

/** User data along with their permissions */
export type FindUserPermissionsOutput = {
  __typename: "FindUserPermissionsOutput";
  /** Default permission groups of the user. */
  defaultGroups: Maybe<Array<Maybe<PermissionGroup>>>;
  /** Permission groups of the user. */
  permissionGroups: Maybe<Array<Maybe<PermissionGroup>>>;
  /** User Data */
  user: Maybe<UserV2>;
};

/** Denotes a bonus on first deposit. */
export type FirstDepositBonus = {
  __typename: "FirstDepositBonus";
  /** Bonus amount user receives on first deposit */
  amount: Maybe<Scalars["Float"]["output"]>;
};

export type FollowInput = {
  /** Id of the user to be followed/unfollowed */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Data for follow notifications */
export type FollowNotificationData = {
  __typename: "FollowNotificationData";
  /** user doing the following */
  followerUser: PublicUser;
};

export enum FollowStatus {
  FollowedBy = "FOLLOWED_BY",
  Following = "FOLLOWING",
  FollowingAndFollowedBy = "FOLLOWING_AND_FOLLOWED_BY",
  NoFollow = "NO_FOLLOW",
}

/** Suggested users for a user for following */
export type FollowSuggestionOutput = {
  __typename: "FollowSuggestionOutput";
  /** Users entries returned by the query */
  items: Array<Maybe<FollowSuggestionUser>>;
};

export type FollowSuggestionUser = PublicUserInterface & {
  __typename: "FollowSuggestionUser";
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /**
   * User cheating gamesessions count
   * @deprecated `cheatSessions` are not required anymore.
   */
  cheatSessions: Maybe<Scalars["Int"]["output"]>;
  /** Criteria in which the user is suggested */
  criteria: Maybe<Scalars["String"]["output"]>;
  /** User ID */
  id: Scalars["String"]["output"];
  /** User's last checkin time */
  lastCheckin: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Username unique to the user. */
  username: Maybe<Scalars["String"]["output"]>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

export type FollowersInput = {
  /** Number of items to be returned */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of the items to be returned */
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** Id of the user we want the followers of */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes the response for getFreeCoins resolver. */
export type FreeCoins = {
  __typename: "FreeCoins";
  /**
   * Maximum coin balance allowed to claim free coins
   * @deprecated Free coins can now be claimed regardless of user's current wallet balance.
   */
  maxBalanceRequired: Maybe<Scalars["Int"]["output"]>;
  /** Time at which the user can next request for free coins */
  nextAvailableTime: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Return success or error message based on conditions */
  status: Maybe<Free_Coins_Status>;
};

/** Denotes details of free coins */
export type FreeCoinsBonus = {
  __typename: "FreeCoinsBonus";
  /** Amount of coins user recieves daily */
  amount: Maybe<Scalars["Float"]["output"]>;
  /**
   * Maximum balance required to claim the free coins
   * @deprecated Free coins can now be claimed regardless of user's current wallet balance.
   */
  maxBalanceRequired: Maybe<Scalars["Float"]["output"]>;
};

/** Denotes a friend request. */
export type Friend = {
  __typename: "Friend";
  /** Indicates whether friend request has been accepted or not */
  accepted: Maybe<Scalars["Boolean"]["output"]>;
  /** Date and time at which the friend request was created */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Friend Request ID */
  id: Scalars["ID"]["output"];
  /** Receiver User Details */
  receiver: LightUser;
  /** Receiver User ID */
  receiverId: Scalars["ID"]["output"];
  /** Sender User Details */
  sender: LightUser;
  /** Sender User ID */
  senderId: Scalars["ID"]["output"];
  /** Date and time at which the friend request was last updated */
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
};

export enum FriendStatus {
  Friend = "FRIEND",
  InviteReceived = "INVITE_RECEIVED",
  InviteSent = "INVITE_SENT",
  NotFriend = "NOT_FRIEND",
}

/** Data for friend notifications */
export type FriendUserNotificationData = {
  __typename: "FriendUserNotificationData";
  /** friend information */
  friend: PublicUser;
};

/**
 * Denotes a transaction.
 * `FullTransaction` gives a more user-friendly response when compared to `Transaction` which gives raw transaction data.
 */
export type FullTransaction = {
  __typename: "FullTransaction";
  /** Amount credited or debited to/from the user's wallet. */
  amount: Scalars["Float"]["output"];
  /**
   * Represents the overall category of transaction.
   * E.g. Transaction made to deposit or withdraw from the wallet will have the `wallet` category, and transacations made for paying tournament or battle fees will have the `tournament` or `battle` category.
   */
  category: TransactionCategory;
  /** Date and time at which the transaction was created. */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** Currency of the transaction. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Entry ID for the transaction. */
  entryId: Scalars["String"]["output"];
  /** Date and time at which transaction will expire for bonus and promotions types */
  expiryDate: Maybe<Scalars["AWSDateTime"]["output"]>;
  /**
   * ID of game.
   * Only present if transaction was made in relation to a game else `null`.
   */
  gameId: Maybe<Scalars["String"]["output"]>;
  /**
   * Title of game.
   * Only present if transaction was made in relation to a game else `null`.
   */
  gameTitle: Maybe<Scalars["String"]["output"]>;
  /** Reference of the transaction. */
  reference: Maybe<Scalars["String"]["output"]>;
  /** Wallet balance after the credit/debit. */
  runningBalance: Maybe<Scalars["Float"]["output"]>;
  /** Current status of the transaction. Possible values are either `completed` or `pending`. */
  status: Maybe<Scalars["String"]["output"]>;
  /** Denotes the specific reason for the transaction. */
  transactionType: TransactionActionType;
  /** Specifies if it is a credit or debit transaction. */
  type: Scalars["String"]["output"];
  /** Specifies whether it's a virtual or currency wallet. */
  walletType: Maybe<Scalars["String"]["output"]>;
  /**
   * The raw workflow of the transaction. The `category` and `transactionType` field values are derived from this field.
   * **Note: This is used for reference and debugging purposes only. Use the `category` and `transactionType` fields for any computational operations.**
   */
  workflow: Maybe<TransactionWorkflow>;
};

export enum Game_Rotation_Mode {
  Landscape = "LANDSCAPE",
  Portrait = "PORTRAIT",
}

export enum Game_Type {
  Embed = "EMBED",
  Html = "HTML",
  Stream = "STREAM",
}

/** Game object including battle cost and tournament info */
export type Game = {
  __typename: "Game";
  battleCost: Maybe<Array<Maybe<BattleCost>>>;
  bonusTournamentInfo: Maybe<Array<Maybe<TournamentInfo>>>;
  /** @deprecated Cash has been removed. */
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  category: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  company: Maybe<Scalars["String"]["output"]>;
  countMoments: Maybe<Scalars["Int"]["output"]>;
  /** Dynamic metadata which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  featured: Maybe<Scalars["Boolean"]["output"]>;
  gamesPlayedByUser: Maybe<Scalars["Int"]["output"]>;
  hasBattle: Maybe<Scalars["Boolean"]["output"]>;
  hasSolo: Maybe<Scalars["Boolean"]["output"]>;
  hasTournament: Maybe<Scalars["Boolean"]["output"]>;
  /** Hot battles for game */
  hotBattles: Maybe<Array<Maybe<HotBattle>>>;
  /** House battles for game */
  houseBattles: Maybe<Array<Maybe<HouseBattle>>>;
  id: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  isLike: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated This field has been deprecated and should no longer be used. */
  likeCount: Maybe<Scalars["Int"]["output"]>;
  moments: Maybe<Array<Maybe<Moment>>>;
  publisher: Maybe<Scalars["String"]["output"]>;
  /** Rank Categories of the Game (Only available in getUserFeed and getGame resolver) */
  rankCategories: Maybe<Array<Maybe<ILevel>>>;
  /** Raw untrimmed game metadata */
  rawData: Maybe<Scalars["String"]["output"]>;
  rotationMode: Maybe<RotationMode>;
  /** User's ELO (not available for all resolvers) */
  skill: Maybe<Scalars["Int"]["output"]>;
  /** Solo Challenges for game */
  soloChallenges: Maybe<Array<Maybe<Moment>>>;
  status: Maybe<GameStatus>;
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  totalCompleted: Maybe<Scalars["Int"]["output"]>;
  tournamentInfo: Maybe<Array<Maybe<TournamentInfo>>>;
  tournaments: Maybe<Array<Maybe<Tournament>>>;
  type: Maybe<GameType>;
  /**
   * Indicates whether the game uses server-side simulation
   * @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored.
   */
  useSimulation: Maybe<Scalars["Boolean"]["output"]>;
};

export type GameIdForEloMatchInput = {
  gameId: Scalars["String"]["input"];
};

export type GameSession = {
  __typename: "GameSession";
  appId: Scalars["ID"]["output"];
  cheatingAttributes: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  dockerVersion: Maybe<Scalars["String"]["output"]>;
  edgeNodeId: Scalars["String"]["output"];
  failureMessage: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  matchId: Maybe<Scalars["String"]["output"]>;
  momentId: Scalars["ID"]["output"];
  score: Maybe<Scalars["Float"]["output"]>;
  scoreReviewed: Maybe<ScoreReviewed>;
  scoreReviewedFault: Maybe<ScoreReviewedFault>;
  scoreValidated: Maybe<Scalars["Int"]["output"]>;
  sessionType: Maybe<GameSessionType>;
  status: Maybe<GameSessionStatus>;
  time: Maybe<Scalars["Int"]["output"]>;
  tournamentId: Maybe<Scalars["String"]["output"]>;
  tournamentPlayerId: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["AWSDateTime"]["output"];
  userId: Scalars["String"]["output"];
};

/** Denotes the types of game session statuses */
export enum GameSessionStatus {
  /** Game session is cheated */
  Cheated = "CHEATED",
  /** Game session is closed */
  Closed = "CLOSED",
  /** Game session is created */
  Created = "CREATED",
  /** Game session is playing */
  Playing = "PLAYING",
  /** Game session is reviewed */
  Reviewed = "REVIEWED",
}

export enum GameSessionType {
  Battle = "BATTLE",
  Casual = "CASUAL",
  Challenge = "CHALLENGE",
  HouseBattle = "HOUSE_BATTLE",
  Promo = "PROMO",
  Tournament = "TOURNAMENT",
  Tutorial = "TUTORIAL",
}

export type GameSessionV2 = {
  __typename: "GameSessionV2";
  appId: Maybe<Scalars["String"]["output"]>;
  cheatingAttributes: Maybe<Scalars["String"]["output"]>;
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  dockerVersion: Maybe<Scalars["String"]["output"]>;
  edgeNodeId: Scalars["String"]["output"];
  failureMessage: Maybe<Scalars["String"]["output"]>;
  host: Maybe<User>;
  id: Scalars["ID"]["output"];
  matchId: Maybe<Scalars["String"]["output"]>;
  moment: Maybe<Moment>;
  momentId: Maybe<Scalars["String"]["output"]>;
  paid: Maybe<Scalars["Boolean"]["output"]>;
  score: Maybe<Scalars["Float"]["output"]>;
  scoreReviewed: Maybe<ScoreReviewed>;
  scoreReviewedFault: Maybe<ScoreReviewedFault>;
  scoreValidated: Maybe<Scalars["Int"]["output"]>;
  sessionType: Maybe<GameSessionType>;
  status: Maybe<GameSessionStatus>;
  time: Maybe<Scalars["Int"]["output"]>;
  tournamentId: Maybe<Scalars["String"]["output"]>;
  tournamentPlayerId: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
};

/** Denotes the current publish status of a game. */
export enum GameStatus {
  Disabled = "DISABLED",
  Draft = "DRAFT",
  Live = "LIVE",
  Upcoming = "UPCOMING",
}

/** Denotes the type of a game. */
export enum GameType {
  Embed = "EMBED",
  Html = "HTML",
  Stream = "STREAM",
}

/**
 * Denotes list of games by categories.
 * hasTournament, hasBattle, hasSolo is not available in this resolver.
 */
export type GamesByCategories = {
  __typename: "GamesByCategories";
  /** List of top 5 most recently released games */
  newReleases: Maybe<Array<Maybe<NGame>>>;
  /** List of top 10 most recently played games by the user */
  recentlyPlayed: Maybe<Array<Maybe<NGame>>>;
  /** List of recommended games for user */
  recommended: Maybe<Array<Maybe<NGame>>>;
  /** List of top 10 trending games */
  trending: Maybe<Array<Maybe<NGame>>>;
};

export type GetBeatItInput = {
  gameId: Scalars["String"]["input"];
};

/** Input for retrieving a CodeBuild environment */
export type GetCodeBuildEnvironmentInput = {
  /** Name of the environment being retrieved (e.g. b2bomantel) */
  environmentName: Scalars["String"]["input"];
};

/** Input data to fetch deposit details. */
export type GetDepositInput = {
  /**
   * Deposit ID
   * @deprecated No longer in use.
   */
  depositId: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for fetching a single game. */
export type GetGameInput = {
  /** Game ID */
  id: Scalars["String"]["input"];
  /**
   * Filter moments by status.
   * If not provided, moment status is defaulted to `LIVE`.
   */
  momentStatus: InputMaybe<Array<InputMaybe<Status>>>;
};

/**
 * Input filters for fetching a game session.
 * Requires either a game session or edge node id.
 */
export type GetGameSessionInput = {
  /** Edge Node ID */
  edgeNodeId: InputMaybe<Scalars["String"]["input"]>;
  /** Game Session ID */
  gameSessionId: InputMaybe<Scalars["String"]["input"]>;
};

export type GetLeaderboardInput = {
  chronoScroll: InputMaybe<Scalars["Int"]["input"]>;
  chronoType: ChronoType;
  gameId: Scalars["String"]["input"];
  leaderboardDate: InputMaybe<Scalars["String"]["input"]>;
  momentId: Scalars["String"]["input"];
  pageNo: InputMaybe<Scalars["Int"]["input"]>;
  pageSize: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data for fetching the leaderboard of a tournament. */
export type GetLeaderboardsInput = {
  /** Number of entries to retrieve. */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input to get the rank of a specific entry in the tournament */
export type GetMyRankInput = {
  /** Provide tournamentPlayerId to get the rank of a specific tournamentPlayerId rank */
  tournamentPlayerId: InputMaybe<Scalars["String"]["input"]>;
};

export type GetPercentScoreReviewedInput = {
  appId: InputMaybe<Scalars["String"]["input"]>;
  dockerVersion: InputMaybe<Scalars["String"]["input"]>;
  momentId: InputMaybe<Scalars["String"]["input"]>;
};

export type GetPercentScoreReviewedOutput = {
  __typename: "GetPercentScoreReviewedOutput";
  bad: Maybe<Scalars["Int"]["output"]>;
  critical: Maybe<Scalars["Int"]["output"]>;
  good: Maybe<Scalars["Int"]["output"]>;
};

/** Input data to fetch promo details. */
export type GetPromoInput = {
  /** promo ID */
  promoId: Scalars["String"]["input"];
};

/** Input data for fetching a single tournament. */
export type GetTournamentInput = {
  /** Tournament ID */
  id: Scalars["String"]["input"];
  /**
   * Tournament Player ID
   * @deprecated Dead Field. No longer being used by backend.
   */
  tournamentPlayerId: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for fetching user activities. */
export type GetUserActivitiesInput = {
  /** User ID */
  userId: Scalars["ID"]["input"];
};

/** Input data for fetching user data as an admin. */
export type GetUserAsAdminInput = {
  /** User ID */
  userId: Scalars["String"]["input"];
};

/** Input data for get user most played games */
export type GetUserMostPlayedGamesInput = {
  /** If provided, uses this user id, otherwise uses current user's id */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for fetching user wallets. */
export type GetUserWalletsInput = {
  /** User ID */
  userId: Scalars["String"]["input"];
};

/** Input data to fetch withdrawal details. */
export type GetWithdrawalInput = {
  /** Withdrawal ID */
  withdrawalId: Scalars["String"]["input"];
};

/** Denotes a Global Leaderboard */
export type GlobalLeaderboard = {
  __typename: "GlobalLeaderboard";
  /** All time leaderboard */
  alltime: GlobalLeaderboardItem;
  /** Contest leaderboard */
  contest: Maybe<GlobalLeaderboardItem>;
  /** Monthly leaderboard */
  monthly: GlobalLeaderboardItem;
  /** Weekly leaderboard */
  weekly: GlobalLeaderboardItem;
};

export type GlobalLeaderboardInput = {
  /** Expiry date for current leaderboard */
  endDate: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  /** Leaderboard Type */
  leaderboardType: Leaderboard_Type;
};

export type GlobalLeaderboardItem = {
  __typename: "GlobalLeaderboardItem";
  /** Expiry date for current leaderboard */
  endDate: Scalars["AWSDateTime"]["output"];
  /** An array of all the leading players, in order of their rank */
  leaderboard: Maybe<Array<Maybe<LeaderboardUser>>>;
  /** Rank/position of current user on current leaderboard (zero-based) */
  myRank: Maybe<Scalars["Int"]["output"]>;
  /** Score of current user on current leaderboard */
  myScore: Maybe<Scalars["Int"]["output"]>;
};

/** Denotes a hot battle. */
export type HotBattle = {
  __typename: "HotBattle";
  /** Cost to play the battle by the user. */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Date and time at which the battle was created. */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Currency of the battle. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Platform fees. This is included in the `costPerUser` amount. */
  entryFee: Maybe<Scalars["Float"]["output"]>;
  /** Details of the game being used for battle. */
  game: Maybe<Game>;
  /** Details of the user who started the battle. */
  host: Maybe<PublicUser>;
  /** Match ID */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** Current state of the match. */
  matchState: Maybe<MatchState>;
  /** Prize amount */
  prize: Maybe<Scalars["Float"]["output"]>;
  /** The score need to beat */
  scoreToBeat: Maybe<Scalars["Int"]["output"]>;
  /** Indicates winning criteria. */
  winType: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a house battle as hot battle */
export type HouseBattle = {
  __typename: "HouseBattle";
  /** Cost to play the battle by the user. */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Date and time at which the battle was created. */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Currency of the battle. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Platform fees. This is included in the `costPerUser` amount. */
  entryFee: Maybe<Scalars["Float"]["output"]>;
  /** Details of the game being used for battle. */
  game: Maybe<Game>;
  /** Details of the user who started the battle. */
  host: Maybe<PublicUser>;
  /** Match ID */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** Current state of the match. */
  matchState: Maybe<MatchState>;
  /** Indicates the prize multiplier for the user's bet amount */
  oddMultiplier: Maybe<Scalars["Float"]["output"]>;
  /** Prize amount */
  prize: Maybe<Scalars["Float"]["output"]>;
  /** The score need to beat */
  scoreToBeat: Maybe<Scalars["Int"]["output"]>;
  /** Indicates winning criteria. */
  winType: Maybe<Scalars["String"]["output"]>;
};

export type IFilterListApps = {
  category: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<Status>;
  type: InputMaybe<GameType>;
};

export type IFilterListGameSession = {
  /** Use app id to filter game session */
  appId: InputMaybe<Scalars["String"]["input"]>;
  /** Use docker version to filter game session */
  dockerVersion: InputMaybe<Scalars["String"]["input"]>;
  /** Game session maximum score */
  maxScore: InputMaybe<Scalars["Float"]["input"]>;
  /** Game session minimum score */
  minScore: InputMaybe<Scalars["Float"]["input"]>;
  /** Use moment id to filter game session */
  momentId: InputMaybe<Scalars["String"]["input"]>;
  /** Game session played date */
  playedDate: InputMaybe<Scalars["String"]["input"]>;
  /** Use player cheat status to filter game session */
  playerCheatStatus: InputMaybe<Scalars["String"]["input"]>;
  /** Use reviewed score, can be GOOD, BAD or CRITICAL, to filter game session */
  scoreReviewed: InputMaybe<ScoreReviewed>;
  /** Use sessionType to filter game session */
  sessionType: InputMaybe<GameSessionType>;
  /** Use game session status to filter game session, by default the game sessions of PLAYING state will be filtered */
  status: InputMaybe<Array<InputMaybe<GameSessionStatus>>>;
  /** Use tournament id to filter game session */
  tournamentId: InputMaybe<Scalars["String"]["input"]>;
  /** Use tournamentType to filter game session */
  tournamentType: InputMaybe<Scalars["String"]["input"]>;
  /** Use user id to filter game session */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Filter parameters for listMoments resolver. */
export type IFilterListMoments = {
  /** Game ID */
  appId: InputMaybe<Scalars["String"]["input"]>;
  /** Type of the moment */
  momentType: InputMaybe<MomentType>;
  /** Snapshot ID */
  snapshotId: InputMaybe<Scalars["String"]["input"]>;
  /** Status */
  status: InputMaybe<Status>;
};

/** Denotes a particular game category */
export type ILevel = {
  __typename: "ILevel";
  /** Name of the game category */
  name: Maybe<Scalars["String"]["output"]>;
  /** Starting ELO score of the corresponding game category */
  value: Maybe<Scalars["Int"]["output"]>;
};

export enum Inventory_Category {
  Avatar = "AVATAR",
  Mixed = "MIXED",
}

/** User device extra value latitude and longitude */
export type IUserDeviceExtra = {
  /** Suggested geolocation latitude value. Mandatory if the coordinates are not included in deviceInfo. */
  latitude: InputMaybe<Scalars["Float"]["input"]>;
  /** Suggested geolocation longitude value.Mandatory if the coordinates are not included in deviceInfo. */
  longitude: InputMaybe<Scalars["Float"]["input"]>;
};

export enum ImagePullCredentialsType {
  Codebuild = "CODEBUILD",
  ServiceRole = "SERVICE_ROLE",
}

export type ImportAllowancesInput = {
  allowances: Scalars["String"]["input"];
};

/** Input for giving out bonus cash and coins (support and banking) */
export type InsertBonusQueueInput = {
  /** Amount of currency to be given in bonus */
  amount: Scalars["Float"]["input"];
  /** Type of bonus */
  bonusType: InputMaybe<BonusType>;
  /** Currency (use XXX for coins) */
  currency: Scalars["String"]["input"];
  /** Description of reason for bonus */
  reason: Scalars["String"]["input"];
  /** User ID of user to be given bonus */
  userId: Scalars["String"]["input"];
};

export type InventoriesInput = {
  /** ID of a bundle inventory used to fetch the items */
  bundleInventoryId: InputMaybe<Scalars["String"]["input"]>;
  /** Filter by inventory categories if provided */
  categories: InputMaybe<Array<InputMaybe<Inventory_Category>>>;
  /** Additional input filters */
  filters: InputMaybe<InventoriesInputFilters>;
  /** Number of items to be returned */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of the items to be returned */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

export type InventoriesInputFilters = {
  /** Return only bundle items in response */
  restrictToBundles: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Return only free items in response */
  restrictToFree: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Return only non-bundle items in response */
  restrictToItems: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter to return Inventory items with given status */
  status: InputMaybe<Array<InputMaybe<InventoryStatus>>>;
};

export type Inventory = {
  __typename: "Inventory";
  /** Indicates whether the inventory item should be applied immediately upon purchase. */
  applyImmediately: Scalars["Boolean"]["output"];
  /** Category of the inventory item, categorizing it into a specific group. */
  category: Inventory_Category;
  /** List of costs associated with acquiring the inventory item, in different coin types. */
  costs: Array<InventoryCost>;
  /** The timestamp when the inventory item was created. Uses the AWSDateTime scalar type for date-time strings in ISO format. */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** Data associated with the inventory item */
  data: InventoryData;
  /**
   * Description of the inventory item, providing more detailed information.
   * @deprecated The description has been removed from the inventory table, as it isn't useful.
   */
  description: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier for the inventory item. */
  id: Scalars["ID"]["output"];
  /** Indicates whether the inventory item is a bundle consisting of multiple items. */
  isBundle: Scalars["Boolean"]["output"];
  /** Indicates if the inventory was purchased by the user */
  isPurchased: Scalars["Boolean"]["output"];
  /** Metadata associated with the inventory item */
  metadata: Maybe<Scalars["JSONObject"]["output"]>;
  /** Current status of the inventory item, indicating its availability and visibility. */
  status: InventoryStatus;
  /** Title of the inventory item. */
  title: Scalars["String"]["output"];
  /** The timestamp when the inventory item was last updated. Uses the AWSDateTime scalar type for date-time strings in ISO format. */
  updatedAt: Scalars["AWSDateTime"]["output"];
};

export type InventoryAvatarData = {
  __typename: "InventoryAvatarData";
  url: Scalars["String"]["output"];
};

export type InventoryBundleData = {
  __typename: "InventoryBundleData";
  items: Array<Scalars["String"]["output"]>;
  previewUrl: Scalars["String"]["output"];
};

export type InventoryCost = {
  __typename: "InventoryCost";
  amount: Scalars["Float"]["output"];
  coinType: CoinType;
};

export type InventoryCostInput = {
  amount: Scalars["Int"]["input"];
  coinType: CoinType;
};

export type InventoryData = InventoryAvatarData | InventoryBundleData;

export enum InventoryStatus {
  Available = "AVAILABLE",
  Disabled = "DISABLED",
  Hidden = "HIDDEN",
  Restricted = "RESTRICTED",
}

export type JoinFriendMatchInput = {
  /** Device information for playing match */
  device: UserDeviceInput;
  /** Match Id to play */
  matchId: Scalars["String"]["input"];
  /** Playing streaming match region */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Input for joining an existing livestream */
export type JoinLivestreamInput = {
  /** Livestream ID */
  sessionId: Scalars["String"]["input"];
  /** WebSocket Connection ID */
  wsConnectionId: Scalars["String"]["input"];
};

/** Input data for joining an existing match. */
export type JoinMatchInput = {
  /** Invitation Code */
  inviteCode: Scalars["String"]["input"];
};

/** Response for `joinMatch` mutation. */
export type JoinMatchOutput = {
  __typename: "JoinMatchOutput";
  /** Match ID derived from the invite code. */
  matchId: Scalars["String"]["output"];
};

export enum Leaderboard_Type {
  Contest = "CONTEST",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

export enum Login_Reward_Status {
  Claimed = "CLAIMED",
  Unavailable = "UNAVAILABLE",
  Unclaimed = "UNCLAIMED",
}

export type LastFrame = {
  frame: InputMaybe<Scalars["String"]["input"]>;
  frameCroppedScore: InputMaybe<Scalars["String"]["input"]>;
  frameCroppedScoreFiltered: InputMaybe<Scalars["String"]["input"]>;
};

export type LeaderboardActivity = {
  __typename: "LeaderboardActivity";
  activityType: ActivityType;
  banner: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["AWSDateTime"]["output"];
  data: Maybe<Scalars["String"]["output"]>;
  endDate: Scalars["String"]["output"];
  name: Maybe<Scalars["String"]["output"]>;
  rank: Scalars["Int"]["output"];
  timeFrame: Maybe<TimeFrame>;
  type: Scalars["String"]["output"];
};

/** Denotes the public profile of a user with limited fields. */
export type LeaderboardUser = {
  __typename: "LeaderboardUser";
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /** User ID */
  id: Scalars["String"]["output"];
  /** Score on current leaderboard */
  score: Maybe<Scalars["Int"]["output"]>;
  /** Username unique to the user. */
  username: Maybe<Scalars["String"]["output"]>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Data for leaderboard notifications */
export type LeaderboardUserNotificationData = {
  __typename: "LeaderboardUserNotificationData";
  /** Contest banner */
  banner: Maybe<Scalars["String"]["output"]>;
  /** Date at which the leaderboard ends */
  endDate: Scalars["String"]["output"];
  /** Type of the leaderboard i.e. weekly, monthly or contest */
  leaderboardType: Maybe<Scalars["String"]["output"]>;
  /** Leaderboard name when contest */
  name: Maybe<Scalars["String"]["output"]>;
  /** Rank the user finished at */
  rank: Scalars["Int"]["output"];
  /** Time frame of the leaderboard involved */
  timeFrame: Maybe<TimeFrame>;
};

/** Input data for fetch a single match. */
export type LightMatchInput = {
  /** Game ID */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** Match ID */
  matchId: Scalars["String"]["input"];
};

/** Denotes the short profile of a user. */
export type LightUser = {
  __typename: "LightUser";
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /** User ID */
  id: Scalars["ID"]["output"];
  /** Username unique to the user. */
  username: Scalars["String"]["output"];
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Input filters to list all tournaments */
export type ListAllTournamentInput = {
  /** Currency filter */
  currency: InputMaybe<Currency>;
  /** Game ID */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** Number of tournaments to return */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of tournaments to skip */
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** Tournament status filter */
  status: Tournament_Status;
};

export type ListAppsWithPaginationInput = {
  filters: InputMaybe<IFilterListApps>;
  limit: InputMaybe<Scalars["Int"]["input"]>;
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored. */
  useSimulation: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Input data to list cheater contact with filter and pagination */
export type ListCheaterContactInput = {
  /** Filters to apply to the search */
  filters: InputMaybe<Array<InputMaybe<CheaterContactFilter>>>;
  /** Maximum of entries to return default is 100 */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of entries to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Deprecated, to be removed along query 'listFriendsV2' */
export type ListFriendsInput = {
  userId: Scalars["String"]["input"];
};

export type ListGameSessionWithFiltersInput = {
  /** Game session filter */
  filters: InputMaybe<IFilterListGameSession>;
  /** Limit Game Session */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Offset Game Session */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Output parameter for ListGameSessionsWithFilters. */
export type ListGameSessionsWithFiltersOutput = {
  __typename: "ListGameSessionsWithFiltersOutput";
  /** Game ID */
  appId: Scalars["ID"]["output"];
  /**
   * Game session cheat status
   * @deprecated No longer being used.
   */
  cheatStatus: Maybe<Scalars["String"]["output"]>;
  /**
   * Cheating flag
   * @deprecated No longer being used.
   */
  cheating: Maybe<Scalars["Boolean"]["output"]>;
  /** Game session cheat attributes */
  cheatingAttributes: Maybe<Scalars["String"]["output"]>;
  /** Created At */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Docker Version */
  dockerVersion: Maybe<Scalars["String"]["output"]>;
  /** The ID of stream node for streaming games, and HTML for HTML games. */
  edgeNodeId: Scalars["String"]["output"];
  /** Failure messages for session to fail */
  failureMessage: Maybe<Scalars["String"]["output"]>;
  /** Host User */
  host: Maybe<User>;
  /** GameSession ID */
  id: Scalars["ID"]["output"];
  /** Match ID */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** GameSession Moment */
  moment: Maybe<Moment>;
  /** Moment ID */
  momentId: Scalars["ID"]["output"];
  /** Player cheat status */
  playerCheatStatus: Maybe<Scalars["String"]["output"]>;
  /** Game session Score */
  score: Maybe<Scalars["Float"]["output"]>;
  /** Game Score Reviewed */
  scoreReviewed: Maybe<ScoreReviewed>;
  /** Game Score Reviewed Fault */
  scoreReviewedFault: Maybe<ScoreReviewedFault>;
  /** Game Score Validated */
  scoreValidated: Maybe<Scalars["Int"]["output"]>;
  /** Game Session Type */
  sessionType: Maybe<GameSessionType>;
  status: Maybe<GameSessionStatus>;
  /** Time taken in session */
  time: Maybe<Scalars["Int"]["output"]>;
  /** Game Title */
  title: Maybe<Scalars["String"]["output"]>;
  /** Tournament ID */
  tournamentId: Maybe<Scalars["String"]["output"]>;
  /** Tournament Player ID */
  tournamentPlayerId: Maybe<Scalars["String"]["output"]>;
  /** Type of tournament */
  tournamentType: Maybe<Scalars["String"]["output"]>;
  /** Updated At */
  updatedAt: Scalars["AWSDateTime"]["output"];
  /** User ID */
  userId: Scalars["String"]["output"];
};

/** Input filters for fetching user's matches. */
export type ListMatchesInput = {
  /** Filter by Game ID. */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** Filter by match state. */
  matchState: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated No longer being used. */
  pageNo: InputMaybe<Scalars["Int"]["input"]>;
  /** @deprecated No longer being used. */
  pageSize: InputMaybe<Scalars["Int"]["input"]>;
  /** Filter by play state. */
  playState: InputMaybe<Scalars["String"]["input"]>;
};

/** Response for `listMatches` resolver. */
export type ListMatchesOutput = {
  __typename: "ListMatchesOutput";
  /** Total number of matches in the response. */
  matchCount: Maybe<Scalars["Int"]["output"]>;
  /** List of matches of the user. */
  matchList: Maybe<Array<Maybe<Match>>>;
};

/** Input filters for listMoments resolver. */
export type ListMomentsWithPaginationInput = {
  /** Filter parameters */
  filters: InputMaybe<IFilterListMoments>;
  /** Maximum of moments is to return */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of moments to skip */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data to list limit number of tournaments, if not provided, list all of tournaments */
export type ListTournamentInput = {
  limit: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input for coinTransactions */
export type ListTransactionsInput = {
  /** List transactions only of these coin types (optional) */
  coinTypes: InputMaybe<Array<InputMaybe<CoinType>>>;
  /** Currency of transaction. Only transactions matching the specified currency will be returned. */
  currency: Scalars["String"]["input"];
  /** List coins only before this date (optional) */
  endDate: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  /** Number of items to be returned */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of the items to be returned */
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** List coins only after this date (optional) */
  startDate: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  /** List transactions only of these transaction types (optional) */
  transactionTypes: InputMaybe<Array<InputMaybe<CoinTransactionType>>>;
};

/** Input data for list user game skills */
export type ListUserGameSkillsInput = {
  /** gameId provided, return current user specific game skill, if userId provided too, return the current user game skill */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** userId provided, return that user all game skills, if gameId provided too, return the specific game user skill */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** User Game Skills Output */
export type ListUserGameSkillsOutput = {
  __typename: "ListUserGameSkillsOutput";
  gameId: Maybe<Scalars["String"]["output"]>;
  userEloRating: Maybe<Scalars["Float"]["output"]>;
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Input data for listing users with specific permissions */
export type ListUsersByPermissionGroupInput = {
  /** Permission groups we want the list of users of */
  permissionGroups: InputMaybe<Array<InputMaybe<PermissionGroup>>>;
};

/** Input parameters for listUsersWithPagination resolver. */
export type ListUsersWithPaginationInput = {
  /** Maximum number of users to return. Defaults to 100 when not provided */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Number of users to skip. Defaults to 0 when not provided */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Filters for fetching user wallets by currency. */
export type ListWalletsByCurrencyInput = {
  /** Currency of the wallets. Only wallets matching the specified currency will be returned. */
  currency: Scalars["String"]["input"];
  /** ID of user whose wallets to fetch, if not provided, return the current logged-in user's wallets. The current logged-in user needs Publisher/Editoral/Support permission to use this field. */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes a livestream */
export type Livestream = {
  __typename: "Livestream";
  /** Type of battle */
  battleType: GameSessionType;
  /** User Info of the current player */
  currentPlayer: PublicUser;
  /** Game Info of the game that is being played */
  game: NGame;
  /** User info of the host */
  host: PublicUser;
  /** Livestream Session ID */
  id: Scalars["String"]["output"];
  /** Number of game sessions played by the user in the livestreaming sesssion */
  numberOfGameSessions: Scalars["Int"]["output"];
  /** Number of users watching the livestream */
  numberOfViewers: Scalars["Int"]["output"];
  /** Date and time the livestream was started at */
  startedAt: Scalars["AWSDateTime"]["output"];
  /** Livestream status */
  status: LivestreamStatus;
};

/**
 * Denotes the livestream session information.
 * Visible only to players and active viewers of a stream.
 */
export type LivestreamSession = {
  __typename: "LivestreamSession";
  /** Current Battle ID */
  battleId: Scalars["String"]["output"];
  /** Type of battle */
  battleType: GameSessionType;
  /** Current player of the livestream */
  currentPlayer: PublicUser;
  /** Streaming Edge Node ID */
  edgeNodeId: Scalars["String"]["output"];
  /** Game Session ID */
  gameSessionId: Scalars["String"]["output"];
  /** Host of the livestream */
  host: PublicUser;
  /** Livestream ID */
  id: Scalars["String"]["output"];
  /** Current status of the livestream */
  status: LivestreamStatus;
  /** Active viewers watching the livestream */
  viewers: Array<Maybe<PublicUser>>;
};

/** Denotes the status of the livestream */
export enum LivestreamStatus {
  Ended = "ENDED",
  Live = "LIVE",
}

/** Filters for fetching livestreams */
export type LivestreamsFilterInput = {
  /** Returns recently closed livestreams when set to `true` */
  includeClosedStreams: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Game Session Type */
  sessionType: InputMaybe<GameSessionType>;
  /** User ID - Used to filter livestream of a particular user */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Response payload for `livestreams` resolver. */
export type LivestreamsPayload = {
  __typename: "LivestreamsPayload";
  /** List of livestreams */
  items: Maybe<Array<Maybe<Livestream>>>;
  /** Total number of active livestreams */
  totalCount: Scalars["Int"]["output"];
};

/** Input data for the `login` mutation */
export type LoginInput = {
  /** The encrypted OIDC state from the user's login session */
  encryptedOidcState: InputMaybe<Scalars["String"]["input"]>;
  /** Oncode */
  oncode: InputMaybe<Scalars["String"]["input"]>;
};

/** Output type of Login resolver */
export type LoginOutput = {
  __typename: "LoginOutput";
  /** User authentication details */
  authUser: AuthUser;
  /**
   * Authentication Email
   * @deprecated Use authUser
   */
  email: Maybe<Scalars["String"]["output"]>;
  /**
   * Username
   * @deprecated Use authUser
   */
  name: Scalars["String"]["output"];
  /** Oncode Response */
  oncodeResponse: Maybe<OncodeResult>;
  /**
   * Oncode Result
   * @deprecated Use oncodeResponse
   */
  oncodeResult: Maybe<Scalars["String"]["output"]>;
  /**
   * Permissions
   * @deprecated Use authUser
   */
  permissions: Maybe<Array<Maybe<PermissionGroup>>>;
  /**
   * Authentication Phone
   * @deprecated Use authUser
   */
  phone: Maybe<Scalars["String"]["output"]>;
  /**
   * User ID
   * @deprecated Use authUser
   */
  userId: Scalars["String"]["output"];
};

export type LoginRewardProgress =
  | AchievementLoginRewardProgress
  | CoinLoginRewardProgress;

/** Denotes if the operation was successful or failed. */
export enum Message_Response {
  Fail = "FAIL",
  Success = "SUCCESS",
}

/** Type of message in the message queue */
export enum Message_Type {
  BattleResult = "BATTLE_RESULT",
  Default = "DEFAULT",
}

/** Denotes a match. */
export type Match = {
  __typename: "Match";
  /** Battle Payment Details */
  battleInfo: Maybe<Battle>;
  /** Error Message */
  error: Maybe<Scalars["String"]["output"]>;
  /** Date and time at which the match expires. */
  expiryTime: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Game ID */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** ID of the user who started the match. */
  hostUserId: Maybe<Scalars["String"]["output"]>;
  /** Invitation code that can be used by other players to join this match. */
  inviteCode: Maybe<Scalars["String"]["output"]>;
  /** Number of players who have joined the match. */
  joinedPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Match ID */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether a match is being played or has already been played by players. */
  matchState: Maybe<Scalars["String"]["output"]>;
  /** Match moment */
  moment: Maybe<Moment>;
  /** Moment ID */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Total number of players allowed in the match. */
  noOfPlayers: Maybe<Scalars["Int"]["output"]>;
  /** List of players in the match. */
  players: Maybe<Array<Maybe<MatchPlayer>>>;
  /** Details of match win amount. */
  winAmount: Maybe<Scalars["String"]["output"]>;
  /** Details of match winners. */
  winners: Maybe<Array<Maybe<MatchWinner>>>;
};

/** Response for matchMaking related mutation. */
export type MatchIdForMatchMakingInput = {
  /** Match ID used to do the matchMaking analysis */
  matchId: Scalars["String"]["input"];
};

/** Denotes a match player. */
export type MatchPlayer = {
  __typename: "MatchPlayer";
  /** Time at which the game was last played by the player. */
  lastPlayedTime: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Current payment state of the player for the match. */
  paymentState: Maybe<PaymentState>;
  /** Current play state of the match. */
  playState: Maybe<PlayState>;
  /** Player score */
  score: Maybe<Scalars["Int"]["output"]>;
  /** Indicates whether the player is the current user. */
  self: Maybe<Scalars["Boolean"]["output"]>;
  /** User Data */
  user: Maybe<PublicUser>;
  /** User ID of the player. */
  userId: Maybe<Scalars["String"]["output"]>;
};

export enum MatchState {
  Created = "CREATED",
  Expired = "EXPIRED",
  Played = "PLAYED",
  Playing = "PLAYING",
  Rejected = "REJECTED",
}

export type MatchUser = {
  __typename: "MatchUser";
  /** Match user score */
  score: Maybe<Scalars["Int"]["output"]>;
  /** Match user */
  user: LightUser;
};

/** Denotes a match winner. */
export type MatchWinner = {
  __typename: "MatchWinner";
  /** Amount won */
  amount: Maybe<Scalars["String"]["output"]>;
  /** Player rank */
  rank: Maybe<Scalars["Int"]["output"]>;
  /** Player User ID */
  userId: Maybe<Scalars["String"]["output"]>;
};

export type Message = {
  __typename: "Message";
  /** Created at timestamp */
  createdAt: Maybe<Scalars["String"]["output"]>;
  /** Message data */
  data: Maybe<Scalars["JSONObject"]["output"]>;
  /** Sequence order / ID of the message */
  sequenceId: Maybe<Scalars["Int"]["output"]>;
  /** Type of message */
  type: Maybe<Message_Type>;
};

/** Denotes a bonus on minimum deposit. */
export type MinimumDepositBonus = {
  __typename: "MinimumDepositBonus";
  /** Bonus amount user receives on deposit of minimum amount */
  amount: Maybe<Scalars["Float"]["output"]>;
  /** Minimum deposit amount required to receive bonus */
  minAmount: Maybe<Scalars["Float"]["output"]>;
};

/** Denotes details of minimum amount to be withdrawn */
export type MinimumWithdrawalAmount = {
  __typename: "MinimumWithdrawalAmount";
  /** Minimum amount to be withdrawn */
  amount: Maybe<Scalars["Float"]["output"]>;
};

export type ModelAppConnection = {
  __typename: "ModelAppConnection";
  items: Maybe<Array<Maybe<App>>>;
  nextToken: Maybe<Scalars["String"]["output"]>;
};

export enum ModelAttributeTypes {
  Null = "_null",
  Binary = "binary",
  BinarySet = "binarySet",
  Bool = "bool",
  List = "list",
  Map = "map",
  Number = "number",
  NumberSet = "numberSet",
  String = "string",
  StringSet = "stringSet",
}

export type ModelBooleanInput = {
  attributeExists: InputMaybe<Scalars["Boolean"]["input"]>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  eq: InputMaybe<Scalars["Boolean"]["input"]>;
  ne: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModelFloatInput = {
  attributeExists: InputMaybe<Scalars["Boolean"]["input"]>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  between: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  eq: InputMaybe<Scalars["Float"]["input"]>;
  ge: InputMaybe<Scalars["Float"]["input"]>;
  gt: InputMaybe<Scalars["Float"]["input"]>;
  le: InputMaybe<Scalars["Float"]["input"]>;
  lt: InputMaybe<Scalars["Float"]["input"]>;
  ne: InputMaybe<Scalars["Float"]["input"]>;
};

export type ModelGameSessionConditionInput = {
  and: InputMaybe<Array<InputMaybe<ModelGameSessionConditionInput>>>;
  appId: InputMaybe<ModelIdInput>;
  controller: InputMaybe<ModelStringInput>;
  createdAt: InputMaybe<ModelStringInput>;
  edgeNodeId: InputMaybe<ModelStringInput>;
  incognito: InputMaybe<ModelBooleanInput>;
  isStreamReady: InputMaybe<ModelBooleanInput>;
  matchId: InputMaybe<ModelStringInput>;
  momentId: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelGameSessionConditionInput>;
  or: InputMaybe<Array<InputMaybe<ModelGameSessionConditionInput>>>;
  sessionResults: InputMaybe<ModelStringInput>;
  sessionType: InputMaybe<ModelGameSessionTypeInput>;
};

export type ModelGameSessionConnection = {
  __typename: "ModelGameSessionConnection";
  items: Maybe<Array<Maybe<GameSession>>>;
  nextToken: Maybe<Scalars["String"]["output"]>;
};

export type ModelGameSessionFilterInput = {
  and: InputMaybe<Array<InputMaybe<ModelGameSessionFilterInput>>>;
  appId: InputMaybe<ModelIdInput>;
  controller: InputMaybe<ModelStringInput>;
  createdAt: InputMaybe<ModelStringInput>;
  edgeNodeId: InputMaybe<ModelStringInput>;
  id: InputMaybe<ModelIdInput>;
  incognito: InputMaybe<ModelBooleanInput>;
  isStreamReady: InputMaybe<ModelBooleanInput>;
  matchId: InputMaybe<ModelStringInput>;
  momentId: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelGameSessionFilterInput>;
  or: InputMaybe<Array<InputMaybe<ModelGameSessionFilterInput>>>;
  sessionResults: InputMaybe<ModelStringInput>;
  sessionType: InputMaybe<ModelGameSessionTypeInput>;
  userId: InputMaybe<ModelStringInput>;
};

export type ModelGameSessionTypeInput = {
  eq: InputMaybe<GameSessionType>;
  ne: InputMaybe<GameSessionType>;
};

export type ModelIdInput = {
  attributeExists: InputMaybe<Scalars["Boolean"]["input"]>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  beginsWith: InputMaybe<Scalars["ID"]["input"]>;
  between: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  contains: InputMaybe<Scalars["ID"]["input"]>;
  eq: InputMaybe<Scalars["ID"]["input"]>;
  ge: InputMaybe<Scalars["ID"]["input"]>;
  gt: InputMaybe<Scalars["ID"]["input"]>;
  le: InputMaybe<Scalars["ID"]["input"]>;
  lt: InputMaybe<Scalars["ID"]["input"]>;
  ne: InputMaybe<Scalars["ID"]["input"]>;
  notContains: InputMaybe<Scalars["ID"]["input"]>;
  size: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists: InputMaybe<Scalars["Boolean"]["input"]>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  between: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  eq: InputMaybe<Scalars["Int"]["input"]>;
  ge: InputMaybe<Scalars["Int"]["input"]>;
  gt: InputMaybe<Scalars["Int"]["input"]>;
  le: InputMaybe<Scalars["Int"]["input"]>;
  lt: InputMaybe<Scalars["Int"]["input"]>;
  ne: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModelNotificationConditionInput = {
  and: InputMaybe<Array<InputMaybe<ModelNotificationConditionInput>>>;
  createdAt: InputMaybe<ModelStringInput>;
  data: InputMaybe<ModelStringInput>;
  not: InputMaybe<ModelNotificationConditionInput>;
  or: InputMaybe<Array<InputMaybe<ModelNotificationConditionInput>>>;
  state: InputMaybe<ModelNotificationStateInput>;
  type: InputMaybe<ModelStringInput>;
  updatedAt: InputMaybe<ModelStringInput>;
};

export type ModelNotificationFilterInput = {
  and: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>;
  createdAt: InputMaybe<ModelStringInput>;
  data: InputMaybe<ModelStringInput>;
  id: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelNotificationFilterInput>;
  or: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>;
  state: InputMaybe<ModelNotificationStateInput>;
  type: InputMaybe<ModelStringInput>;
  updatedAt: InputMaybe<ModelStringInput>;
  userId: InputMaybe<ModelIdInput>;
};

export type ModelNotificationStateInput = {
  eq: InputMaybe<NotificationState>;
  ne: InputMaybe<NotificationState>;
};

export type ModelSizeInput = {
  between: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  eq: InputMaybe<Scalars["Int"]["input"]>;
  ge: InputMaybe<Scalars["Int"]["input"]>;
  gt: InputMaybe<Scalars["Int"]["input"]>;
  le: InputMaybe<Scalars["Int"]["input"]>;
  lt: InputMaybe<Scalars["Int"]["input"]>;
  ne: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ModelSortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type ModelStatusInput = {
  eq: InputMaybe<Status>;
  ne: InputMaybe<Status>;
};

export type ModelStringInput = {
  attributeExists: InputMaybe<Scalars["Boolean"]["input"]>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  beginsWith: InputMaybe<Scalars["String"]["input"]>;
  between: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains: InputMaybe<Scalars["String"]["input"]>;
  eq: InputMaybe<Scalars["String"]["input"]>;
  ge: InputMaybe<Scalars["String"]["input"]>;
  gt: InputMaybe<Scalars["String"]["input"]>;
  le: InputMaybe<Scalars["String"]["input"]>;
  lt: InputMaybe<Scalars["String"]["input"]>;
  ne: InputMaybe<Scalars["String"]["input"]>;
  notContains: InputMaybe<Scalars["String"]["input"]>;
  size: InputMaybe<ModelSizeInput>;
};

export type ModelStringKeyConditionInput = {
  beginsWith: InputMaybe<Scalars["String"]["input"]>;
  between: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  eq: InputMaybe<Scalars["String"]["input"]>;
  ge: InputMaybe<Scalars["String"]["input"]>;
  gt: InputMaybe<Scalars["String"]["input"]>;
  le: InputMaybe<Scalars["String"]["input"]>;
  lt: InputMaybe<Scalars["String"]["input"]>;
};

export type ModelUserActivityConditionInput = {
  and: InputMaybe<Array<InputMaybe<ModelUserActivityConditionInput>>>;
  createdAt: InputMaybe<ModelStringInput>;
  data: InputMaybe<ModelStringInput>;
  not: InputMaybe<ModelUserActivityConditionInput>;
  or: InputMaybe<Array<InputMaybe<ModelUserActivityConditionInput>>>;
  type: InputMaybe<ModelStringInput>;
  userId: InputMaybe<ModelIdInput>;
};

export type ModelUserActivityConnection = {
  __typename: "ModelUserActivityConnection";
  items: Maybe<Array<Maybe<UserActivity>>>;
  nextToken: Maybe<Scalars["String"]["output"]>;
};

export type ModelUserActivityFilterInput = {
  and: InputMaybe<Array<InputMaybe<ModelUserActivityFilterInput>>>;
  createdAt: InputMaybe<ModelStringInput>;
  data: InputMaybe<ModelStringInput>;
  id: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelUserActivityFilterInput>;
  or: InputMaybe<Array<InputMaybe<ModelUserActivityFilterInput>>>;
  type: InputMaybe<ModelStringInput>;
  userId: InputMaybe<ModelIdInput>;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items: Maybe<Array<Maybe<User>>>;
  nextToken: Maybe<Scalars["String"]["output"]>;
};

export type ModelUserMomentStatsConditionInput = {
  and: InputMaybe<Array<InputMaybe<ModelUserMomentStatsConditionInput>>>;
  appId: InputMaybe<ModelIdInput>;
  createdAt: InputMaybe<ModelStringInput>;
  momentId: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelUserMomentStatsConditionInput>;
  numberOfTimesPlayed: InputMaybe<ModelIntInput>;
  or: InputMaybe<Array<InputMaybe<ModelUserMomentStatsConditionInput>>>;
  totalTimePlayed: InputMaybe<ModelIntInput>;
  updatedAt: InputMaybe<ModelStringInput>;
  userId: InputMaybe<ModelIdInput>;
  xp: InputMaybe<ModelIntInput>;
};

export type ModelUserMomentStatsFilterInput = {
  and: InputMaybe<Array<InputMaybe<ModelUserMomentStatsFilterInput>>>;
  appId: InputMaybe<ModelIdInput>;
  createdAt: InputMaybe<ModelStringInput>;
  id: InputMaybe<ModelIdInput>;
  momentId: InputMaybe<ModelIdInput>;
  not: InputMaybe<ModelUserMomentStatsFilterInput>;
  numberOfTimesPlayed: InputMaybe<ModelIntInput>;
  or: InputMaybe<Array<InputMaybe<ModelUserMomentStatsFilterInput>>>;
  totalTimePlayed: InputMaybe<ModelIntInput>;
  updatedAt: InputMaybe<ModelStringInput>;
  userId: InputMaybe<ModelIdInput>;
  xp: InputMaybe<ModelIntInput>;
};

/** Denotes a Moment. */
export type Moment = {
  __typename: "Moment";
  /** Game Details */
  app: Maybe<App>;
  /** Game ID */
  appId: Scalars["ID"]["output"];
  /** Created At */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** JSON Data related to the moment (triggers) */
  data: Maybe<Scalars["String"]["output"]>;
  /** Description */
  description: Maybe<Scalars["String"]["output"]>;
  /** Moment ID */
  id: Scalars["ID"]["output"];
  /** Is Completed */
  isCompleted: Maybe<Scalars["Boolean"]["output"]>;
  /** Type of the moment */
  momentType: Maybe<MomentType>;
  /** Order of solo challenge moment */
  order: Maybe<Scalars["Int"]["output"]>;
  /**
   * Cost to play the moment
   * @deprecated No longer in use.
   */
  playCost: Maybe<Scalars["Float"]["output"]>;
  /** Order of ranking */
  rankOrder: Maybe<RankOrder>;
  /**
   * Score Label
   * @deprecated No longer in use.
   */
  scoreLabel: Maybe<Scalars["String"]["output"]>;
  /**
   * Score Zone ID
   * @deprecated No longer in use.
   */
  scoreZoneId: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether the timer should be shown to user */
  showTimer: Maybe<Scalars["Boolean"]["output"]>;
  /** Snapshot ID */
  snapshotId: Scalars["ID"]["output"];
  /** Status */
  status: Maybe<Status>;
  /** Duration of the moment in seconds */
  time: Maybe<Scalars["Int"]["output"]>;
  /** Number of times the moment has been played */
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  /** Title */
  title: Scalars["String"]["output"];
  /** Indicates whether it's a score or time based moment */
  type: Maybe<Scalars["String"]["output"]>;
  /**
   * Cost required to unlock the moment
   * @deprecated No longer in use.
   */
  unlockCost: Maybe<Scalars["Float"]["output"]>;
  /**
   * XP required to unlock the moment
   * @deprecated No longer in use.
   */
  unlockXp: Maybe<Scalars["Int"]["output"]>;
  /** Updated At */
  updatedAt: Scalars["AWSDateTime"]["output"];
  /** Indicates the solo moments section */
  zoneId: Maybe<Scalars["String"]["output"]>;
};

/** This is the number of moments, which will be returned my listMoments resolver if same filters are applied. */
export type MomentCount = {
  __typename: "MomentCount";
  /** Count of moments */
  count: Maybe<Scalars["Int"]["output"]>;
};

/** Denotes the type of the moment. */
export enum MomentType {
  Battle = "BATTLE",
  Casual = "CASUAL",
  Challenge = "CHALLENGE",
  Tutorial = "TUTORIAL",
  Zen = "ZEN",
}

export type Mutation = {
  __typename: "Mutation";
  /** @deprecated friend system replaced by follow system which do not require accepting anything */
  acceptFriendRequest: Maybe<Friend>;
  /**
   * Add new Setting
   * @deprecated This resolver is deprecated and is no longer maintained. Please use `updateSetting` resolver to get the latest features.
   */
  addSetting: Maybe<Setting>;
  addTournamentTypeDetails: Maybe<TournamentTypeDetails>;
  /** Add a user to a permission group */
  addUserToGroup: Maybe<Default>;
  adminAdjustCoins: Maybe<Array<Maybe<Wallet>>>;
  adminAdjustIncome: Maybe<Array<Maybe<Wallet>>>;
  adminAdjustMarketing: Maybe<Array<Maybe<Wallet>>>;
  adminAdjustUserWallet: Maybe<Array<Maybe<Wallet>>>;
  adminCreateCurrency: Maybe<Array<Maybe<Wallet>>>;
  /** Disable a user's account. Requires `ADMIN` permission. */
  adminDisableUser: Maybe<Default>;
  /** Enable a user's account. Requires `ADMIN` permission. */
  adminEnableUser: Maybe<Default>;
  adminRefundBattleEntry: Maybe<Array<Maybe<Wallet>>>;
  /** Apply an inventory effect to a user */
  applyInventory: Maybe<Default>;
  /** Call a B2B API */
  callB2bAdminApi: Maybe<B2bAdminApiOutput>;
  /** Cancel an existing match. */
  cancelMatch: Maybe<CancelMatchOutput>;
  /** Cancel Tournament. Restricted to Bankers */
  cancelTournament: Maybe<Default>;
  /** Check-in the logged-in user. */
  checkinUser: Maybe<Checkin>;
  /** Claim an login reward */
  claimLoginReward: Maybe<Default>;
  clearNotifications: Maybe<Default>;
  /** Close an open tournament if specified, otherwise close all open tournaments */
  closeTournaments: Maybe<Default>;
  /** @deprecated friend system replaced by follow system. Use 'followUser' instead */
  createFriendRequest: Maybe<Friend>;
  /**
   * Create a friend request onboarding code
   * @deprecated friend system replaced by follow system
   */
  createFriendRequestCode: Maybe<CreateFriendRequestCodeOutput>;
  /** Create a house match from house battle */
  createHouseMatch: Maybe<CreateMatchOutput>;
  /**
   * Start a new friend battle
   * If the opponents list is not provided, the user will be matched with a random opponent.
   */
  createMatch: Maybe<CreateMatchOutput>;
  /** Create a mutual follow onboarding code */
  createMutualFollowCode: CreateMutualFollowCodeOutput;
  /** Update or create a regional allowance for each specified region */
  createOrUpdateRegionalAllowance: Maybe<Array<Maybe<RegionalAllowance>>>;
  /**
   * Update or create an user allowance for each specified username
   * @deprecated Cash has been removed.
   */
  createOrUpdateUserAllowance: Maybe<Array<Maybe<UserAllowance>>>;
  /** Create a new Promo. */
  createPromo: Maybe<Promo>;
  /** Create a shareable url that can be shared via social apps */
  createShareableUrl: Maybe<ShareableUrl>;
  /** Create solo challenge transaction for the current user. */
  createSoloChallengeTransaction: Maybe<Array<Maybe<CoinWallet>>>;
  /** @deprecated friend system replaced by follow system. Use 'unfollowUser' instead */
  deleteFriend: Maybe<Scalars["String"]["output"]>;
  /** Delete a promo. */
  deletePromo: Maybe<Default>;
  /** Delete a regional allowance for country/region */
  deleteRegionalAllowance: Maybe<Default>;
  /** Delete setting */
  deleteSetting: Maybe<Default>;
  /** Delete the logged-in user from everywhere. */
  deleteUser: Maybe<Default>;
  /**
   * Delete an user allowance for domain/username
   * @deprecated Cash has been removed.
   */
  deleteUserAllowance: Maybe<Default>;
  /**
   * Disable the logged-in user.
   * @deprecated This resolver is deprecated and is replaced by `disableUser` and `deleteUser`.
   */
  deleteUserV2: Maybe<Default>;
  /** Disable the logged-in user. */
  disableUser: Maybe<Default>;
  endGameSession: Maybe<Default>;
  endHtmlGameSession: Maybe<Default>;
  /** Complete/End a promo battle. */
  endPromo: Maybe<Default>;
  /** Returns the gameSessionId and edgeNodeId to enter the moment. */
  enterSoloMoment: Maybe<EnterSoloMomentOutput>;
  /**
   * Enter a tournament.
   * This resolver creates gameSessionId and edgeNodeId"
   */
  enterTournament: Maybe<TournamentGameSession>;
  /** Create a Follow entry from the current user to the specified user  */
  followUser: Default;
  /** Generate login token to allow login as a test user */
  generateTestLoginToken: Maybe<TestLoginToken>;
  /** Give Free coins promotion for the user */
  getFreeCoins: Maybe<FreeCoins>;
  globalLeaderboards: Maybe<GlobalLeaderboard>;
  importAllowances: Maybe<Default>;
  /** Insert an item into the bonus queue */
  insertBonusQueue: Maybe<Default>;
  /**
   * Start a existing friend battle
   * This resolver is used to play the match already be created and played by a joined player, if match is not playable, create a new one
   */
  joinFriendMatch: Maybe<CreateMatchOutput>;
  /** Join an existing livestreaming session */
  joinLivestream: Maybe<LivestreamSession>;
  /**
   * Join a existing match using invite code.
   * This adds the current user as one of players of the match but does not start it. Use `playMatch` resolver to actually the start playing the match and to get the Game Session ID."
   */
  joinMatch: Maybe<JoinMatchOutput>;
  /** Login or signup a user */
  login: Maybe<LoginOutput>;
  /**
   * Play a house battle.
   * @deprecated Use 'createHouseMatch' and 'playHouseMatch' resolvers instead.
   */
  playHouseBattle: Maybe<PlayMatch>;
  /** Play a house match */
  playHouseMatch: Maybe<PlayMatchOutput>;
  /**
   * Play a friend battle
   * This resolver is used to charge the payment, update the game session status
   */
  playMatch: Maybe<PlayMatchOutput>;
  /**
   * Play a match.
   * This resolver has to be used after the user joined an existing match using the `joinMatch` resolver to actually start the match.
   * @deprecated Use 'joinFriendMatch' and 'playMatch' resolvers instead.
   */
  playMatchV2: Maybe<PlayMatch>;
  playMoment: Maybe<PlayMomentOutput>;
  /** Does the payment to play the solo moment. */
  playSoloMoment: Maybe<PlaySoloMomentOutput>;
  /**
   * Play a tournament.
   * This resolver debits required amount from the user's wallet."
   */
  playTournament: Maybe<PlayTournamentOutput>;
  /** Create a signed url for uploading a document to an environment */
  presignDocumentUrl: PresignedDocumentUrl;
  /** Purchase an inventory */
  purchaseInventory: Default;
  /**
   * Reject battle invitation.
   * Rejected battles will be sent to Hot Battles where they can be played any user in the platform.
   */
  rejectBattleInvitation: Maybe<RejectBattleInvitationOutput>;
  /** Remind opponents to play the match. */
  remindOpponentsToPlay: Maybe<Default>;
  removeTournamentTypeDetails: Maybe<TournamentTypeDetails>;
  /** Remove a user from a permission group */
  removeUserFromGroup: Maybe<Default>;
  reviewGameSession: Maybe<GameSession>;
  /** Start a new livestreaming session */
  startLivestream: Maybe<LivestreamSession>;
  /**
   * Start or join a new 1v1 battle.
   * If the opponents list is not provided, the user will be matched with a random opponent.
   * @deprecated Use 'createMatch' and 'playMatch' resolvers instead.
   */
  startMatch: Maybe<PlayMatch>;
  /** Start a promo battle. */
  startPromo: Maybe<PromoSession>;
  trackActivity: Maybe<Default>;
  /** Delete a Follow entry from the current user to the specified user  */
  unfollowUser: Default;
  updateAllGameSkills: Maybe<Array<Maybe<UpdateGameSkillOutput>>>;
  /** Update all valid matches user skills */
  updateAllUserSkills: Maybe<Default>;
  /**
   * Sets the cash enabled property of a game. If cash is not enabled, the game will not be available for cash-based tournaments and battles, even if the skill level would normally allow it.
   * @deprecated Cash has been removed.
   */
  updateCashEnabled: Maybe<Default>;
  /** Update a CodeBuild project environment */
  updateCodeBuildEnvironment: Maybe<Default>;
  /**
   * Update the game allowance for a particular game.
   * If cash is not enabled, the game will not be available for cash-based tournaments and battles, even if the skill level would normally allow it.
   * If disabled status is not true, the game will not be available.
   */
  updateGameAllowance: Maybe<Default>;
  updateGameSessionStatus: Maybe<Default>;
  updateGameSkillByGameId: Maybe<Array<Maybe<UpdateGameSkillOutput>>>;
  /** Update a promo. */
  updatePromo: Maybe<Promo>;
  /** Update the Setting */
  updateSetting: Maybe<Setting>;
  /** Update user cheat status. */
  updateUserCheatStatus: Maybe<Default>;
  /** Update user metadata */
  updateUserMetadata: Maybe<Default>;
  /** Update user skill by match id */
  updateUserSkillByMatchId: Maybe<Default>;
  /** Update user profile. */
  updateUserV2: Maybe<Default>;
};

export type MutationAcceptFriendRequestArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAddSettingArgs = {
  createSettingInput: InputMaybe<CreateSettingInput>;
};

export type MutationAddTournamentTypeDetailsArgs = {
  addTournamentTypeDetailsInput: InputMaybe<AddTournamentTypeDetailsInput>;
};

export type MutationAddUserToGroupArgs = {
  permissionGroupUpdateInput: InputMaybe<PermissionGroupUpdateInput>;
};

export type MutationAdminAdjustCoinsArgs = {
  adminAdjustCoinsInput: AdminAdjustCoinsInput;
};

export type MutationAdminAdjustIncomeArgs = {
  adminAdjustIncomeInput: AdminAdjustIncomeInput;
};

export type MutationAdminAdjustMarketingArgs = {
  adminAdjustMarketingInput: AdminAdjustMarketingInput;
};

export type MutationAdminAdjustUserWalletArgs = {
  adminAdjustUserWalletInput: AdminAdjustUserWalletInput;
};

export type MutationAdminCreateCurrencyArgs = {
  adminCreateCurrencyInput: AdminCreateCurrencyInput;
};

export type MutationAdminDisableUserArgs = {
  disabledReason: InputMaybe<DisabledReason>;
  userId: Scalars["String"]["input"];
};

export type MutationAdminEnableUserArgs = {
  userId: Scalars["String"]["input"];
};

export type MutationAdminRefundBattleEntryArgs = {
  adminRefundBattleEntryInput: AdminRefundBattleEntryInput;
};

export type MutationApplyInventoryArgs = {
  applyInventoryInput: InputMaybe<ApplyInventoryInput>;
};

export type MutationCallB2bAdminApiArgs = {
  b2bAdminApiInput: B2bAdminApiInput;
};

export type MutationCancelMatchArgs = {
  cancelMatchInput: CancelMatchInput;
};

export type MutationCancelTournamentArgs = {
  cancelTournamentInput: InputMaybe<CancelTournamentInput>;
};

export type MutationCheckinUserArgs = {
  input: InputMaybe<CheckinUserInput>;
};

export type MutationClaimLoginRewardArgs = {
  claimLoginRewardInput: InputMaybe<ClaimLoginRewardInput>;
};

export type MutationClearNotificationsArgs = {
  clearNotificationsInput: InputMaybe<ClearNotificationsInput>;
};

export type MutationCloseTournamentsArgs = {
  closeTournamentsInput: InputMaybe<CloseTournamentsInput>;
};

export type MutationCreateFriendRequestArgs = {
  createFriendRequestInput: CreateFriendRequestInput;
};

export type MutationCreateHouseMatchArgs = {
  createHouseMatchInput: CreateHouseMatchInput;
};

export type MutationCreateMatchArgs = {
  createMatchInput: CreateMatchInput;
};

export type MutationCreateOrUpdateRegionalAllowanceArgs = {
  updateRegionalAllowanceInput: InputMaybe<UpdateRegionalAllowanceInput>;
};

export type MutationCreateOrUpdateUserAllowanceArgs = {
  updateUserAllowanceInput: InputMaybe<UpdateUserAllowanceInput>;
};

export type MutationCreatePromoArgs = {
  createPromoInput: InputMaybe<CreatePromoInput>;
};

export type MutationCreateShareableUrlArgs = {
  createShareableUrlInput: InputMaybe<CreateShareableUrlInput>;
};

export type MutationCreateSoloChallengeTransactionArgs = {
  createSoloChallengeTransactionInput: CreateSoloChallengeTransactionInput;
};

export type MutationDeleteFriendArgs = {
  deleteFriendInput: InputMaybe<DeleleFriendInput>;
};

export type MutationDeletePromoArgs = {
  deletePromoInput: InputMaybe<DeletePromoInput>;
};

export type MutationDeleteRegionalAllowanceArgs = {
  deleteRegionalAllowanceInput: InputMaybe<DeleteRegionalAllowanceInput>;
};

export type MutationDeleteSettingArgs = {
  deleteSettingInput: InputMaybe<DeleteSettingInput>;
};

export type MutationDeleteUserAllowanceArgs = {
  deleteUserAllowanceInput: InputMaybe<DeleteUserAllowanceInput>;
};

export type MutationEndGameSessionArgs = {
  endGameSessionInput: EndGameSessionInput;
};

export type MutationEndHtmlGameSessionArgs = {
  endHtmlGameSessionInput: EndHtmlGameSessionInput;
};

export type MutationEndPromoArgs = {
  endPromoInput: EndPromoInput;
};

export type MutationEnterSoloMomentArgs = {
  enterSoloMomentInput: InputMaybe<EnterSoloMomentInput>;
};

export type MutationEnterTournamentArgs = {
  enterTournamentInput: InputMaybe<EnterTournamentInput>;
};

export type MutationFollowUserArgs = {
  followUserInput: FollowInput;
};

export type MutationGenerateTestLoginTokenArgs = {
  testTokenInput: InputMaybe<TestLoginTokenInput>;
};

export type MutationImportAllowancesArgs = {
  importAllowancesInput: InputMaybe<ImportAllowancesInput>;
};

export type MutationInsertBonusQueueArgs = {
  insertBonusQueueInput: InsertBonusQueueInput;
};

export type MutationJoinFriendMatchArgs = {
  joinFriendMatchInput: JoinFriendMatchInput;
};

export type MutationJoinLivestreamArgs = {
  joinLivestreamInput: JoinLivestreamInput;
};

export type MutationJoinMatchArgs = {
  joinMatchInput: JoinMatchInput;
};

export type MutationLoginArgs = {
  loginInput: InputMaybe<LoginInput>;
  oncode: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPlayHouseBattleArgs = {
  playHouseBattleInput: PlayHouseBattleInput;
};

export type MutationPlayHouseMatchArgs = {
  playHouseMatchInput: PlayHouseMatchInput;
};

export type MutationPlayMatchArgs = {
  playFriendMatchInput: PlayFriendMatchInput;
};

export type MutationPlayMatchV2Args = {
  playMatchInput: PlayMatchInput;
};

export type MutationPlayMomentArgs = {
  playMomentInput: InputMaybe<PlayMomentInput>;
};

export type MutationPlaySoloMomentArgs = {
  playSoloMomentInput: InputMaybe<PlaySoloMomentInput>;
};

export type MutationPlayTournamentArgs = {
  playTournamentInput: InputMaybe<PlayTournamentInput>;
};

export type MutationPresignDocumentUrlArgs = {
  presignDocumentUrlInput: PresignDocumentUrlInput;
};

export type MutationPurchaseInventoryArgs = {
  purchaseInventoryInput: PurchaseInventoryInput;
};

export type MutationRejectBattleInvitationArgs = {
  matchId: Scalars["String"]["input"];
};

export type MutationRemindOpponentsToPlayArgs = {
  remindOpponentsToPlayInput: RemindOpponentsToPlayInput;
};

export type MutationRemoveTournamentTypeDetailsArgs = {
  removeTournamentTypeDetailsInput: InputMaybe<RemoveTournamentTypeDetailsInput>;
};

export type MutationRemoveUserFromGroupArgs = {
  permissionGroupUpdateInput: InputMaybe<PermissionGroupUpdateInput>;
};

export type MutationReviewGameSessionArgs = {
  reviewGameSessionInput: InputMaybe<ReviewGameSessionInput>;
};

export type MutationStartLivestreamArgs = {
  startLivestreamInput: StartLivestreamInput;
};

export type MutationStartMatchArgs = {
  startMatchInput: StartMatchInput;
};

export type MutationStartPromoArgs = {
  startPromoInput: StartPromoInput;
};

export type MutationTrackActivityArgs = {
  trackActivityInput: InputMaybe<TrackActivityInput>;
};

export type MutationUnfollowUserArgs = {
  unfollowUserInput: FollowInput;
};

export type MutationUpdateCashEnabledArgs = {
  updateCashEnabledInput: UpdateCashEnabledInput;
};

export type MutationUpdateCodeBuildEnvironmentArgs = {
  updateCodeBuildEnvironmentInput: UpdateCodeBuildEnvironmentInput;
};

export type MutationUpdateGameAllowanceArgs = {
  updateGameAllowanceInput: UpdateGameAllowanceInput;
};

export type MutationUpdateGameSessionStatusArgs = {
  updateGameSessionStatusInput: InputMaybe<UpdateGameSessionStatusInput>;
};

export type MutationUpdateGameSkillByGameIdArgs = {
  updateGameSkillByGameIdInput: InputMaybe<GameIdForEloMatchInput>;
};

export type MutationUpdatePromoArgs = {
  updatePromoInput: InputMaybe<UpdatePromoInput>;
};

export type MutationUpdateSettingArgs = {
  updateSettingInput: InputMaybe<UpdateSettingInput>;
};

export type MutationUpdateUserCheatStatusArgs = {
  updateUserCheatStatusInput: UpdateUserCheatStatusInput;
};

export type MutationUpdateUserMetadataArgs = {
  updateUserMetadataInput: UpdateUserMetadataInput;
};

export type MutationUpdateUserSkillByMatchIdArgs = {
  updateUserSkillByMatchIdInput: MatchIdForMatchMakingInput;
};

export type MutationUpdateUserV2Args = {
  updateUserV2Input: UpdateUserV2Input;
};

export type NAllGame = {
  __typename: "NAllGame";
  /** Indicates whether the game can use cash */
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  /** The category is represented as a JSON string which contains a array of category values */
  category: Maybe<Scalars["String"]["output"]>;
  /**
   * Game Developer
   * @deprecated No longer in use.
   */
  company: Maybe<Scalars["String"]["output"]>;
  /** Game created time */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Dynamic meta data which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  /** Game Description */
  description: Maybe<Scalars["String"]["output"]>;
  /** Game ID */
  id: Maybe<Scalars["ID"]["output"]>;
  /** Orientation required to play the game */
  rotationMode: Maybe<RotationMode>;
  /** Game Status */
  status: Maybe<GameStatus>;
  /** Number of times the game has been played by users */
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  /** Game Title */
  title: Maybe<Scalars["String"]["output"]>;
  /** Game Type */
  type: Maybe<GameType>;
  /** Game updated time */
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /**
   * Indicates whether the game uses server-side simulation
   * @deprecated This field has been deprecated and should no longer be used. Any value given to it will be ignored.
   */
  useSimulation: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 * Denotes a Game.
 * This is a special type as it does not use any sub-resolvers. Any resolvers that use this type will have to fetch all the dependent field data in the same resolver.
 */
export type NGame = {
  __typename: "NGame";
  /** Cost to play the game */
  battleCost: Maybe<Array<Maybe<BattleCost>>>;
  /** Indicates whether the game can use cash */
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  /** The category is represented as a JSON string which contains a array of category values */
  category: Maybe<Scalars["String"]["output"]>;
  /**
   * Game Developer
   * @deprecated This field has been deprecated and should no longer be used.
   */
  company: Maybe<Scalars["String"]["output"]>;
  /** The total number of LIVE moments associated with the game (not available for all resolvers) */
  countMoments: Maybe<Scalars["Int"]["output"]>;
  /** Dynamic metadata which gives additional information about the game */
  data: Maybe<Scalars["String"]["output"]>;
  /** Game Description */
  description: Maybe<Scalars["String"]["output"]>;
  /** The status of game skill elo calculation (not available for all resolvers) */
  eloStatus: Maybe<Scalars["String"]["output"]>;
  /** Game Featured Status */
  featured: Maybe<Scalars["Boolean"]["output"]>;
  /** Number of times this user played this game (not available for all resolvers) */
  gamesPlayedByUser: Maybe<Scalars["Int"]["output"]>;
  /** Indicates whether game has one-on-one battle moments (not available for all resolvers) */
  hasBattle: Maybe<Scalars["Boolean"]["output"]>;
  /** Indicates whether game has solo moments (not available for all resolvers) */
  hasSolo: Maybe<Scalars["Boolean"]["output"]>;
  /** Indicates whether game has open tournaments (not available for all resolvers) */
  hasTournament: Maybe<Scalars["Boolean"]["output"]>;
  /** Game ID */
  id: Maybe<Scalars["ID"]["output"]>;
  /**
   * Indicates whether the currently logged-in user has liked the game or not
   * @deprecated This field has been deprecated and should no longer be used.
   */
  isLike: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Number of users who have liked the game (not available for all resolvers)
   * @deprecated This field has been deprecated and should no longer be used.
   */
  likeCount: Maybe<Scalars["Int"]["output"]>;
  /** Moments of the game */
  moment: Maybe<Moment>;
  /** Rank divisions of the game. (Only available in getUserFeed and getGame resolver) */
  rankCategories: Maybe<Array<Maybe<ILevel>>>;
  /** Raw untrimmed game metadata */
  rawData: Maybe<Scalars["String"]["output"]>;
  /** Orientation required to play the game */
  rotationMode: Maybe<RotationMode>;
  /** Game skill from elo calculation (not available for all resolvers) */
  skill: Maybe<Scalars["Int"]["output"]>;
  /** Game Status */
  status: Maybe<GameStatus>;
  /** Number of times the game has been played by users */
  timesPlayed: Maybe<Scalars["Int"]["output"]>;
  /** Game Title */
  title: Maybe<Scalars["String"]["output"]>;
  /** Number of moments completed by the currently logged-in user (not available for all resolvers) */
  totalCompleted: Maybe<Scalars["Int"]["output"]>;
  /** List of open tournaments for the game (not available for all resolvers) */
  tournaments: Maybe<Array<Maybe<NTournament>>>;
  /** Game Type */
  type: Maybe<GameType>;
};

/**
 * Denotes a Tournament.
 * This is a special type as it does not use any sub-resolvers. Any resolvers that use this type will have to fetch all the dependent field data in the same resolver.
 */
export type NTournament = {
  __typename: "NTournament";
  /** Cost to participate in the tournament */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Currency */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Date and Time at which the tournament expired */
  expiredAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Platform fees */
  fee: Maybe<Scalars["Float"]["output"]>;
  /** Game ID */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Highest score of the tournament */
  highestScore: Maybe<Scalars["Float"]["output"]>;
  /** Tournament ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Tournament leaderboards - List of top players with their scores */
  leaderboards: Maybe<Array<Maybe<TournamentPlayers>>>;
  /** Tournament Moment */
  moment: Maybe<Moment>;
  /** ID of the tournament moment */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Moment Type */
  momentType: Maybe<Scalars["String"]["output"]>;
  /** Currently logged-in user's ranking in the tournament */
  myRank: Maybe<Scalars["Int"]["output"]>;
  /** Score ranking order */
  rankingOrder: Maybe<Scalars["String"]["output"]>;
  /** Date and Time at which the tournament was started */
  startedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Tournament Status */
  status: Maybe<Scalars["String"]["output"]>;
  /** Total number of players who have played in the tournament */
  totalPlayers: Maybe<Scalars["Int"]["output"]>;
};

/**
 * Denotes a Tournament.
 * This is a special type as it does not use any sub-resolvers. Any resolvers that use this type will have to fetch all the dependent field data in the same resolver.
 */
export type NTournamentLeaderboardsArgs = {
  getLeaderboardInput: InputMaybe<GetLeaderboardsInput>;
};

/**
 * Denotes a Tournament.
 * This is a special type as it does not use any sub-resolvers. Any resolvers that use this type will have to fetch all the dependent field data in the same resolver.
 */
export type NTournamentMyRankArgs = {
  getMyRankInput: InputMaybe<GetMyRankInput>;
};

/** Denotes the current read status of a notification. */
export enum NotificationState {
  New = "NEW",
  Read = "READ",
  Seen = "SEEN",
}

/** Denotes the type of a notification. */
export enum NotificationType {
  AchievementReward = "ACHIEVEMENT_REWARD",
  BattleExpired = "BATTLE_EXPIRED",
  BattleExpiring = "BATTLE_EXPIRING",
  BattleInvitation = "BATTLE_INVITATION",
  BattleLost = "BATTLE_LOST",
  BattleRefunded = "BATTLE_REFUNDED",
  BattleReminder = "BATTLE_REMINDER",
  BattleWon = "BATTLE_WON",
  Bonus = "BONUS",
  CryptoDeposit = "CRYPTO_DEPOSIT",
  /** @deprecated friend system has been replaced by follow system */
  FriendAccepted = "FRIEND_ACCEPTED",
  /** @deprecated friend system has been replaced by follow system */
  FriendInvitation = "FRIEND_INVITATION",
  GlobalLeaderboardEnded = "GLOBAL_LEADERBOARD_ENDED",
  TournamentCancelled = "TOURNAMENT_CANCELLED",
  TournamentDemotion = "TOURNAMENT_DEMOTION",
  TournamentLost = "TOURNAMENT_LOST",
  TournamentRefunded = "TOURNAMENT_REFUNDED",
  TournamentWon = "TOURNAMENT_WON",
}

export type OncodeActionResult = {
  __typename: "OncodeActionResult";
  /** Oncode action */
  action: Scalars["String"]["output"];
  /** Response data */
  data: Maybe<OncodeData>;
  /** Status of the oncode execution */
  status: Maybe<Scalars["String"]["output"]>;
};

/** Oncode response data */
export type OncodeData = {
  __typename: "OncodeData";
  /** Reason for the failure */
  failureReason: Maybe<Scalars["String"]["output"]>;
  /** Redirect path */
  redirect: Maybe<Scalars["String"]["output"]>;
};

/** Oncode Result Type */
export type OncodeResult = {
  __typename: "OncodeResult";
  /** Result of the oncode execution */
  result: Maybe<Array<Maybe<OncodeActionResult>>>;
  /** Is `true` if the provided oncode was invalid */
  validationError: Maybe<Scalars["Boolean"]["output"]>;
};

/** Denotes the Onmo Pay URL. */
export type OnmoPayUrl = {
  __typename: "OnmoPayUrl";
  /** The user must be redirected to this URL so that they can make the specified payment. */
  url: Maybe<Scalars["String"]["output"]>;
};

/** Withdrawal Response */
export type OnmoPayUrlWithWallets = {
  __typename: "OnmoPayUrlWithWallets";
  /** Transactions involved in the withdrawal. */
  transactions: Maybe<Array<Maybe<Transaction>>>;
  /** Onmo Pay URL */
  url: Maybe<Scalars["String"]["output"]>;
  /** Wallets that will be affected by the withdrawal. */
  wallets: Maybe<Array<Maybe<Wallet>>>;
};

export type OnmoPaymentResponse = {
  __typename: "OnmoPaymentResponse";
  bonus: Maybe<Bonus>;
  transactions: Maybe<Array<Maybe<Transaction>>>;
  updatedWallets: Maybe<Array<Maybe<Wallet>>>;
};

export enum Prize_Type {
  Amount = "AMOUNT",
  None = "NONE",
  Percent = "PERCENT",
}

export type PaginatedAchievementOutput = {
  __typename: "PaginatedAchievementOutput";
  items: Maybe<Array<Maybe<Achievement>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated battle payment list */
export type PaginatedBattlePaymentOutput = {
  __typename: "PaginatedBattlePaymentOutput";
  items: Maybe<Array<Maybe<BattlePayment>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated bonus list */
export type PaginatedBonusOutput = {
  __typename: "PaginatedBonusOutput";
  items: Maybe<Array<Maybe<Bonus>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated cheater contact list */
export type PaginatedCheaterContactOutput = {
  __typename: "PaginatedCheaterContactOutput";
  items: Maybe<Array<Maybe<CheaterContact>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated coin purchase list */
export type PaginatedCoinPurchaseOutput = {
  __typename: "PaginatedCoinPurchaseOutput";
  items: Maybe<Array<Maybe<CoinPurchase>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated deposit list */
export type PaginatedDepositOutput = {
  __typename: "PaginatedDepositOutput";
  items: Maybe<Array<Maybe<Deposit>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated output for followers */
export type PaginatedFollowerOutput = {
  __typename: "PaginatedFollowerOutput";
  /** UserFollower entries returned by the query */
  items: Array<Maybe<UserFollower>>;
  /** Number of items returned */
  totalCount: Scalars["Int"]["output"];
};

/** Paginated output for inventories */
export type PaginatedInventoryOutput = {
  __typename: "PaginatedInventoryOutput";
  /** Inventories returned by the query */
  items: Array<Maybe<Inventory>>;
  /** Number of items returned */
  totalCount: Scalars["Int"]["output"];
};

/** Paginated promotion list */
export type PaginatedPromotionOutput = {
  __typename: "PaginatedPromotionOutput";
  items: Maybe<Array<Maybe<Promotion>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated tournament type details list */
export type PaginatedTournamentTypeDetailsOutput = {
  __typename: "PaginatedTournamentTypeDetailsOutput";
  items: Maybe<Array<Maybe<TournamentTypeDetails>>>;
  totalCount: Scalars["Int"]["output"];
};

export type PaginatedTransactionOutput = {
  __typename: "PaginatedTransactionOutput";
  items: Maybe<Array<Maybe<Transaction>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated user list */
export type PaginatedUserOutput = {
  __typename: "PaginatedUserOutput";
  items: Maybe<Array<Maybe<UserV2>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Admin Wallet List Response */
export type PaginatedWalletAdminOutput = {
  __typename: "PaginatedWalletAdminOutput";
  items: Maybe<Array<Maybe<WalletAdmin>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Paginated withdrawal list */
export type PaginatedWithdrawalOutput = {
  __typename: "PaginatedWithdrawalOutput";
  items: Maybe<Array<Maybe<Withdrawal>>>;
  totalCount: Scalars["Int"]["output"];
};

/** Input filters for fetching with pagination */
export type PaginationInput = {
  /** Number of items to be returned */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of the items to be returned */
  offset: InputMaybe<Scalars["Int"]["input"]>;
};

/** Denotes the current payment state of a player for a match. */
export enum PaymentState {
  Charged = "CHARGED",
  Na = "NA",
  Paid = "PAID",
  Pending = "PENDING",
  Refunded = "REFUNDED",
}

/**
 * Denotes the onmo-auth permission group.
 * *Note: `Editorial`, `Publisher` and `Banker` groups are deprecated and replaced with `EDITOR`, `PUBLISHER` AND `BANKER`. Please switch to using the new groups since the old groups will be removed in the next release.*
 */
export enum PermissionGroup {
  Admin = "ADMIN",
  B2BAdmin = "B2B_ADMIN",
  B2BSupport = "B2B_SUPPORT",
  Banker = "BANKER",
  Editor = "EDITOR",
  Manager = "MANAGER",
  OnmopayAdmin = "ONMOPAY_ADMIN",
  OnmopayManager = "ONMOPAY_MANAGER",
  Publisher = "PUBLISHER",
  Qa = "QA",
  StreamingTest = "STREAMING_TEST",
  Support = "SUPPORT",
}

export type PermissionGroupUpdateInput = {
  /** Group the user is added to or removed from */
  permissionGroup: InputMaybe<PermissionGroup>;
  /** User ID */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

export type PlayFriendMatchInput = {
  /** Play friend battle game sesion id */
  gameSessionId: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to start a match. */
export type PlayHouseBattleInput = {
  /** Cost to start the match. */
  cost: Scalars["Float"]["input"];
  /** Information about the user's device. */
  device: UserDeviceInput;
  /** Replay house battle provided edgeNodeId */
  edgeNodeId: InputMaybe<Scalars["String"]["input"]>;
  /** Game ID */
  matchId: Scalars["String"]["input"];
  /** Region for streaming house battle */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to play a match. */
export type PlayHouseMatchInput = {
  /** Game Session Id */
  gameSessionId: Scalars["String"]["input"];
};

/** Response for `startMatch` and `playMatch` mutations. */
export type PlayMatch = {
  __typename: "PlayMatch";
  /** ID of stream node. Value will be `HTML` for HTML games. */
  edgeNodeId: Maybe<Scalars["String"]["output"]>;
  /** Full Match Information */
  fullBattleInfo: Maybe<Match>;
  /** New Game Session ID */
  gameSessionId: Maybe<Scalars["String"]["output"]>;
  /** Match ID */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** Match Moment */
  moment: Maybe<Moment>;
  /** Match players */
  players: Maybe<Array<Maybe<LightUser>>>;
  /** Wallets involved in payment transaction. */
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
};

export type PlayMatchInput = {
  /** Device information for playing match */
  device: UserDeviceInput;
  /** Match Id to play */
  matchId: Scalars["String"]["input"];
  /** Playing streaming match region */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Response for `playHouseMatch` mutations. */
export type PlayMatchOutput = {
  __typename: "PlayMatchOutput";
  /** ID of stream node. Value will be `HTML` for HTML games. */
  edgeNodeId: Scalars["String"]["output"];
  /** New Game Session ID */
  gameSessionId: Scalars["String"]["output"];
  /** Match ID */
  matchId: Scalars["String"]["output"];
  /** Updated coin wallets */
  updatedWallets: Array<Maybe<CoinWallet>>;
};

export type PlayMomentInput = {
  /** Play moment device information */
  device: UserDeviceInput;
  /** Play moment game id */
  gameId: InputMaybe<Scalars["String"]["input"]>;
  /** Play moment game session id */
  gameSessionId: InputMaybe<Scalars["String"]["input"]>;
  /** Play moment id */
  momentId: InputMaybe<Scalars["String"]["input"]>;
  /** Play moment session type */
  sessionType: InputMaybe<GameSessionType>;
  /** Play moment region */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

export type PlayMomentOutput = {
  __typename: "PlayMomentOutput";
  edgeNodeId: Scalars["String"]["output"];
  gameSessionId: Scalars["ID"]["output"];
  updatedWallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Input data to play a solo moment. */
export type PlaySoloMomentInput = {
  /** Game Session ID returned by `enterSoloMoment` resolver. */
  gameSessionId: Scalars["String"]["input"];
};

/** Response of playSoloMoment mutation. */
export type PlaySoloMomentOutput = {
  __typename: "PlaySoloMomentOutput";
  /** This indicates if the payment is successful or not. */
  status: Maybe<Message_Response>;
};

/** Denotes the current play state of a match. */
export enum PlayState {
  NotPlayed = "NOT_PLAYED",
  Open = "OPEN",
  Played = "PLAYED",
  Playing = "PLAYING",
}

/** Input data to play a tournament. */
export type PlayTournamentInput = {
  /** Game Session ID */
  gameSessionId: Scalars["String"]["input"];
};

/** Success or failure message of the payment. Value will be SUCCESS if the payment was successful. */
export type PlayTournamentOutput = {
  __typename: "PlayTournamentOutput";
  /** List of user's wallets. */
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Input data to start coin purchase. */
export type PreparePurchaseCoinInput = {
  /** Redirect URL. The user will be redirected to this URL after the payment. */
  return_url: Scalars["String"]["input"];
  /** Item to purchase. Should be from the coins category. */
  store_item_id: Scalars["String"]["input"];
};

/** Input data to start cash deposit. */
export type PreparePurchaseDepositInput = {
  /**
   * Amount to deposit. The amount will be used if the store item is not specified.
   * @deprecated No longer being used.
   */
  amount: InputMaybe<Scalars["Float"]["input"]>;
  /**
   * Redirect URL. The user will be redirected to this URL after the payment.
   * @deprecated No longer being used.
   */
  return_url: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Item to purchase. Should be from the cash category. If no item is specified, the amount will be used.
   * @deprecated No longer being used.
   */
  store_item_id: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to start cash withdrawal. */
export type PrepareWithdrawalInput = {
  /** Amount to withdraw. */
  amount: Scalars["Float"]["input"];
  /** Redirect URL. The user will be redirected to this URL after Onmo Pay processes the withdrawal. */
  returnUrl: Scalars["String"]["input"];
};

/** Input data to create a presigned url for uploading document for a tenant / environment */
export type PresignDocumentUrlInput = {
  /** Environment Name */
  environmentName: InputMaybe<Scalars["String"]["input"]>;
  /** Filename of the document */
  filename: InputMaybe<Scalars["String"]["input"]>;
};

/** Signed URL of the document */
export type PresignedDocumentUrl = {
  __typename: "PresignedDocumentUrl";
  /** Filename of the document */
  filename: Scalars["String"]["output"];
  /** Signed URL that can be used to upload the document body */
  presignedUrl: Scalars["String"]["output"];
};

export type PrizeBreakup = {
  __typename: "PrizeBreakup";
  endRank: Maybe<Scalars["Int"]["output"]>;
  perUserAmount: Maybe<Scalars["Float"]["output"]>;
  startRank: Maybe<Scalars["Int"]["output"]>;
  totalAmount: Maybe<Scalars["Float"]["output"]>;
};

export type PrizeBreakupInput = {
  endRank: Scalars["Int"]["input"];
  perUserAmount: InputMaybe<Scalars["Float"]["input"]>;
  startRank: Scalars["Int"]["input"];
  totalAmount: Scalars["Float"]["input"];
};

/** Prize pool of the type details */
export type PrizePool = {
  __typename: "PrizePool";
  /** Prize for the rank */
  prize: Maybe<Scalars["Float"]["output"]>;
  /** Number of the rank */
  toRank: Maybe<Scalars["Int"]["output"]>;
};

/** Input data for prize pool of tournament type details */
export type PrizePoolInput = {
  /** prize for the rank */
  prize: InputMaybe<Scalars["Float"]["input"]>;
  /** number of the rank */
  toRank: InputMaybe<Scalars["Int"]["input"]>;
};

/** Denotes the prize pools schema and fields */
export type PrizePools = {
  __typename: "PrizePools";
  /** Battle coins payouts */
  battle: Maybe<BattlePayout>;
  /** Challenge Rewards */
  challenge: Maybe<ChallengeRewards>;
};

export type Promo = {
  __typename: "Promo";
  /** Promo end date */
  endDate: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Promo game info */
  game: Maybe<Game>;
  /** Game ID */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Promo ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Promo moment info */
  moment: Maybe<Moment>;
  /** Moment ID */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Player score */
  score: Maybe<Scalars["Int"]["output"]>;
  /** Promo start date */
  startDate: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Promo User */
  user: Maybe<User>;
  /** User ID */
  userId: Maybe<Scalars["String"]["output"]>;
};

export type PromoSession = {
  __typename: "PromoSession";
  /** ID of stream node. Value will be `HTML` for HTML games. */
  edgeNodeId: Maybe<Scalars["String"]["output"]>;
  /** New Game Session ID */
  gameSessionId: Maybe<Scalars["String"]["output"]>;
  /** Match Moment */
  moment: Maybe<Moment>;
  /** Match players */
  players: Maybe<Array<Maybe<LightUser>>>;
  /** Match ID */
  promo: Maybe<Promo>;
  /** New Promo Session ID */
  promoSessionId: Maybe<Scalars["String"]["output"]>;
};

export type Promotion = {
  __typename: "Promotion";
  amount: Maybe<Scalars["Float"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  date: Maybe<Scalars["AWSDateTime"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  promotion_type: Maybe<Scalars["String"]["output"]>;
  source_wallet_id: Maybe<Scalars["String"]["output"]>;
  updated_at: Maybe<Scalars["AWSDateTime"]["output"]>;
  user_id: Maybe<Scalars["String"]["output"]>;
  wallet_id: Maybe<Scalars["String"]["output"]>;
};

export type PromotionFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The promotion's field to filter on */
  field: PromotionFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list promotions on */
export enum PromotionFilterField {
  /** Amount of the promotion */
  Amount = "amount",
  /** Currency the promotion is in */
  Currency = "currency",
  /** Date the promotion was created */
  Date = "date",
  /** identifier for the promotion */
  Id = "id",
  /** Type of the promotion */
  PromotionType = "promotion_type",
  /** User who made the promotion */
  UserId = "user_id",
  /** Wallet the promotion is from */
  WalletId = "wallet_id",
}

export type PublicUser = PublicUserInterface & {
  __typename: "PublicUser";
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /**
   * User cheating gamesessions count
   * @deprecated `cheatSessions` are not required anymore.
   */
  cheatSessions: Maybe<Scalars["Int"]["output"]>;
  /** Follow status between a user and the caller */
  followStatus: Maybe<FollowStatus>;
  /** User ID */
  id: Scalars["String"]["output"];
  /** User's last checkin time */
  lastCheckin: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Username unique to the user. */
  username: Maybe<Scalars["String"]["output"]>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Denotes the public profile of a user with limited fields. */
export type PublicUserInterface = {
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /**
   * User cheating gamesessions count
   * @deprecated `cheatSessions` are not required anymore.
   */
  cheatSessions: Maybe<Scalars["Int"]["output"]>;
  /** User ID */
  id: Scalars["String"]["output"];
  /** Username unique to the user. */
  username: Maybe<Scalars["String"]["output"]>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

export type PurchaseInventoryInput = {
  /** Specify true if the user wants the inventory effect applied on purchase */
  applyImmediately: InputMaybe<Scalars["Boolean"]["input"]>;
  /** An inventory can have multiple prices, the selected option is specified here (empty when free) */
  inventoryCost: InputMaybe<InventoryCostInput>;
  /** ID of the inventory being purchased */
  inventoryId: Scalars["String"]["input"];
};

export type Query = {
  __typename: "Query";
  /** This returns a list of all open gamesessions. Requires Publisher/Editorial/Support permissions. */
  ListGameSessionsV2: Maybe<Array<Maybe<GameSession>>>;
  achievements: Maybe<PaginatedAchievementOutput>;
  activities: Maybe<ActivityOutput>;
  adminFetchTransactions: Maybe<Array<Maybe<AdminFetchTransactionsOutput>>>;
  /** Fetch all games required for admin */
  adminGames: Maybe<AdminGames>;
  adminListBattlePayments: Maybe<PaginatedBattlePaymentOutput>;
  adminListBonuses: Maybe<PaginatedBonusOutput>;
  adminListCoinPurchases: Maybe<PaginatedCoinPurchaseOutput>;
  adminListCurrencies: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  adminListDeposits: Maybe<PaginatedDepositOutput>;
  adminListInternalTransactions: Maybe<Array<Maybe<SerializedTransaction>>>;
  adminListInternalWallets: Maybe<Array<Maybe<Wallet>>>;
  adminListPromotions: Maybe<PaginatedPromotionOutput>;
  adminListTournamentTypeDetails: Maybe<PaginatedTournamentTypeDetailsOutput>;
  /** List the transaction workflow enum values */
  adminListTransactionWorkflows: Maybe<
    Array<Maybe<Scalars["String"]["output"]>>
  >;
  adminListTransactionsWithPagination: Maybe<PaginatedTransactionOutput>;
  /** Search for users based on his id, username or email */
  adminListUsers: Maybe<PaginatedUserOutput>;
  adminListWalletType: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  adminListWalletsWithPagination: Maybe<PaginatedWalletAdminOutput>;
  adminListWithdrawals: Maybe<PaginatedWithdrawalOutput>;
  /** Fetch all tournaments. Requires Editorial / Publisher / Support access. */
  adminTournaments: Maybe<AdminTournaments>;
  /** Get a list of B2B environments */
  b2bEnvironments: Maybe<Array<Maybe<B2bEnvironment>>>;
  challenges: Maybe<Array<Maybe<Challenge>>>;
  /** Fetch a CodeBuild project */
  codeBuildEnvironment: Maybe<CodeBuildEnvironment>;
  /** List active coin buckets for user */
  coinBuckets: Maybe<CoinBucketsOutput>;
  /**
   * Get the user transactions using optional parameters to filter
   * @deprecated This resolver has been deprecated and should no longer be used. Use 'transactions' instead.
   */
  coinTransactions: Maybe<CoinTransactionsOutput>;
  /** List coin wallets for user */
  coinWallets: Maybe<CoinWalletsOutput>;
  /** This resolver is used to fetch the configuration options for user. */
  config: Maybe<ConfigInfo>;
  contests: Maybe<ContestsOutput>;
  continuePlaying: Maybe<Array<Maybe<ContinuePlayingActivity>>>;
  /**
   * Fetch the count of filtered moments.
   * This can be used before calling listMoments to know the number of Moments that listMoments will return.
   */
  countMomentsByFilter: Maybe<MomentCount>;
  /** Deploy a CodePipeline project */
  deployPipeline: Maybe<Default>;
  eventRules: Maybe<Array<Maybe<EventRule>>>;
  featureFlags: Maybe<FeatureFlags>;
  /** Fetch a single moment. */
  fetchMoment: Maybe<Moment>;
  fetchStreamingEndpoint: Maybe<StreamingEndpoint>;
  /** Fetch user by username. */
  findUser: Maybe<Array<Maybe<PublicUser>>>;
  /**
   * Fetch user by username as an admin.
   * This resolver lets you fetch full user data of any user provided the logged-in has admin privileges.
   * This is different from the `findUser` resolver since it fetches the full profile instead of the public profile.
   */
  findUserAsAdmin: Maybe<Array<Maybe<UserV2>>>;
  /** Search for a user's permissions based on his id, username or email */
  findUserPermissions: Maybe<FindUserPermissionsOutput>;
  /** List all the suggested users for the user */
  followSuggestions: Maybe<FollowSuggestionOutput>;
  /** List all the users following the specified user, or caller when userId is not specified */
  followers: Maybe<PaginatedFollowerOutput>;
  /** List all the users the specified user is following, or caller when userId is not specified */
  following: Maybe<PaginatedFollowerOutput>;
  /**
   * This deprecated resolver is used to fetch the configuration options for user.
   * @deprecated Use 'config' resolver instead
   */
  getConfig: Maybe<ConfigInfo>;
  /** Fetch a single game. */
  getGame: Maybe<Game>;
  /**
   * Fetch a game session.
   * Requires either a game session or edge node id.
   */
  getGameSessionV2: Maybe<GameSessionV2>;
  /** Fetch details of a house battle. */
  getHouseMatch: Maybe<Match>;
  /** Fetch details of a single match. */
  getMatch: Maybe<Match>;
  /** This returns PercentScoreReview of gamesessions filtered by the given App ID and Moment ID. */
  getPercentScoreReviewed: Maybe<GetPercentScoreReviewedOutput>;
  /** Fetch promo details. */
  getPromo: Maybe<Promo>;
  /** Fetch details of a single tournament. */
  getTournament: Maybe<TournamentInfo>;
  /**
   * Fetch user data as an admin.
   * This resolver lets you fetch full user data of any user provided the logged-in has admin privileges.
   * This is different from the `getUserProfile` resolver since it fetches the full profile instead of the public profile.
   */
  getUserAsAdmin: Maybe<UserV2>;
  /** Gives all the major elements for the discover page in one resolver */
  getUserFeed: Maybe<Array<Maybe<UserFeedGame>>>;
  /** Fetch user's metadata */
  getUserMetadata: Maybe<UserMetadata>;
  /** This returns a list of all the user's most played games. */
  getUserMostPlayedGames: Maybe<Array<Maybe<NGame>>>;
  /**
   * Fetch user profile.
   * This resolver can be used to fetch profiles of all users compared to the `getUserV2` resolver which only fetches the profile of the logged-in user.
   * Note that only the public profile of the other users will be returned with limited properties.
   */
  getUserProfile: Maybe<UserProfile>;
  /**
   * Fetch the full profile of the logged-in user.
   * 'firstTime' field will not work appropriately if 'checkinUser' is called before 'getUserV2'.
   */
  getUserV2: Maybe<UserInfo>;
  /** Fetch user wallets. */
  getUserWallets: Maybe<Array<Maybe<CoinWallet>>>;
  /**
   * Fetch the welcome coins status
   * @deprecated Replaced by the `unseenRewardTransactions` field in `getUserV2` resolver.
   */
  getWelcomeCoinsStatus: Maybe<WelcomeCoinsStatus>;
  /** Fetch withdrawal details. */
  getWithdrawal: Maybe<Withdrawal>;
  globalLeaderboard: Maybe<GlobalLeaderboardItem>;
  /** List all inventories */
  inventories: Maybe<PaginatedInventoryOutput>;
  /** Fetch legacy user transactions. */
  legacyTransactions: Maybe<TransactionsOutput>;
  /** Fetch all the games with the attribute of canUseCash, This is a resolver for the banking tool. */
  listAllGames: Maybe<Array<Maybe<NAllGame>>>;
  listApps: Maybe<Array<Maybe<App>>>;
  listAppsV2: Maybe<Array<Maybe<Game>>>;
  /** Fetch Battle Statuses */
  listBattleStatuses: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Fetch Bonus Types */
  listBonusTypes: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** List cheater contact */
  listCheaterContact: Maybe<PaginatedCheaterContactOutput>;
  /** Fetch Coin Purchase Statuses */
  listCoinPurchaseStatuses: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** @deprecated friend system has been replaced by follow system. See 'followers' instead */
  listFriendsReceivedV2: Maybe<Array<Maybe<UserFriendRequest>>>;
  /** @deprecated friend system has been replaced by follow system. See 'following' instead */
  listFriendsSentV2: Maybe<Array<Maybe<UserFriendRequest>>>;
  /** @deprecated friend system has been replaced by follow system. See 'following'/'followers' instead. */
  listFriendsV2: Maybe<Array<Maybe<UserFriend>>>;
  /** This returns a list of gamesessions filtered by the given App ID, Moment ID, User ID and Score Reviewed. */
  listGameSessionsWithFilters: Maybe<
    Array<Maybe<ListGameSessionsWithFiltersOutput>>
  >;
  /**
   * Fetch games based on a array of statuses.
   * This is a more efficient resolver when compared to `listAppsV2` because it fetches all field level data in one go rather than using sub-resolvers.
   */
  listGames: Maybe<Array<Maybe<NGame>>>;
  listGamesByCategories: Maybe<GamesByCategories>;
  /** Fetch hot battles that can be played by the user. */
  listHotBattles: Maybe<Array<Maybe<HotBattle>>>;
  /** Fetch user's matches. */
  listMatches: Maybe<ListMatchesOutput>;
  /** This returns a list of moments filtered by App ID, Snapshot ID, Status, Moment Type */
  listMoments: Maybe<Array<Maybe<Moment>>>;
  /** This returns a list of moments filtered by the given App ID and Moment Status. */
  listMomentsV2: Maybe<Array<Maybe<Moment>>>;
  /** List available permission groups */
  listPermissionGroups: Maybe<Array<Maybe<PermissionGroup>>>;
  /** List promo winners. */
  listPromoWinners: Maybe<Array<Maybe<PublicUser>>>;
  /** List active promos. */
  listPromos: Maybe<Array<Maybe<Promo>>>;
  listRegionalAllowances: Maybe<Array<Maybe<RegionalAllowance>>>;
  /** List settings */
  listSettings: Maybe<Setting>;
  /** List featured tournaments */
  listTournament: Maybe<Array<Maybe<TournamentInfo>>>;
  /** Fetch the list of user activities. */
  listUserActivities: Maybe<Array<Maybe<UserActivity>>>;
  /** @deprecated Cash has been removed. */
  listUserAllowances: Maybe<Array<Maybe<UserAllowance>>>;
  /** list user game skills */
  listUserGameSkills: Maybe<Array<Maybe<ListUserGameSkillsOutput>>>;
  /**
   * Fetch user transactions.
   * @deprecated Use 'transactions' and 'legacyTransactions' resolvers instead.
   */
  listUserTransactions: Maybe<Array<Maybe<FullTransaction>>>;
  /** List users that have the specified permissions */
  listUsersByPermissionGroup: Maybe<Array<Maybe<UserV2>>>;
  /** This returns a list of all users, it requires Publisher or Editorial permissions. */
  listUsersWithPagination: Maybe<Array<Maybe<User>>>;
  /** Fetch user wallets by currency. */
  listWalletsByCurrency: Maybe<Array<Maybe<CoinWallet>>>;
  /** Fetch Withdrawal Statuses */
  listWithdrawalStatuses: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** List all the active livestreams */
  livestreams: Maybe<LivestreamsPayload>;
  loginRewards: Maybe<Array<Maybe<LoginRewardProgress>>>;
  messageQueue: Maybe<Array<Maybe<Message>>>;
  /** Get deployment status of a CodePipeline project */
  pipelineDeployStatus: Maybe<DeployStatus>;
  /**
   * Search through games, tournaments, friends and users,
   * @deprecated This resolver has been deprecated and should no longer be used. Use 'users' instead.
   */
  search: Maybe<SearchGameTournamentPlayer>;
  /** Fetch user transactions */
  transactions: Maybe<TransactionsOutput>;
  /** Fetch user house battles */
  userHouseBattles: Maybe<Array<Maybe<HotBattle>>>;
  /** Get user inventories */
  userInventories: Maybe<PaginatedInventoryOutput>;
  /** Fetch user notifications */
  userNotifications: Maybe<Array<Maybe<UserNotification>>>;
  /** Search users by username */
  users: Maybe<UsersOutput>;
};

export type QueryAchievementsArgs = {
  achievementsInput: InputMaybe<AchievementsInput>;
};

export type QueryActivitiesArgs = {
  activitiesInput: InputMaybe<ActivitiesInput>;
};

export type QueryAdminFetchTransactionsArgs = {
  adminFetchTransactionsInput: InputMaybe<AdminFetchTransactionsInput>;
};

export type QueryAdminGamesArgs = {
  adminGamesInput: InputMaybe<PaginationInput>;
};

export type QueryAdminListBattlePaymentsArgs = {
  adminListBattlePaymentsInput: InputMaybe<AdminListBattlePaymentsInput>;
};

export type QueryAdminListBonusesArgs = {
  adminListBonusesInput: InputMaybe<AdminListBonusesInput>;
};

export type QueryAdminListCoinPurchasesArgs = {
  adminListCoinPurchasesInput: InputMaybe<AdminListCoinPurchasesInput>;
};

export type QueryAdminListDepositsArgs = {
  adminListDepositsInput: InputMaybe<AdminListDepositsInput>;
};

export type QueryAdminListInternalTransactionsArgs = {
  adminListInternalTransactionsInput: InputMaybe<AdminListInternalTransactionsInput>;
};

export type QueryAdminListPromotionsArgs = {
  adminListPromotionsInput: InputMaybe<AdminListPromotionsInput>;
};

export type QueryAdminListTournamentTypeDetailsArgs = {
  adminListTournamentTypeDetailsInput: InputMaybe<AdminListTournamentTypeDetailsInput>;
};

export type QueryAdminListTransactionsWithPaginationArgs = {
  adminListTransactionsWithPaginationInput: InputMaybe<AdminListTransactionsWithPaginationInput>;
};

export type QueryAdminListUsersArgs = {
  adminListUsersInput: InputMaybe<AdminListUsersInput>;
};

export type QueryAdminListWalletsWithPaginationArgs = {
  adminListWalletsWithPaginationInput: InputMaybe<AdminListWalletsWithPaginationInput>;
};

export type QueryAdminListWithdrawalsArgs = {
  adminListWithdrawalsInput: InputMaybe<AdminListWithdrawalsInput>;
};

export type QueryAdminTournamentsArgs = {
  adminTournamentsInput: ListAllTournamentInput;
};

export type QueryCodeBuildEnvironmentArgs = {
  getCodeBuildEnvironmentInput: GetCodeBuildEnvironmentInput;
};

export type QueryCoinTransactionsArgs = {
  coinTransactionsInput: InputMaybe<ListTransactionsInput>;
};

export type QueryCountMomentsByFilterArgs = {
  countMomentsWithPaginationInput: InputMaybe<CountMomentsWithPaginationInput>;
};

export type QueryDeployPipelineArgs = {
  codeBuildEnvironment: GetCodeBuildEnvironmentInput;
};

export type QueryFetchMomentArgs = {
  fetchMomentInput: InputMaybe<FetchMomentInput>;
};

export type QueryFindUserArgs = {
  findUserInput: InputMaybe<FindUserInput>;
};

export type QueryFindUserAsAdminArgs = {
  findUserInput: InputMaybe<FindUserAsAdminInput>;
};

export type QueryFindUserPermissionsArgs = {
  findUserPermissionsInput: InputMaybe<FindUserPermissionsInput>;
};

export type QueryFollowersArgs = {
  followersInput: InputMaybe<FollowersInput>;
};

export type QueryFollowingArgs = {
  followingInput: InputMaybe<FollowersInput>;
};

export type QueryGetGameArgs = {
  getGameInput: InputMaybe<GetGameInput>;
};

export type QueryGetGameSessionV2Args = {
  getGameSessionInput: InputMaybe<GetGameSessionInput>;
};

export type QueryGetHouseMatchArgs = {
  matchId: Scalars["String"]["input"];
};

export type QueryGetMatchArgs = {
  getMatchInput: LightMatchInput;
};

export type QueryGetPercentScoreReviewedArgs = {
  getPercentScoreReviewedInput: InputMaybe<GetPercentScoreReviewedInput>;
};

export type QueryGetPromoArgs = {
  getPromoInput: InputMaybe<GetPromoInput>;
};

export type QueryGetTournamentArgs = {
  getTournamentInput: InputMaybe<GetTournamentInput>;
};

export type QueryGetUserAsAdminArgs = {
  getUserAsAdminInput: InputMaybe<GetUserAsAdminInput>;
};

export type QueryGetUserMostPlayedGamesArgs = {
  getUserMostPlayedGamesInput: InputMaybe<GetUserMostPlayedGamesInput>;
};

export type QueryGetUserProfileArgs = {
  userProfileInput: UserProfileInput;
};

export type QueryGetUserWalletsArgs = {
  getUserWalletsInput: InputMaybe<GetUserWalletsInput>;
};

export type QueryGetWithdrawalArgs = {
  getWithdrawalInput: InputMaybe<GetWithdrawalInput>;
};

export type QueryGlobalLeaderboardArgs = {
  globalLeaderboardInput: InputMaybe<GlobalLeaderboardInput>;
};

export type QueryInventoriesArgs = {
  inventoriesInput: InputMaybe<InventoriesInput>;
};

export type QueryLegacyTransactionsArgs = {
  listLegacyTransactionsInput: InputMaybe<TransactionsInput>;
};

export type QueryListAppsArgs = {
  listAppsWithPaginationInput: InputMaybe<ListAppsWithPaginationInput>;
};

export type QueryListAppsV2Args = {
  status: Status;
};

export type QueryListCheaterContactArgs = {
  listCheaterContactInput: InputMaybe<ListCheaterContactInput>;
};

export type QueryListFriendsV2Args = {
  listFriendsInput: InputMaybe<ListFriendsInput>;
};

export type QueryListGameSessionsWithFiltersArgs = {
  listGameSessionWithFiltersInput: InputMaybe<ListGameSessionWithFiltersInput>;
};

export type QueryListGamesArgs = {
  status: Array<InputMaybe<GameStatus>>;
};

export type QueryListMatchesArgs = {
  listMatchesInput: ListMatchesInput;
};

export type QueryListMomentsArgs = {
  listMomentsWithPaginationInput: InputMaybe<ListMomentsWithPaginationInput>;
};

export type QueryListMomentsV2Args = {
  appId: Scalars["String"]["input"];
  status: InputMaybe<Array<InputMaybe<Status>>>;
};

export type QueryListPromoWinnersArgs = {
  getPromoInput: InputMaybe<GetPromoInput>;
};

export type QueryListTournamentArgs = {
  listTournamentInput: InputMaybe<ListTournamentInput>;
};

export type QueryListUserActivitiesArgs = {
  getUserActivitiesInput: InputMaybe<GetUserActivitiesInput>;
};

export type QueryListUserGameSkillsArgs = {
  listUserGameSkillsInput: InputMaybe<ListUserGameSkillsInput>;
};

export type QueryListUserTransactionsArgs = {
  listTransactionsInput: InputMaybe<ListTransactionsInput>;
};

export type QueryListUsersByPermissionGroupArgs = {
  listUsersByPermissionGroupInput: InputMaybe<ListUsersByPermissionGroupInput>;
};

export type QueryListUsersWithPaginationArgs = {
  listUsersWithPaginationInput: InputMaybe<ListUsersWithPaginationInput>;
};

export type QueryListWalletsByCurrencyArgs = {
  listWalletsByCurrencyInput: InputMaybe<ListWalletsByCurrencyInput>;
};

export type QueryLivestreamsArgs = {
  livestreamFilters: InputMaybe<LivestreamsFilterInput>;
};

export type QueryPipelineDeployStatusArgs = {
  codeBuildEnvironment: GetCodeBuildEnvironmentInput;
};

export type QuerySearchArgs = {
  searchInput: InputMaybe<SearchInput>;
};

export type QueryTransactionsArgs = {
  listTransactionsInput: InputMaybe<TransactionsInput>;
};

export type QueryUserHouseBattlesArgs = {
  restrictToRecommended: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryUserInventoriesArgs = {
  userInventoriesInput: InputMaybe<PaginationInput>;
};

export type QueryUsersArgs = {
  searchUsersInput: SearchUsersInput;
};

export enum RankOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type RegionalAllowance = {
  __typename: "RegionalAllowance";
  /** Custom bonus types configured for the regional allowance */
  bonusTypes: Maybe<Array<Maybe<BonusTypeDetail>>>;
  /** Cash allowance details for country/region */
  cashGameAllowance: Maybe<CashAllowance>;
  /** Coin allowance details for country/region */
  coinShopAllowance: Maybe<CoinAllowance>;
  /** Country Code for the Allowance */
  country: Maybe<Scalars["String"]["output"]>;
  /** Minimum skill needed for a game to use cash for country/region */
  minSkill: Maybe<Scalars["Float"]["output"]>;
  /** Region Code for the Allowance */
  region: Maybe<Scalars["String"]["output"]>;
};

/** Response for `rejectBattleInvitation` mutation. */
export type RejectBattleInvitationOutput = {
  __typename: "RejectBattleInvitationOutput";
  /** Updated wallets of the user. */
  updatedWallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** Input data to remind opponents to play the match. */
export type RemindOpponentsToPlayInput = {
  /** Match ID */
  matchId: Scalars["String"]["input"];
};

/** Input data for removing tournament type details */
export type RemoveTournamentTypeDetailsInput = {
  /** Tournament Type Detail ID */
  id: Scalars["ID"]["input"];
};

export type ReviewGameSessionInput = {
  gameSessionId: Scalars["String"]["input"];
  scoreReviewed: InputMaybe<ScoreReviewed>;
  scoreReviewedFault: InputMaybe<ScoreReviewedFault>;
  scoreValidated: InputMaybe<Scalars["Int"]["input"]>;
};

/** Denotes the orientation required by a game. */
export enum RotationMode {
  Any = "ANY",
  Landscape = "LANDSCAPE",
  Portrait = "PORTRAIT",
}

/** Denotes Setting Keys */
export enum Setting_Keys {
  AcceptableRangeForHouseBattle = "ACCEPTABLE_RANGE_FOR_HOUSE_BATTLE",
  AntiCheatEnabled = "ANTI_CHEAT_ENABLED",
  BattleCoinPayouts = "BATTLE_COIN_PAYOUTS",
  BattleRewardCurrency = "BATTLE_REWARD_CURRENCY",
  CashAllowed = "CASH_ALLOWED",
  ChallengeRewards = "CHALLENGE_REWARDS",
  DefaultMinBattle = "DEFAULT_MIN_BATTLE",
  EnvironmentsBaseUrl = "ENVIRONMENTS_BASE_URL",
  FreeCoinsEnabled = "FREE_COINS_ENABLED",
  HomepageBeatitGames = "HOMEPAGE_BEATIT_GAMES",
  HomepageChallengeGames = "HOMEPAGE_CHALLENGE_GAMES",
  LeaderboardFakeUsersPerDay = "LEADERBOARD_FAKE_USERS_PER_DAY",
  LeaderboardMaxFakeScorePerDay = "LEADERBOARD_MAX_FAKE_SCORE_PER_DAY",
  LoginRewards = "LOGIN_REWARDS",
  OnmoPayApiKey = "ONMO_PAY_API_KEY",
  OnmoSubscriptionEncryptionKey = "ONMO_SUBSCRIPTION_ENCRYPTION_KEY",
  OnmoSubscriptionLinkMsisdn = "ONMO_SUBSCRIPTION_LINK_MSISDN",
  OnmoSubscriptionNextChargeDate = "ONMO_SUBSCRIPTION_NEXT_CHARGE_DATE",
  OnmoSubscriptionResumeParams = "ONMO_SUBSCRIPTION_RESUME_PARAMS",
  OnmoSubscriptionResumePassword = "ONMO_SUBSCRIPTION_RESUME_PASSWORD",
  OnmoSubscriptionResumeUrl = "ONMO_SUBSCRIPTION_RESUME_URL",
  OnmoSubscriptionResumeUsername = "ONMO_SUBSCRIPTION_RESUME_USERNAME",
  OnmoSubscriptionSignaturePassword = "ONMO_SUBSCRIPTION_SIGNATURE_PASSWORD",
  OnmoSubscriptionSignatureUsername = "ONMO_SUBSCRIPTION_SIGNATURE_USERNAME",
  OnmoSubscriptionStatusParams = "ONMO_SUBSCRIPTION_STATUS_PARAMS",
  OnmoSubscriptionStatusUrl = "ONMO_SUBSCRIPTION_STATUS_URL",
  OnmoSubscriptionUserIdField = "ONMO_SUBSCRIPTION_USER_ID_FIELD",
  OnmoSubscriptionUseOpsign = "ONMO_SUBSCRIPTION_USE_OPSIGN",
  OnmoSubscriptionValidateRefid = "ONMO_SUBSCRIPTION_VALIDATE_REFID",
  ShowClosedHotbattles = "SHOW_CLOSED_HOTBATTLES",
  StreamingStartBitrate = "STREAMING_START_BITRATE",
  StreamingVendor = "STREAMING_VENDOR",
  StreamingVendorOverflow = "STREAMING_VENDOR_OVERFLOW",
  TierLevels = "TIER_LEVELS",
  Timezone = "TIMEZONE",
}

export type ScoreAttribute = {
  __typename: "ScoreAttribute";
  coins: Maybe<Scalars["String"]["output"]>;
};

export type ScoreAttributeInput = {
  score: InputMaybe<Scalars["String"]["input"]>;
  type: InputMaybe<Scalars["String"]["input"]>;
};

export type ScoreInput = {
  gameId: InputMaybe<Scalars["String"]["input"]>;
  momentId: InputMaybe<Scalars["String"]["input"]>;
  pageNo: InputMaybe<Scalars["Int"]["input"]>;
  pageSize: InputMaybe<Scalars["Int"]["input"]>;
  sort: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
};

export enum ScoreReviewed {
  Bad = "BAD",
  Critical = "CRITICAL",
  Good = "GOOD",
}

export enum ScoreReviewedFault {
  Filter = "FILTER",
  Ocr = "OCR",
}

/** Denotes the response of a search resolver */
export type SearchGameTournamentPlayer = {
  __typename: "SearchGameTournamentPlayer";
  /**
   * List of tournaments matching search string
   * @deprecated This field has been deprecated and should no longer be used.
   */
  friends: Maybe<Array<Maybe<Friend>>>;
  /**
   * List of games matching search string
   * @deprecated This field has been deprecated and should no longer be used.
   */
  games: Maybe<Array<Maybe<Game>>>;
  /**
   * List of users matching search string
   * @deprecated This field has been deprecated and should no longer be used.
   */
  users: Maybe<Array<Maybe<SearchUser>>>;
};

export type SearchInput = {
  search: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes the public profile of a user with limited fields. */
export type SearchUser = PublicUserInterface & {
  __typename: "SearchUser";
  /** Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /**
   * User cheating gamesessions count
   * @deprecated `cheatSessions` are not required anymore.
   */
  cheatSessions: Maybe<Scalars["Int"]["output"]>;
  /** Follow status between a user and the caller */
  followStatus: Maybe<FollowStatus>;
  /**
   * ID of the friend request. `null` if doesn't exist
   * @deprecated friend system has been replaced by follow system, use followStatus instead
   */
  friendRequestId: Maybe<Scalars["String"]["output"]>;
  /**
   * Status of the friend request, if sent or received
   * @deprecated friend system has been replaced by follow system, use followStatus instead
   */
  friendStatus: FriendStatus;
  /** User ID */
  id: Scalars["String"]["output"];
  /** Username unique to the user. */
  username: Maybe<Scalars["String"]["output"]>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Denotes teh input parameter of users resolver. */
export type SearchUsersInput = {
  /** Limit */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Offset */
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** IDs of users to fetch */
  userIds: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Username to be searched. */
  username: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchableBooleanFilterInput = {
  eq: InputMaybe<Scalars["Boolean"]["input"]>;
  ne: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SearchableFloatFilterInput = {
  eq: InputMaybe<Scalars["Float"]["input"]>;
  gt: InputMaybe<Scalars["Float"]["input"]>;
  gte: InputMaybe<Scalars["Float"]["input"]>;
  lt: InputMaybe<Scalars["Float"]["input"]>;
  lte: InputMaybe<Scalars["Float"]["input"]>;
  ne: InputMaybe<Scalars["Float"]["input"]>;
  range: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
};

export type SearchableIdFilterInput = {
  eq: InputMaybe<Scalars["ID"]["input"]>;
  exists: InputMaybe<Scalars["Boolean"]["input"]>;
  gt: InputMaybe<Scalars["ID"]["input"]>;
  gte: InputMaybe<Scalars["ID"]["input"]>;
  lt: InputMaybe<Scalars["ID"]["input"]>;
  lte: InputMaybe<Scalars["ID"]["input"]>;
  match: InputMaybe<Scalars["ID"]["input"]>;
  matchPhrase: InputMaybe<Scalars["ID"]["input"]>;
  matchPhrasePrefix: InputMaybe<Scalars["ID"]["input"]>;
  multiMatch: InputMaybe<Scalars["ID"]["input"]>;
  ne: InputMaybe<Scalars["ID"]["input"]>;
  range: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  regexp: InputMaybe<Scalars["ID"]["input"]>;
  wildcard: InputMaybe<Scalars["ID"]["input"]>;
};

export type SearchableIntFilterInput = {
  eq: InputMaybe<Scalars["Int"]["input"]>;
  gt: InputMaybe<Scalars["Int"]["input"]>;
  gte: InputMaybe<Scalars["Int"]["input"]>;
  lt: InputMaybe<Scalars["Int"]["input"]>;
  lte: InputMaybe<Scalars["Int"]["input"]>;
  ne: InputMaybe<Scalars["Int"]["input"]>;
  range: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export type SearchableStringFilterInput = {
  eq: InputMaybe<Scalars["String"]["input"]>;
  exists: InputMaybe<Scalars["Boolean"]["input"]>;
  gt: InputMaybe<Scalars["String"]["input"]>;
  gte: InputMaybe<Scalars["String"]["input"]>;
  lt: InputMaybe<Scalars["String"]["input"]>;
  lte: InputMaybe<Scalars["String"]["input"]>;
  match: InputMaybe<Scalars["String"]["input"]>;
  matchPhrase: InputMaybe<Scalars["String"]["input"]>;
  matchPhrasePrefix: InputMaybe<Scalars["String"]["input"]>;
  multiMatch: InputMaybe<Scalars["String"]["input"]>;
  ne: InputMaybe<Scalars["String"]["input"]>;
  range: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  regexp: InputMaybe<Scalars["String"]["input"]>;
  wildcard: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchableUserConnection = {
  __typename: "SearchableUserConnection";
  items: Maybe<Array<Maybe<User>>>;
  nextToken: Maybe<Scalars["String"]["output"]>;
  total: Maybe<Scalars["Int"]["output"]>;
};

export type SerializedTransaction = {
  __typename: "SerializedTransaction";
  amount: Maybe<Scalars["Float"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  wallet: Maybe<Scalars["String"]["output"]>;
};

/** Denotes the response for list settings resolver */
export type Setting = {
  __typename: "Setting";
  ACCEPTABLE_RANGE_FOR_HOUSE_BATTLE: Maybe<SettingDataInt>;
  ANTI_CHEAT_ENABLED: Maybe<SettingDataBoolean>;
  BATTLE_COIN_PAYOUTS: Maybe<SettingDataObjectArray>;
  BATTLE_REWARD_CURRENCY: Maybe<SettingDataString>;
  CASH_ALLOWED: Maybe<SettingDataBoolean>;
  CHALLENGE_REWARDS: Maybe<SettingDataJson>;
  DEFAULT_MIN_BATTLE: Maybe<SettingDataInt>;
  ENVIRONMENTS_BASE_URL: Maybe<SettingDataJson>;
  FREE_COINS_ENABLED: Maybe<SettingDataBoolean>;
  HOMEPAGE_BEATIT_GAMES: Maybe<SettingDataStringArray>;
  HOMEPAGE_CHALLENGE_GAMES: Maybe<SettingDataStringArray>;
  LEADERBOARD_FAKE_USERS_PER_DAY: Maybe<SettingDataInt>;
  LEADERBOARD_MAX_FAKE_SCORE_PER_DAY: Maybe<SettingDataInt>;
  LOGIN_REWARDS: Maybe<SettingDataJson>;
  ONMO_PAY_API_KEY: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_ENCRYPTION_KEY: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_LINK_MSISDN: Maybe<SettingDataBoolean>;
  ONMO_SUBSCRIPTION_NEXT_CHARGE_DATE: Maybe<SettingDataInt>;
  ONMO_SUBSCRIPTION_RESUME_PARAMS: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_RESUME_PASSWORD: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_RESUME_URL: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_RESUME_USERNAME: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_STATUS_PARAMS: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_STATUS_URL: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_USER_ID_FIELD: Maybe<SettingDataString>;
  ONMO_SUBSCRIPTION_USE_OPSIGN: Maybe<SettingDataBoolean>;
  ONMO_SUBSCRIPTION_VALIDATE_REFID: Maybe<SettingDataBoolean>;
  SHOW_CLOSED_HOTBATTLES: Maybe<SettingDataBoolean>;
  STREAMING_START_BITRATE: Maybe<SettingDataInt>;
  STREAMING_VENDOR: Maybe<SettingDataString>;
  STREAMING_VENDOR_OVERFLOW: Maybe<SettingDataBoolean>;
  TIER_LEVELS: Maybe<SettingDataObjectArray>;
  TIMEZONE: Maybe<SettingDataString>;
};

export type SettingDataBoolean = {
  __typename: "SettingDataBoolean";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["Boolean"]["output"]>;
};

export type SettingDataInt = {
  __typename: "SettingDataInt";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["Int"]["output"]>;
};

export type SettingDataJson = {
  __typename: "SettingDataJSON";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["JSONObject"]["output"]>;
};

export type SettingDataObjectArray = {
  __typename: "SettingDataObjectArray";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Array<Maybe<Scalars["JSONObject"]["output"]>>>;
};

export type SettingDataString = {
  __typename: "SettingDataString";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Scalars["String"]["output"]>;
};

export type SettingDataStringArray = {
  __typename: "SettingDataStringArray";
  default: Maybe<Scalars["Boolean"]["output"]>;
  value: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type ShareableUrl = {
  __typename: "ShareableUrl";
  /** Shareable URL */
  url: Scalars["String"]["output"];
};

/** Input for starting a new livestream */
export type StartLivestreamInput = {
  /** Game Session ID */
  gameSessionId: Scalars["String"]["input"];
  /** WebSocket Connection ID */
  wsConnectionId: Scalars["String"]["input"];
};

/** Input data to start a match. */
export type StartMatchInput = {
  /** Cost to start the match. */
  cost: InputMaybe<Scalars["Float"]["input"]>;
  /** Currency of the match. */
  currency: InputMaybe<Scalars["String"]["input"]>;
  /** Information about the user's device. */
  device: UserDeviceInput;
  /** Game ID */
  gameId: Scalars["String"]["input"];
  /** Invitation Code to be associated with match. */
  inviteCode: InputMaybe<Scalars["String"]["input"]>;
  /** Replay match id */
  matchId: InputMaybe<Scalars["String"]["input"]>;
  /** List of opponent user IDs. */
  opponents: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Streaming game match streamingRegion */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data to start a promo battle. */
export type StartPromoInput = {
  /** Information about the user's device. */
  device: UserDeviceInput;
  /** Promo ID */
  promoId: Scalars["String"]["input"];
  /** Region for streaming promo battle */
  streamingRegion: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes the status of the moment. */
export enum Status {
  Disabled = "DISABLED",
  Draft = "DRAFT",
  Live = "LIVE",
  Upcoming = "UPCOMING",
}

export type StoreItem = {
  __typename: "StoreItem";
  available_at: Maybe<Scalars["AWSDateTime"]["output"]>;
  created_at: Maybe<Scalars["AWSDateTime"]["output"]>;
  currency: Scalars["String"]["output"];
  definition: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  expired_at: Maybe<Scalars["AWSDateTime"]["output"]>;
  icon: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  price: Scalars["Float"]["output"];
  store_item_category: Maybe<StoreItemCategory>;
  store_item_category_id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updated_at: Maybe<Scalars["AWSDateTime"]["output"]>;
};

export type StoreItemCategory = {
  __typename: "StoreItemCategory";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  store_items: Maybe<Array<Maybe<StoreItem>>>;
};

export type StreamingEndpoint = {
  __typename: "StreamingEndpoint";
  streamingUrl: Maybe<Scalars["String"]["output"]>;
};

export type Subscription = {
  __typename: "Subscription";
  /** @deprecated friend system has been replaced by follow system. */
  acceptedFriendRequest: Maybe<Friend>;
  battleCompleted: Maybe<BattleCompleted>;
  coinWalletsUpdated: Maybe<CoinWalletsUpdatedEvent>;
  /** @deprecated friend system has been replaced by follow system. */
  deletedFriend: Maybe<UserFriendRequest>;
  depositBonusPaid: Maybe<DepositBonusPaid>;
  endScreen: Maybe<EndScreen>;
  friendRequestBattle: Maybe<PlayMatch>;
  /** @deprecated friend system has been replaced by follow system. */
  friendRequestCreated: Maybe<Friend>;
  livestreamUpdated: Maybe<Livestream>;
  newSubscriptionTransaction: Maybe<TransactionUpdate>;
  userNotificationCreated: Maybe<UserNotification>;
  walletUpdated: Maybe<WalletUpdatedEvent>;
};

export enum SubscriptionIdField {
  U = "u",
  Umrid = "umrid",
}

/** Denotes tournament categories */
export enum Tournament_Category {
  Beatit = "BEATIT",
  Bonus = "BONUS",
  Discover = "DISCOVER",
  Featured = "FEATURED",
  Practice = "PRACTICE",
}

export enum Tournament_Status {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Completed = "COMPLETED",
  Open = "OPEN",
  Reviewed = "REVIEWED",
}

export enum Tournament_Type {
  Basic = "BASIC",
  PracticeHtml = "PRACTICE_HTML",
  PracticeStream = "PRACTICE_STREAM",
  Pro = "PRO",
  ProPercent = "PRO_PERCENT",
  Rookie = "ROOKIE",
  RookiePercent = "ROOKIE_PERCENT",
}

/** Denotes the type of a transaction */
export enum Transaction_Type {
  Credit = "credit",
  Debit = "debit",
}

/** Temporary login token for test users */
export type TestLoginToken = {
  __typename: "TestLoginToken";
  /** Temporary login token for test users. Valid for 15 minutes. */
  token: Scalars["String"]["output"];
};

export type TestLoginTokenInput = {
  /** userId should have `tester-` as prefix for any name given */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes a Tier Level. */
export type TierLevel = {
  __typename: "TierLevel";
  /** Amount of the tier level */
  amount: Maybe<Scalars["Int"]["output"]>;
  /** ID of the tier level. */
  levelId: Maybe<Scalars["String"]["output"]>;
};

export enum TimeFrame {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

/** Denotes a user wallet earnings for the current day. */
export type TodayWalletEarning = {
  __typename: "TodayWalletEarning";
  /** Type of currency. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Wallet external id. */
  external_id: Maybe<Scalars["String"]["output"]>;
  /** Sum of winnings. */
  totalWinnings: Maybe<Scalars["Float"]["output"]>;
  /** Type of wallet. */
  type: Maybe<Scalars["String"]["output"]>;
  /** Wallet  ID */
  walletId: Maybe<Scalars["ID"]["output"]>;
};

/** Denotes a tournament. */
export type Tournament = {
  __typename: "Tournament";
  /** Cost to play per user (includes fee) */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Currency of tournament */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Tournament end time */
  expiredAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Fee to play (i.e. not included in prize) */
  fee: Maybe<Scalars["Float"]["output"]>;
  /** Game ID of tournament */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Highest score achieved by a player in the tournament */
  highestScore: Maybe<Scalars["Float"]["output"]>;
  /** Tournament ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Tournament leaderboards - List of top players with their scores */
  leaderboards: Maybe<Array<Maybe<TournamentPlayers>>>;
  /** Tournament Moment Data */
  moment: Maybe<Moment>;
  /** Moment ID used for tournament */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Type of Moment used for tournament */
  momentType: Maybe<Scalars["String"]["output"]>;
  /** Currently logged-in user's ranking in the tournament */
  myRank: Maybe<Scalars["Int"]["output"]>;
  /** Order of score ranking */
  rankingOrder: Maybe<Scalars["String"]["output"]>;
  /** Username used to review the tournament */
  reviewedBy: Maybe<Scalars["String"]["output"]>;
  /** Tournament start time */
  startedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Tournament current status */
  status: Maybe<Scalars["String"]["output"]>;
  /** Total number of players who have played in the tournament */
  totalPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Total prize at the moment */
  totalPrize: Maybe<Scalars["Float"]["output"]>;
  /** Type of tournament */
  tournamentType: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a tournament. */
export type TournamentLeaderboardsArgs = {
  getLeaderboardInput: InputMaybe<GetLeaderboardsInput>;
};

/** Denotes a tournament. */
export type TournamentMyRankArgs = {
  getMyRankInput: InputMaybe<GetMyRankInput>;
};

export type TournamentActivity = {
  __typename: "TournamentActivity";
  /** @deprecated tournament activities are removed */
  currentRank: Scalars["Int"]["output"];
  /** @deprecated tournament activities are removed */
  gameId: Scalars["String"]["output"];
  /** @deprecated tournament activities are removed */
  tournamentId: Scalars["String"]["output"];
};

/** Denotes a newly created tournament game session. */
export type TournamentGameSession = {
  __typename: "TournamentGameSession";
  /** The ID of stream node for streaming games, and HTML for HTML games. */
  edgeNodeId: Maybe<Scalars["String"]["output"]>;
  /** New Game Session ID */
  gameSessionId: Maybe<Scalars["String"]["output"]>;
  /**
   * Tournament Entry ID
   * @deprecated This field has been deprecated and should no longer be used. tournamentPlayerId is created when playTournament resolver is called
   */
  tournamentPlayerId: Maybe<Scalars["String"]["output"]>;
  /** Updated user wallets */
  updatedWallets: Maybe<Array<Maybe<CoinWallet>>>;
};

/** General information about the current status of a tournament */
export type TournamentInfo = {
  __typename: "TournamentInfo";
  /** Avatar of the player with the top score at the moment */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Array of prizes, where [0]=rank 1 prize, [1]=rank 2 prize, etc */
  calculatedPrizePool: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  /** Cost to play per user (includes fee) */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Currency of tournament */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Tournament end time */
  expiredAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Fee to play (i.e. not included in prize) */
  fee: Maybe<Scalars["Float"]["output"]>;
  /** Game ID of tournament */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Highest score achieved by a player in the tournament */
  highestScore: Maybe<Scalars["Float"]["output"]>;
  /** Tournament ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Featured banner image for these tournaments */
  imageUrl: Maybe<Scalars["String"]["output"]>;
  /** Tournament leaderboards - List of top players with their scores */
  leaderboards: Maybe<Array<Maybe<TournamentPlayers>>>;
  /** All active livestreams of the tournament. Only available in `getTournament` and `getUserFeed` resolvers */
  livestreams: Maybe<Array<Maybe<Livestream>>>;
  /** Maximum number of plays allowed in this tournament */
  maxPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Tournament Moment Data */
  moment: Maybe<Moment>;
  /** Moment ID used for tournament */
  momentId: Maybe<Scalars["String"]["output"]>;
  /** Type of Moment used for tournament */
  momentType: Maybe<Scalars["String"]["output"]>;
  /** Currently logged-in user's ranking in the tournament */
  myRank: Maybe<Scalars["Int"]["output"]>;
  /** Time the player with the top score played */
  playedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Distinct number of players in the tournament */
  playerCount: Maybe<Scalars["Int"]["output"]>;
  /** Order of score ranking */
  rankingOrder: Maybe<Scalars["String"]["output"]>;
  /** Subresolver that returns all refunds given in this tournament */
  refunds: Maybe<Array<Maybe<TournamentRefund>>>;
  /** Username used to review the tournament */
  reviewedBy: Maybe<Scalars["String"]["output"]>;
  /** Tournament start time */
  startedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Tournament current status */
  status: Maybe<Scalars["String"]["output"]>;
  /** Featured title for these tournaments */
  title: Maybe<Scalars["String"]["output"]>;
  /** Best score at the moment */
  topScore: Maybe<Scalars["Int"]["output"]>;
  /** Total number of players who have played in the tournament */
  totalPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Total prize at the moment */
  totalPrize: Maybe<Scalars["Float"]["output"]>;
  /** Array of categories this tournament belongs to */
  tournamentCategories: Maybe<Array<Maybe<Tournament_Category>>>;
  /** Type of tournament */
  tournamentType: Maybe<Tournament_Type>;
  /** Best score by the user for the tournament */
  userBestScore: Maybe<Scalars["Int"]["output"]>;
  /** Userid of player with the top score at the moment */
  userId: Maybe<Scalars["String"]["output"]>;
  /** Username of the player with the top score at the moment */
  username: Maybe<Scalars["String"]["output"]>;
};

/** General information about the current status of a tournament */
export type TournamentInfoLeaderboardsArgs = {
  getLeaderboardInput: InputMaybe<GetLeaderboardsInput>;
};

/** General information about the current status of a tournament */
export type TournamentInfoMyRankArgs = {
  getMyRankInput: InputMaybe<GetMyRankInput>;
};

export type TournamentPlayedActivity = {
  __typename: "TournamentPlayedActivity";
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  activityType: ActivityType;
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  battleId: Scalars["String"]["output"];
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  battleType: CoinBattleType;
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  data: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  gameId: Scalars["String"]["output"];
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  gameTitle: Scalars["String"]["output"];
  /** @deprecated Use 'BattlePlayedActivity' instead. */
  rank: Scalars["Int"]["output"];
};

export type TournamentPlayers = {
  __typename: "TournamentPlayers";
  /** Tournament player cheat status */
  cheatStatus: Maybe<Scalars["String"]["output"]>;
  /** Tournament ended time */
  endedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Tournament entry id */
  id: Maybe<Scalars["String"]["output"]>;
  /** Tournament score */
  score: Maybe<Scalars["Float"]["output"]>;
  /** Tournament started time */
  startedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Tournament player playing status */
  status: Maybe<Scalars["String"]["output"]>;
  /** Tournament id */
  tournamentId: Maybe<Scalars["String"]["output"]>;
  /** Tournament player */
  user: Maybe<PublicUser>;
  /** Tournament user id */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Refund given for a tournament */
export type TournamentRefund = {
  __typename: "TournamentRefund";
  /** Amount of refund */
  amount: Maybe<Scalars["Float"]["output"]>;
  /** Reason for the refund */
  reason: Maybe<Scalars["String"]["output"]>;
  /** User given the refund */
  userId: Maybe<Scalars["String"]["output"]>;
};

export enum TournamentReviewStatus {
  Pending = "PENDING",
  Reviewed = "REVIEWED",
}

/** TOURNAMENT TYPE DETAILS output type */
export type TournamentTypeDetails = {
  __typename: "TournamentTypeDetails";
  /** Currency to be used for rewarding coin */
  coinType: Maybe<CoinType>;
  /** Cost for each user */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Currency used in the tournament */
  currency: Maybe<Currency>;
  /** Tournament daily start time */
  dailyStartTime: Maybe<Scalars["String"]["output"]>;
  /** Number of hours for tournament */
  durationHours: Maybe<Scalars["Int"]["output"]>;
  /** Number of minutes */
  durationPlusMinus: Maybe<Scalars["Int"]["output"]>;
  /** End date of the tournament type will becomes inactive */
  endDate: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Fee to play the tournament */
  feePerUser: Maybe<Scalars["Float"]["output"]>;
  /** Ids of game associated, if empty array all games will be affected */
  gameIds: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  /** Type of the game */
  gameType: Maybe<Array<Maybe<Game_Type>>>;
  /** Id of the type details */
  id: Maybe<Scalars["String"]["output"]>;
  /** Featured banner image for these tournaments */
  imageUrl: Maybe<Scalars["String"]["output"]>;
  /** Maximum bonus percentage */
  maxBonusPercent: Maybe<Scalars["Int"]["output"]>;
  /** Maximum cash percentage */
  maxCashPercent: Maybe<Scalars["Int"]["output"]>;
  /** Maximum number of players */
  maxPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Maximum random tournament */
  maxRandomTournaments: Maybe<Scalars["Int"]["output"]>;
  /** Minimum number of players */
  minPlayers: Maybe<Scalars["Int"]["output"]>;
  /** Minimum winners */
  minWinners: Maybe<Scalars["Int"]["output"]>;
  /** Prize pool for the tournament */
  prizePool: Maybe<Array<Maybe<PrizePool>>>;
  /** Type of the prize */
  prizeType: Maybe<Prize_Type>;
  /** Start date the type details is added */
  startDate: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** Featured title for these tournaments */
  title: Maybe<Scalars["String"]["output"]>;
  /** Category tournament belong to */
  tournamentCategory: Maybe<Array<Maybe<Tournament_Category>>>;
  /** Type of the tournament */
  tournamentType: Maybe<Tournament_Type>;
};

export type TournamentTypeDetailsFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The tournament type detail's field to filter on */
  field: TournamentTypeDetailsFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list tournament type details on */
export enum TournamentTypeDetailsFilterField {
  /** Currency the tournament type details is in */
  Currency = "currency",
  /** Data of the tournament type details */
  Data = "data",
  /** End date the tournament type details is finish */
  EndDate = "end_date",
  /** identifier for the tournament type details */
  Id = "id",
  /** Start date the tournament type details is available */
  StartDate = "start_date",
  /** Tournament type of the tournament type details */
  TournamentType = "tournament_type",
}

/** Data for tournament notifications */
export type TournamentUserNotificationData = {
  __typename: "TournamentUserNotificationData";
  /** User that replaced the notification's recipient in the tournament */
  demotingUser: Maybe<PublicUser>;
  /** GAME ID */
  gameId: Scalars["String"]["output"];
  /** Rank of the user in that tournament. */
  rank: Scalars["Int"]["output"];
  /** TOURNAMENT ID */
  tournamentId: Scalars["String"]["output"];
};

/** For tracking activities - temporary */
export type TrackActivityInput = {
  activityType: InputMaybe<ActivityType>;
  battleId: InputMaybe<Scalars["String"]["input"]>;
  battleType: InputMaybe<CoinBattleType>;
  gameId: InputMaybe<Scalars["String"]["input"]>;
  prizeAmount: InputMaybe<Scalars["Int"]["input"]>;
  prizeCoinType: InputMaybe<CoinType>;
  rank: InputMaybe<Scalars["Int"]["input"]>;
  userId: InputMaybe<Scalars["String"]["input"]>;
  winner: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Transaction = {
  __typename: "Transaction";
  amount: Maybe<Scalars["Float"]["output"]>;
  currency: Maybe<Scalars["String"]["output"]>;
  date: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  entryId: Maybe<Scalars["String"]["output"]>;
  escrowId: Maybe<Scalars["String"]["output"]>;
  postedOn: Maybe<Scalars["AWSTimestamp"]["output"]>;
  reference: Maybe<Scalars["String"]["output"]>;
  runningBalance: Maybe<Scalars["Float"]["output"]>;
  sequence: Maybe<Scalars["Int"]["output"]>;
  walletId: Maybe<Scalars["String"]["output"]>;
  workflow: Maybe<Scalars["String"]["output"]>;
};

/** Denotes the specific reason for a transaction. */
export enum TransactionActionType {
  AccountAdjusted = "account_adjusted",
  BonusCashReceived = "bonus_cash_received",
  BonusCoinsReceived = "bonus_coins_received",
  CashPaid = "cash_paid",
  CashRefunded = "cash_refunded",
  CashWon = "cash_won",
  CoinsAdjusted = "coins_adjusted",
  CoinsCancelled = "coins_cancelled",
  CoinsExpired = "coins_expired",
  CoinsPaid = "coins_paid",
  CoinsPurchaseFailed = "coins_purchase_failed",
  CoinsPurchased = "coins_purchased",
  CoinsRefunded = "coins_refunded",
  CoinsWon = "coins_won",
  DepositFailed = "deposit_failed",
  DepositPending = "deposit_pending",
  DepositSuccess = "deposit_success",
  Other = "other",
  SubscriptionCoinsReceived = "subscription_coins_received",
  TopupCoinsReceived = "topup_coins_received",
  WithdrawalFailed = "withdrawal_failed",
  WithdrawalPending = "withdrawal_pending",
  WithdrawalSuccess = "withdrawal_success",
}

/** Denotes the overall category of a transaction. */
export enum TransactionCategory {
  Battle = "battle",
  Other = "other",
  Solo = "solo",
  Tournament = "tournament",
  Wallet = "wallet",
}

/** Denotes which field to filter the admin transactions on. */
export enum TransactionFilterField {
  /** Amount of the transaction */
  Amount = "amount",
  /** Currency the transaction is in */
  Currency = "currency",
  /** Date the transaction was completed */
  Date = "date",
  /** Additional information about the transaction */
  Description = "description",
  /** identifier for the transaction */
  EntryId = "entry_id",
  /** Reference link of the transaction */
  Reference = "reference",
  /** Balance of the wallet at the creation of this transaction */
  RunningBalance = "running_balance",
  /** Number used to order the transactions */
  Sequence = "sequence",
  /** Owner of the wallet containing the transaction */
  UserId = "user_id",
  /** Wallet containing the transaction */
  WalletId = "wallet_id",
  /** Which workflow the transition was created for */
  Workflow = "workflow",
}

/**
 * Denotes a Transaction.
 * Used in newSubscriptionTransaction resolver.
 */
export type TransactionUpdate = {
  __typename: "TransactionUpdate";
  /** Amount of the transaction */
  amount: Maybe<Scalars["Int"]["output"]>;
  /** Type of the transaction */
  type: Maybe<Transaction_Type>;
};

/** Denotes the raw workflow of a transaction. */
export enum TransactionWorkflow {
  AdjAddToBonus = "adj_add_to_bonus",
  AdjAddToDeposit = "adj_add_to_deposit",
  AdjAddToVirtual = "adj_add_to_virtual",
  AdjAddToWinnings = "adj_add_to_winnings",
  AdjDoInternalDepositReal = "adj_do_internal_deposit_real",
  AdjDoInternalDepositVirtual = "adj_do_internal_deposit_virtual",
  AdjDoInternalWithdrawalReal = "adj_do_internal_withdrawal_real",
  AdjDoInternalWithdrawalVirtual = "adj_do_internal_withdrawal_virtual",
  AdjInternalTransferReal = "adj_internal_transfer_real",
  AdjInternalTransferVirtual = "adj_internal_transfer_virtual",
  AdjRemoveFromBonus = "adj_remove_from_bonus",
  AdjRemoveFromDeposit = "adj_remove_from_deposit",
  AdjRemoveFromVirtual = "adj_remove_from_virtual",
  AdjRemoveFromWinnings = "adj_remove_from_winnings",
  BattleCancelReal = "battle_cancel_real",
  BattleCancelVirtual = "battle_cancel_virtual",
  BattleJoinReal = "battle_join_real",
  BattleJoinVirtual = "battle_join_virtual",
  BattleRefundReal = "battle_refund_real",
  BattleRefundVirtual = "battle_refund_virtual",
  BattleWinReal = "battle_win_real",
  BattleWinRealFixed = "battle_win_real_fixed",
  BattleWinVirtual = "battle_win_virtual",
  BonusCancel = "bonus_cancel",
  BonusPay = "bonus_pay",
  CancelCoins = "cancel_coins",
  DepositAdd = "deposit_add",
  DepositFailed = "deposit_failed",
  DepositPending = "deposit_pending",
  LoginReward = "login_reward",
  PromotionCancelVirtual = "promotion_cancel_virtual",
  PromotionPayVirtual = "promotion_pay_virtual",
  StorePurchaseCoins = "store_purchase_coins",
  StorePurchaseCoinsAddIncome = "store_purchase_coins_add_income",
  StorePurchaseItemsVirtual = "store_purchase_items_virtual",
  StoreSellItemsVirtual = "store_sell_items_virtual",
  VoidCoinPurchase = "void_coin_purchase",
  VoidCoinPurchaseAddIncome = "void_coin_purchase_add_income",
  VoidDeposit = "void_deposit",
  WithdrawalComplete = "withdrawal_complete",
  WithdrawalFail = "withdrawal_fail",
  WithdrawalStart = "withdrawal_start",
}

/** Filters for fetching transactions. */
export type TransactionsInput = {
  /** Type of wallet. Only available in the `transactions` resolver. */
  coinTypes: InputMaybe<Array<InputMaybe<CoinType>>>;
  /** Number of transactions to be returned. Default to 100. */
  limit: InputMaybe<Scalars["Int"]["input"]>;
  /** Starting index of transactions. Default to 0 (starting index in the table). */
  offset: InputMaybe<Scalars["Int"]["input"]>;
  /** ID of user whose transactions to fetch. The current logged-in user needs Publisher/Editoral/Support permission to use this field. */
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Output from coinTransactions */
export type TransactionsOutput = {
  __typename: "TransactionsOutput";
  /** A list of transactions */
  items: Maybe<Array<Maybe<FullTransaction>>>;
  /** Total count of results */
  totalCount: Scalars["Int"]["output"];
};

export enum Userevent_Notification_Type {
  BattleExpired = "BATTLE_EXPIRED",
  BattleInviteReceived = "BATTLE_INVITE_RECEIVED",
  BattleInviteSent = "BATTLE_INVITE_SENT",
  BattleLost = "BATTLE_LOST",
  BattleRejected = "BATTLE_REJECTED",
  BattleTied = "BATTLE_TIED",
  BattleWon = "BATTLE_WON",
  ChallengeFailed = "CHALLENGE_FAILED",
  ChallengePass = "CHALLENGE_PASS",
  Ignore = "IGNORE",
  TournamentCancelled = "TOURNAMENT_CANCELLED",
  TournamentInReview = "TOURNAMENT_IN_REVIEW",
  TournamentLost = "TOURNAMENT_LOST",
  TournamentPending = "TOURNAMENT_PENDING",
  TournamentPlaying = "TOURNAMENT_PLAYING",
  TournamentWon = "TOURNAMENT_WON",
}

export enum User_Cheat_Status {
  /** BLACKLIST */
  B = "B",
  /** SUSPICIOUS */
  S = "S",
  /** WHITELIST */
  W = "W",
}

export type UpdateAppInput = {
  category: InputMaybe<Scalars["String"]["input"]>;
  data: InputMaybe<Scalars["String"]["input"]>;
  description: InputMaybe<Scalars["String"]["input"]>;
  hasBattle: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  rotationMode: InputMaybe<RotationMode>;
  status: InputMaybe<Status>;
  timesPlayed: InputMaybe<Scalars["Int"]["input"]>;
  title: InputMaybe<Scalars["String"]["input"]>;
  type: InputMaybe<GameType>;
};

/** Input data for update game cashEnabled status */
export type UpdateCashEnabledInput = {
  /** Cash enabled */
  cashEnabled: Scalars["Boolean"]["input"];
  /** Game ID */
  gameId: Scalars["String"]["input"];
};

/** Input for updating a CodeBuild environment */
export type UpdateCodeBuildEnvironmentInput = {
  /** Environment parameters including environment variables */
  environment: CodeBuildEnvironmentInput;
  /** Name of the environment being updated (e.g. b2bomantel) */
  environmentName: Scalars["String"]["input"];
};

/** Input data for updating gameSkills cashEnabled and disabled status */
export type UpdateGameAllowanceInput = {
  /**
   * Enables or disables cash for games.
   * @deprecated Cash feature is being removed and is no longer maintained.
   */
  cashEnabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Enables or disables game. */
  disabled: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates if game is featured or not. */
  featured: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Game ID */
  gameId: Scalars["String"]["input"];
};

export type UpdateGameSessionInput = {
  appId: InputMaybe<Scalars["ID"]["input"]>;
  controller: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  edgeNodeId: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  incognito: InputMaybe<Scalars["Boolean"]["input"]>;
  isStreamReady: InputMaybe<Scalars["Boolean"]["input"]>;
  matchId: InputMaybe<Scalars["String"]["input"]>;
  momentId: InputMaybe<Scalars["ID"]["input"]>;
  sessionResults: InputMaybe<Scalars["String"]["input"]>;
  sessionType: InputMaybe<GameSessionType>;
  userId: InputMaybe<Scalars["String"]["input"]>;
};

/** Input data for update game session cheat status */
export type UpdateGameSessionStatusInput = {
  gameSessionId: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<GameSessionStatus>;
};

export type UpdateGameSkillOutput = {
  __typename: "UpdateGameSkillOutput";
  gameId: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

/** Input parameter for UpdatePromo. */
export type UpdatePromoInput = {
  /** Promo end date */
  endDate: Scalars["AWSDateTime"]["input"];
  /** Game ID */
  gameId: Scalars["ID"]["input"];
  /** ID */
  id: Scalars["ID"]["input"];
  /** Moment ID */
  momentId: Scalars["ID"]["input"];
  /** Game play score */
  score: Scalars["Int"]["input"];
  /** Promo start date */
  startDate: Scalars["AWSDateTime"]["input"];
  /** User ID */
  userId: Scalars["ID"]["input"];
};

/** Input data to create a new regional allowance */
export type UpdateRegionalAllowanceInput = {
  /** Custom bonus types for the regional allowance */
  bonusTypes: InputMaybe<Array<InputMaybe<BonusTypeDetailInput>>>;
  /** Cash allowance for country/region */
  cashGameAllowance: InputMaybe<CashAllowanceInput>;
  /** Coin allowance for country/region */
  coinShopAllowance: InputMaybe<CoinAllowanceInput>;
  /** Country Code for the Allowance, empty corresponds to all (*) */
  country: InputMaybe<Scalars["String"]["input"]>;
  /** Minimum skill needed for a game to use cash for country/region */
  minSkill: InputMaybe<Scalars["Float"]["input"]>;
  /** Region Codes for the Allowances, empty corresponds to all(*) */
  regions: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Input data for updating a setting */
export type UpdateSettingInput = {
  /** Key of the setting */
  key: InputMaybe<Setting_Keys>;
  /** Value of the setting */
  value: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserActivityInput = {
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  data: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  type: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["ID"]["input"]>;
};

/** Input data to create a new User allowance */
export type UpdateUserAllowanceInput = {
  /** Cash allowance for domain/username */
  cashGameAllowance: InputMaybe<CashAllowanceInput>;
  /** Coin allowance for domain/username */
  coinShopAllowance: InputMaybe<CoinAllowanceInput>;
  /** Domain name part for the Allowance, empty corresponds to all (*) */
  domain: InputMaybe<Scalars["String"]["input"]>;
  /** Minimum skill needed for a game to use cash for domain/username */
  minSkill: InputMaybe<Scalars["Float"]["input"]>;
  /** Indicates if the user allowance should ignore the existing regional allowances */
  overrideRegional: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Usernames for the Allowances, empty corresponds to all(*) */
  usernames: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** Input data for updating user cheat status. status W indicates whitelist, status B indicated blacklist, status null indicates regular player. */
export type UpdateUserCheatStatusInput = {
  /** Cheat status */
  status: InputMaybe<User_Cheat_Status>;
  /** User ID */
  userId: Scalars["ID"]["input"];
};

/** Input data to update user metadata */
export type UpdateUserMetadataInput = {
  /** Desired resolution to be set for the game play */
  desiredStreamResolution: InputMaybe<Scalars["Int"]["input"]>;
  /** Indicates whether user has received their welcome coins */
  hasReceivedWelcomeCoins: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Indicates whether the user has seen the welcome coins popup */
  hasSeenWelcomePopup: InputMaybe<Scalars["Boolean"]["input"]>;
  /**
   * Indicates whether the user has completed onboarding
   * @deprecated This field has been replaced by the `onboarding` field.
   */
  isOnboarded: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Sequence ID of the last read message from the `messageQueue` */
  lastReadMessageSequenceId: InputMaybe<Scalars["Int"]["input"]>;
  /** ID of the transaction that was last seen by user */
  lastSeenTransactionId: InputMaybe<Scalars["String"]["input"]>;
  /** Onboarding status */
  onboarding: InputMaybe<Scalars["String"]["input"]>;
  /** Preferred game categories of user */
  preferredGameCategories: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
};

export type UpdateUserMomentStatsInput = {
  appId: InputMaybe<Scalars["ID"]["input"]>;
  createdAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  id: Scalars["ID"]["input"];
  momentId: InputMaybe<Scalars["ID"]["input"]>;
  numberOfTimesPlayed: InputMaybe<Scalars["Int"]["input"]>;
  totalTimePlayed: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt: InputMaybe<Scalars["AWSDateTime"]["input"]>;
  userId: InputMaybe<Scalars["ID"]["input"]>;
  xp: InputMaybe<Scalars["Int"]["input"]>;
};

/** Input data for updating a user's profile. */
export type UpdateUserV2Input = {
  /**
   * New avatar URL.
   * @deprecated Replaced by new inventory system for applying avatars.
   */
  avatar: InputMaybe<Scalars["String"]["input"]>;
  /** New banner URL. */
  banner: InputMaybe<Scalars["String"]["input"]>;
  /** New billing currency. */
  billingCurrency: InputMaybe<Scalars["String"]["input"]>;
  /** New billing email. */
  billingEmail: InputMaybe<Scalars["String"]["input"]>;
  /** New billing name. */
  billingName: InputMaybe<Scalars["String"]["input"]>;
  /** New billing phone number. */
  billingPhone: InputMaybe<Scalars["String"]["input"]>;
  /** New User Language Setting */
  language: InputMaybe<Scalars["String"]["input"]>;
  /** New push subscription endpoint object. This object must supplied as JSON encoded string. */
  pushSubscription: InputMaybe<Scalars["String"]["input"]>;
  /** New username. */
  username: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes a user. */
export type User = {
  __typename: "User";
  /** User Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /** Billing Currency */
  billingCurrency: Maybe<Scalars["String"]["output"]>;
  /** Billing Email ID */
  billingEmail: Maybe<Scalars["String"]["output"]>;
  /** Billing Name */
  billingName: Maybe<Scalars["String"]["output"]>;
  /** Billing Phone Number */
  billingPhone: Maybe<Scalars["String"]["output"]>;
  /** Cheater Status */
  cheatStatus: Maybe<Scalars["String"]["output"]>;
  /** Date and time at which the user was created. */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Currency of the user. */
  currency: Maybe<Scalars["Int"]["output"]>;
  /** Email ID */
  email: Maybe<Scalars["String"]["output"]>;
  /** Facebook User ID. Either Facebook or Google ID will be present. */
  facebookId: Maybe<Scalars["String"]["output"]>;
  /** Google User ID. Either Facebook or Google ID will be present. */
  googleId: Maybe<Scalars["String"]["output"]>;
  /**
   * Legacy field
   * @deprecated Legacy Field
   */
  hasWelcome: Maybe<Scalars["Boolean"]["output"]>;
  /** User ID */
  id: Scalars["ID"]["output"];
  /**
   * Indicates whether the user is currently online.
   * @deprecated Dead Field. Functionality does not exist and this field will always return null.
   */
  online: Maybe<Scalars["Boolean"]["output"]>;
  /** Phone Number */
  phone: Maybe<Scalars["String"]["output"]>;
  /** JSON encoded string containing the push notification endpoint required to send push notifications. */
  pushSubscription: Maybe<Scalars["String"]["output"]>;
  /** Date and time at which the user was updated. */
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Username unique to the user. */
  username: Scalars["String"]["output"];
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Denotes a user activity. */
export type UserActivity = {
  __typename: "UserActivity";
  /** Date and time at which the activity was created. */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** JSON encoded string that contains additional information about the activity. */
  data: Maybe<Scalars["String"]["output"]>;
  /** Activity  ID */
  id: Scalars["ID"]["output"];
  /** Type of activity. */
  type: Scalars["String"]["output"];
  /** Date and time at which the activity was updated. */
  updatedAt: Scalars["AWSDateTime"]["output"];
  /** ID of the user who performed the activity. */
  userId: Scalars["ID"]["output"];
};

export type UserAllowance = {
  __typename: "UserAllowance";
  /** Cash allowance details for domain/username */
  cashGameAllowance: Maybe<CashAllowance>;
  /** Coin allowance details for domain/username */
  coinShopAllowance: Maybe<CoinAllowance>;
  /** Domain part of email for the Allowance */
  domain: Maybe<Scalars["String"]["output"]>;
  /** Minimum skill needed for a game to use cash for domain/username */
  minSkill: Maybe<Scalars["Float"]["output"]>;
  /** Indicates if this allowance should ignore the regional allowance */
  overrideRegional: Maybe<Scalars["Boolean"]["output"]>;
  /** Username part of email for the Allowance */
  username: Maybe<Scalars["String"]["output"]>;
};

/** Input data for providing user device information. */
export type UserDeviceInput = {
  /** device extra value for latitude and longitude */
  extra: InputMaybe<IUserDeviceExtra>;
  /** JSON string containing the details of user's device. */
  info: InputMaybe<Scalars["String"]["input"]>;
  /** Screen size of the device. */
  screen_size: InputMaybe<Scalars["String"]["input"]>;
};

/** Denotes a user event */
export type UserEvent = {
  __typename: "UserEvent";
  /** Total amount available to be won in the match or tournament. */
  amount: Maybe<Scalars["Float"]["output"]>;
  /** Avatar URL of the opponent user. This value will only be present for battles that have another player. */
  avatar: Maybe<Scalars["String"]["output"]>;
  /**
   * Cost to play per user (includes fee).
   * costPerUser will be null for non battle types.
   */
  costPerUser: Maybe<Scalars["Float"]["output"]>;
  /** Currency of the battle or tournament. */
  currency: Maybe<Scalars["String"]["output"]>;
  /**
   * Platform fees. This is included in the `costPerUser` amount.
   * entryFee will be null for non battle types.
   */
  entryFee: Maybe<Scalars["Float"]["output"]>;
  /** Indicates the activity user engaged in. */
  eventType: Maybe<Userevent_Notification_Type>;
  /** Date and time at which the event expired. */
  expiredAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Determine if it's a friend battle. */
  friendBattle: Maybe<Scalars["Boolean"]["output"]>;
  /** ID of the game the user played. */
  gameId: Maybe<Scalars["String"]["output"]>;
  /** Indicates if user is the only player in the tournament. */
  isOnlyPlayer: Maybe<Scalars["Boolean"]["output"]>;
  /** ID of the match the user played. Will be `null` for non battle events. */
  matchId: Maybe<Scalars["String"]["output"]>;
  /** Indicates whether it was a score or time based moment. */
  momentType: Maybe<Scalars["String"]["output"]>;
  /** ID of the opponent user. */
  opponentId: Maybe<Scalars["String"]["output"]>;
  /** Score attemped by the opponent user. Will be `null` for non battle events. */
  opponentScore: Maybe<Scalars["Int"]["output"]>;
  /** Player ranking in the match or tournament. Will be `null` for non tournament events. */
  playerRank: Maybe<Scalars["Int"]["output"]>;
  /** Score attained by the user in the match or tournament. */
  playerScore: Maybe<Scalars["Int"]["output"]>;
  /** PrizePool as a JSON string */
  prizePool: Maybe<Scalars["String"]["output"]>;
  /** Date and time at which the last reminder was sent. */
  sentReminderAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Date and time at which the event was started. */
  startedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Game Title */
  title: Maybe<Scalars["String"]["output"]>;
  /** Total number of distinct players in the tournament. */
  tournamentDistinctPlayers: Maybe<Scalars["Int"]["output"]>;
  /** ID of the tournament the user participated in. Will be `null` for non tournament events. */
  tournamentId: Maybe<Scalars["String"]["output"]>;
  /** Touranment type of the tournament the user participated in. Will be `null` for non tournament events. */
  tournamentType: Maybe<Scalars["String"]["output"]>;
  /** Username of the opponent user. This value will only be present for battles that have another player. */
  username: Maybe<Scalars["String"]["output"]>;
  /** Total amount won by the user in this match or tournament. */
  winAmount: Maybe<Scalars["Float"]["output"]>;
};

export type UserFeedGame = {
  __typename: "UserFeedGame";
  /** The game details */
  game: Maybe<NGame>;
  /**
   * All hot battles for this game
   * @deprecated This field has been deprecated and should no longer be used.
   */
  hotBattles: Maybe<Array<Maybe<HotBattle>>>;
  /** All house battles for this game */
  houseBattles: Maybe<Array<Maybe<HouseBattle>>>;
  /**
   * All tournaments related to the game
   * @deprecated This field has been deprecated and should no longer be used.
   */
  tournaments: Maybe<Array<Maybe<TournamentInfo>>>;
};

export type UserFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The user's field to filter on */
  field: UserFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list users on */
export enum UserFilterField {
  /** Cheat Status of the user */
  CheatStatus = "cheat_status",
  /** Disabled status of the user */
  Disable = "disable",
  /** Email of the user */
  Email = "email",
  /** identifier for the user */
  Id = "id",
  /** Phone of the user */
  Phone = "phone",
  /** Username of the user */
  Username = "username",
}

/** Denotes a user follower. */
export type UserFollower = {
  __typename: "UserFollower";
  /** Date and time at which the entry was created */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** User follower ID */
  id: Scalars["ID"]["output"];
  /** Receiver User Details */
  receiver: PublicUser;
  /** User Id of the user being followed */
  receiverId: Scalars["ID"]["output"];
  /** Sender User Details */
  sender: PublicUser;
  /** User Id of the user doing the following */
  senderId: Scalars["ID"]["output"];
  /** Date and time at which the entry was last updated */
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
};

/**
 * Deprecated type to be removed when we removed its associated query 'listFriendsV2'
 * Denotes a user's friend
 */
export type UserFriend = {
  __typename: "UserFriend";
  /** Date and time at which the request was sent */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Friend Request ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Information of the user who received the friend request */
  receiver: Maybe<LightUser>;
  /** Information of the user who sent the friend request */
  sender: Maybe<LightUser>;
  /** Date and time at which the request was last updated */
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
};

/** Deprecated type to be removed when we removed its associated query 'listFriendsSentV2', 'listFriendsReceivedV2' and subscription 'deletedFriend' */
export type UserFriendRequest = {
  __typename: "UserFriendRequest";
  accepted: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  receiver: Maybe<LightUser>;
  receiverId: Maybe<Scalars["String"]["output"]>;
  sender: Maybe<LightUser>;
  senderId: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["AWSDateTime"]["output"]>;
};

/** Denotes the user profile. */
export type UserInfo = {
  __typename: "UserInfo";
  /** User's battle streak */
  battleStreak: Maybe<Scalars["Int"]["output"]>;
  /**
   * Indicates whether the user can use cash.
   * @deprecated Cash has been removed.
   */
  canUseCash: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Indicates if this is the user's first login.
   * @deprecated This field has been replaced by the `getUserMetadata` resolver which gives specific details about the user's state.
   */
  firstTime: Maybe<Scalars["Boolean"]["output"]>;
  /** Global access token that grants access to specific resources or endpoints on all environments. */
  globalAccessToken: Maybe<Scalars["String"]["output"]>;
  /** All house battles for this game */
  houseBattles: Maybe<Array<Maybe<HouseBattle>>>;
  /**
   * Indicates whether first time deposit bonus is added to user.
   * @deprecated Cash has been disabled.
   */
  initialDepositBonus: Maybe<Scalars["Boolean"]["output"]>;
  /** Number of battles pending to be played by the user. */
  nbBattlesPending: Maybe<Scalars["Int"]["output"]>;
  /**
   * Number of unaccepted friend requests of the user.
   * @deprecated Friend system replaced by follow system which does not require accepting a request.
   */
  nbFriendRequestUnaccepted: Maybe<Scalars["Int"]["output"]>;
  /** Number of unread notifications of the user. */
  nbNotificationsUnread: Maybe<Scalars["Int"]["output"]>;
  /**
   * Permission groups of the user.
   * *Note: `Editorial`, `Publisher` and `Banker` groups are deprecated and replaced with `EDITOR`, `PUBLISHER` AND `BANKER`. Please switch to using the new groups since the old groups will be removed in the next release.*
   */
  permissionGroups: Maybe<Array<Maybe<PermissionGroup>>>;
  /** Subscription Data */
  subscription: Maybe<UserSubscription>;
  /** Popup transactoins that are yet to be seen by the user */
  unseenRewardTransactions: Maybe<Array<Maybe<FullTransaction>>>;
  /** User Data */
  user: Maybe<UserV2>;
  /** List of user events that occurred when the user was away. */
  whileAway: Maybe<Array<Maybe<UserEvent>>>;
};

/** Denotes the user metadata */
export type UserMetadata = {
  __typename: "UserMetadata";
  /** Desired resolution to be set for the game play */
  desiredStreamResolution: Maybe<Scalars["Int"]["output"]>;
  /** Indicates whether user has received their welcome coins */
  hasReceivedWelcomeCoins: Scalars["Boolean"]["output"];
  /** Indicates whether the user has seen the welcome coins popup */
  hasSeenWelcomePopup: Scalars["Boolean"]["output"];
  /**
   * Indicates whether the user has completed onboarding
   * @deprecated This field has been replaced by the `onboarding` field.
   */
  isOnboarded: Maybe<Scalars["Boolean"]["output"]>;
  /** ID of the transaction that was last seen by user */
  lastSeenTransactionId: Maybe<Scalars["String"]["output"]>;
  /** Onboarding status */
  onboarding: Maybe<Scalars["String"]["output"]>;
  /** Preferred game categories of user */
  preferredGameCategories: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

/** Denotes a user Notification. */
export type UserNotification = {
  __typename: "UserNotification";
  /** Date and time at which the notification was created */
  createdAt: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Details about the notification. Format is based on notification type */
  data: UserNotificationData;
  /** Notification ID */
  id: Scalars["ID"]["output"];
  /** Notification Type */
  type: UserNotificationType;
  /** User ID */
  userId: Scalars["ID"]["output"];
};

/** Possible format of notification data depending on the notification type */
export type UserNotificationData =
  | AchievementRewardUserNotificationData
  | BattleUserNotificationData
  | FollowNotificationData
  | FriendUserNotificationData
  | LeaderboardUserNotificationData
  | TournamentUserNotificationData;

/** Denotes the type of a notification. */
export enum UserNotificationType {
  AchievementReward = "ACHIEVEMENT_REWARD",
  BattleExpired = "BATTLE_EXPIRED",
  BattleExpiring = "BATTLE_EXPIRING",
  BattleInvitation = "BATTLE_INVITATION",
  BattleLost = "BATTLE_LOST",
  BattleWon = "BATTLE_WON",
  FollowedByUser = "FOLLOWED_BY_USER",
  /** @deprecated friend system has been replaced by follow system */
  FriendAccepted = "FRIEND_ACCEPTED",
  /** @deprecated friend system has been replaced by follow system */
  FriendInvitation = "FRIEND_INVITATION",
  GlobalLeaderboardEnded = "GLOBAL_LEADERBOARD_ENDED",
  GlobalLeaderboardMonthlyReminder = "GLOBAL_LEADERBOARD_MONTHLY_REMINDER",
  GlobalLeaderboardWeeklyReminder = "GLOBAL_LEADERBOARD_WEEKLY_REMINDER",
  TournamentCompletion = "TOURNAMENT_COMPLETION",
  TournamentDemotion = "TOURNAMENT_DEMOTION",
}

/** Denotes public profile of a user. */
export type UserProfile = {
  __typename: "UserProfile";
  /** User's all time rank */
  allTimeRank: Maybe<Scalars["Int"]["output"]>;
  /** Battle streak data of the user */
  battleStreakData: Maybe<BattleStreakData>;
  /** Number of unique solo challenges completed by user. */
  challengesCompleted: Maybe<Scalars["Int"]["output"]>;
  /** Follow status between a user and the caller */
  followStatus: Maybe<FollowStatus>;
  /**
   * Id of the friend request, if sent or received
   * @deprecated friend system replaced by follow system, use followStatus instead
   */
  friendRequestId: Maybe<Scalars["String"]["output"]>;
  /**
   * Status of the friend request, if sent or received
   * @deprecated friend system replaced by follow system, use followStatus instead
   */
  friendStatus: FriendStatus;
  /** User's last checkin time */
  lastCheckin: Maybe<Scalars["AWSDateTime"]["output"]>;
  /**
   * Number of user's friends.
   * @deprecated friend system replaced by follow system
   */
  nbFriends: Maybe<Scalars["Int"]["output"]>;
  /** Number of battles won by user. */
  nbMatchesWon: Maybe<Scalars["Int"]["output"]>;
  /** User Data */
  user: Maybe<User>;
  /** Log of user activities. */
  userActivity: Maybe<Array<Maybe<UserActivity>>>;
  /** Win percentage. */
  winningPercent: Maybe<Scalars["Int"]["output"]>;
};

/** Input data for fetching user profile. */
export type UserProfileInput = {
  /** User ID */
  userId: Scalars["ID"]["input"];
};

/** Denotes user subscription details. */
export type UserSubscription = {
  __typename: "UserSubscription";
  /** Field name for User ID */
  field: Maybe<SubscriptionIdField>;
  /** Subscription renewal date - provided when in unsubscription process */
  renewalDate: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Subscription status */
  status: Maybe<Scalars["String"]["output"]>;
  /** Subscripion User ID */
  umrid: Maybe<Scalars["String"]["output"]>;
};

/**
 * Denotes a user.
 * Alternate type for `User` with a `wallets` property.
 */
export type UserV2 = {
  __typename: "UserV2";
  /** User Avatar URL */
  avatar: Maybe<Scalars["String"]["output"]>;
  /** Banner URL */
  banner: Maybe<Scalars["String"]["output"]>;
  /** Billing Currency */
  billingCurrency: Maybe<Scalars["String"]["output"]>;
  /** Billing Email ID */
  billingEmail: Maybe<Scalars["String"]["output"]>;
  /** Billing Name */
  billingName: Maybe<Scalars["String"]["output"]>;
  /** Billing Phone Number */
  billingPhone: Maybe<Scalars["String"]["output"]>;
  /** User cheating gamesessions count */
  cheatSessions: Maybe<Scalars["Int"]["output"]>;
  /** Cheater status */
  cheatStatus: Maybe<Scalars["String"]["output"]>;
  /** User's created time */
  createdAt: Maybe<Scalars["String"]["output"]>;
  /** Currency of the user. */
  currency: Scalars["Int"]["output"];
  /** User's currency locked or not */
  currencyLocked: Maybe<Scalars["Boolean"]["output"]>;
  /** User's disabled status */
  disabled: Maybe<Scalars["Boolean"]["output"]>;
  /** Email ID */
  email: Maybe<Scalars["String"]["output"]>;
  /** Facebook User ID. Either Facebook or Google ID will be present. */
  facebookId: Maybe<Scalars["String"]["output"]>;
  /** Google User ID. Either Facebook or Google ID will be present. */
  googleId: Maybe<Scalars["String"]["output"]>;
  /**
   * Legacy field
   * @deprecated Legacy Field
   */
  hasWelcome: Maybe<Scalars["Boolean"]["output"]>;
  /** User ID */
  id: Scalars["ID"]["output"];
  /** User IdpId */
  idpId: Maybe<Scalars["String"]["output"]>;
  /** User IdpName */
  idpName: Maybe<Scalars["String"]["output"]>;
  /** User's Language Setting. */
  language: Maybe<Scalars["String"]["output"]>;
  /** User's last checkin IP */
  lastIP: Maybe<Scalars["String"]["output"]>;
  /** Phone Number */
  phone: Maybe<Scalars["String"]["output"]>;
  /** JSON encoded string containing the push notification endpoint required to send push notifications. */
  pushSubscription: Maybe<Scalars["String"]["output"]>;
  /** Total User Deposits. */
  totalDeposits: Maybe<Array<Maybe<WalletDeposit>>>;
  /** Total User Earnings. */
  totalEarnings: Maybe<Array<Maybe<CoinWalletEarning>>>;
  /** Total User Withdrawals. */
  totalWithdrawals: Maybe<Array<Maybe<WalletWithdrawal>>>;
  /** Username unique to the user. */
  username: Scalars["String"]["output"];
  /** List of user's wallets. */
  wallets: Maybe<Array<Maybe<CoinWallet>>>;
  /** XP Points */
  xp: Maybe<Scalars["Int"]["output"]>;
};

/** Paginated output for users resolver */
export type UsersOutput = {
  __typename: "UsersOutput";
  /** Users returned by the query */
  items: Array<PublicUser>;
  /** Number of Users returned */
  totalCount: Scalars["Int"]["output"];
};

export enum Wallet_Type {
  Achievement = "achievement",
  Coin = "coin",
  Inventory = "inventory",
}

/** Denotes a wallet. */
export type Wallet = {
  __typename: "Wallet";
  /** Updated wallet balance */
  balance: Maybe<Scalars["Float"]["output"]>;
  /** Wallet currency */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Wallet sequence */
  sequence: Maybe<Scalars["Int"]["output"]>;
  /** Type of wallet */
  type: Maybe<Scalars["String"]["output"]>;
};

export type WalletAdmin = {
  __typename: "WalletAdmin";
  /** Updated wallet balance */
  balance: Maybe<Scalars["Float"]["output"]>;
  /** Wallet currency */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Id of wallet */
  id: Maybe<Scalars["String"]["output"]>;
  /** the latest entry date */
  last_entry_date: Maybe<Scalars["AWSDateTime"]["output"]>;
  /** Name of wallet */
  name: Maybe<Scalars["String"]["output"]>;
  /** Type of wallet */
  type: Maybe<Scalars["String"]["output"]>;
  /** User ID of wallet */
  user_id: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a user's wallet deposits. */
export type WalletDeposit = {
  __typename: "WalletDeposit";
  /** Type of wallet. */
  coinType: Maybe<CoinType>;
  /** Sum of winnings. */
  total: Maybe<Scalars["Int"]["output"]>;
  /** User  ID */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a user wallet earnings for the current day. */
export type WalletEarning = {
  __typename: "WalletEarning";
  /** Type of currency. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Wallet external id. */
  external_id: Maybe<Scalars["String"]["output"]>;
  /** Wallet  ID */
  id: Maybe<Scalars["ID"]["output"]>;
  /** Sum of winnings. */
  totalWinnings: Maybe<Scalars["Float"]["output"]>;
  /** Type of wallet. */
  type: Maybe<Scalars["String"]["output"]>;
};

/** Denotes the field to filter the admin wallets search. */
export enum WalletFilterField {
  /** Balance field, filter the wallets by value and an operation like < <= = > >= */
  Balance = "balance",
  /** Currency field, filter the wallets from a currency */
  Currency = "currency",
  /** Id field, filter the wallets from an id */
  Id = "id",
  /** Last Entry Date field, if only value greater then but if with have additional param we use Between */
  LastEntryDate = "last_entry_date",
  /** Name field, filter the wallets with like Value */
  Name = "name",
  /** Type field, filter the wallets from a wallet type */
  Type = "type",
  /** User Id field, filter the wallets from userId */
  UserId = "user_id",
}

export type WalletUpdatedEvent = {
  __typename: "WalletUpdatedEvent";
  availableBalance: Maybe<Scalars["Float"]["output"]>;
  balance: Maybe<Scalars["Float"]["output"]>;
  currency: Maybe<Currency>;
  escrowId: Maybe<Scalars["String"]["output"]>;
  eventType: Maybe<Scalars["String"]["output"]>;
  externalId: Maybe<Scalars["String"]["output"]>;
  isClosed: Maybe<Scalars["Boolean"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
  sequence: Maybe<Scalars["Int"]["output"]>;
  type: Maybe<Scalars["String"]["output"]>;
  updatedFields: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  userId: Maybe<Scalars["String"]["output"]>;
  walletId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes a user's wallet withdrawals. */
export type WalletWithdrawal = {
  __typename: "WalletWithdrawal";
  /** Type of wallet. */
  coinType: Maybe<CoinType>;
  /** Sum of winnings. */
  total: Maybe<Scalars["Int"]["output"]>;
  /** User  ID */
  userId: Maybe<Scalars["String"]["output"]>;
};

/** Denotes the welcome coins status */
export type WelcomeCoinsStatus = {
  __typename: "WelcomeCoinsStatus";
  /** Indicates if welcome coins have been paid out or not */
  paidOut: Scalars["Boolean"]["output"];
};

/** Denotes a withdrawal. */
export type Withdrawal = {
  __typename: "Withdrawal";
  /** Currency of the amount involved in the withdrawal. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the withdrawal was created. */
  date: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** ID of the escrow wallet. */
  escrowId: Maybe<Scalars["String"]["output"]>;
  /** Total withdrawal amount. */
  grossAmount: Maybe<Scalars["Float"]["output"]>;
  /** Withdrawal ID */
  id: Maybe<Scalars["String"]["output"]>;
  /** Actual amount paid to the user. */
  paidAmount: Maybe<Scalars["Float"]["output"]>;
  /** ID of the wallet from where the amount was debited from. */
  sourceWalletId: Maybe<Scalars["String"]["output"]>;
  /** Current status of the withdrawal. */
  status: Maybe<Scalars["String"]["output"]>;
  /** Fees incurred for the withdrawal. */
  transactionFee: Maybe<Scalars["Float"]["output"]>;
  /** ID of the transaction that represents the withdrawal. */
  transactionId: Maybe<Scalars["String"]["output"]>;
  /** Complete list of user transactions. */
  transactions: Maybe<Array<Maybe<FullTransaction>>>;
  /** ID of the wallet to which the amount was credited to. */
  transferWalletId: Maybe<Scalars["String"]["output"]>;
  /** Timestamp of when the withdrawal was updated. */
  updatedAt: Maybe<Scalars["AWSTimestamp"]["output"]>;
  /** ID of the user who initiated the withdrawal. */
  userId: Maybe<Scalars["String"]["output"]>;
};

export type WithdrawalFilter = {
  /** Additional parameter used to filter */
  additionalParam: InputMaybe<Scalars["String"]["input"]>;
  /** The withdrawal's field to filter on */
  field: WithdrawalFilterField;
  /** The value to use to apply the filter */
  value: Scalars["String"]["input"];
};

/** Denotes which field to filter admin list withdrawals on */
export enum WithdrawalFilterField {
  /** Currency the withdrawal is in */
  Currency = "currency",
  /** Date the withdrawal was created */
  Date = "date",
  /** Amount of the withdrawal */
  GrossAmount = "gross_amount",
  /** identifier for the withdrawal */
  Id = "id",
  /** Wallet the withdrawal is from */
  SourceWalletId = "source_wallet_id",
  /** Current status of the withdrawal */
  Status = "status",
  /** User who made the withdrawal */
  UserId = "user_id",
}

/**
 * Denotes the output of create friend request code resolver.
 * Deprecated, to be removed along with mutation `createFriendRequestCode`
 */
export type CreateFriendRequestCodeOutput = {
  __typename: "createFriendRequestCodeOutput";
  /** Oncode for friend request */
  oncode: Maybe<Scalars["String"]["output"]>;
};

/**
 * Denotes a transaction.
 * `serializeTransaction` gives a reponse of transaction with serialization.
 */
export type SerializeTransaction = {
  __typename: "serializeTransaction";
  /** Specifies the workflow of the transaction. */
  action: Scalars["String"]["output"];
  /** Amount credited or debited to/from the user's wallet. */
  amount: Scalars["String"]["output"];
  /** Date and time at which the transaction was created. */
  createdAt: Scalars["AWSDateTime"]["output"];
  /** Currency of the transaction. */
  currency: Maybe<Scalars["String"]["output"]>;
  /** Entry id for the transaction */
  entryId: Maybe<Scalars["String"]["output"]>;
  /** It is actually the game id related to the transaction. */
  image: Maybe<Scalars["String"]["output"]>;
  /** Wallet balance after the credit/debit. */
  runningBalance: Maybe<Scalars["String"]["output"]>;
  /**
   * Current status of the transaction. Possible values are either `completed` or `pending`.
   * Or if it is a transaction for a match, it specifies the state of the match result.
   */
  status: Maybe<Scalars["String"]["output"]>;
  /** Specifies the description of the transaction result */
  text: Scalars["String"]["output"];
  /** It is actually the game title related to the transation. */
  title: Maybe<Scalars["String"]["output"]>;
  /** Specifies if it is a credit or debit transaction. */
  type: Scalars["String"]["output"];
  /** Specifies whether it's a virtual or currency wallet. */
  walletType: Maybe<Scalars["String"]["output"]>;
};
