import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { Box } from "../";
import { useStyles } from "./Alert.style";
import {
  TypographyColor,
  TypographyVariant,
  Typography,
} from "../Typography/Typography";
import { StaticImage } from "../../common/StaticImage";
import SuccessIconWebp from "../../../assets/notification/webp/success.webp";

interface IAlertProps {
  classify: string;
  message: string;
}

export const Alert: FC<IAlertProps & HTMLAttributes<HTMLDivElement>> = (
  props
) => {
  const classes = useStyles();
  const { classify, message, ...othersProps } = props;

  return (
    <Box
      {...othersProps}
      display="flex"
      alignItems="center"
      className={clsx(classes.root, `s-${classify}`, props.className)}
    >
      {classify === "success" && (
        <StaticImage src={SuccessIconWebp} height={28} />
      )}
      <Typography
        variant={TypographyVariant.BodyB2Bold}
        color={TypographyColor.DarkDefault}
        className={classes.message}
      >
        {message}
      </Typography>
    </Box>
  );
};
