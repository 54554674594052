import { createUseStyles } from "react-jss";
import { Colors, DarkThemeColors } from "../../../constants/colors";

export const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "block",
    position: "relative",
    overflow: "hidden",
    backgroundColor: DarkThemeColors.Background5,
    "&:after": {
      position: "absolute",
      top: 0,
      insetInlineEnd: 0,
      bottom: 0,
      insetInlineStart: 0,
      transform: "translateX(-100%)",
      background: Colors.GradientSkeletonLoading,
      animation: `$loading 2s infinite`,
      content: `""`,
    },
  },
  backgroundLight: {
    backgroundColor: "#e0e0e0",
  },
  text: {
    transform: "scale(1, 0.60)",
    borderRadius: 5,
  },
  circle: {
    borderRadius: "100%",
  },
  "@keyframes loading": {
    "0%": {
      transform: "translateX(-30%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
});
