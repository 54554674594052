import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

interface ISplashScreen {
  percentage: number;
  isOpen: boolean;
}

const initialState = {
  percentage: 0,
  isOpen: true,
} as ISplashScreen;

export const resetSplashScreenState = createAction("splashScreen/resetState");

export const splashScreenSlice = createSlice({
  name: "splashScreen",
  initialState: initialState,
  reducers: {
    hide: (state) => {
      state.percentage = 100;
      state.isOpen = false;
    },
    setPercentage: (state, action: PayloadAction<{ percentage: number }>) => {
      state.percentage =
        state.percentage >= 100 ? 100 : action.payload.percentage;
      state.isOpen = state.percentage < 100;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSplashScreenState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const { setPercentage, hide } = splashScreenSlice.actions;

export default splashScreenSlice.reducer;
