import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { GridPaddingMapping } from "../../../constants/padding";
import { FontWeight } from "../../../constants/fontWeight";
import { ZIndex } from "../../../constants/zIndex";

export const useStyles = createUseStyles({
  root: {
    position: "relative",
  },
  content: {
    width: 150,
    justifyContent: "space-between !important",
    padding: `${GridPaddingMapping.s}px !important`,
  },
  selectedText: {
    justifyContent: "space-between",
    display: "flex",
  },
  img: {
    marginInlineEnd: GridPaddingMapping.xxs,
  },
  listItem: {
    background: `${DarkThemeColors.Background4}`,
    width: 150,
    padding: `${GridPaddingMapping.xs}px 0`,
    position: "absolute",
    top: 50,
    visibility: "hidden",
    zIndex: ZIndex.dialog,
    "&.open": {
      visibility: "visible",
    },
  },
  item: {
    width: "100%",
    padding: `${GridPaddingMapping.xs}px ${GridPaddingMapping.s}px`,
    textAlign: "start",
    borderRadius: 0,
    color: `${DarkThemeColors.Text2} !important`,
    fontWeight: `${FontWeight.regular} !important`,
    "&.selected": {
      background: `${DarkThemeColors.Background5} !important`,
      color: `${DarkThemeColors.Text1} !important`,
      fontWeight: `${FontWeight.bold} !important`,
    },
  },
});
