import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { GridPaddingMapping } from "../../../constants/padding";
import { ZIndex } from "../../../constants/zIndex";

export const useStyles = createUseStyles({
  root: {
    zIndex: ZIndex.dialog,
    position: "fixed",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    insetInlineEnd: 0,
    color: DarkThemeColors.Text1,
  },
  powerBy: {
    marginBottom: 15,
    textAlign: "center",
  },
  themeLogo: {
    height: 23,
  },
  powerBy1: {
    marginTop: 15,
    textAlign: "center",
  },
  bottom: {
    position: "absolute",
    bottom: 15,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  progressContainer: {
    padding: `0px ${GridPaddingMapping.h}px ${GridPaddingMapping.h}px ${GridPaddingMapping.h}px`,
    width: "50%",
    marginTop: GridPaddingMapping.h * 2,
  },
  hasLogo2: {
    marginTop: GridPaddingMapping.xxh,
  },
  header: {
    position: "absolute",
    width: "100%",
  },
  topContent: {
    padding: 15,
    width: "100%",
    boxSizing: "border-box",
    position: "absolute",
    top: 0,
  },
});
