import React, { FC, HTMLAttributes } from "react";
import clsx from "classnames";
import { useStyles } from "./Icon.style";

interface IIconProps {
  name: IconName;
  size?: number;
}

export const iconsList = [
  "icon-network-alert",
  "icon-bold-cup",
  "icon-bold-debug",
  "icon-box-add",
  "icon-repeat-circle",
  "icon-shopping-bag1",
  "icon-user-add-1",
  "icon-message-question",
  "icon-card-pos",
  "icon-document",
  "icon-headphone",
  "icon-map",
  "icon-medal",
  "icon-monitor",
  "icon-shield-security",
  "icon-size",
  "icon-wallet-check",
  "icon-copy",
  "icon-profile-tick",
  "icon-like",
  "icon-battle",
  "icon-user-add",
  "icon-profile-add",
  "icon-bold-add-circle",
  "icon-bold-add-square",
  "icon-bold-add",
  "icon-bold-arrow-circle-down",
  "icon-bold-arrow-circle-left",
  "icon-bold-arrow-circle-right",
  "icon-bold-arrow-circle-up",
  "icon-bold-arrow-down-3",
  "icon-bold-arrow-down-circle",
  "icon-bold-arrow-down",
  "icon-bold-arrow-left-3",
  "icon-bold-arrow-left-circle",
  "icon-bold-arrow-left",
  "icon-bold-arrow-right-3",
  "icon-bold-arrow-right-circle",
  "icon-bold-arrow-right",
  "icon-bold-arrow-square-down",
  "icon-bold-arrow-square-left",
  "icon-bold-arrow-square-right",
  "icon-bold-arrow-square-up",
  "icon-bold-arrow-up-1",
  "icon-bold-arrow-up-circle",
  "icon-bold-arrow-up",
  "icon-bold-bank",
  "icon-bold-battle",
  "icon-bold-battles",
  "icon-bold-card-pos",
  "icon-bold-check-only",
  "icon-bold-clipboard-tick",
  "icon-bold-clock",
  "icon-bold-close-circle",
  "icon-bold-close-square",
  "icon-bold-close",
  "icon-bold-danger",
  "icon-bold-dot-horizontal",
  "icon-bold-dot-vertical",
  "icon-bold-download",
  "icon-bold-edit-2",
  "icon-bold-edit",
  "icon-bold-empty-wallet",
  "icon-bold-export",
  "icon-bold-eye-slash",
  "icon-bold-eye",
  "icon-bold-flag-2",
  "icon-bold-flag",
  "icon-bold-frame",
  "icon-game-bold",
  "icon-bold-global",
  "icon-bold-heart",
  "icon-bold-info-circle-2",
  "icon-bold-info-circle",
  "icon-bold-lock-circle",
  "icon-bold-lock",
  "icon-bold-logo",
  "icon-bold-magic-star",
  "icon-bold-maximize",
  "icon-bold-menu",
  "icon-bold-minimize",
  "icon-bold-minus-cirlce",
  "icon-bold-minus-square",
  "icon-bold-minus",
  "icon-bold-mobile",
  "icon-bold-monitor",
  "icon-bold-more-circle",
  "icon-bold-more-square",
  "icon-bold-network",
  "icon-bold-next",
  "icon-bold-notification-bing",
  "icon-bold-notification",
  "icon-bold-pause",
  "icon-bold-people",
  "icon-bold-play",
  "icon-bold-profile-2user",
  "icon-bold-profile-circle",
  "icon-bold-radar",
  "icon-bold-refresh",
  "icon-bold-rotate",
  "icon-bold-search-normal",
  "icon-bold-send-2",
  "icon-bold-send-3",
  "icon-bold-send-arrow",
  "icon-bold-setting-2",
  "icon-bold-setting-5",
  "icon-bold-share-circle",
  "icon-bold-share",
  "icon-bold-shopping-cart",
  "icon-bold-smile",
  "icon-bold-sms",
  "icon-bold-sort",
  "icon-bold-star",
  "icon-bold-stickynote",
  "icon-bold-stop",
  "icon-bold-tick-circle",
  "icon-bold-tick-square",
  "icon-bold-timer",
  "icon-bold-trash",
  "icon-bold-user-cirlce-add",
  "icon-bold-user-tick",
  "icon-bold-user",
  "icon-bold-volume-high",
  "icon-bold-volume-slash",
  "icon-bold-wallet-2",
  "icon-bold-warning",
  "icon-linear-add-circle",
  "icon-linear-add-square",
  "icon-linear-add",
  "icon-linear-arrow-circle-down",
  "icon-linear-arrow-circle-left",
  "icon-linear-arrow-circle-right",
  "icon-linear-arrow-circle-up",
  "icon-linear-arrow-down-3",
  "icon-linear-arrow-down-circle",
  "icon-linear-arrow-down",
  "icon-linear-arrow-left-3",
  "icon-linear-arrow-left-circle",
  "icon-linear-arrow-right-3",
  "icon-linear-arrow-right-circle",
  "icon-linear-arrow-right",
  "icon-linear-arrow-square-down",
  "icon-linear-arrow-square-left",
  "icon-linear-arrow-square-right",
  "icon-linear-arrow-square-up",
  "icon-linear-arrow-up-3",
  "icon-linear-arrow-up-circle",
  "icon-linear-arrow-up",
  "icon-linear-bank",
  "icon-linear-battles",
  "icon-linear-card-pos",
  "icon-linear-check-only",
  "icon-linear-clipboard-tick",
  "icon-linear-clock",
  "icon-linear-close-circle",
  "icon-linear-close-square",
  "icon-linear-close",
  "icon-linear-danger",
  "icon-linear-dot-horizontal",
  "icon-linear-dot-vertical",
  "icon-linear-download",
  "icon-linear-edit-2",
  "icon-linear-edit",
  "icon-linear-empty-wallet",
  "icon-linear-eye-slash",
  "icon-linear-eye",
  "icon-linear-flag-2",
  "icon-linear-flag",
  "icon-linear-frame",
  "icon-linear-game",
  "icon-linear-global",
  "icon-linear-heart",
  "icon-linear-info-circle-2",
  "icon-linear-info-circle",
  "icon-linear-lock-circle",
  "icon-linear-lock",
  "icon-linear-logo",
  "icon-linear-maximize",
  "icon-linear-menu",
  "icon-linear-minimize",
  "icon-linear-minus-cirlce",
  "icon-linear-minus-square",
  "icon-linear-minus",
  "icon-linear-mobile",
  "icon-linear-monitor",
  "icon-linear-more-circle",
  "icon-linear-more-square",
  "icon-linear-next",
  "icon-linear-notification-bing",
  "icon-linear-notification",
  "icon-linear-pause",
  "icon-linear-people",
  "icon-linear-play",
  "icon-linear-profile-2user",
  "icon-linear-profile-circle",
  "icon-linear-radar",
  "icon-linear-refresh",
  "icon-linear-rotate",
  "icon-linear-search-normal",
  "icon-linear-send-1",
  "icon-linear-send-2",
  "icon-linear-send",
  "icon-linear-setting-2",
  "icon-linear-setting-5",
  "icon-linear-share-circle",
  "icon-linear-share",
  "icon-linear-shopping-cart",
  "icon-linear-smile",
  "icon-linear-sms",
  "icon-linear-sort",
  "icon-linear-star",
  "icon-linear-stickynote",
  "icon-linear-stop",
  "icon-linear-tick-circle",
  "icon-linear-tick-square",
  "icon-linear-timer",
  "icon-linear-trash",
  "icon-linear-user-cirlce-add",
  "icon-linear-user-tick",
  "icon-linear-user-add",
  "icon-linear-user",
  "icon-linear-volume-high",
  "icon-linear-volume-slash",
  "icon-linear-wallet",
  "icon-linear-warning",
  "icon-bold-map",
  "icon-bold-shopping-bag",
] as const;

export type IconName = (typeof iconsList)[number];

export const Icon: FC<IIconProps & HTMLAttributes<HTMLSpanElement>> = ({
  name,
  size,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <span
      {...otherProps}
      style={{ fontSize: size }}
      className={clsx(classes.root, otherProps.className, name)}
    />
  );
};

Icon.defaultProps = {
  name: "icon-linear-warning",
};
