import { sortBy } from "lodash-es";
import {
  Activity,
  BattleInviteActivity,
  FollowStatus,
  FollowSuggestionUser,
  UserFollower,
} from "../../legacyGraphql/graphql";

export interface ICreateFriendRequestInput {
  id: string;
  username: string;
  avatar: string;
}

export interface IUserRelation {
  id: string;
  avatar: string;
  username: string;
  xp: number;
  followStatus: FollowStatus;
  criteria?: string;
  lastCheckin?: string;
  battle?: {
    battleId: string;
    gameId: string;
    gameTitle: string;
  };
}

export const userFollowToUserRelation = (
  listFollow: UserFollower[],
  myId: string
): IUserRelation[] => {
  const listFollowRelation = listFollow.map((follow) => {
    const otherUser =
      follow.receiver.id === myId ? follow.sender : follow.receiver;

    const { id, username, xp, avatar, lastCheckin, followStatus } = otherUser;

    return {
      id,
      username,
      xp,
      avatar,
      requestId: follow.id,
      lastCheckin,
      followStatus: followStatus || FollowStatus.NoFollow,
    };
  });

  return sortBy(listFollowRelation, (user) =>
    user.username.toLocaleLowerCase()
  );
};

export const getNewFollowStatus = (
  follow: IUserRelation,
  param?: { unfollow?: boolean }
): IUserRelation => {
  if (param?.unfollow) {
    if (follow.followStatus === FollowStatus.FollowingAndFollowedBy) {
      return { ...follow, followStatus: FollowStatus.FollowedBy };
    } else {
      return { ...follow, followStatus: FollowStatus.NoFollow };
    }
  } else {
    if (follow.followStatus === FollowStatus.FollowedBy) {
      return { ...follow, followStatus: FollowStatus.FollowingAndFollowedBy };
    } else {
      return { ...follow, followStatus: FollowStatus.Following };
    }
  }
};

export const userFollowSuggestionToUserRelation = (
  listFollowSuggestion: FollowSuggestionUser[]
): IUserRelation[] => {
  const listFollowSuggestionRelation = listFollowSuggestion.map((user) => {
    const { id, username, xp, avatar, criteria, lastCheckin } = user;
    return {
      id,
      username,
      xp,
      avatar,
      criteria,
      followStatus: FollowStatus.NoFollow,
      lastCheckin,
    };
  });

  return sortBy(listFollowSuggestionRelation, (user) =>
    user.username.toLocaleLowerCase()
  );
};

export const userActivityToUserRelation = (
  activities: Activity[]
): IUserRelation[] => {
  return activities.map((activity) => {
    const battleActivity = activity as BattleInviteActivity;
    const { id, username, xp, avatar, lastCheckin, followStatus } =
      battleActivity.opponentUsers[0];
    const { battleId, gameId, gameTitle } = battleActivity;

    return {
      id,
      username,
      xp,
      avatar,
      lastCheckin,
      followStatus,
      battle: {
        battleId,
        gameId,
        gameTitle,
      },
    };
  });
};
