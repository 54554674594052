import { createUseStyles } from "react-jss";
import { BorderRadius } from "../../../constants/borderRadius";
import { GridPaddingMapping } from "../../../constants/padding";
import { DarkThemeColors } from "../../../constants/colors";
import { ITheme } from "../../../types/theme";
import { NB_GEMS_FLY } from "../../../constants/endscreen";
import { SpacingMapping } from "../../../constants/spacing";
import { ZIndex } from "../../../constants/zIndex";

const mathGem = (nbGem: number) => {
  return Math.min(nbGem, NB_GEMS_FLY);
};

export const useStyles = (nbGem: number) => {
  const nbGems = mathGem(nbGem);
  return createUseStyles((theme: ITheme) => ({
    root: {
      objectFit: "cover",
      zIndex: ZIndex.normal,
      "&.game": {
        borderRadius: BorderRadius.sm,
      },
      "&.avatar": {
        borderRadius: "50%",
      },
    },
    rank: {
      position: "absolute",
      zIndex: ZIndex.normal,
      "&.m": {
        bottom: -GridPaddingMapping.xxs,
        insetInlineEnd: -GridPaddingMapping.xxs,
      },
      "&.xl": {
        bottom: -GridPaddingMapping.xs,
        insetInlineEnd: -GridPaddingMapping.xs,
      },
      "&.xxl": {
        bottom: -GridPaddingMapping.xs,
        insetInlineEnd: -GridPaddingMapping.xs,
      },
    },
    roundedAvatar: {
      display: "flex",
      position: "relative",
      width: "fit-content",
      "&.unselected": {
        padding: GridPaddingMapping.xs,
        borderRadius: BorderRadius.sm,
        backgroundColor: DarkThemeColors.Background3,
      },
      "&.selected": {
        padding: GridPaddingMapping.xs,
        borderRadius: BorderRadius.sm,
        backgroundColor: theme.palette.primary.main,
      },
    },
    checkedIcon: {
      position: "absolute",
      top: GridPaddingMapping.xxs,
      insetInlineEnd: GridPaddingMapping.xxs,
      width: 14,
      height: 14,
    },
    progress: {
      position: "absolute !important",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    status: {
      width: 12,
      height: 12,
      borderRadius: "50%",
      position: "absolute",
      zIndex: ZIndex.normal,
      insetInlineEnd: -GridPaddingMapping.xxs,
      top: -GridPaddingMapping.xxs,
      "&.Online": {
        background: DarkThemeColors.StatusOnline,
      },
      "&.Offline": {
        background: DarkThemeColors.StatusOffline,
      },
      "&.Away": {
        background: DarkThemeColors.StatusAway,
      },
      "&.m": {
        width: 10,
        height: 10,
        top: GridPaddingMapping.none,
      },
    },
    crownIcon: {
      position: "absolute !important",
      left: "50%",
      top: -SpacingMapping.space12,
      transform: "translate(-50%, -50%)",
      width: 32,
      height: 32,
      zIndex: ZIndex.normal,
    },
    rankAnimation: {
      animation: "$splash 0.1s infinite 0.4s",
      animationIterationCount: nbGems / 2,
      zIndex: ZIndex.normal + 1,
    },
    hideRank: {
      display: "none",
    },
    "@keyframes splash": {
      "0%": {
        transform: "scale(1)",
      },
      "100%": {
        transform: "scale(1.3)",
      },
    },
  }))();
};
