import apollo from "../../../clients/apollo";
import {
  getUserProfileGQL,
  getConfigGQL,
  getUserMetadataGQL,
  getUnseenRewardTransactionsGQL,
  loginRewardsGQL,
  searchUserGQL,
} from "../../queries/users";
import { getUserB2bSubscriptionGQL } from "../../queries/subcriptionb2b";

import { QueryOptions } from "@apollo/client";
import {
  FullTransaction,
  Query,
  SearchUsersInput,
  UserProfile,
  UserSubscription,
} from "../../../legacyGraphql/graphql";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();
interface ITotalCount {
  totalCount: number;
}
interface IGetUserProfileResponse {
  getUserProfile: UserProfile;
  following: ITotalCount;
  followers: ITotalCount;
}

export const getUserMetadata = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["getUserMetadata"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserMetadataGQL,
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.getUserMetadata;
};

export const getUserProfile = async (
  userId: string,
  options: Partial<QueryOptions> = {}
): Promise<IGetUserProfileResponse> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserProfileGQL,
    variables: {
      userProfileInput: { userId },
      followingInput: { userId },
      followersInput: { userId },
    },
    context: { api: ApiLegacyName.Legacy },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }

  return res.data;
};

export const getUnseenRewardTransaction = async (
  options: Partial<QueryOptions> = {}
): Promise<FullTransaction[]> => {
  const res = await apolloClient.query({
    ...options,
    query: getUnseenRewardTransactionsGQL,
    variables: {},
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.getUserV2.unseenRewardTransactions;
};

export const getUserB2bSubscription = async (
  options: Partial<QueryOptions> = {}
): Promise<UserSubscription> => {
  const res = await apolloClient.query({
    ...options,
    query: getUserB2bSubscriptionGQL,
    variables: {},
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.getUserV2.subscription;
};

export const getConfig = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["config"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getConfigGQL,
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.getConfig;
};

export const getLoginRewards = async (
  options: Partial<QueryOptions> = {}
): Promise<Query["loginRewards"]> => {
  const loginRewards = await apolloClient.query({
    ...options,
    query: loginRewardsGQL,
    context: { api: ApiLegacyName.Legacy },
  });

  return loginRewards.data.loginRewards;
};

export const searchUser = async (
  params: Partial<SearchUsersInput>,
  options: Partial<QueryOptions> = {}
): Promise<Query["users"]> => {
  const res = await apolloClient.query({
    ...options,
    query: searchUserGQL,
    variables: { searchUsersInput: params },
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.users;
};
