import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { useStyles } from "./Skeleton.style";

interface ISkeletonProps {
  variant?: "circle" | "text" | "rect";
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  backgroundLight?: boolean;
}

const SkeletonComponent: FC<
  ISkeletonProps & HTMLAttributes<HTMLSpanElement>
> = (props) => {
  const classes = useStyles();
  const {
    className,
    style,
    height,
    width,
    variant,
    backgroundLight,
    borderRadius,
    ...otherProps
  } = props;

  return (
    <span
      {...otherProps}
      className={clsx(
        classes.root,
        backgroundLight && classes.backgroundLight,
        variant === "circle" && classes.circle,
        variant === "text" && classes.text,
        className
      )}
      style={{ ...style, height, width, borderRadius }}
    />
  );
};

export const Skeleton = React.memo(SkeletonComponent);
