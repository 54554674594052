import { createUseStyles } from "react-jss";
import { SpacingMapping } from "../../../constants/spacing";
import { DarkThemeColors } from "../../../constants/colors";

export const useStyles = createUseStyles({
  root: {
    background: "#000000",
    width: "fit-content",
    padding: "5px 10px 5px 5px",
    borderRadius: 15,
  },
  info: {
    gap: SpacingMapping.space4,
  },
  freeCoin: {
    margin: `0px ${SpacingMapping.space4}px 0px ${SpacingMapping.space8}px`,
    fontSize: 38,
    fontWeight: 700,
    fontFamily: "AlumniSans",
    lineHeight: "46px",
  },
  triangle: {
    background: "#000000",
    height: 15,
    width: 15,
    position: "absolute",
    bottom: -10,
    insetInlineEnd: 40,
    transform: "rotate(45deg)",
    borderRadius: 2,
    borderBottom: `2px solid ${DarkThemeColors.Border2}`,
    borderRight: `2px solid ${DarkThemeColors.Border2}`,
  },
});
