import { gql } from "graphql-tag";

export const listLiveStreams = gql`
  query livestreams($livestreamFilters: LivestreamsFilterInput) {
    livestreams(livestreamFilters: $livestreamFilters) {
      items {
        host {
          banner
          avatar
          id
          username
        }
        currentPlayer {
          banner
          avatar
          id
          username
        }
        id
        numberOfViewers
        numberOfGameSessions
        status
        game {
          id
          gamesPlayedByUser
          type
          title
        }
        startedAt
      }
      totalCount
    }
  }
`;
