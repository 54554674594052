import apollo from "../../../clients/apollo";
import {
  getTournamentGQL,
  listMWCTournamentsGQL,
  listTournamentsGQL,
} from "../../queries/tournament";
import { QueryOptions } from "@apollo/client";
import {
  GetLeaderboardsInput,
  ListTournamentInput,
  Query,
} from "../../../legacyGraphql/graphql";
import { IParamsGetTournament } from "../../../types/tournament";
import { ApiLegacyName } from "../../../constants/apollo";

const apolloClient = apollo.getInstance();

export const getTournament = async (
  params: IParamsGetTournament,
  options: Partial<QueryOptions> = {}
): Promise<Query["getTournament"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getTournamentGQL,
    variables: {
      getTournamentInput: { id: params.id },
      getLeaderboardInput: {
        limit: params?.limit ?? 10,
      },
    },
    context: { api: ApiLegacyName.Legacy },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }
  return res.data.getTournament;
};

export const listMWCTournaments = async (
  params: {
    listTournamentInput?: Partial<ListTournamentInput>;
    getLeaderboardInput?: Partial<GetLeaderboardsInput>;
  },
  options: Partial<QueryOptions> = {}
): Promise<Query["listTournament"]> => {
  const res = await apolloClient.query({
    query: listMWCTournamentsGQL,
    variables: params,
    ...options,
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.listTournament;
};
export const listTournaments = async (
  params: {
    listTournamentInput?: Partial<ListTournamentInput>;
    getLeaderboardInput?: Partial<GetLeaderboardsInput>;
  },
  options: Partial<QueryOptions> = {}
): Promise<Query["listTournament"]> => {
  const res = await apolloClient.query({
    query: listTournamentsGQL,
    variables: params,
    ...options,
    context: { api: ApiLegacyName.Legacy },
  });

  return res.data.listTournament;
};
