import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { Livestream } from "../legacyGraphql/graphql";
import { listLivestreams } from "../legacyGraphql/resolvers/queries/liveStream";
import { AppThunk } from "../store";
import { orderBy } from "lodash-es";

interface ILivePlayers {
  livePlayers: Livestream[];
}

const initialState = {
  livePlayers: [],
} as ILivePlayers;

export const resetLivePlayersState = createAction("livePlayers/resetState");

export const livePlayersSlice = createSlice({
  name: "livePlayers",
  initialState: initialState,
  reducers: {
    setLivePlayers: (
      state,
      action: PayloadAction<{ livePlayers: Livestream[] }>
    ) => {
      state.livePlayers = action.payload.livePlayers;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetLivePlayersState, () => initialState);
  },
});

// Action creators are generated for each case reducer function
const { setLivePlayers } = livePlayersSlice.actions;

export const getLivePlayersAction = (): AppThunk => async (dispatch) => {
  const liveStream = await listLivestreams();
  const liveStreamOrdered = orderBy(
    liveStream.items,
    ["status", "startedAt"],
    ["desc", "desc"]
  );
  const uniqueUsers = [] as Livestream[];
  liveStreamOrdered.forEach((ls, index) => {
    if (index > 0 && ls.host.id == liveStreamOrdered[index - 1].host.id) {
      return;
    }
    uniqueUsers.push(ls);
  });

  dispatch(setLivePlayers({ livePlayers: uniqueUsers }));
};

export default livePlayersSlice.reducer;
