import Avatar1x from "./assets/avatar-light_1x.webp";
import Avatar2x from "./assets/avatar-light_2x.webp";
import Avatar3x from "./assets/avatar-light_3x.webp";
import Game1x from "./assets/game-error_1x.webp";
import Game2x from "./assets/game-error_2x.webp";
import Game3x from "./assets/game-error_3x.webp";

export type AvatarSizeT = "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
export const defaultProfileSrcSet = `${Avatar1x} 1x, ${Avatar2x} 2x, ${Avatar3x} 3x`;
export const defaultGameSrcSet = `${Game1x} 1x, ${Game2x} 2x, ${Game3x} 3x`;

export const AvatarWidthMapping = {
  s: 40,
  m: 60,
  l: 80,
  xl: 120,
  xxl: 163.5,
  xxxl: 255,
};

export const AvatarHeightMapping = {
  s: 55,
  m: 80,
  l: 108,
  xl: 162,
  xxl: 220.274,
  xxxl: 340,
};

export const getSizeBadge = (size: AvatarSizeT) => {
  switch (size) {
    case "s":
      return "m";
    case "m":
      return "xl";
    case "l":
      return "xxl";
  }
};

export const getSizeProgress = (size: AvatarSizeT) => {
  switch (size) {
    case "s":
      return "small";
    case "m":
      return "medium";
    case "l":
      return "large";
  }
};
