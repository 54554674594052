import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  IActionStream,
  IEmojiStreamAction,
  IEndBattleMetadata,
} from "../types/liveStream";
import { AppThunk, AsyncAppThunk, RootState } from "../store";
import { OnmoLocation } from "../models/onmoLocation";
import { compact, concat, sortBy, uniqBy } from "lodash-es";
import { LivestreamSession, PublicUser } from "../legacyGraphql/graphql";
import { searchUser } from "../legacyGraphql/resolvers/queries/users";
import { setNotificationError } from "./alert";
import { EMOJIS, MAX_PLAYER_IN_LIVE_STREAM } from "../constants/livestream";
import { System } from "../models/system";
import {
  getDifferenceViewer,
  mergeListViewer,
} from "../models/liveStream/liveStream";
import { QueryParams } from "../models/queryParam";

export const VIEWER_SESSION_SUB_STATES = {
  NONE: 0,
  STREAM_ENDED: 258,
  ERROR_LIVE_FULL_SEATS: 20,
  ERROR_LIVE_RECONNECT: 132,
};

interface ILiveStream {
  livestreamSession?: LivestreamSession;
  viewerSessionState: number;
  listViewer: PublicUser[];
  listBotViewing: PublicUser[];
  viewerControl?: PublicUser;
  host?: PublicUser;
  actionList: IActionStream[];
  emojiList: IEmojiStreamAction[];
  endBattleMetadata?: IEndBattleMetadata;
}

const initialState = {
  livestreamSession: undefined,
  viewerSessionState: VIEWER_SESSION_SUB_STATES.NONE,
  listViewer: [],
  listBotViewing: [],
  actionList: [],
  emojiList: [],
  listViewerFollowStatus: [],
  viewerControl: undefined,
  endBattleMetadata: undefined,
  host: undefined,
} as ILiveStream;

export const resetSessionLivestream = createAction("livestream/resetState");

export const livestreamSlice = createSlice({
  name: "livestream",
  initialState: initialState,
  reducers: {
    updateLivestreamSession: (
      state,
      action: PayloadAction<{ livestreamSession?: LivestreamSession }>
    ) => {
      state.livestreamSession = action.payload.livestreamSession;
    },
    updateSessionState: (
      state,
      action: PayloadAction<{ viewerSessionState: number }>
    ) => {
      state.viewerSessionState = action.payload.viewerSessionState;
    },
    setListViewer: (
      state,
      action: PayloadAction<{ listViewer: PublicUser[] }>
    ) => {
      state.listViewer = action.payload.listViewer;
    },
    setListBotViewer: (
      state,
      action: PayloadAction<{ listBotViewing: PublicUser[] }>
    ) => {
      state.listBotViewing = action.payload.listBotViewing;
    },
    setViewerControl: (
      state,
      action: PayloadAction<{ viewerControl?: PublicUser }>
    ) => {
      state.viewerControl = action.payload.viewerControl;
    },
    setHost: (state, action: PayloadAction<{ host?: PublicUser }>) => {
      state.host = action.payload.host;
    },
    pushActionList: (
      state,
      action: PayloadAction<{ action: IActionStream }>
    ) => {
      state.actionList = state.actionList
        .concat(action.payload.action)
        .slice(-100);
    },
    pushEmojiList: (
      state,
      action: PayloadAction<{ emoji: IEmojiStreamAction }>
    ) => {
      state.emojiList = state.emojiList
        .concat(action.payload.emoji)
        .slice(-100);
    },
    resetEmojiList: (
      state,
      action: PayloadAction<{ emojiList: IEmojiStreamAction[] }>
    ) => {
      state.emojiList = action.payload.emojiList || [];
    },
    resetActionList: (
      state,
      action: PayloadAction<{ action: IActionStream[] }>
    ) => {
      state.actionList = action.payload.action || [];
    },
    popHelpAction: (state) => {
      state.actionList = state.actionList.filter(
        (item) => item.action !== "ls_needs help"
      );
    },
    setEndBattleMetadata: (
      state,
      action: PayloadAction<{ endBattleMetadata?: IEndBattleMetadata }>
    ) => {
      state.endBattleMetadata = action.payload.endBattleMetadata;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetSessionLivestream, () => initialState);
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLivestreamSession,
  updateSessionState,
  setListViewer,
  setHost,
  setViewerControl: setViewerControlAction,
  pushActionList,
  resetActionList,
  popHelpAction,
  setEndBattleMetadata,
  pushEmojiList,
  setListBotViewer,
  resetEmojiList,
} = livestreamSlice.actions;

export const setViewerControl =
  (viewerControl?: PublicUser): AsyncAppThunk =>
  async (dispatch, getState) => {
    const currentViewerControl = getState().liveStream.listViewer.find(
      (viewer) => viewer.id === viewerControl?.id
    );

    dispatch(
      setViewerControlAction({
        viewerControl: currentViewerControl || viewerControl,
      })
    );
  };
export const pushSimulateEmoji =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const liveStreamState = getState().liveStream;
    if (!liveStreamState.listBotViewing.length) return;
    const randomBotViewer = System.getRandomValue(
      liveStreamState.listBotViewing
    );
    const newEmoji: IEmojiStreamAction = {
      action: System.getRandomValue(EMOJIS).value,
      sentAt: Date.now(),
      sender: randomBotViewer,
    };
    dispatch(pushEmojiList({ emoji: newEmoji }));
  };

export const fetchLiveStreamFollowStatus =
  (): AsyncAppThunk => async (dispatch, getState) => {
    const liveStreamState = getState().liveStream;
    if (!liveStreamState.listViewer.length) return;
    try {
      const listViewerId = liveStreamState.listViewer.map(
        (viewer) => viewer.id
      );
      const listViewer = await searchUser({
        userIds: listViewerId,
      });

      dispatch(setListViewer({ listViewer: listViewer.items }));
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
    }
  };

export const fetchHostFollowStatus =
  (host: PublicUser): AsyncAppThunk =>
  async (dispatch) => {
    try {
      const user = await searchUser({ userIds: [host.id] });
      dispatch(setHost({ host: user.items[0] }));
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
    }
  };

export const fetchViewerStatus =
  (newViewer: PublicUser): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      const newViewerData = await searchUser({ userIds: [newViewer.id] });
      const liveStreamState = getState().liveStream;

      const isBotViewer = !!liveStreamState.listBotViewing.find(
        (viewer) => viewer.id === newViewer.id
      );

      if (liveStreamState.host?.id === newViewer.id) {
        dispatch(setHost({ host: newViewerData.items[0] }));
        return;
      } else if (!isBotViewer) {
        const newViewerList = liveStreamState.listViewer?.map((viewer) => {
          if (viewer.id === newViewer.id) {
            return newViewerData.items[0];
          } else return viewer;
        });
        dispatch(setListViewer({ listViewer: newViewerList }));
      } else {
        const newBotViewerList = liveStreamState.listBotViewing?.map(
          (viewer) => {
            if (viewer.id === newViewer.id) {
              return newViewerData.items[0];
            } else return viewer;
          }
        );
        dispatch(setListBotViewer({ listBotViewing: newBotViewerList }));
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
    }
  };
export const fetchNewBotViewerStatus =
  (newViewer: PublicUser): AsyncAppThunk =>
  async (dispatch, getState) => {
    try {
      const newViewerData = await searchUser({ userIds: [newViewer.id] });
      const liveStreamState = getState().liveStream;
      dispatch(
        setListBotViewer({
          listBotViewing: liveStreamState.listBotViewing.concat(
            newViewerData.items
          ),
        })
      );
      dispatch(
        pushActionList({
          action: {
            action: "ls_joined",
            sender: newViewerData.items[0],
            sentAt: Date.now(),
          },
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(setNotificationError(e.message));
      }
    }
  };

export const botLeaveAction = (): AppThunk => async (dispatch, getState) => {
  const state = getState();

  if (
    !QueryParams.isOnboardingMoment() &&
    !state.liveStream.livestreamSession
  ) {
    return;
  }

  const { listBotViewing } = state.liveStream;
  if (!listBotViewing.length) return;

  const randomBotViewer = System.getRandomValue(listBotViewing);
  dispatch(
    pushActionList({
      action: {
        action: "ls_left",
        sender: randomBotViewer,
        sentAt: Date.now(),
      },
    })
  );
  dispatch(
    setListBotViewer({
      listBotViewing: listBotViewing.filter((v) => v.id !== randomBotViewer.id),
    })
  );
};

export const onboardingBotJoinAction =
  (listBotUser: PublicUser[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { listBotViewing } = state.liveStream;

    const listBotUserLeft = listBotUser.filter(
      (v) => !listBotViewing.includes(v)
    );
    const randomUser = System.getRandomValue(listBotUserLeft);
    pushActionList({
      action: {
        action: "ls_joined",
        sender: randomUser,
        sentAt: Date.now(),
      },
    });

    dispatch(
      setListBotViewer({
        listBotViewing: listBotViewing.concat(randomUser),
      })
    );
  };

export const botJoinAction = (): AppThunk => async (dispatch, getState) => {
  const state = getState();

  if (
    !QueryParams.isOnboardingMoment() &&
    !state.liveStream.livestreamSession
  ) {
    return;
  }

  const { listBotViewing, listViewer } = state.liveStream;
  if (
    listBotViewing.length + listViewer.length >=
    MAX_PLAYER_IN_LIVE_STREAM - 1
  ) {
    return;
  }

  const listSimulatedUsers =
    state.theme.theme.pages.game?.simulatedUsers?.split(",");

  const listUserSimulateLeft = listSimulatedUsers?.filter(
    (userId) =>
      !listBotViewing.find((viewer) => viewer.id === userId) &&
      !listViewer.find((viewer) => viewer.id === userId)
  );

  if (!listUserSimulateLeft?.length) return;

  const randomUser = {
    id: System.getRandomValue(listUserSimulateLeft),
  } as PublicUser;

  dispatch(fetchNewBotViewerStatus(randomUser));
};

export const sendRandomViewerAction =
  (listBotUser: PublicUser[]): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { listViewer, listBotViewing } = state.liveStream;
    const isMaximumViewer =
      listViewer.length + listBotViewing.length + 1 > MAX_PLAYER_IN_LIVE_STREAM;

    if (isMaximumViewer) {
      if (listBotViewing.length) {
        // bot leave if total viewer is more than MAX
        dispatch(botLeaveAction());
      } else {
        // total viewer is MAX
        return;
      }
    } else if (!listBotViewing.length) {
      // bot join if total viewer is less than MAX and no bot
      dispatch(onboardingBotJoinAction(listBotUser));
    } else {
      // random join or leave
      if (Math.random() < 0.5) {
        dispatch(botLeaveAction());
      } else {
        dispatch(onboardingBotJoinAction(listBotUser));
      }
    }
  };

export const updateListViewer =
  (newListViewer: PublicUser[]): AsyncAppThunk =>
  async (dispatch, getState) => {
    const liveStreamState = getState().liveStream;
    if (!liveStreamState.host?.id) return;

    dispatch(
      setListViewer({
        listViewer: mergeListViewer(
          liveStreamState.listViewer,
          newListViewer,
          liveStreamState.host,
          liveStreamState.viewerControl
        ),
      })
    );

    const newViewer = getDifferenceViewer(
      newListViewer,
      liveStreamState.listViewer,
      liveStreamState.viewerControl,
      liveStreamState.host
    );
    const leftViewer = getDifferenceViewer(
      liveStreamState.listViewer,
      newListViewer,
      liveStreamState.viewerControl,
      liveStreamState.host
    );

    if (newViewer?.length || leftViewer?.length) {
      dispatch(
        pushActionList({
          action: {
            action: newViewer?.length ? "ls_joined" : "ls_left",
            sender: newViewer?.length ? newViewer[0] : leftViewer[0],
            sentAt: Date.now(),
          },
        })
      );
    }

    if (newViewer?.[0]) {
      dispatch(fetchViewerStatus(newViewer[0]));
      const totalUser =
        liveStreamState.listBotViewing.length + newListViewer.length + 1;
      if (totalUser > MAX_PLAYER_IN_LIVE_STREAM) {
        dispatch(botLeaveAction());
      }
    }
  };

export const updateEndBattleMetadata =
  (endBattleMetadata?: string): AsyncAppThunk =>
  async (dispatch) => {
    const endBattleMetadataParsed = endBattleMetadata
      ? JSON.parse(endBattleMetadata || "undefined")
      : undefined;

    dispatch(
      setEndBattleMetadata({ endBattleMetadata: endBattleMetadataParsed })
    );
  };

export const isControllingStream = createSelector(
  [(state: RootState) => state.liveStream, (state: RootState) => state.user],
  (liveStreamState, userState) => {
    const isViewStream = OnmoLocation.isViewLivestream();

    const isControlStream = !isViewStream
      ? !liveStreamState.viewerControl
      : liveStreamState.viewerControl?.id === userState.me?.id;

    return isControlStream;
  }
);

export const listMessageSelector = createSelector(
  [(state: RootState) => state.liveStream.actionList],
  (actionList) => {
    return sortBy(actionList, (a) => a.action === "ls_needs help");
  }
);
export const listUserSelector = createSelector(
  [(state: RootState) => state.liveStream],
  (livestream) => {
    const { host, viewerControl, listViewer, listBotViewing } = livestream;
    return compact(
      uniqBy(concat(host, listViewer, listBotViewing, viewerControl), "id")
    );
  }
);

export const isViewerDisconnectedSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return (
      viewerSessionState === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_RECONNECT
    );
  }
);
export const isStreamEndedSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return viewerSessionState === VIEWER_SESSION_SUB_STATES.STREAM_ENDED;
  }
);
export const isStreamFullSelector = createSelector(
  [(state: RootState) => state.liveStream.viewerSessionState],
  (viewerSessionState) => {
    return (
      viewerSessionState === VIEWER_SESSION_SUB_STATES.ERROR_LIVE_FULL_SEATS
    );
  }
);

export default livestreamSlice.reducer;
