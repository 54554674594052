import ShopBannerImage from "../components/common/Featured/assets/ONMO_Landing_Banner.webp";
import { FeaturedType } from "../constants/tournaments";
import { Contest } from "../legacyGraphql/graphql";
import { Game } from "../graphql/graphql";
import { Featured } from "../slices/discover";
import { FeatureItem } from "../types/tournament";
import { findGameImage } from "./game/game";
import { get } from "lodash-es";

const createBanner = (bannerImageUrl: string, bannerRedirectUrl?: string) => ({
  id: "Banner",
  imageUrl: bannerImageUrl,
  url: bannerRedirectUrl ?? undefined,
  __typename: "Banner",
});

const createShopBanner = () => ({
  id: "ShopBanner",
  imageUrl: ShopBannerImage,
  __typename: "ShopBanner",
});

const getQualifiedContests = (contests?: Contest[]) =>
  contests?.reduce((list: Featured, item) => {
    if (item.isQualified) {
      list.push({
        id: "Contest",
        imageUrl: item.banner,
        url: item.url,
        __typename: "Contest",
      });
    }
    return list;
  }, []);

const getImageBg = (listGame: Game[], item: FeatureItem) => {
  const { type, data } = item;
  const imageUrl = get(data, "imageUrl");

  const getGameImageUrl = (gameId: string) =>
    findGameImage(listGame, { gameId, type: "big" }).webp3x;

  switch (type) {
    case FeaturedType.Game:
      return `url(${getGameImageUrl(data.id)})`;
    case FeaturedType.Tournament:
      return `url(${imageUrl ?? getGameImageUrl(data.gameId)})`;
    case FeaturedType.Contest:
    case FeaturedType.Banner:
      return `url(${imageUrl})`;
    default:
      return `url(${imageUrl ?? getGameImageUrl(data.id)})`;
  }
};

export const Feature = {
  createBanner,
  createShopBanner,
  getQualifiedContests,
  getImageBg,
};
