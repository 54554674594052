import { createUseStyles } from "react-jss";
import { BorderRadius } from "../../../constants/borderRadius";
import { LegacyColor } from "../../../constants/colors";

export const useStyles = createUseStyles({
  root: {
    margin: 0,
    padding: 20,
    boxSizing: "border-box",
    backgroundColor: LegacyColor.color5,
    color: LegacyColor.color14,
    "-webkit-transition": "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: BorderRadius.md,
    boxShadow: `0 1px 2px 0 ${LegacyColor.color4}`,
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,
    textAlign: "center",
  },
});
