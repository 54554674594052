import { createUseStyles } from "react-jss";
import { DarkThemeColors } from "../../../constants/colors";
import { ZIndex } from "../../../constants/zIndex";
import { ITheme } from "../../../types/theme";

export const useStyles = createUseStyles((theme: ITheme) => ({
  root: {
    top: 0,
    insetInlineStart: 0,
    width: "100%",
    position: "fixed",
    "&.s-success": {
      background: theme.palette.primary.main,
    },
    "&.s-warning": {
      background: DarkThemeColors.Warning,
    },
    "&.s-error": {
      background: DarkThemeColors.Error,
    },
    padding: 15,
    zIndex: ZIndex.dialog + 3,
  },
  icon: {
    height: 28,
  },
  message: {
    width: "100%",
    textAlign: "center",
    marginInlineStart: 5,
  },
}));
