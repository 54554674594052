import React, { HTMLAttributes, FC } from "react";
import clsx from "classnames";
import { useStyles } from "./OnmoLoading.style";
import LogoIconWebp from "../../../assets/login/webp/logo.webp";
import { Grid, Box } from "../../core";
import { useTranslation } from "react-i18next";
import {
  TypographyColor,
  TypographyVariant,
  Typography,
} from "../../core/Typography/Typography";
import { useAppSelector } from "../../../hooks";

interface IOnmoLoadingProps {
  textLoading?: string;
}

const OnmoLoadingComponent: FC<
  IOnmoLoadingProps & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const classes = useStyles();
  const { textLoading, ...otherProps } = props;
  const isSplashOpen = useAppSelector((state) => state.splashScreen.isOpen);
  const { t } = useTranslation();

  if (isSplashOpen) {
    return null;
  }

  return (
    <Box
      {...otherProps}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      className={clsx(classes.container, props.className)}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.spinner} />
        <img
          src={LogoIconWebp}
          height={15}
          alt="onmo-logo"
          className={classes.logo}
        />
      </Grid>
      <Typography
        variant={TypographyVariant.BodyB5Bold}
        className={classes.textLoading}
        color={TypographyColor.DarkDefault}
      >
        {textLoading || t("Loading...")}
      </Typography>
    </Box>
  );
};

export const OnmoLoading = React.memo(OnmoLoadingComponent);
